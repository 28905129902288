import { Icon, Text } from "app/components";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import { resizeImage } from "app/utils/utils";
import styled from "styled-components";
import { useState } from "react";

const sizeMap = {
  small: "35px",
  medium: "50px",
  large: "65px",
};

const NoImageWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: ${(p) => p.width || get(sizeMap, p.size)};
  height: ${(p) => p.height || get(sizeMap, p.size)};
  border: 1px solid ${colors.grey2};
  background: ${colors.grey1};
  border-radius: 8px;
`;

const Thumb = styled.img`
  width: ${(p) => get(sizeMap, p.size)};
  height: ${(p) => get(sizeMap, p.size)};
  border: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightGrey : colors.grey2)};
  border-radius: 8px;
  object-fit: ${(p) => p.objectFit || "cover"};

  ${(p) =>
    p.forceSize &&
    `
    width: ${p.forceSize};
    height: ${p.forceSize};
  `}
`;

export const NoImage = ({ size, height, width, iconSize }) => (
  <NoImageWrapper size={size} height={height} width={width}>
    <Icon
      data={{ icon: "FiImage", color: colors.grey2, size: iconSize || 30 }}
    />
  </NoImageWrapper>
);

const Thumbnail = ({ src, size, objectFit, darkMode, forceSize = null }) => {
  const [error, setError] = useState(false);

  if (!src || error) {
    return <NoImage size={size} darkMode={darkMode} />;
  }

  return (
    <Thumb
      darkMode={darkMode}
      forceSize={forceSize}
      src={resizeImage({
        url: src,
        height: 100,
        quality: 60,
        forceJpg: true,
      })}
      size={size}
      objectFit={objectFit}
      onError={() => setError(true)}
    />
  );
};

export default Thumbnail;
