import { get, isNil } from "lodash";

export const Button = {
  componentId: "Button",
  resources: [
    {
      id: "text",
      label: "Text",
      componentId: "DynamicString",
      section: "content",
      defaultValue: "New Button",
      useDefault: true,
    },
    {
      id: "buttonColor",
      label: "Background Color",
      section: "background",
      componentId: "ColorPicker",
      orientation: "horizontal",
      defaultValue: "",
      isStyle: true,
    },
    {
      id: "clickAction",
      label: "Click Action",
      componentId: "Action",
      section: "actions",
      isAction: true,
    },
    {
      id: "fontColor",
      label: "Font Color",
      section: "font",
      componentId: "ColorPicker",
      orientation: "horizontal",
      defaultValue: "",
      isStyle: true,
    },
    {
      id: "fontSize",
      label: "Font Size",
      section: "font",
      componentId: "Select",
      defaultValue: "14",
      hideEmptyItem: true,
      orientation: "horizontal",
      width: "120px",
      options: [
        {
          value: "14",
          label: "14",
        },
        {
          value: "16",
          label: "16",
        },
        {
          value: "18",
          label: "18",
        },
        {
          value: "20",
          label: "20",
        },
        {
          value: "24",
          label: "24",
        },
        {
          value: "28",
          label: "28",
        },
      ],
      isStyle: true,
    },
    {
      id: "fontWeight",
      label: "Font Weight",
      section: "font",
      componentId: "Select",
      defaultValue: "500",
      hideEmptyItem: true,
      orientation: "horizontal",
      width: "120px",
      isStyle: true,
      options: [
        {
          value: "500",
          label: "Normal",
        },
        {
          value: "600",
          label: "Bold",
        },
      ],
    },
    {
      id: "icon",
      label: "Icon",
      componentId: "IconBrowser",
      section: "icon",
      orientation: "horizontal",
      isStyle: true,
    },
    {
      id: "flippedIcon",
      label: "Flip Icon Position",
      componentId: "Switch",
      section: "icon",
      orientation: "horizontal",
      isStyle: true,
    },
    {
      id: "borderRadius",
      label: "Border Radius",
      type: "number",
      section: "border",
      isStyle: true,
      hint: "The border radius of the image in pixels (to create a curved edge)",
      componentId: "Input",
    },
    // This is hiden now unless someone is actively using it
    {
      id: "type",
      label: "Type",
      componentId: "Select",
      section: "styling",
      defaultValue: "primary",
      isStyle: true,
      hideEmptyItem: true,
      options: [
        { value: "primary", label: "Primary" },
        { value: "secondary", label: "Secondary" },
        // { value: "danger", label: "Danger" },
      ],
      displayCondition: (b) =>
        !isNil(get(b, "type")) && get(b, "type") !== "primary",
    },
  ],
};
