import { Button, Menu } from "app/components";
import { get, startCase } from "lodash";
import {
  getHighest,
  migrateConditionFormat,
  operatorLabelMap,
  operators,
} from "app/utils/utils";

import { AdminForm } from "app/adminApp/components";
import ItemBubble from "../MultiAddForm/ItemBubble";
import { useState } from "react";

export const getConditionLabel = (item) => {
  const operatorMap = {
    equals: "=",
    does_not_equal: "!=",
    greater_than: ">",
    less_than: "<",
    length_greater_than: "len >",
    length_less_than: "len <",
    date_after: ">=", // assuming inclusivity
    date_before: "<=", // assuming inclusivity
  };

  const operator = get(item, "operator", "equals");
  const displayOperator = get(operatorMap, operator) || startCase(operator);
  const value1 = startCase(get(item, "value1"));
  const value2 = startCase(get(item, "value2"));

  if (["exists", "does_not_exist", "is_true", "is_false"].includes(operator)) {
    return `${value1} ${displayOperator}`;
  }

  if (value1 && value2) {
    return `${value1} ${displayOperator} ${value2}`;
  }

  return "New Condition";
};

const DisplayConditions = ({ data }) => {
  const items = get(data, "value", []).map((i) => migrateConditionFormat(i));

  const [anchorElement, setAnchorElement] = useState(null);
  const [activeItemId, setActiveItemId] = useState(null);

  const activeItem = items.find((f) => f.id === activeItemId);

  const fields = [
    {
      id: "value1",
      key: "value1",
      label: "Value 1",
      hint: "The value to compare with value 2 in this condition",
      componentId: "DynamicString",
      value: get(activeItem, "value1"),
    },
    {
      id: "operator",
      key: "operator",
      label: "Operator",
      componentId: "Select",
      defaultValue: "equals",
      hideEmptyItem: true,
      value: get(activeItem, "operator"),
      options: operators.map((o) => ({
        label: get(operatorLabelMap, o),
        value: o,
      })),
    },
    {
      id: "value2",
      key: "value2",
      label: "Value 2",
      hint: "The value to compare with value 1 in this condition",
      componentId: "DynamicString",
      value: get(activeItem, "value2"),
      displayCondition: () =>
        !["exists", "does_not_exist", "is_true", "is_false"].includes(
          activeItem?.operator
        ),
    },
    {
      id: "conditionType",
      key: "conditionType",
      label: "Condition Type",
      hint: "How you want your condition to be applied, relative to the other conditions.",
      componentId: "Select",
      value: get(activeItem, "conditionType", "and"),
      options: [
        {
          label: "And",
          value: "and",
        },
        {
          label: "Or",
          value: "or",
        },
      ],
      displayCondition: () => !get(data, "hideConditionType", false),
    },
  ].filter(
    (f) => !f.displayCondition || (f.displayCondition && f.displayCondition(f))
  );

  // TODO - Add 'calculatorInput' to the available data Sources in the injector

  return (
    <div>
      {anchorElement && activeItem && (
        <Menu
          anchorElement={anchorElement}
          hide={() => {
            setAnchorElement(null);
            setActiveItemId(null);
          }}
          label={`Edit ${data.labelSingular || "Condition"}`}
          minWidth={"300px"}
        >
          <AdminForm
            excludedSources={get(data, "excludedSources", [])}
            dynamicSources={get(data, "dynamicSources", [])}
            previousSteps={get(data, "previousSteps", [])}
            sectionPadding="0px"
            onChange={(k, v) =>
              data.onChange(
                items.map((f, i) => {
                  if (f.id === activeItemId) {
                    return { ...f, [k]: v };
                  }
                  return f;
                })
              )
            }
            fields={fields}
          />
        </Menu>
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {items.map((f, id) => (
          <div key={id}>
            <ItemBubble
              data={f}
              label={getConditionLabel(f)}
              icon={data.icon}
              type={data.id}
              onClick={(e) => {
                setAnchorElement(e.currentTarget);
                setActiveItemId(f.id);
              }}
              onDelete={() =>
                data.onChange(items.filter((fi) => fi.id !== f.id))
              }
            />
          </div>
        ))}
      </div>
      <Button
        data={{
          text: "Add Condition",
          onClick: () =>
            data.onChange([
              ...items,
              {
                id: getHighest(items, "id") + 1,
              },
            ]),
          size: "small",
          type: "basic",
          icon: "FiPlus",
          margin: "8px 0px 0px 0px",
        }}
      />
    </div>
  );
};

export default DisplayConditions;
