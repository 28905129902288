import AdminForm from "app/adminApp/components/AdminForm";
import { PanelHeader } from "./PageStyles";
import { Text } from "app/components";
import { get } from "lodash";
import { rShowStylesPanel } from "app/utils/recoil";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import { useRecoilState } from "recoil";

const CustomBlockStyles = () => {
  const { activeCustomBlock, setActiveCustomBlock } = useActiveCustomBlock();

  const [showStylesPanel, setShowStylesPanel] =
    useRecoilState(rShowStylesPanel);

  const fields = [
    {
      id: "containerType",
      label: "Container Type",
      componentId: "Select",
      hint: "Choose how your blocks will be laid out in the container",
      value: get(activeCustomBlock, "containerType"),
      placeholder: "25",
      orientation: "horizontal",
      width: "120px",
      section: "spacing",
      displayCondition: () => get(activeCustomBlock, "repeating"),
      hideEmptyItem: true,
      defaultValue: "grid",
      options: [
        {
          label: "List",
          value: "list",
        },
        {
          label: "Grid",
          value: "grid",
        },
        {
          label: "Carousel",
          value: "carousel",
        },
      ],
    },
    {
      id: "gap",
      label: "Block Spacing",
      componentId: "Input",
      hint: "The distance between the root blocks on your page, in px",
      value: get(activeCustomBlock, "gap"),
      placeholder: "25",
      orientation: "horizontal",
      width: "120px",
      section: "spacing",
      displayCondition: () => get(activeCustomBlock, "repeating"),
    },
    {
      id: "minWidth",
      label: "Min Item Width",
      componentId: "Input",
      hint: "The minimum width of each item in the repeating block, in px",
      value: get(activeCustomBlock, "minWidth"),
      placeholder: "300",
      orientation: "horizontal",
      width: "120px",
      section: "spacing",
      displayCondition: () => get(activeCustomBlock, "repeating"),
    },
  ].filter(
    (f) => !f.displayCondition || (f.displayCondition && f.displayCondition())
  );

  return (
    <div>
      <PanelHeader
        label="Container Styles"
        expanded={showStylesPanel}
        setExpanded={setShowStylesPanel}
      />
      {fields.length === 0 && (
        <Text
          data={{
            margin: "20px",
            text: "No styles available for this block",
          }}
        />
      )}
      <AdminForm
        fields={fields}
        orientation={"horizontal"}
        borderBottom={true}
        onChange={(k, v) => setActiveCustomBlock({ [k]: v })}
      />
    </div>
  );
};

export default CustomBlockStyles;
