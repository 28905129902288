import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { colorSchemes } from "./utils";
import { get } from "lodash";

const ApexChart = ({ data }) => {
  const { labels, series, type = "column" } = data;

  const [fetching, setFetching] = useState(false);

  // decimalsInFloat: 0,

  const xAxisType = "category"; // datetime

  const isStacked = get(data, "isStacked");

  const showLabels = get(data, "showLabels");

  const showLegend = get(data, "showLegend");

  const typeMap = {
    AreaApex: "area",
    LineApex: "line",
    BarApex: "bar",
    ColumnApex: "bar",
  };

  const matchedType = get(typeMap, type);

  const config = {
    series,
    options: {
      chart: {
        height: 450,
        stacked: isStacked,
        type: matchedType,
      },
      plotOptions: {
        bar: {
          horizontal: type === "ColumnApex" ? false : true,
          // columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: showLabels,
      },
      colors: get(colorSchemes, 1),
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: xAxisType,
        categories: labels,
      },
      // This is used to manipulate the tooltip that appears
      //   tooltip: {
      //     x: {
      //       format: "dd/MM/yy HH:mm",
      //     },
      //   },
      legend: showLegend && {
        position: "top",
        horizontalAlign: "left",
      },
    },
  };

  const swap = async () => {
    setFetching(true);
    await new Promise((resolve) => setTimeout(resolve, 100));
    setFetching(false);
  };

  useEffect(() => {
    swap();
  }, [matchedType]);

  if (fetching) {
    return <div>Fetching</div>;
  }

  return (
    <Chart
      options={config.options}
      series={config.series}
      type={matchedType}
      width="100%"
      height={"350px"}
    />
  );
};

export default ApexChart;
