import { Icon, Text } from "app/components";

import styled from "styled-components";

const BackButton = ({
  onClick,
  background,
  color,
  text = "Back",
  width,
  margin,
}) => {
  return (
    <BackRow onClick={onClick} margin={margin} width={width}>
      <IconWrapper background={background}>
        <Icon
          data={{
            icon: "FiArrowLeft",
            color: color || "#6E7175",
            hover: true,
          }}
        />
      </IconWrapper>
      <Text
        data={{
          text,
          fontStyle: "headingLg",
          cursor: "pointer",
          color: color || "#6E7175",
        }}
      />
    </BackRow>
  );
};

export default BackButton;

const BackRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  margin: ${(p) => p.margin || "0 0 20px 0"};
  width: ${(p) => p.width || "140px"};
  &:hover {
    opacity: 0.8;
  }
`;

const IconWrapper = styled.div`
  background: ${(p) => p.background || "#e4e5e7"};
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
