import { Navigate, Outlet } from "react-router-dom";

import Cookies from "js-cookie";
import React from "react";

const PrivateRoute = () => {
  const accessToken = Cookies.get("accessToken");

  const getRedirectRoute = () => {
    return "/login";
  };
  if (accessToken) {
    return <Outlet />;
  } else {
    localStorage.removeItem("currentSubdomain");
  }
  return <Navigate to={getRedirectRoute()} />;
};

export default PrivateRoute;
