import { label, labelColor } from "./shared";

export const Chatbot = {
  componentId: "Chatbot",
  resources: [
    // label,
    // labelColor,
    {
      id: "systemPrompt",
      label: "System Prompt",
      componentId: "TextArea",
      hint: "Set the initial context or behavior for the assistant.",
      section: "settings",
    },
    {
      id: "initialMessage",
      label: "Initial Message",
      componentId: "TextArea",
      hint: "Set an initial message to start the conversation.",
      section: "settings",
    },
    {
      id: "placeholder",
      label: "Input Placeholder",
      componentId: "Input",
      hint: "Placeholder text for the user input field.",
      section: "settings",
      defaultValue: "Type a message...",
    },
    {
      id: "sendButtonText",
      label: "Send Button Text",
      componentId: "Input",
      hint: "Text for the send button.",
      section: "settings",
      defaultValue: "Send",
    },
    {
      id: "assistantName",
      label: "Assistant Name",
      componentId: "Input",
      hint: "Name displayed for the assistant in the chat.",
      section: "settings",
      defaultValue: "Assistant",
    },
    {
      id: "userName",
      label: "User Name",
      componentId: "Input",
      hint: "Name displayed for the user in the chat.",
      section: "settings",
      defaultValue: "You",
    },
    {
      id: "showTimestamps",
      label: "Show Timestamps",
      componentId: "Switch",
      hint: "Toggle to show or hide timestamps for messages.",
      section: "settings",
      defaultValue: true,
    },
    {
      id: "chatHistoryLimit",
      label: "Chat History Limit",
      componentId: "Input",
      type: "number",
      hint: "Maximum number of messages to display (0 for unlimited).",
      section: "settings",
      defaultValue: 0,
    },
    {
      id: "textColor",
      label: "Text Color",
      componentId: "ColorPicker",
      hint: "Default text color for messages.",
      section: "chatBubbles",
      isStyle: true,
      defaultValue: "#000000",
    },
    {
      id: "assistantBubbleColor",
      label: "Assistant Bubble Color",
      componentId: "ColorPicker",
      hint: "Background color for the assistant's messages.",
      section: "chatBubbles",
      isStyle: true,
      defaultValue: "#EDEDED",
    },
    {
      id: "userBubbleColor",
      label: "User Bubble Color",
      componentId: "ColorPicker",
      hint: "Background color for the user's messages.",
      section: "chatBubbles",
      isStyle: true,
      defaultValue: "#E5F2FF",
    },
  ],
};
