import axios from "axios";

export const getCoordinatesFromAddress = async (address, key) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${key}`;

  const { data } = await axios.get(url);

  if (!data || data.status === "ZERO_RESULTS") {
    throw new Error("Could not find the location for the given address.");
  }

  const coordinates = data.results[0].geometry.location;

  return coordinates;
};

export const parseCoordinates = (coords) => {
  if (typeof coords.lat === "string" && typeof coords.lng === "number") {
    return new window.google.maps.LatLng(parseFloat(coords.lat), coords.lng);
  } else if (typeof coords.lat === "number" && typeof coords.lng === "string") {
    return new window.google.maps.LatLng(coords.lat, parseFloat(coords.lng));
  } else if (typeof coords.lat === "string" && typeof coords.lng === "string") {
    return new window.google.maps.LatLng(
      parseFloat(coords.lat),
      parseFloat(coords.lng)
    );
  }
  return new window.google.maps.LatLng(coords.lat, coords.lng);
};
