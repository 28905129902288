import { Button, Icon, Input, Menu, Row } from ".";
import { colors, fontObject } from "app/utils/theme";
import { getDateFormatString, getPixels } from "app/utils/utils";
import {
  rApp,
  rAppDateFormat,
  rDarkMode,
  rSavedSpreadsheets,
  rTranslations,
} from "app/utils/recoil";
import { useEffect, useState } from "react";

import CustomFilterButton from "./CustomFilterButton";
import SimpleCalendar from "./SimpleCalendar";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const Container = styled.div`
  display: flex;
  margin: ${(p) => p.margin || "0px"};
  flex-direction: row;
  width: ${(p) => p.width || "auto"};
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  padding-right: 10px;
  background: ${(p) => p.background};
  border: 1px solid ${(p) => p.borderColor || colors.pitchBorder};
  border-width: ${(p) => p.borderWidth};
`;

const SearchInput = styled.input`
  width: 100%;
  border: 0px;
  background: transparent;
  font-size: 15px;
  color: ${(p) => p.color || (p.darkMode ? "white" : colors.grey4)};
  padding: ${(p) => p.padding || "10px"};
  font-size: ${(p) => getPixels(p.fontSize || 14)};
  font-weight: ${(p) => p.fontWeight || 400};
  outline: 0px;
  &::placeholder {
    color: ${(p) => p.placeholderColor || colors.grey3};
  }

  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: ${(p) => p.placeholderColor || colors.grey3};
  }
`;

const DateTimePicker = ({ data }) => {
  const {
    width,
    onChange,
    margin,
    color,
    background = "white",
    borderWidth,
    borderRadius,
    borderColor,
    setupMode,
    padding,
    placeholderColor,
    disableTime = false,
    isRange = false,
  } = data;

  const [anchor, setAnchor] = useState(false);
  const [screen, setScreen] = useState("calendar");

  // DATE RANGE STUFF
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const value = get(data, "value", "");

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const app = useRecoilValue(rApp);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const primaryColor = get(app, "primary_color") || colors.primary;

  const spreadsheetColumnDateFormat = get(
    savedSpreadsheets.find((s) => s.id === data.blockSpreadsheetId),
    ["field_data", "config", data.key, "dateFormat"]
  );

  // For handling the rate range picker... for now
  let custom = disableTime ? { inputTime: null, outputTime: null } : {};

  const dateFormat = {
    ...appDateFormat,
    ...spreadsheetColumnDateFormat,
    ...get(data, "dateFormat", {}),
    ...custom,
  };

  const inputDate = get(dateFormat, "inputDate");

  useEffect(() => {
    if (isRange) {
      if (value) {
        const [start, end] = value.split("|");
        setStartDate(start || "");
        setEndDate(end || "");
      } else {
        setStartDate("");
        setEndDate("");
      }
    }
  }, [value, inputDate]);

  const [dateRangeType, setDateRangeType] = useState("start");

  const formatString = getDateFormatString(dateFormat);
  const parsedDate = moment(value, formatString);

  // Decide whether to show time
  const views = getDateFormatViews(dateFormat);
  const outputTime = get(dateFormat, "outputTime");
  const showAmPm = outputTime !== "none" && get(dateFormat, "showAmPm", false);

  const showTime = views.includes("hours") && views.includes("minutes");

  // If value is not set, use today
  const finalDate = value ? parsedDate : moment();

  const hours = showAmPm
    ? ["12", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"]
    : [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];

  const minutes = [
    "00",
    "05",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
  ];

  // This will always be in 24 time, but mayble need to adjust it in other spots
  const currentHour = parseInt(parsedDate.format("HH"));
  const currentMinute = parsedDate.format("mm");

  const currentHourAdjusted = parseInt(
    parsedDate.format(showAmPm ? "h" : "HH")
  );

  const currentMonth = parsedDate.format("MMMM");
  const currentYear = parsedDate.format("YYYY");

  const fontStyle = get(data, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);

  // Scroll to active year
  useEffect(() => {
    const element = document.getElementById(`year${currentYear}`);
    if (element) {
      // Scroll to active block
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [screen]);

  return (
    <>
      <Menu
        anchorElement={anchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        padding={"0px"}
        hide={() => {
          setAnchor(null);
          setScreen("calendar");
        }}
      >
        <div
          style={{
            background: darkMode ? colors.darkModeLightBackground : "white",
          }}
        >
          <PickerContainer darkMode={darkMode}>
            {screen === "years" && (
              <MonthsContainer>
                <Header darkMode={darkMode}>Select Year</Header>
                <YearsGrid>
                  {getYears().map((year) => (
                    <MonthYearItem
                      darkMode={darkMode}
                      id={`year${year}`}
                      active={year == currentYear}
                      primaryColor={primaryColor}
                      onClick={() => {
                        // Copy and update the existing date object
                        const updatedDate = finalDate.clone();
                        updatedDate.year(year);
                        const parsed = updatedDate.format(formatString);
                        onChange(parsed);
                        setScreen("months");
                      }}
                    >
                      {year}
                    </MonthYearItem>
                  ))}
                </YearsGrid>
              </MonthsContainer>
            )}
            {screen === "months" && (
              <MonthsContainer>
                <Header darkMode={darkMode}>Select Month</Header>
                <MonthsGrid>
                  {moment.months().map((month, index) => (
                    <MonthYearItem
                      darkMode={darkMode}
                      id={`month${currentMonth}`}
                      active={month === currentMonth}
                      primaryColor={primaryColor}
                      onClick={() => {
                        // Copy and update the existing date object
                        const updatedDate = finalDate.clone();
                        updatedDate.month(index);
                        const parsed = updatedDate.format(formatString);
                        onChange(parsed);
                        setScreen("calendar");
                      }}
                    >
                      {get(translations, `calendarMonth${month}`, month)}
                    </MonthYearItem>
                  ))}
                </MonthsGrid>
              </MonthsContainer>
            )}
            {screen === "calendar" && (
              <SimpleCalendar
                isRange={isRange}
                finalDate={finalDate}
                showScreen={setScreen}
                startDate={moment(startDate, formatString)}
                endDate={moment(endDate, formatString)}
                onChange={(d) => {
                  if (isRange) {
                    // HANDLE DATE RANGE
                    if (dateRangeType === "start") {
                      const newStartDate = d.format(formatString);
                      setDateRangeType("end");

                      // Clear end date
                      onChange(`${newStartDate}|`);
                    } else {
                      const newEndDate = d.format(formatString);
                      setDateRangeType("start");
                      onChange(`${startDate}|${newEndDate}`);
                    }
                  } else {
                    // HANDLE SINGLE DATE
                    // Copy and update the existing date object
                    const updatedDate = finalDate.clone();
                    updatedDate.year(d.format("YYYY"));
                    updatedDate.month(parseInt(d.format("MM")) - 1);
                    updatedDate.date(d.format("DD"));

                    const parsed = updatedDate.format(formatString);

                    onChange(parsed);
                  }
                }}
              />
            )}

            {showTime && (
              <>
                <ScrollSection>
                  {hours.map((h) => (
                    <ScrollItem
                      primaryColor={primaryColor}
                      active={parseInt(h) == currentHourAdjusted}
                      onClick={() => {
                        // Copy and update the existing date object
                        const updatedDate = finalDate.clone();

                        // adjust hour based on AM/PM
                        if (showAmPm) {
                          if (currentHour < 12) {
                            updatedDate.hours(parseInt(h));
                          } else {
                            updatedDate.hours(parseInt(h) + 12);
                          }
                        } else {
                          updatedDate.hours(parseInt(h));
                        }

                        const formatStr = getDateFormatString({
                          ...dateFormat,
                          showAmPm: false,
                        });

                        const parsed = updatedDate.format(formatStr);

                        onChange(parsed);
                      }}
                    >
                      {h}
                    </ScrollItem>
                  ))}
                </ScrollSection>
                <ScrollSection>
                  {minutes.map((m) => (
                    <ScrollItem
                      primaryColor={primaryColor}
                      active={m == currentMinute}
                      onClick={() => {
                        // Copy and update the existing date object
                        const updatedDate = finalDate.clone();
                        updatedDate.minutes(parseInt(m));

                        const formatStr = getDateFormatString({
                          ...dateFormat,
                          showAmPm: false,
                        });

                        const parsed = updatedDate.format(formatStr);

                        onChange(parsed);
                      }}
                    >
                      {m}
                    </ScrollItem>
                  ))}
                </ScrollSection>
                {showAmPm && (
                  <ScrollSection>
                    {["AM", "PM"].map((m) => {
                      const getActive = (m) => {
                        if (m === "AM") {
                          return currentHour < 12;
                        } else {
                          return currentHour >= 12;
                        }
                      };

                      return (
                        <ScrollItem
                          primaryColor={primaryColor}
                          active={getActive(m)}
                          onClick={() => {
                            // Copy and update the existing date object
                            const updatedDate = finalDate.clone();

                            // adjust hour based on AM/PM
                            if (currentHour < 12) {
                              updatedDate.hours(currentHour + 12);
                            } else {
                              updatedDate.hours(currentHour - 12);
                            }

                            const formatStr = getDateFormatString({
                              ...dateFormat,
                              showAmPm: false,
                            });

                            const parsed = updatedDate.format(formatStr);
                            onChange(parsed);
                          }}
                        >
                          {m}
                        </ScrollItem>
                      );
                    })}
                  </ScrollSection>
                )}
              </>
            )}
          </PickerContainer>
          <Row gap="15px" style={{ padding: "15px" }}>
            <Input
              data={{
                value,
                color: darkMode ? "white" : colors.grey4,
                background: darkMode ? colors.darkModeInputBackground : "white",
                border: `1px solid ${
                  darkMode ? colors.darkModeLightBorder : colors.grey2
                }`,
                disabled: isRange,
                onChange: (v) => onChange(v),
                placeholder: isRange
                  ? `${formatString} | ${formatString}`
                  : formatString,
              }}
            />
            <Button
              data={{
                text: get(translations, "clear", "Clear"),
                onClick: () => onChange(""),
                type: "basic",
                color: darkMode ? "white" : colors.grey4,
                backgroundColor: darkMode
                  ? colors.darkModeInputBackground
                  : "white",
                border: `1px solid ${
                  darkMode ? colors.darkModeLightBorder : colors.grey2
                }`,
              }}
            />
          </Row>
        </div>
      </Menu>

      {data.filterButton && (
        <CustomFilterButton
          {...data.filterButton}
          onClick={(e) => setAnchor(e.currentTarget)}
        />
      )}

      {!data.filterButton && (
        <Container width={width} margin={margin}>
          <SearchContainer
            background={background}
            borderRadius={borderRadius}
            borderColor={borderColor}
            borderWidth={borderWidth}
          >
            <SearchInput
              placeholderColor={placeholderColor}
              darkMode={darkMode}
              padding={padding}
              color={color}
              {...styleObject}
              placeholder={formatString}
              onChange={(e) => {
                e.stopPropagation();
                if (!setupMode) {
                  onChange(e.target.value);
                }
              }}
              value={value}
            />
            <Icon
              data={{
                icon: "FiCalendar",
                color: color || (darkMode ? colors.grey1 : colors.grey3),
                onClick: (e) => setAnchor(e.currentTarget),
                hover: true,
              }}
            />
          </SearchContainer>
        </Container>
      )}
    </>
  );
};

export default DateTimePicker;

const getYears = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const startYear = currentYear - 100; // Calculate 100 years back
  const endYear = currentYear + 20; // Calculate 20 years forward
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(year); // Populate the years array with the range from startYear to endYear
  }

  return years; // Return the array of years
};

const Header = styled.div`
  color: ${(p) => (p.darkMode ? colors.darkModeLightText : "#111827")};
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 15px;
  &:hover {
    color: ${(p) => p.primaryColor};
  }
`;

const MonthsContainer = styled.div`
  width: 290px;
  padding: 10px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MonthsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  width: 100%;
  gap: 15px;
  overflow-y: auto;
`;

const YearsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  height: 100%;
  width: 100%;
  gap: 20px;
  overflow-y: auto;
`;

const PickerContainer = styled.div`
  display: flex;
  background: ${(p) => (p.darkMode ? colors.darkModeLightBackground : "white")};
  height: 290px;
  border-bottom: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightBorder : colors.grey2)};
  width: fit-content;
`;

const ScrollSection = styled.div`
  height: 100%;
  overflow-y: auto;
  border-left: 1px solid ${colors.grey2};
  padding: 6px 6px 0 6px;
`;

const ScrollItem = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-height: 34px;
  min-width: 34px;
  max-height: 34px;
  max-width: 34px;
  cursor: pointer;
  ${(p) => p.active && `color: white;`}
  ${(p) => p.active && `background: ${p.primaryColor};`}
  &:hover {
    background: ${(p) => (p.active ? p.primaryColor : colors.grey1)};
  }
`;

const MonthYearItem = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  min-height: 34px;
  max-height: 34px;
  cursor: pointer;
  color: ${(p) => (p.darkMode ? "white" : colors.default)};
  ${(p) => p.active && `color: white;`}
  ${(p) => p.active && `background: ${p.primaryColor};`}
  &:hover {
    filter: brightness(90%);
  }
`;

const getDateFormatViews = (format) => {
  const inputTime = get(format, "inputTime");
  const inputDate = get(format, "inputDate");
  const outputTime = get(format, "outputTime");

  if (inputDate && inputTime && outputTime !== "none") {
    return ["year", "month", "day", "hours", "minutes"];
  } else if (inputDate) {
    return ["year", "month", "day"];
  } else if (inputTime && outputTime !== "none") {
    return ["hours", "minutes"];
  }

  return ["year", "month", "day"];
};
