import { Button, Modal, Row, SearchBar, Spinner, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, startCase } from "lodash";
import { getUrlParameter, safeLower } from "app/utils/utils";
import {
  rApp,
  rApps,
  rConfirmationModalData,
  rSetConfig,
  rSubscription,
  rUser,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "./AdminWrapper";
import CardGrid from "../components/CardGrid";
import CreateAppModal from "../generate/CreateApp";
import CreateModal from "../components/CreateModal";
import { Label } from "./Pages";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { copyApp } from "./duplicateUtils";
import mixpanel from "mixpanel-browser";
import useFetchAppConfig from "app/utils/useFetchAppConfig";

export const AppsContent = ({ itemsPerPage = 12 }) => {
  const fetchAppConfig = useFetchAppConfig();

  const user = useRecoilValue(rUser);

  const app = useRecoilValue(rApp);

  const setConfig = useSetRecoilState(rSetConfig);

  const navigate = useNavigate();

  const location = useLocation();

  const installAppId = getUrlParameter("install_app_id", location);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const [search, setSearch] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(null);
  const [showNewAppModal, setShowNewAppModal] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(null);

  const [duplicatingStatus, setDuplicatingStatus] = useState(null);

  const [apps, setApps] = useRecoilState(rApps);

  const appId = get(app, "id");

  const subscription = useRecoilValue(rSubscription);

  const currentPlan = get(subscription, "plan");
  const appLimit = get(subscription, "app_limit");

  const hasUnlimitedApps = currentPlan && (appLimit === 0 || !appLimit);

  const canCreateApp =
    hasUnlimitedApps ||
    (currentPlan && apps.length < appLimit) ||
    (!currentPlan && apps.length < 3);

  useEffect(() => {
    if (app && !appId) {
      navigate("/pages");
    }
  }, [appId]);

  useEffect(() => {
    mixpanel.track("App Management Page View");
  }, []);

  useEffect(() => {
    if (installAppId) {
      setDuplicateAppSubdomain(installAppId);
    }
  }, [installAppId]);

  const deleteApp = (appId) =>
    setConfirmationModalData({
      title: "Delete App",
      text: "Are you sure you want to delete this app? This is really serious, and is irreversible. All pages, users, spreadsheets and everything else associated with this app will be removed permanently.",
      confirm: () => {
        successNotification("App Deleted");
        setApps(apps.filter((a) => a.id !== appId));
        apiRequest.delete("/app_settings/", {
          data: { id: appId },
        });
      },
    });

  const filteredApps = apps.filter((a) => {
    if (search === "") return true;
    return (
      safeLower(a.name).includes(safeLower(search)) ||
      safeLower(a.subdomain).includes(safeLower(search))
    );
  });

  const [duplicateAppSubdomain, setDuplicateAppSubdomain] = useState(null);

  // Duplicate app and set to config
  const duplicateApp = async (subdomain, type = "new") => {
    setDuplicateAppSubdomain(null);
    try {
      const newAppData = await copyApp(subdomain, setDuplicatingStatus, type);

      if (newAppData.error) {
        errorNotification(newAppData.error);
        setDuplicatingStatus(null);
        return;
      }

      // TODO - NEED TO SET EVERYTHING IN THE APP CONFIG
      setConfig(newAppData);

      successNotification("App Duplicated");
      navigate("/pages");
      localStorage.setItem("appId", get(newAppData, ["app", "id"]));
      mixpanel.track("App Duplicated");
    } catch (error) {
      console.error("Error creating demo app:", error);
      // Handle error accordingly. Maybe set an error state or show a notification to the user.
    }
  };

  const getDropdownOptions = (isActive, app) => {
    let deleteOption = [];

    const isOwner = get(user, "role") === "owner";

    if (isActive) {
      if (isOwner) {
        // Is active, is owner
        deleteOption = [
          {
            label: "Delete (Disabled)",
            icon: "FiTrash",
            onClick: () =>
              errorNotification("Sorry, but you can't delete your active app."),
          },
        ];
      }
    } else {
      // Not active
      if (isOwner) {
        deleteOption = [
          {
            label: "Delete",
            icon: "FiTrash",
            onClick: () => deleteApp(app.id),
          },
        ];
      }
    }

    return [
      ...deleteOption,
      {
        label: "Duplicate App",
        icon: "FiCopy",
        onClick: () => setDuplicateAppSubdomain(app.subdomain),
      },
    ];
  };

  return (
    <div>
      {showUpgradeModal && (
        <UpgradeModal hide={() => setShowUpgradeModal(false)} />
      )}
      {duplicateAppSubdomain && duplicateAppSubdomain === installAppId && (
        <Modal
          hide={() => setDuplicateAppSubdomain(null)}
          minWidth="600px"
          header={{
            title: `Install the ${startCase(
              installAppId
            )} app from another user?`,
            description:
              "This is an app made by another user of Frontly. If you don't know who built this app, DO NOT install this app. You should only see this if you deliberately followed an install link provided by another user who you trust. Are you sure you want to install this app?",
          }}
        >
          <Row gap={"15px"} margin="10px 0 0 0">
            <Button
              data={{
                text: "Install This App",
                onClick: () => duplicateApp(duplicateAppSubdomain, "new"),
              }}
            />
          </Row>
        </Modal>
      )}
      {duplicateAppSubdomain && duplicateAppSubdomain !== installAppId && (
        <Modal
          hide={() => setDuplicateAppSubdomain(null)}
          minWidth="600px"
          header={{
            title: "Duplicate App",
            description:
              "Do you want a brand-new app, or do you want the spreadsheets and pages to copy into your current app?",
          }}
        >
          <Row gap={"15px"} margin="10px 0 0 0">
            <Button
              data={{
                text: "Create New App",
                onClick: () => duplicateApp(duplicateAppSubdomain, "new"),
              }}
            />
            <Button
              data={{
                text: "Copy Content Into Current App",
                onClick: () => duplicateApp(duplicateAppSubdomain, "current"),
              }}
            />
          </Row>
        </Modal>
      )}

      {duplicatingStatus && (
        <Modal
          minWidth="600px"
          header={{
            title: `${duplicatingStatus}...`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <Spinner color={colors.primary} />
          </div>
        </Modal>
      )}
      {showNewAppModal && (
        <CreateAppModal hide={() => setShowNewAppModal(false)} />
      )}
      {showCreateModal && (
        <CreateModal
          hide={() => setShowCreateModal(false)}
          defaultType="app"
          showNewApp={() => {
            setShowNewAppModal(true);
            setShowCreateModal(false);
          }}
        />
      )}
      <Row justifyContent="space-between" margin="0 0 30px 0">
        <Label>Apps</Label>
        <Row alignItems="center" gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search apps",
              onChange: (v) => setSearch(v),
            }}
          />
          {get(user, "can_create_apps") && (
            <Button
              data={{
                text: "New App",
                icon: "FiPlus",
                onClick: () => {
                  if (canCreateApp) {
                    setShowCreateModal(true);
                  } else {
                    setShowUpgradeModal(true);
                  }
                },
              }}
            />
          )}
        </Row>
      </Row>

      <CardGrid
        noResultsName={"apps"}
        items={filteredApps.map((a) => {
          const isActive = a.id === appId;
          const dropdownOptions = getDropdownOptions(isActive, a);

          return {
            title: a.name,
            description: a.subdomain,
            onClick: () => {
              if (isActive) {
                navigate("/pages");
              } else {
                fetchAppConfig({
                  appId: a.id,
                  callback: () => navigate("/pages"),
                });
              }
            },
            badges: [
              {
                color: a.id === appId ? "#b8e7d2" : "#F0F0F0",
                text: a.id === appId ? "Current App" : "Click To Open",
              },
            ],
            options: dropdownOptions,
          };
        })}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
};

const Apps = () => {
  return (
    <AdminWrapper>
      <AppsContent />
    </AdminWrapper>
  );
};

export default Apps;

export const UpgradeModal = ({ hide }) => {
  const navigate = useNavigate();

  return (
    <Modal header={{ title: "Upgrade To Create More Apps" }} hide={hide}>
      <Text
        data={{
          fontStyle: "bodyLg",
          text: "You've reached your plan limit. To create more apps, upgrade your plan.",
        }}
      />

      <Button
        data={{
          text: "View Pricing Plans",
          onClick: () => {
            hide();
            navigate("/billing");
          },
          size: "large",
          margin: "20px 0 0 0",
        }}
      />
    </Modal>
  );
};
