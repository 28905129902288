import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { Icon, Menu, Row, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";

import { AdminForm } from "app/adminApp/components";
import { arrayMove } from "app/utils/utils";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const ReorderItems = ({ data }) => {
  const activeApp = useRecoilValue(rApp);

  const navigation = get(activeApp, "navigation", {});

  const sections = get(navigation, "sections", []);

  const items = get(data, "options", []);

  const [editId, setEditId] = useState(null);
  const [anchor, setAnchor] = useState(null);

  const activeItem = items.find((i) => i.value === editId);

  return (
    <div style={{ width: "200px" }}>
      {editId && (
        <Menu anchorElement={anchor} hide={() => setEditId(null)}>
          <AdminForm
            onChange={(k, v) => {
              const newItems = items.map((o) => {
                if (o.value === editId) {
                  return { ...o, [k]: v };
                }
                return o;
              });
              console.log("newItems", newItems);
              data?.onChange(newItems);
            }}
            fields={[
              {
                id: "hidePage",
                label: "Hide Page From Navigation",
                componentId: "Switch",
                value: get(activeItem, "hidePage"),
              },
              {
                id: "section",
                label: "Section",
                hint: "Position this page inside a section in the navigation bar",
                componentId: "Select",
                options: sections.map((s) => ({
                  ...s,
                  value: s.id,
                })),
                value: get(activeItem, "section"),
              },
            ]}
          />
        </Menu>
      )}
      {items.length > 0 && (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            width: "450px",
          }}
          dragHandleSelector=".drag-item"
          lockAxis="y"
          onDrop={(e) => {
            const { addedIndex, removedIndex } = e;
            const movedOptions = arrayMove(items, removedIndex, addedIndex);
            data?.onChange(movedOptions);
          }}
        >
          {items.map((opt, i) => (
            <Draggable key={opt.id}>
              <FieldContainer
                className="drag-item"
                onClick={(e) => {
                  setEditId(opt.value);
                  setAnchor(e.currentTarget);
                }}
              >
                <Row alignItems="center">
                  <Icon
                    data={{
                      icon: "RxDragHandleDots2",
                      color: colors.grey2,
                      size: 18,
                    }}
                  />
                  <Text
                    data={{
                      text: opt.label,
                      cursor: "pointer",
                      margin: "0 3px 0 3px",
                    }}
                  />
                </Row>
              </FieldContainer>
            </Draggable>
          ))}
        </Container>
      )}
    </div>
  );
};

export default ReorderItems;

const FieldContainer = styled.div`
  padding: ${spacing.s2} 0px ${spacing.s2} 0px;
  border-bottom: 1px solid ${colors.grey2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 200ms all;
  &:hover {
    background: ${colors.grey1};
  }
  :not(:last-child) {
    border-bottom: 1px solid ${colors.grey1};
  }
`;
