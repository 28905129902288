export const Text = {
  componentId: "Text",
  resources: [
    {
      id: "text",
      label: "Text",
      section: "content",
      componentId: "DynamicString",
      defaultValue: "Enter text here",
    },
    {
      id: "fontSize",
      label: "Font Size",
      section: "font",
      componentId: "Input",
      type: "number",
      orientation: "horizontal",
      width: "120px",
      defaultValue: 14,
      isStyle: true,
    },
    {
      id: "fontWeight",
      label: "Font Weight",
      section: "font",
      componentId: "Select",
      defaultValue: 300,
      hideEmptyItem: true,
      orientation: "horizontal",
      width: "120px",
      isStyle: true,
      options: [
        {
          value: "200",
          label: "Extra Light",
        },
        {
          value: "300",
          label: "Light",
        },
        {
          value: "400",
          label: "Normal",
        },
        {
          value: "500",
          label: "Medium",
        },
        {
          value: "600",
          label: "Semi Bold",
        },
        {
          value: "700",
          label: "Bold",
        },
      ],
    },
    {
      id: "fontColor",
      label: "Font Color",
      section: "font",
      isStyle: true,
      componentId: "ColorPicker",
      orientation: "horizontal",
    },
    {
      label: "Text Align",
      id: "textAlign",
      section: "alignment",
      isStyle: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "left",
      options: [
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
        { label: "Right", value: "right" },
      ],
    },
    {
      label: "Click Action",
      id: "clickAction",
      section: "actions",
      isAction: true,
      advanced: true,
      componentId: "Action",
    },
    {
      id: "isMarkdown",
      label: "Is Markdown",
      hint: "Render text as markdown. Requires the standard Markdown format, which supports bolding and other formatting.",
      section: "formatting",
      componentId: "Switch",
      orientation: "horizontal",
    },
    {
      id: "truncate",
      key: "truncate",
      label: "Character Limit",
      componentId: "Input",
      section: "font",
      type: "number",
      isStyle: true,
      orientation: "horizontal",
      width: "120px",
    },
  ],
};
