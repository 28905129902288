import { Row, Text } from "app/components";
import SettingsCard, {
  CardsColumn,
  ColumnsRow,
} from "./components/SettingsCard";
import { rApp, rPages } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "../components/AdminForm";
import { InfoBox } from "./InfoBox";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import UserGroupPermissions from "../components/UserGroupPermissions";

const Navigation = () => {
  const pages = useRecoilValue(rPages);

  const [app, setApp] = useRecoilState(rApp);

  const [changes, setChanges] = useState({});

  const [permissionsAnchor, setPermissionsAnchor] = useState(null);
 
  const navigation = get(app, "navigation", {});
  const [selectedCustomLink, setSelectedCustomLink] = useState(null);

  const navigationType = get(navigation, "type", "sidebar");
  const textColor = get(navigation, "textColor", "dark");
  const pageOrder = get(navigation, "pageOrder", []);

  const navigate = useNavigate();

  const save = () => {
    setChanges({});
    successNotification("Saved");
    apiRequest.post("/app_settings/", {
      navigation,
    });
  };

  const links = get(navigation, "customLinks", []);

  // This is copied because it's different than the one needed for end navigation
  const sortPages = (pages, pageOrder) => {
    // Use a Set to keep track of page ids that are in pageOrder
    const pageOrderIds = new Set(pageOrder.map((p) => p.value));

    // Add pages that are in the pageOrder to sortedPages in the given order
    const sortedPages = pageOrder
      .map((po) => {
        const test =
          pages.find((p) => {
            if (p.isCustomLink) {
              return p.id === po.value;
            }

            return p.pageId === po.value;
          }) || null;

        return test;
      })
      .filter((p) => p !== null); // Filter out any null entries

    // Add pages that are NOT in the pageOrder to sortedPages
    pages.forEach((page) => {
      if (page.isCustomLink) {
        if (!pageOrderIds.has(page.id)) {
          sortedPages.push(page);
        }
      } else {
        if (!pageOrderIds.has(page.pageId)) {
          sortedPages.push(page);
        }
      }
    });

    return sortedPages;
  };

  let combinedItems = [
    ...pages.map((p) => ({ ...p, pageId: p.id })),
    ...links.map((l) => ({ ...l, isCustomLink: true, name: l.label })),
  ];

  const pageOptions = sortPages(combinedItems, pageOrder).map((page) => {
    const matchingConfig = pageOrder.find((v) => v.value === page.id);
    return {
      value: page.id,
      label: page.name,
      section: get(matchingConfig, "section"),
      hidePage: get(matchingConfig, "hidePage", false),
    };
  });

  const styling = [
    {
      id: "type",
      label: "Navigation Type",
      componentId: "Select",
      value: navigationType,
      hideEmptyItem: true,
      options: [
        {
          label: "Sidebar",
          value: "sidebar",
        },
        {
          label: "Header",
          value: "header",
        },
      ],
    },
    {
      id: "sidebarStyle",
      label: "Sidebar Style",
      componentId: "Select",
      hideEmptyItem: true,
      defaultValue: "default",
      value: get(navigation, "sidebarStyle"),
      options: [
        {
          label: "Default",
          value: "default",
        },
        {
          label: "Minimal",
          value: "minimal",
        },
      ],
      displayCondition: () => navigationType === "sidebar",
    },

    {
      id: "sidebarSectionTopMargin",
      label: "Sidebar Section Top Margin",
      componentId: "Input",
      hint: "The distance between the sidebar logo and the first item",
      value: get(navigation, "sidebarSectionTopMargin"),
      defaultValue: 20,
      orientation: "horizontal",
      width: "120px",
      displayCondition: () => navigationType === "sidebar",
    },
    {
      id: "sectionGap",
      label: "Sidebar Item Gap",
      componentId: "Input",
      hint: "The distance between items in the sidebar sections",
      value: get(navigation, "sectionGap"),
      defaultValue: 8,
      orientation: "horizontal",
      width: "120px",
      displayCondition: () => navigationType === "sidebar",
    },
    {
      id: "backgroundColor",
      label: "Navigation Background",
      componentId: "ColorPicker",
      value: get(navigation, "backgroundColor"),
    },
    {
      id: "showBorder",
      label: "Show Navigation Border",
      componentId: "Switch",
      defaultValue: false,
      width: "180px",
      value: get(navigation, "showBorder"),
    },
    {
      id: "borderColor",
      label: "Border Color",
      componentId: "ColorPicker",
      width: "180px",
      value: get(navigation, "borderColor"),
      displayCondition: () => get(navigation, "showBorder"),
    },
    {
      id: "textColor",
      label: "Text Color",
      componentId: "Select",
      hideEmptyItem: true,
      value: textColor,
      options: [
        {
          label: "Light",
          value: "light",
        },
        {
          label: "Dark",
          value: "dark",
        },
      ],
    },
  ];

  const sorting = [
    {
      id: "pageOrder",
      // label: "Page Order",
      componentId: "ReorderItems",
      value: get(navigation, "pageOptions", []),
      options: pageOptions,
      orientation: "vertical",
    },
  ];

  const customLinks = [
    {
      id: "customLinks",
      componentId: "MultiForm",
      width: "300px",
      orientation: "vertical",
      labelSingular: "Custom Link",
      value: get(navigation, "customLinks", []),
      fields: [
        {
          key: "label",
          label: "Label",
          componentId: "Input",
        },
        {
          key: "link",
          label: "Link",
          componentId: "Input",
          hint: "Enter a full link with https for external links or enter a page or form URL",
        },
        {
          key: "icon",
          label: "Icon",
          orientation: "horizontal",
          componentId: "IconBrowser",
        },
        {
          id: "userGroupPermissions",
          label: "User Groups",
          componentId: "FormButton",
          hint: "Limit Block access to certain users",
          link: "https://help.frontly.ai/en/articles/7974437-user-groups",
          icon: "FiKey",
          width: "36px",
          section: "visibility",
          orientation: "horizontal",
          onClick: (e, data) => {
            setSelectedCustomLink(data.activeField);
            setPermissionsAnchor(e.currentTarget)
          },
          advanced: true,
        },
        {
          id: "displayConditions",
          label: "Display Conditions",
          componentId: "DisplayConditions",
          orientation: "vertical",
          section: "visibility",
          hint: "Define custom conditions for when this Block should be visible",
          advanced: true,
        },
        // {
        //   key: "openNewWindow",
        //   label: "Open In New Window",
        //   orientation: "horizontal",
        //   componentId: "Switch",
        // },
      ],
    },
  ];

  const sections = [
    {
      id: "sections",
      componentId: "MultiForm",
      width: "300px",
      orientation: "vertical",
      labelSingular: "Section",
      value: get(navigation, "sections", []),
      fields: [
        {
          key: "label",
          label: "Label",
          componentId: "Input",
        },
        {
          key: "icon",
          label: "Icon",
          orientation: "horizontal",
          componentId: "IconBrowser",
        },
        {
          key: "expandable",
          componentId: "Switch",
          label: "Expandable",
          orientation: "horizontal",
          hint: "Allow the section to be expanded or contracted to show/hide items",
          defaultValue: true,
        },
        {
          key: "defaultExpanded",
          componentId: "Switch",
          label: "Default Expanded",
          orientation: "horizontal",
          hint: "Whether the expandable section should be defaulted to open or closed",
          defaultValue: true,
          displayCondition: (f) => f.expandable,
        },
      ],
    },
  ];

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="App Navigation"
      description="Configure the navigation bar for users to move between pages in your app"
      articleLink="https://help.frontly.ai/en/articles/8008379-app-navigation-sidebar-header"
    >
      {permissionsAnchor && (
        <UserGroupPermissions
          isUserPage
          noGroupsText="To determine which pages this user can access, define User Groups in Settings"
          anchorElement={permissionsAnchor}
          onClose={() => {
            setPermissionsAnchor(null);
          }}
          values={selectedCustomLink?.user_groups || []}
          onChange={(v) => {
            setChanges({ ...changes, customLinks: true });
            setApp({
              navigation: {
                ...navigation,
                customLinks: navigation.customLinks.map((item) => item.id === selectedCustomLink.id ? { ...item, user_groups: v } : item),
              },
            });
          }}
        />
      )}
      <Row gap="30px">
        <ColumnsRow>
          <CardsColumn>
            <SettingsCard
              label="Styling"
              description="Adjust basic styling like colors and format"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={styling.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Changes"}
                submit={get(changes, "styling") && save}
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, styling: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />

              <Row gap="8px" margin="15px 0 0 5px">
                <Text
                  data={{
                    text: "Looking to change your Navigation Logo?",
                    fontStyle: "bodyMd",
                  }}
                />
                <Text
                  data={{
                    text: "Go to Branding Settings",
                    fontStyle: "headingSm",
                    color: colors.primary,
                    onClick: () => navigate("/settings?tab=branding"),
                  }}
                />
              </Row>
            </SettingsCard>

            <SettingsCard
              label="Custom Links"
              description="Add custom links to your navigation, such as external links"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={customLinks.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Custom Links"}
                submit={get(changes, "customLinks") && save}
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, customLinks: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>
          </CardsColumn>
          <CardsColumn>
            <SettingsCard
              label="Sorting"
              description="Drag items to adjust the order of how they appear in the navigation"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={sorting.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Sorting"}
                submit={get(changes, "sorting") && save}
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, sorting: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />
              <InfoBox margin="15px 5px 5px 5px">
                Pages and Custom Links will appear in this list.
              </InfoBox>
            </SettingsCard>
            <SettingsCard
              label="Navigation Sections"
              description="Group navigation items in expandable sections"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={sections.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Changes"}
                submit={get(changes, "sections") && save}
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, sections: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>
          </CardsColumn>
        </ColumnsRow>
      </Row>
    </SettingsSection>
  );
};

export default Navigation;
