import { listBlockResources } from "./shared";

export const Map = {
  componentId: "Map",
  resources: [
    ...listBlockResources,
    {
      id: "latitude",
      label: "Latitude",
      section: "mapData",
      orientation: "horizontal",
      width: "150px",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      wizard: true,
    },
    {
      id: "longitude",
      label: "Longitude",
      section: "mapData",
      orientation: "horizontal",
      width: "150px",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      wizard: true,
    },
    {
      id: "height",
      label: "Height",
      section: "size",
      isStyle: true,
      requiresSheet: true,
      type: "number",
      orientation: "horizontal",
      width: "150px",
      hint: "The height of the map in pixels",
      componentId: "Input",
      defaultValue: 400,
    },
    {
      id: "borderRadius",
      label: "Border Radius",
      type: "number",
      section: "border",
      isStyle: true,
      hint: "The border radius of the image in pixels (to create a curved edge)",
      componentId: "Input",
    },
  ],
};
