import { allBlocks, defaultTrue } from "app/utils/utils";

import { get } from "lodash";

export const getCalc = (
  visibleFilters,
  block,
  headerWidth,
  createButtonWidth = 0,
  labelWidth = 0
) => {
  let totalFiltersWidth = 0;

  const arbitraryExtraSpace = 130;

  // label and create button
  let totalOtherWidth = createButtonWidth + labelWidth + arbitraryExtraSpace;

  // Search bar
  if (get(block, "showSearch")) {
    totalOtherWidth += 200;
  }

  let displayItems = [];

  visibleFilters.forEach((f) => {
    const componentId = get(f, "componentId", "Input");

    const displayValue = get(f, "displayValue");

    let localValue = 0;

    // Add label length to total
    localValue += displayValue.length * 7;

    // Add extra 100 space for input field
    if (componentId === "Input") {
      localValue += 100;
    }

    // Add extra 20 for padding
    localValue += 20;

    // If icon, add it's width
    if (get(f, "icon")) {
      localValue += 22;
    }

    totalFiltersWidth += localValue;
    displayItems.push(localValue);
  });

  // Add gap between filters
  totalFiltersWidth += (visibleFilters.length - 1) * 15;

  // based on headerWidth, loop through displayFilters and figure out how many filters can be shown, and how many need to be collapsed
  let total = 0;
  let firstHiddenIndex = null;

  let availableWidth = headerWidth - totalOtherWidth;

  let finalDisplayWidth = 0;

  displayItems.forEach((item, index) => {
    total += item;
    if (firstHiddenIndex === null) {
      // if the total is less than the remaining width, add the item to the total
      if (total > availableWidth) {
        firstHiddenIndex = index;
      } else {
        finalDisplayWidth += item;
      }
    }
  });

  if (firstHiddenIndex !== null) {
    // if there is at least one hidden filter, add filter icon space + gap
    finalDisplayWidth += 55;
  }

  return {
    finalDisplayWidth,
    firstHiddenIndex,
  };
};

export const getShowBackground = (block) => {
  const blockConfig = allBlocks.find(
    (b) => b.type === get(block, "componentId")
  );

  const cardSetting = get(blockConfig, "card");

  if (cardSetting === "disabled") {
    return false;
  }

  if (cardSetting === "on") {
    return defaultTrue(get(block, "showBackground"));
  }

  if (cardSetting === "off") {
    return get(block, "showBackground");
  }

  return false;
};
