import { Button, Menu } from "app/components";

import AdminForm from "./AdminForm";
import { get } from "lodash";
import useActiveBlock from "app/utils/useActiveBlock";
import useBlockVersion from "app/utils/useBlockVersion";
import { useState } from "react";

const FormValidationSimple = ({ data }) => {
  const originalActiveBlock = useActiveBlock();

  const { getBlockWithVersion } = useBlockVersion();

  const { blockWithVersion: activeBlock } =
    getBlockWithVersion(originalActiveBlock);

  const validation = get(activeBlock, "validation", {});

  const updateField = (key, value) => {
    data.onChange({
      ...validation,
      [key]: value,
    });
  };

  const [anchorElement, setAnchorElement] = useState(null);

  const validationFields = [
    {
      id: "required",
      label: "Required",
      componentId: "Switch",
      section: "Validation",
      defaultValue: true,
      orientation: "horizontal",
      hint: "Is this field required to submit form?",
      value: get(validation, "required") === false ? false : true,
    },
    {
      id: "isEmail",
      label: "Is Email",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains a valid email address",
      value: get(validation, "isEmail"),
    },
    {
      id: "isPrice",
      label: "Is Price",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains a valid price. Allows $ but will be removed on save.",
      value: get(validation, "isPrice"),
    },
    {
      id: "isNumber",
      label: "Is Number",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains only valid numbers",
      value: get(validation, "isNumber"),
    },
    {
      id: "isTrue",
      label: "Is True",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value is true, generally used to confirm a checkbox or switch are turned 'on'.",
      value: get(validation, "isTrue"),
    },
    {
      id: "isAlphabetical",
      label: "Is Alphabetical",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains only letters",
      value: get(validation, "isAlphabetical"),
    },
    {
      id: "minOffset",
      label: "Min Chars/Value",
      hint: "Enter minimum characters required or minimum number for the input to be",
      componentId: "Input",
      section: "Validation",
      required: true,
      type: "number",
      defaultValue: "",
      value: get(validation, "minOffset"),
    },
    {
      id: "maxOffset",
      label: "Max Chars/Value",
      hint: "Enter maximum characters required or maximum number for the input to be",
      componentId: "Input",
      section: "Validation",
      type: "number",
      required: true,
      defaultValue: "",
      value: get(validation, "maxOffset"),
    },
    {
      id: "customRegex",
      label: "Custom Regex",
      hint: "A custom regex to validate the input value. This is an advanced feature for highly technical users.",
      componentId: "Input",
      section: "Validation",
      required: false,
      defaultValue: "",
      value: get(validation, "customRegex"),
    },
    {
      id: "customRegexError",
      label: "Custom Regex Error",
      hint: "An error to be displayed when the user's input doesn't meet the the custom regex requirements",
      componentId: "Input",
      section: "Validation",
      required: false,
      defaultValue: "",
      value: get(validation, "customRegexError"),
    },
  ];

  return (
    <div>
      <Menu
        anchorElement={anchorElement}
        hide={() => {
          setAnchorElement(null);
        }}
        label="Field Validation"
      >
        <AdminForm
          sectionPadding="0px"
          fields={validationFields}
          onChange={(k, v) => updateField(k, v)}
        />
      </Menu>
      <Button
        data={{
          text: "Edit Field Validation",
          type: "basic",
          onClick: (e) => setAnchorElement(e.currentTarget),
        }}
      />
    </div>
  );
};

export default FormValidationSimple;
