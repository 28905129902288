import React, { useState } from "react";

import ChartHoverPopup from "./ChartHoverPopup";
import Legend from "./Legend";
import { defaultTrue } from "app/utils/utils";
import { get } from "lodash";
import { getColor } from "./utils";
import styled from "styled-components";

const Pie = ({ data }) => {
  const values = get(data, "values", []);

  const variant = get(data, "variant", "Filled");

  const itemClickAction = get(data, "itemClickAction");

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [mouseLocation, setMouseLocation] = useState({ top: 100, left: 100 });
  const hoveredItem = get(values, hoveredIndex);

  const colorScheme = get(data, "colorScheme");

  const handleMouseMove = (e) => {
    setMouseLocation({ top: e.clientY, left: e.clientX });
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const totalValue = values.reduce((acc, item) => acc + item.value, 0);

  let accumulatedValue = 0;

  const outerRadius = 16;
  const innerRadius = variant === "Doughnut" ? 8 : 0;

  const showLegend = defaultTrue(get(data, "showLegend"));

  const customColorScheme = get(data, "customColorScheme");

  return (
    <Container onMouseMove={handleMouseMove}>
      {showLegend && (
        <Legend
          values={values}
          colorScheme={colorScheme}
          customColorScheme={customColorScheme}
        />
      )}
      {hoveredIndex !== null && (
        <ChartHoverPopup
          item={hoveredItem}
          location={mouseLocation}
          index={hoveredIndex}
          colorScheme={colorScheme}
          data={data}
        />
      )}
      <ChartContainer>
        <svg width="100%" height="100%" viewBox="0 0 32 32">
          {values.map((item, index) => {
            const startAngle = (accumulatedValue * 360) / totalValue;

            // if only one slice, modify angle slightly so it renders properly
            const endAngle =
              values.length === 1
                ? ((accumulatedValue + item.value - 0.001) * 360) / totalValue
                : ((accumulatedValue + item.value) * 360) / totalValue;

            accumulatedValue += item.value;

            const x1 =
              16 + outerRadius * Math.cos((Math.PI * startAngle) / 180);
            const y1 =
              16 - outerRadius * Math.sin((Math.PI * startAngle) / 180);

            const x2 = 16 + outerRadius * Math.cos((Math.PI * endAngle) / 180);
            const y2 = 16 - outerRadius * Math.sin((Math.PI * endAngle) / 180);

            const x3 = 16 + innerRadius * Math.cos((Math.PI * endAngle) / 180);
            const y3 = 16 - innerRadius * Math.sin((Math.PI * endAngle) / 180);

            const x4 =
              16 + innerRadius * Math.cos((Math.PI * startAngle) / 180);
            const y4 =
              16 - innerRadius * Math.sin((Math.PI * startAngle) / 180);

            const largeArcFlag = item.value / totalValue > 0.5 ? 1 : 0;

            return (
              <StyledPath
                key={index}
                d={`M ${x1} ${y1}
               A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 0 ${x2} ${y2}
               L ${x3} ${y3}
               A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 1 ${x4} ${y4} Z`}
                // style={{ cursor: "pointer" }}
                fill={getColor(index, colorScheme, customColorScheme)} // function to generate color
                onMouseEnter={(e) => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => itemClickAction(item)}
              />
            );
          })}
        </svg>
      </ChartContainer>
    </Container>
  );
};

export default Pie;

const StyledPath = styled.path`
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
`;

const Container = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 250px;
`;
