import { Button, Card, Row, Spinner, Tabs, Text } from "app/components";
import { get, startCase } from "lodash";
import { rExpertProfile, rOrganization } from "app/utils/recoil";
import { useEffect, useState } from "react";

import AdminWrapper from "../../home/AdminWrapper";
import { Label } from "../../home/Pages";
import { TableComponent } from "app/renderingApp/blocks/Table";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const Projects = () => {
  const [isFetching, setIsFetching] = useState(false);

  const expertProfile = useRecoilValue(rExpertProfile);

  const organization = useRecoilValue(rOrganization);

  const isExpert = get(organization, "is_expert");

  const [projects, setProjects] = useState([
    {
      id: 1,
      recipient: "John Doe",
      status: "Active",
      unread_messages: 3,
    },
    {
      id: 2,
      recipient: "Peter Parker",
      status: "Complete",
      unread_messages: 0,
    },
  ]);

  // FETCH PROJECTS
  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/projects/").then((res) => {
      const projectsData = get(res, "data", []);
      setProjects(projectsData);
      setIsFetching(false);
    });
  }, []);

  const navigate = useNavigate();

  const [tab, setTab] = useState("all");

  const filteredProjects = projects.filter((p) => {
    if (tab === "all") return true;
    return p.status === tab;
  });

  if (projects.length === 0 && isExpert) {
    return (
      <AdminWrapper contentWidth="100%">
        <Label>You have no projects</Label>
        <Text
          data={{
            text: "When a Frontly user submits a project proposal, you will be able to view it here.",
            margin: "10px 0 10px 0",
          }}
        />
        <Button
          data={{
            text: "Review Your Profile",
            onClick: () => navigate("/expert-profile"),
          }}
        />
      </AdminWrapper>
    );
  }

  if (projects.length === 0) {
    return (
      <AdminWrapper contentWidth="100%">
        <Label>You have no projects</Label>
        <Text
          data={{
            text: "Browse Frontly's Expert Marketplace to find a professional who can help you build or complete your project",
            margin: "10px 0 10px 0",
          }}
        />
        <Button
          data={{
            text: "View Experts Marketplace",
            onClick: () => navigate("/experts-marketplace"),
          }}
        />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper contentWidth="100%">
      <Row alignItems="center" justifyContent="space-between">
        <Label>Your Projects</Label>
        {!isFetching && !expertProfile && (
          <Button
            data={{
              text: "View Experts Marketplace",
              onClick: () => navigate("/experts-marketplace"),
            }}
          />
        )}
      </Row>

      <Tabs
        data={{
          margin: "25px 0 10px 0",
          tabs: [
            {
              label: "All",
              active: tab === "all",
              onClick: () => setTab("all"),
            },
            {
              label: "Pending",
              active: tab === "pending",
              onClick: () => setTab("pending"),
            },
            {
              label: "Approved",
              active: tab === "approved",
              onClick: () => setTab("approved"),
            },
            {
              label: "Cancelled",
              active: tab === "cancelled",
              onClick: () => setTab("cancelled"),
            },
            {
              label: "Completed",
              active: tab === "completed",
              onClick: () => setTab("completed"),
            },
          ],
        }}
      />

      <Card width="100%" margin={"15px 0 0 0"}>
        {isFetching && (
          <Spinner color={colors.grey3} size={40} padding={"20px"} />
        )}
        {!isFetching && (
          <TableComponent
            data={{
              preventDarkMode: true,
              isFetching,
              rows: filteredProjects.map((p) => ({
                ...p,
                status: startCase(get(p, "status")),
                startDate: moment(get(p, "date")).format("YYYY-MM-DD"),
                onClick: () => navigate(`/project/${p.id}`),
              })),
              columns: [
                {
                  key: "name",
                },
                {
                  key: expertProfile ? "client" : "expert",
                },
                {
                  key: "status",
                  columnType: "badge",
                },
                {
                  key: "startDate",
                },
              ],
            }}
          />
        )}
      </Card>
    </AdminWrapper>
  );
};

export default Projects;
