import {
  addFetchingBlocks,
  rActiveRequest,
  rAllFormState,
  rApp,
  rAppDateFormat,
  rCustomBlocks,
  rFetchingBlockIds,
  rFetchingVariables,
  rFilters,
  rPagination,
  rSavedSpreadsheets,
  rSorting,
  rSpreadsheets,
  spreadsheetsSelector,
} from "app/utils/recoil";
import { get, isEmpty, toString } from "lodash";
import {
  getCurrentDomain,
  getDateFormatString,
  getGoogleSheetsEndpoint,
  getRelatedSheets,
  isFrontlyAdmin,
  safeArray,
  safeString,
} from "app/utils/utils";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import { apiRequest } from "app/utils/apiRequests";
import { errorNotification } from "app/utils/Notification";
import { getDefaultVisibleFilters } from "app/renderPage";
import usePage from "app/utils/usePage";
import useUtils from "./useUtils";

// Get the inputs for a reusable block
export const getReusableVariables = (dynamicValue) => {
  let variableKeys = [];

  let pattern = /\{\{\s*(.*?)\s*\}\}/g;

  toString(dynamicValue).replace(pattern, function (match, key) {
    // Split the key into dataSourceKey and valueKey
    let parts = key.split(".");
    if (get(parts, 0) === "input") {
      const k = get(parts, 1).split("||")[0];
      variableKeys.push(k);
    }
  });

  return variableKeys.map((v) => safeString(v).trim());
};

export const getCustomVariablesFromValue = (
  dynamicValue,
  appVariables,
  processDynamicText
) => {
  let variables = [];
  let variableKeys = [];

  let pattern = /\{\{\s*(.*?)\s*\}\}/g;

  toString(dynamicValue).replace(pattern, function (match, key) {
    // Split the key into dataSourceKey and valueKey
    let parts = key.split(".");
    if (get(parts, 0) === "custom") {
      const variableKey = get(parts, 1);

      const m = appVariables.find((v) => v.key === variableKey);

      if (m && !variableKeys.includes(variableKey)) {
        variableKeys.push(variableKey);

        if (m.spreadsheet) {
          variables.push({
            ...m,
            filters: get(m, "conditions", []).map((c) => ({
              ...c,
              value: processDynamicText({ text: get(c, "value") }),
            })),
            id: m.spreadsheet,
            is_variable: true,
          });
        }
      }
    }
  });

  return variables;
};

const getCellsFromValue = (dynamicValue) => {
  let cellsToFetch = [];

  let pattern = /\{\{\s*(.*?)\s*\}\}/g;

  toString(dynamicValue).replace(pattern, function (match, key) {
    // Split the key into dataSourceKey and valueKey
    let parts = key.split(".");
    if (get(parts, 0) === "spreadsheets") {
      const sheetId = get(parts, 1);
      const cell = get(parts, 2);

      if (sheetId && cell) {
        cellsToFetch.push({
          id: sheetId,
          cell,
        });
      }
    }
  });

  return cellsToFetch;
};

// Find the static cells to fetch from the Google Sheet
const getCellsToFetch = (page) => {
  const pageString = JSON.stringify(page);
  const pageSheetVars = getCellsFromValue(pageString);
  return pageSheetVars;
};

// This function checks if a block is actually using the fields from the related sheets and only returns true if they are in use
const isBlockUsingRelatedFields = (block, relatedHeaders, relatedFieldKey) => {
  const isDefaultAction = get(block, "recordClickAction") === "default";

  // Can be either true or undefined, but not false
  const fieldDataRelatedKey =
    get(block, ["fieldData", "config", relatedFieldKey, "active"]) !== false;

  if (["Form", "InfoList"].includes(block.componentId)) {
    return fieldDataRelatedKey;
  }

  // If related field is used in the detail view form, return true for all blocks
  if (isDefaultAction && fieldDataRelatedKey) {
    return true;
  }

  // GRID
  if (["Grid", "Kanban", "Calendar"].includes(block.componentId)) {
    const badge = get(block, "badge");

    const gridFields = get(block, "fields", []).map((f) => f.key);
    return (
      relatedHeaders.some((e) => gridFields.includes(e)) ||
      relatedHeaders.includes(badge)
    );
  }
  // TABLE
  else if (block.componentId === "Table") {
    const tableColumnData = get(block, ["columnData", "config"], {});
    return relatedHeaders.some((e) => {
      return get(tableColumnData, [e, "active"]) !== false;
    });
  }

  return true;
};

export const useFetchSpreadsheets = () => {
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const page = usePage();

  const activeApp = useRecoilValue(rApp);

  const dataRelations = get(activeApp, "data_relations", []);

  const { processDynamicText } = useUtils();

  const [spreadsheets, setSpreadsheets] = useRecoilState(spreadsheetsSelector);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const customBlocks = useRecoilValue(rCustomBlocks);

  const pageCustomBlockIds = get(page, "blocks", [])
    .filter((b) => b.componentId === "Custom")
    .map((b) => b.customBlock);

  // The custom blocks that are currently being used on this page
  const activeCustomBlocks = safeArray(customBlocks).filter((b) =>
    pageCustomBlockIds.includes(b.id)
  );

  const resetSpreadsheets = useResetRecoilState(rSpreadsheets);

  const [allFormState, setAllFormState] = useRecoilState(rAllFormState);

  const addToFetchingBlocks = useSetRecoilState(addFetchingBlocks);

  const setIsFetchingVariables = useSetRecoilState(rFetchingVariables);

  const resetFetchingBlockIds = useResetRecoilState(rFetchingBlockIds);

  const recoilPagination = useRecoilValue(rPagination);

  const [activeRequest, setActiveRequest] = useRecoilState(rActiveRequest);

  const filterState = useRecoilValue(rFilters);

  const dbSorting = useRecoilValue(rSorting);

  // Fetch spreadsheets from API based on blocks
  const fetchSpreadsheets = (blocks, isInitial = false) => {
    if (isInitial) {
      // reset to clear any previous page data
      resetSpreadsheets();
    }

    const customVariablesToFetchFromCustomBlocks = getCustomVariablesFromValue(
      JSON.stringify(activeCustomBlocks),
      safeArray(activeApp, "custom_variables"),
      processDynamicText
    );

    // CUSTOM VARIABLES
    const customVariablesToFetchFromPage = getCustomVariablesFromValue(
      JSON.stringify(page),
      safeArray(activeApp, "custom_variables"),
      processDynamicText
    );

    const customVariablesToFetch = [
      ...customVariablesToFetchFromPage,
      ...customVariablesToFetchFromCustomBlocks,
    ];

    // SPREADSHEET CELLS
    const cellsToFetch = getCellsToFetch(page);

    const spreadsheetBlocks = blocks.filter(
      (b) => b.spreadsheet && !["QuoteCalculator"].includes(b.componentId)
    );

    // reset form blocks
    let formBlocks = {};
    spreadsheetBlocks
      .filter((b) => b.componentId === "Form")
      .map((b) => b.id)
      .forEach((id) => {
        formBlocks[id] = null;
      });

    // If form blocks object is not empty, reset their state
    if (!isEmpty(formBlocks)) {
      setSpreadsheets({
        ...spreadsheets,
        ...formBlocks,
      });
    }

    if (
      !isFrontlyAdmin &&
      (spreadsheetBlocks.length > 0 ||
        cellsToFetch.length > 0 ||
        customVariablesToFetch.length > 0)
    ) {
      if (customVariablesToFetch.length > 0) {
        setIsFetchingVariables(true);
      }

      addToFetchingBlocks(spreadsheetBlocks.map((b) => b.id));

      let sheetRequests = [];

      spreadsheetBlocks.forEach((b) => {
        // Use ensure we don't bother fetching related data unless it's being used

        const sheet = savedSpreadsheets.find((s) => s.id === b.spreadsheet);

        const isDataBase = ["supabase", "airtable"].includes(
          get(sheet, "service")
        );

        let visibleFilters = [];

        if (isDataBase) {
          const blockFilters = get(
            isInitial
              ? getDefaultVisibleFilters([b], processDynamicText)
              : filterState,
            b.id,
            {}
          );
          // VISIBLE FILTERS

          get(b, "visibleFilters", []).forEach((vf) => {
            const match = get(blockFilters, get(vf, "key"));
            if (match) {
              visibleFilters.push({
                key: get(vf, "key"),
                operator: get(vf, "operator", "contains"),
                value: match,
              });
            }
          });
        }

        const getSupabaseSorting = () => {
          if (isDataBase) {
            const defaultSortColumn = get(b, "defaultSortColumn");
            const defaultSortDirection = get(b, "defaultSortDirection");

            if (dbSorting) {
              const concatId = `${get(page, "id")}_${get(b, "id")}`;
              const blockMatch = get(dbSorting, concatId);

              if (blockMatch) {
                return blockMatch;
              }
            }

            // Use defaults if set
            if (defaultSortColumn) {
              if (defaultSortDirection) {
                return `${defaultSortColumn}___${defaultSortDirection}`;
              }
              return `${defaultSortColumn}___asc`;
            }

            return null;
          }

          return null;
        };

        const supabaseSorting = getSupabaseSorting();

        const relatedSheetObjects = getRelatedSheets(
          get(sheet, "id"),
          activeApp,
          savedSpreadsheets
        ).map((r) => {
          const relatedSheet = get(r, "relatedSheet");
          const relationColumns = get(r, "relationColumns");
          const matchingRel = dataRelations.find((rel) => rel.id === r.id);

          return {
            relation_id: get(r, "id"),
            id: get(relatedSheet, "id"),
            current_column: get(relationColumns, "current"),
            inner_join: get(matchingRel, "inner_join", false),
            related_column: get(relationColumns, "other"),
            display_column: get(r, "display_column"),
            relation_key: get(r, "relationKey"),
          };
        });

        // HIDDEN FILTERS
        let filters = get(b, "hiddenFilters", [])
          .filter((f) => {
            return (
              f.key &&
              (f.value ||
                ["exists", "does_not_exist", "is_true", "is_false"].includes(
                  f.operator
                ))
            );
          })
          .map((f) => ({
            ...f,
            value: processDynamicText({
              text: f.value,
              context: null,
              skipRecordFrontlyId: false,
              skipGoogleSheetCell: true,
              skipCustomVariable: true,
            }),
          }))
          .map((f) => {
            if (
              ["date_after", "date_before", "date_in_range"].includes(
                f.operator
              )
            ) {
              return {
                ...f,
                date_format: getDateFormatString(appDateFormat),
              };
            }

            return f;
          });

        filters = [...filters, ...visibleFilters];

        let pageSize = get(b, "resultsPerPage", 10);
        if (b.componentId === "Kanban") {
          pageSize = 500;
        }

        let obj = {
          pagination: {
            ...get(recoilPagination, b.id, {}),
            page_size: pageSize,
          },
          id: b.spreadsheet,
          // If has detail view parent, fetch all data, we'll filter on front-end
          filters,
          sorting: supabaseSorting,
          block_id: b.id,
          related_sheets: relatedSheetObjects,
          filters_condition_type: get(b, "hiddenFiltersConditionType", "all"),
        };

        obj["service"] = get(sheet, "service", "google_sheets");

        // handle form and infolist Row ID
        if (["Form", "InfoList"].includes(b.componentId)) {
          obj["row_id"] = processDynamicText({
            text: b.rowId,
            skipGoogleSheetCell: true,
            skipCustomVariable: true,
          });
          obj["row_id_column"] = b.rowIdColumn;
          obj["single_record"] = true;
        }

        if (b.componentId === "Stat") {
          obj["is_calculation"] = true;
          obj["field"] = b.field;
          obj["metric"] = get(b, "metric");
        }

        const isCreateModeForm =
          b.componentId === "Form" && b.mode === "create";

        const numRelatedSheets = get(obj, ["related_sheets", "length"]);

        const isCreateModeDataBaseForm = isCreateModeForm && isDataBase;

        if (
          (!isCreateModeForm || numRelatedSheets > 0) &&
          !isCreateModeDataBaseForm
        ) {
          sheetRequests.push(obj);
        }
      });

      if (
        cellsToFetch.length > 0 ||
        sheetRequests.length > 0 ||
        customVariablesToFetch.length > 0
      ) {
        // If active request, abort it
        if (activeRequest) {
          activeRequest.abort();
        }

        const controller = new AbortController();
        setActiveRequest(controller);

        apiRequest
          .post(
            getGoogleSheetsEndpoint(),
            {
              endpoint_type: "multi",
              spreadsheets: sheetRequests,
              cells_to_fetch: cellsToFetch,
              variables_to_fetch: customVariablesToFetch,
              domain: getCurrentDomain(),
            },
            {
              abortSignal: controller.signal, // Pass the abort signal in the request config
            }
          )
          .then((response) => {
            const error = get(response, ["data", "error"]);

            if (error) {
              errorNotification(error);
            }

            const spreadsheetsData = get(response, ["data"], []);

            const currentPagination = get(spreadsheets, "pagination", {});
            const pagination = get(spreadsheetsData, "pagination", {});
            const newPagination = { ...currentPagination, ...pagination };

            // This part now only holds data for Form and Detail View select dropdowns.
            // They will be stored as an object based on the block ID, and then a
            // column key with an array of unique values to display as options in the dropdown.
            const currentRelations = get(spreadsheets, "relations", {});
            const newRelations = get(spreadsheetsData, "relations", {});
            const relations = { ...currentRelations, ...newRelations };

            const currentVariables = get(spreadsheets, "custom_variables", {});
            const newVariables = get(spreadsheetsData, "custom_variables", {});
            const variables = { ...currentVariables, ...newVariables };

            // HANDLE FORM STATE
            handleFormState({
              blocks,
              allFormState,
              savedSpreadsheets,
              setAllFormState,
              processDynamicText,
              spreadsheetsData,
            });

            resetFetchingBlockIds();

            setSpreadsheets({
              ...spreadsheets,
              ...spreadsheetsData,
              relations,
              custom_variables: variables,
              pagination: newPagination,
            });

            if (customVariablesToFetch.length > 0) {
              setIsFetchingVariables(false);
            }
          });
      } else {
        resetFetchingBlockIds();
        if (customVariablesToFetch.length > 0) {
          setIsFetchingVariables(false);
        }
        // HANDLE FORM STATE
        handleFormState({
          blocks,
          allFormState,
          savedSpreadsheets,
          setAllFormState,
          processDynamicText,
          spreadsheetsData: {},
        });
      }
    }
  };

  return { fetchSpreadsheets };
};

// Handle Form State
const handleFormState = ({
  blocks,
  allFormState,
  savedSpreadsheets,
  setAllFormState,
  processDynamicText,
  spreadsheetsData,
}) => {
  let newFormState = {
    ...allFormState,
  };

  // Create empty objects in spreadsheets datasource for 'create' mode forms
  // let createModeFormData = {};
  blocks
    .filter((b) => b.componentId === "Form")
    .forEach((b) => {
      const blockSpreadsheetId = get(b, "spreadsheet");

      const blockSpreadsheet = savedSpreadsheets.find(
        (s) => s.id === blockSpreadsheetId
      );

      const blockSpreadsheetHeaders = get(blockSpreadsheet, "headers", []);

      const fieldData = get(b, "fieldData", {});
      const config = get(fieldData, "config", {});

      const defaultValues = getDefaultValuesObject(
        b,
        config,
        blockSpreadsheetHeaders,
        processDynamicText
      );

      if (b.mode === "create") {
        newFormState[b.id] = {
          ...defaultValues,
        };
      } else {
        const formState = get(spreadsheetsData, b.id, {});

        let combined = {
          ...formState,
        };

        // If useDefaultsInEditMode is true, use the default values for empty values in the form state
        if (get(b, "useDefaultsInEditMode")) {
          Object.keys(formState).forEach((k) => {
            const v = formState[k];
            if (v === "" || v === null || v === undefined) {
              combined[k] = defaultValues[k];
            }
          });
        }

        newFormState[b.id] = combined;
      }
    });

  // Set the new form state
  setAllFormState(newFormState);
};

const getDefaultValuesObject = (
  block,
  config,
  sheetHeaders,
  processDynamicText
) => {
  let defaultValuesObject = {};
  sheetHeaders.forEach((h) => {
    const m = get(config, h);
    if (["Switch", "Checkbox"].includes(get(m, "componentId"))) {
      defaultValuesObject[h] = get(m, "defaultValue") || false;
    }
    if (get(m, "active") !== false && get(m, "defaultValue")) {
      defaultValuesObject[h] = processDynamicText({
        text: get(m, "defaultValue"),
        reusableBlockId: block.reusableBlockId,
        context: { repeatingRecord: get(block, "repeatingRecord") },
      });
    }
  });
  return defaultValuesObject;
};
