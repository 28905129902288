import { apiRequest } from "app/utils/apiRequests";
import { decryptConfig } from "app/utils/encryptionUtils";
import { get } from "lodash";

// STEP 1 - COPY APP
export const copyApp = async (subdomain, setStatus, type = "new") => {
  try {
    setStatus("Copying App Structure");
    const response = await apiRequest.post("/copy_app/", {
      subdomain,
      type,
    });

    const res = response.data;
    // console.log("STEP 1 - COPY APP RESPONSE", res);

    const error = get(res, "error");

    if (error) {
      return { error };
    }

    const appToCopy = get(res, "app_to_copy");
    const newApp = get(res, "new_app");
    const spreadsheetIds = get(res, "spreadsheet_ids", []);

    let sheetMap = {};

    let existingSheetId = null;

    // COPY ALL SHEETS
    for (let sheetIndex = 0; sheetIndex < spreadsheetIds.length; sheetIndex++) {
      setStatus(`Copying Spreadsheet ${sheetIndex + 1}`);
      const sheetId = spreadsheetIds[sheetIndex];
      const newSheetId = await copySheet(
        sheetId,
        newApp,
        subdomain,
        existingSheetId
      );
      if (!existingSheetId) {
        existingSheetId = newSheetId;
      }
      sheetMap[sheetId] = newSheetId;
    }

    // Generate pages
    const newAppData = await copyPages(sheetMap, appToCopy, newApp, setStatus);

    const decrypted = decryptConfig(newAppData);

    return decrypted;
  } catch (error) {
    console.error("Error copying app: ", error);
    // Handle the error appropriately here
    throw error;
  }
};

// STEP 2 - COPY SHEET(S)
const copySheet = async (sheetId, newApp, subdomain, existingSheetId) => {
  try {
    const response = await apiRequest.post("/copy_sheet/", {
      spreadsheet_to_copy: sheetId,
      existing_sheet_id: existingSheetId,
      new_app: newApp,
      subdomain,
    });

    // console.log("STEP 2 - COPY SHEET RESPONSE", response.data);

    const newSheetId = get(response, ["data", "new_sheet_id"]);

    return newSheetId;
  } catch (error) {
    console.error("Error copying sheet: ", error);
    // Handle the error appropriately here
    throw error;
  }
};

// STEP 3 - COPY PAGES
const copyPages = async (sheetMap, appToCopy, newApp, setStatus) => {
  try {
    setStatus("Copying Pages");
    const response = await apiRequest.post("/copy_pages/", {
      spreadsheet_map: sheetMap,
      current_app: appToCopy,
      new_app: newApp,
    });

    // console.log("STEP 3 - COPY PAGES RESPONSE", response.data);

    setStatus(null);
    return response.data;
  } catch (error) {
    console.error("Error copying pages: ", error);
    // Handle the error appropriately here
    throw error;
  }
};
