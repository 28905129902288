import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import React from "react";

const RechartLine = ({ data, series }) => {
  return (
    <LineChart width={600} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      {series.map((item) => (
        <Line type="monotone" dataKey={item.dataKey} stroke={item.stroke} />
      ))}
    </LineChart>
  );
};

export default RechartLine;
