import { getHighest, safeArray } from "app/utils/utils";
import {
  rConfirmationModalData,
  rCustomBlockChanges,
  rCustomBlocks,
  rPages,
} from "app/utils/recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminForm from "app/adminApp/components/AdminForm";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { getCustomBlockInputs } from "app/adminApp/components/CustomBlockFieldEditor";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";

const CustomBlockConfig = () => {
  const activeBlock = useActiveBlock();

  const { activeCustomBlock, setActiveCustomBlock } = useActiveCustomBlock();

  const pages = useRecoilValue(rPages);

  const { id } = useParams();

  const [customBlocks, setCustomBlocks] = useRecoilState(rCustomBlocks);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const setCustomBlockChanges = useSetRecoilState(rCustomBlockChanges);

  const navigate = useNavigate();

  const isReusable = get(activeCustomBlock, "reusable");

  const customBlockFields = isReusable
    ? getCustomBlockInputs(activeCustomBlock, activeBlock)
    : [];

  const deleteBlock = () => {
    let pageNames = [];
    // Check for block on any pages
    pages.forEach((p) => {
      get(p, "blocks", []).forEach((b) => {
        if (b.componentId === "Custom" && b.customBlock === parseInt(id)) {
          if (!pageNames.includes(p.name)) {
            pageNames.push(p.name);
          }
        }
      });
    });

    if (pageNames.length === 0) {
      setConfirmationModalData({
        title: "Delete Custom Block",
        text: "Are you sure you want to delete this custom block? This action cannot be undone, and if you have this on any of your pages it could break things.",
        confirm: () => {
          navigate("/settings?tab=customBlocks");
          const newBlocks = safeArray(customBlocks).filter((b) => b.id != id);
          setCustomBlocks(newBlocks);
          successNotification("Custom Block Deleted");
          apiRequest.post("/app_settings/", {
            custom_blocks: newBlocks,
          });
        },
      });
    } else {
      const pageString = pageNames.join(", ");

      setConfirmationModalData({
        title: "Can't Delete Block",
        text: `This block cannot be deleted right now because it's still active on the following pages: ${pageString}. Please delete the instances of these blocks before deleting this custom block.`,
      });
    }
  };

  const duplicateBlock = () => {
    const newBlock = {
      ...activeCustomBlock,
      id: getHighest(safeArray(customBlocks), "id") + 1,
      name: `${activeCustomBlock.name} (Copy)`,
    };
    const newBlocks = [...safeArray(customBlocks), newBlock];
    setCustomBlocks(newBlocks);
    successNotification("Custom Block Duplicated");
    apiRequest.post("/app_settings/", {
      custom_blocks: newBlocks,
    });
  };

  const fields = [
    {
      id: "name",
      label: "Name",
      componentId: "Input",
      section: "basics",
    },
    {
      id: "description",
      label: "Description",
      componentId: "Input",
      section: "basics",
    },
    {
      id: "reusable",
      label: "Reusable",
      hint: "The settings for this block will be limited to the dynamic variables you define in the block, and any edits here will affect all instances throughout your apps",
      componentId: "Switch",
      section: "basics",
      orientation: "horizontal",
    },
    {
      id: "repeating",
      label: "Repeating",
      hint: "This block will be rendered for every visible row in the connected data source",
      componentId: "Switch",
      section: "basics",
      orientation: "horizontal",
      displayCondition: () => isReusable,
    },
    ...customBlockFields,
    // TODO - Enable once this feature is built
    // {
    //   id: "addToMarketplace",
    //   label: "Add To Public Marketplace",
    //   hint: "Submit your block to be included in the public marketplace, available for install by other users in other apps",
    //   componentId: "Switch",
    //   section: "basics",
    //   orientation: "horizontal",
    // },
    {
      id: "blockHierarchy",
      componentId: "BlockHierarchy",
      sectionHint: "Configure the blocks on this page",
      section: "blocks",
      orientation: "vertical",
      tab: "basics",
    },
    {
      id: "deleteBlock",
      componentId: "FormButton",
      icon: "FiTrash",
      text: "Delete Custom Block",
      width: "fit-content",
      orientation: "horizontal",
      onClick: deleteBlock,
      section: "deleteBlock",
    },
    {
      id: "duplicateBlock",
      componentId: "FormButton",
      icon: "FiCopy",
      text: "Duplicate Custom Block",
      width: "fit-content",
      orientation: "horizontal",
      onClick: duplicateBlock,
      section: "duplicateBlock",
    },
  ].filter(
    (f) => !f.displayCondition || (f.displayCondition && f.displayCondition())
  );

  return (
    <AdminForm
      fields={fields.map((f) => ({
        ...f,
        value: get(activeCustomBlock, f.id),
        orientation: get(f, "orientation", "vertical"),
      }))}
      borderBottom={true}
      onChange={(k, v) => {
        setCustomBlockChanges(true);
        setActiveCustomBlock({ [k]: v });
      }}
    />
  );
};

export default CustomBlockConfig;
