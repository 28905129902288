export const Video = {
  componentId: "Video",
  resources: [
    {
      id: "url",
      key: "url",
      label: "Url",
      componentId: "DynamicString",
      required: true,
      defaultValue: "",
      wizard: true,
    },
    {
      id: "height",
      label: "Height",
      type: "number",
      hint: "The height of the image in pixels",
      componentId: "Input",
    },
  ],
};
