import { allBlocks, safeArray } from "app/utils/utils";
import { customCss, resultsPerPage } from "app/adminApp/blockConfigs/shared";
import { rCustomBlocks, rShowStylesPanel } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "app/adminApp/components/AdminForm";
import { PanelHeader } from "./PageStyles";
import { blockConfigs } from "app/adminApp/blockConfigs";
import { get } from "lodash";
import { getShowBackground } from "app/renderingApp/RenderBlock/renderblockUtils";
import useActiveBlock from "app/utils/useActiveBlock";
import useBlockVersion from "app/utils/useBlockVersion";
import usePage from "app/utils/usePage";
import useSetPage from "app/utils/useSetPage";

const ActiveBlockStyles = () => {
  const page = usePage();

  const customBlocks = useRecoilValue(rCustomBlocks);

  const originalActiveBlock = useActiveBlock();

  const activeBlockConfig = get(
    blockConfigs,
    get(originalActiveBlock, "componentId")
  );

  const { setBlock } = useSetPage();

  const { getBlockWithVersion } = useBlockVersion();
  const { blockWithVersion: activeBlock, activeVersionId } =
    getBlockWithVersion(originalActiveBlock);

  const blocks = get(page, "blocks", []);

  const blockComponentId = get(activeBlock, "componentId");

  const blockConfig = allBlocks.find((b) => b.type === blockComponentId);
  const blockCardSetting = get(blockConfig, "card");

  const showBackgroundSettings = ["on", "off"].includes(blockCardSetting);

  const showBg = getShowBackground(activeBlock);

  const showBorder = get(activeBlock, "showBorder");

  const blockResources = get(activeBlockConfig, "resources", []).filter((f) => {
    return f.isStyle || get(f, "section") === "styles";
  });

  const [showStylesPanel, setShowStylesPanel] =
    useRecoilState(rShowStylesPanel);

  const activeBlockLayoutParentId = get(activeBlock, "layoutParent");

  const layoutParentBlock = activeBlockLayoutParentId
    ? blocks.find((b) => b.id === activeBlockLayoutParentId)
    : null;

  const parentType = layoutParentBlock
    ? get(layoutParentBlock, "componentId")
    : null;

  const fields = [
    {
      id: "showBackground",
      label: "Show Background",
      orientation: "horizontal",
      componentId: "Switch",
      section: "background",
      isStyle: true,
      value: showBg,
      displayCondition: () => showBackgroundSettings,
    },
    {
      id: "margin",
      label: "Margin",
      section: "spacing",
      isStyle: true,
      orientation: "vertical",
      componentId: "PaddingConfig",
    },
    {
      id: "fillRemainingSpace",
      label: "Fill Remaining Space",
      isStyle: true,
      componentId: "Switch",
      hint: "If toggled, this block will stretch to fill the remaining space in the row",
      value: get(activeBlock, "fillRemainingSpace"),
      width: "120px",
      defaultValue: true,
      orientation: "horizontal",
      section: "size",
      displayCondition: () => ["Row", "Column"].includes(parentType),
    },
    {
      id: "layoutWidth",
      label: "Width",
      isStyle: true,
      componentId: "Input",
      hint: "This field can handle plain numbers or percentages like '500' for pixels or '100%'",
      value: get(activeBlock, "layoutWidth"),
      width: "120px",
      orientation: "horizontal",
      defaultValue: "",
      placeholder: "auto",
      section: "size",
      displayCondition: () => ["Row", "Column"].includes(parentType),
    },
    {
      id: "height",
      label: "Height",
      componentId: "Input",
      hint: "The height of your block, in px",
      value: get(activeBlock, "height"),
      width: "120px",
      defaultValue: "",
      placeholder: "auto",
      orientation: "horizontal",
      section: "size",
      isStyle: true,
      displayCondition: () =>
        showBackgroundSettings && !["Text"].includes(blockComponentId),
    },
    // PADDING
    {
      id: "padding",
      label: "Padding",
      section: "spacing",
      isStyle: true,
      orientation: "vertical",
      componentId: "PaddingConfig",
      displayCondition: () => showBg || "Button" === blockComponentId,
    },
    {
      id: "backgroundColor",
      label: "Background Color",
      componentId: "ColorPicker",
      orientation: "horizontal",
      section: "background",
      isStyle: true,
      value: get(activeBlock, "backgroundColor", "#ffffff"),
      displayCondition: () => showBg,
    },
    {
      id: "showBorder",
      label: "Show Border",
      isStyle: true,
      componentId: "Switch",
      hint: "If toggled, a border will be enabled for this block",
      width: "120px",
      defaultValue: true,
      orientation: "horizontal",
      section: "border",
      displayCondition: () => showBg,
    },
    {
      id: "borderRadius",
      label: "Border Radius",
      section: "border",
      isStyle: true,
      orientation: "vertical",
      componentId: "PaddingConfig",
      displayCondition: () => showBg,
    },
    // BORDER
    {
      id: "borderColor",
      label: "Border Color",
      componentId: "ColorPicker",
      orientation: "horizontal",
      section: "border",
      isStyle: true,
      displayCondition: () => showBorder,
    },
    {
      id: "borderWidth",
      label: "Border Width",
      section: "border",
      isStyle: true,
      orientation: "vertical",
      componentId: "PaddingConfig",
      displayCondition: () => showBorder,
    },
    {
      id: "alignment",
      label: "Alignment",
      section: "alignment",
      hint: "Alignment of the button.",
      componentId: "Select",
      hideEmptyItem: true,
      required: false,
      options: [
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
        { label: "Right", value: "right" }
      ],
      
      defaultValue: "left",
      displayCondition: () => ["Form"].includes(blockComponentId),
    },
  ];

  const activeCustomBlock = safeArray(customBlocks).find(
    (b) => b.id == get(activeBlock, "customBlock")
  );
  const isCustomRepeating = get(activeCustomBlock, "repeating");

  const sectionOrder = [];
  let finalFields = [...fields, ...blockResources, customCss];

  if (isCustomRepeating) {
    finalFields.push(resultsPerPage);
  }

  const formKey = `${get(activeBlock, "id")}${
    activeVersionId ? `-${activeVersionId}` : ""
  }`;

  return (
    <div>
      <PanelHeader
        label="Block Styles"
        expanded={showStylesPanel}
        setExpanded={setShowStylesPanel}
      />
      <AdminForm
        key={formKey}
        sectionOrder={sectionOrder}
        fields={finalFields
          .filter(
            (f) =>
              !f.displayCondition ||
              (f.displayCondition && f.displayCondition(activeBlock))
          )
          .map((f) => ({ ...f, value: f.value || get(activeBlock, f.id) }))}
        borderBottom={true}
        onChange={(k, v) => setBlock({ [k]: v })}
      />
    </div>
  );
};

export default ActiveBlockStyles;
