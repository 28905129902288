import { Icon, Text } from "app/components";

import { DeleteButton } from "./styles";
import { colors } from "app/utils/theme";
import styled from "styled-components";

export const Main = ({ label, description, children }) => {
  return (
    <BodyContent>
      <HeaderText>{label}</HeaderText>
      {description && <HeaderDescription>{description}</HeaderDescription>}
      {children}
    </BodyContent>
  );
};

export const EditOption = ({
  value,
  placeholder,
  onChange,
  onDelete,
  disabled,
}) => {
  return (
    <OptionContainer disabled={disabled}>
      <EditInput
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={disabled ? { cursor: "not-allowed" } : {}}
      />
      {!disabled && (
        <DeleteButton onClick={onDelete}>
          <Icon
            data={{ icon: "FiX", color: colors.grey3, hover: true, size: 15 }}
          />
        </DeleteButton>
      )}
    </OptionContainer>
  );
};

export const NewOptionButton = ({ onClick }) => {
  return (
    <NewOptionButtonContainer onClick={onClick}>
      <Text
        data={{
          text: "Add",
          color: "white",
          fontStyle: "bodyXl",
          cursor: "pointer",
        }}
      />
      <Icon
        data={{
          icon: "FiPlus",
          color: "white",
          size: 22,
          hover: true,
        }}
      />
    </NewOptionButtonContainer>
  );
};

const EditInput = styled.input`
  padding: 10px;
  width: 100%;
  border: 0px;
  outline: none;
  resize: none;
  font-size: 22px;
  width: 200px;
  height: ${(p) => p.height};
  border-radius: 20px;
  &::placeholder {
    color: ${colors.grey3};
  }
  @media screen and (max-width: 800px) {
    padding: 8px;
  }
`;

const OptionContainer = styled.div`
  outline: 5px solid #ffffff50;
  background: white;
  border-radius: 30px;
  display: flex;
  padding: 8px 15px 8px 8px;
  align-items: center;
  position: relative;
  @media screen and (max-width: 800px) {
    outline: 5px solid #ffffff50;
  }
`;

export const BodyContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

export const HeaderText = styled.div`
  font-size: 45px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width: 800px) {
    font-size: 44px;
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 250px;
  right: 0;
  top: 0;
  @media screen and (max-width: 800px) {
    left: 0;
  }
`;

export const HeaderDescription = styled.div`
  font-size: 22px;
  font-weight: 300;
  color: white;
  text-align: center;
  margin-bottom: 50px;
  max-width: 800px;
  opacity: 0.8;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 30px;
  }
`;

const NewOptionButtonContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
  font-weight: 500;
  font-size: 20px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 8px;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
`;
