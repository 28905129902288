import { Button, Row, SearchBar } from "app/components";
import { boxShadow, colors } from "app/utils/theme";
import { openPageInNewTab, safeLower } from "app/utils/utils";
import { rApp, rConfirmationModalData, rPages } from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "./AdminWrapper";
import CardGrid from "../components/CardGrid";
import Cookies from "js-cookie";
import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import NewPageModal from "../pages/NewPageModal";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const PagesContent = ({ itemsPerPage = 12 }) => {
  const [pages, setPages] = useRecoilState(rPages);

  const app = useRecoilValue(rApp);

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [showNewPageModal, setShowNewPageModal] = useState(false);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const deletePage = (pageId) => {
    const newPages = pages.filter((p) => p.id !== pageId);
    setPages(newPages);
    successNotification("Page Deleted");
    mixpanel.track("Delete Page");

    apiRequest.delete("/page/", {
      data: { id: pageId },
    });
  };

  const filteredPages = pages.filter(
    (p) =>
      search === "" ||
      safeLower(p.name).includes(safeLower(search)) ||
      safeLower(p.route).includes(safeLower(search))
  );

  return (
    <div>
      {showNewPageModal && (
        <NewPageModal hide={() => setShowNewPageModal(false)} />
      )}
      <Row
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 30px 0"
      >
        <Label>Pages</Label>
        <Row alignItems="center" gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search pages",
              onChange: (v) => setSearch(v),
            }}
          />
          <Button
            data={{
              text: "New Page",
              icon: "FiPlus",
              onClick: () => setShowNewPageModal(true),
            }}
          />
        </Row>
      </Row>

      {pages.length === 0 && (
        <EmptyStateDashboard
          title="You don't have any pages"
          subtitle="Pages are the basic building blocks of your app, where you can show content. Get started by clicking the button below."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716244997/Create_ljrvzs.svg"
          }
          buttonText="New Page"
          onClick={() => setShowNewPageModal(true)}
        />
      )}

      {pages.length > 0 && (
        <CardGrid
          noResultsName={"pages"}
          items={filteredPages.map((p) => ({
            title: p.name,
            description: p.route,
            onClick: () => navigate(`/page/${p.id}`),
            badges: [
              {
                color: p.published ? "#b8e7d2" : "#AFE6F1",
                text: p.published ? "Published" : "Draft",
              },
            ],
            options: [
              {
                label: "View Live Page",
                icon: "FiExternalLink",
                onClick: () => {
                  const token = Cookies.get("accessToken");
                  openPageInNewTab({ page: p, app, token });
                },
              },
              {
                label: "Duplicate Page",
                icon: "FiCopy",
                onClick: () => {
                  apiRequest
                    .post("/duplicate_page/", { id: p.id })
                    .then((r) => {
                      const newPage = get(r, "data");
                      setPages([...pages, newPage]);
                      successNotification("Page Duplicated");
                    });
                },
              },
              {
                label: "Delete",
                icon: "FiTrash",
                onClick: () =>
                  setConfirmationModalData({
                    title: "Delete Page",
                    text: "Are you sure you want to delete this page? This is irreversible.",
                    confirm: () => deletePage(p.id),
                  }),
              },
            ],
          }))}
          itemsPerPage={itemsPerPage}
        />
      )}
    </div>
  );
};

const Pages = () => {
  return (
    <AdminWrapper>
      <PagesContent />
    </AdminWrapper>
  );
};

export default Pages;

export const Label = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

export const Container = styled.div`
  border-radius: 10px;
  box-shadow: ${boxShadow.card};
  background: white;
  overflow: hidden;
  margin-top: 20px;
`;

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const Header = styled.div`
  padding: 15px;
`;

export const Badge = styled.div`
  background: ${(p) => p.color};
  padding: 5px 7px 5px 7px;
  border-radius: 16px;
  font-size: 11px;
  font-weight: 400;
  color: #202223;
  width: fit-content;
`;

export const CardLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

export const CardText = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${colors.grey3};
  margin-top: 4px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 15px;
  cursor: pointer;
  gap: 20px;
  border: 1px solid ${colors.divider};
  border-radius: 12px;
  &:hover {
    background: ${colors.grey1};
  }
`;

export const CardSection = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid ${colors.divider};
  overflow: auto;
`;
