import { Breadcrumb, Button, Row } from "app/components";

import { boxShadow } from "app/utils/theme";
import { frontlyLogoDark } from "app/utils/utils";
import { startCase } from "lodash";
import styled from "styled-components";

const TemplateBanner = ({ template }) => {
  return (
    <TemplateBannerContainer>
      <Row gap="30px" alignItems="center">
        <Logo src={frontlyLogoDark} />
        {window.innerWidth > 800 && (
          <Breadcrumb
            fontStyle="headingLg"
            items={[
              {
                text: "Templates",
                onClick: () =>
                  (window.location.href =
                    "https://app.frontly.ai/app_templates"),
              },
              {
                text: startCase(template),
              },
            ]}
          />
        )}
      </Row>
      <Button
        data={{
          text: window.innerWidth > 800 ? "Use This Template" : "Use Template",
          icon: "FiArrowRight",
          fontStyle: "headingMd",
          flippedIcon: true,
          onClick: () =>
            window.open(`https://app.frontly.ai/signup?template=${template}`),
        }}
      />
    </TemplateBannerContainer>
  );
};

export default TemplateBanner;

const Logo = styled.img`
  height: 36px;
`;

const TemplateBannerContainer = styled.div`
  display: flex;
  background: white;
  box-shadow: ${boxShadow.popover};
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 60px;
  border-bottom: 1px solid #f0f0f0;
`;
