import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";

import { AdminForm } from "../components";
import { Modal } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { decryptConfig } from "app/utils/encryptionUtils";
import { isValidSubdomain } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import { rSetConfig } from "app/utils/recoil";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useState } from "react";

const CreateAppModal = ({ hide }) => {
  const setConfig = useSetRecoilState(rSetConfig);

  const [isFetching, setIsFetching] = useState(false);
  const [errors, errorsSet] = useState({});
  const [formData, setFormData] = useState({
    name: "New App",
    subdomain: "",
    copy_google_connection: true,
  });
  const navigate = useNavigate();

  const { name, subdomain } = formData;

  const copyGoogleConnection = get(formData, "copy_google_connection");

  const isFormValid = () => {
    let errors = {};

    if (!name) {
      errors["name"] = "Please enter a valid name.";
    } else if (name.length < 3) {
      errors["name"] = "Please enter a longer name.";
    }

    errors = { ...errors, ...isValidSubdomain(subdomain) };

    errorsSet(errors);

    return isEmpty(errors);
  };

  const createApp = () => {
    if (!isFormValid()) return;

    setIsFetching(true);
    apiRequest
      .post("/create_app/", {
        name,
        subdomain,
        copy_google_connection: copyGoogleConnection,
      })
      .then((response) => {
        const data = decryptConfig(get(response, "data"));
        const error = get(data, "error");

        if (error) {
          errorNotification(error);
        } else {
          successNotification("App Created");
          setConfig(data);
          hide();
          mixpanel.track("App Created", { template: "blank" });
          navigate("/pages");
          localStorage.setItem("appId", get(data, ["app", "id"]));
        }
      })
      .catch(() => {
        errorNotification("Something went wrong.");
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <Modal minWidth="400px" header={{ title: "Create New App" }} hide={hide}>
      <AdminForm
        isFetching={isFetching}
        submit={createApp}
        errors={errors}
        submitText={"Create App"}
        onChange={(k, v) =>
          setFormData({
            ...formData,
            [k]: v,
          })
        }
        sectionPadding="0px"
        fields={[
          {
            id: "name",
            componentId: "Input",
            label: "Name",
            value: name,
          },
          {
            id: "subdomain",
            componentId: "Input",
            label: "Subdomain",
            value: subdomain,
            hint: "A subdomain is the web address for your frontly-branded domain, like myappname.frontly.ai. This can be changed from settings at any time.",
          },
          {
            id: "copy_google_connection",
            componentId: "Switch",
            label: "Copy Existing Google Connection",
            hint: "If turned on, this setting will copy your current app's Google Sheets authorization to the new app.",
            value: copyGoogleConnection,
          },
        ]}
      />
    </Modal>
  );
};

export default CreateAppModal;
