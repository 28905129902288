import { get, startCase } from "lodash";

import AdminForm from "./AdminForm";
import { getDisplayHeaders } from "app/utils/utils";
import { rSavedSpreadsheets } from "app/utils/recoil";
import useActiveBlock from "app/utils/useActiveBlock";
import { useRecoilValue } from "recoil";

const GoogleForm = ({ data }) => {
  const activeBlock = useActiveBlock();

  const step = get(data, "step");

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const sheet = savedSpreadsheets.find((s) => s.id === step.spreadsheet);
  const headers = getDisplayHeaders(get(sheet, "headers", []));

  const formData = get(data, "value", {});

  const isForm = get(activeBlock, "componentId") === "Form";

  const fields = headers.map((h) => ({
    id: h,
    label: startCase(h),
    componentId: "DynamicString",
    value: get(formData, h),
    dynamicSources: isForm ? ["form"] : [],
  }));

  return (
    <AdminForm
      previousSteps={get(data, "previousSteps", [])}
      dynamicSources={get(data, "dynamicSources", [])}
      excludedSources={get(data, "excludedSources", [])}
      fields={fields}
      sectionPadding={"0px"}
      gridLayout
      onChange={(k, v) => data.onChange({ ...formData, [k]: v })}
    />
  );
};

export default GoogleForm;
