import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import React from "react";
import { RecoilRoot } from "recoil";
import RootApp from "./app/Root";
import TagManager from "react-gtm-module";
import { createRoot } from "react-dom/client";

const tagManagerArgs = {
  gtmId: "GTM-WN26R9XL",
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <IntercomProvider appId={"yj7svpiv"}>
    <RecoilRoot>
      <BrowserRouter>
        <RootApp />
      </BrowserRouter>
    </RecoilRoot>
  </IntercomProvider>
);
