import { Button, Input, Row, Text } from "app/components";
import SettingsCard, {
  CardsColumn,
  ColumnsRow,
} from "./components/SettingsCard";
import { boxShadow, colors } from "app/utils/theme";
import { defaultTrue, getPixels } from "app/utils/utils";
import { rApp, rDarkMode } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "../components/AdminForm";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const Styling = () => {
  const darkMode = useRecoilValue(rDarkMode);

  const [app, setApp] = useRecoilState(rApp);

  const [pageChanges, setPageChanges] = useState(false);
  const [formChanges, setFormChanges] = useState(false);
  const [buttonChanges, setButtonChanges] = useState(false);
  const [blockChanges, setBlockChanges] = useState(false);
  const [presetChanged, setPresetChanged] = useState(false);
  const [themeChanges, setThemeChanges] = useState(false);
  const [brandColorsChanges, setBrandColorsChanges] = useState(false);

  const styling = get(app, "styling", {});

  const primaryColor = get(app, "primary_color") || colors.primary;

  const save = (section) => {
    if (section === "authentication") {
      apiRequest.post("/app_settings/", {
        authentication: app.authentication,
      });
    }
    if (section === "brandColors") {
      setBrandColorsChanges(false);
    }
    if (section === "theme") {
      setThemeChanges(false);
    }
    if (section === "block") {
      setBlockChanges(false);
    }
    if (section === "form") {
      setFormChanges(false);
    }
    if (section === "button") {
      setButtonChanges(false);
    }
    if (section === "page") {
      setPageChanges(false);
    }

    successNotification("Saved");
    apiRequest.post("/app_settings/", {
      styling,
    });
  };

  const authentication = get(app, "authentication", {});

  const authFields = [
    {
      id: "authBgColorPrimary",
      label: "Background Color Primary",
      hint: "This will apply as a default unless you override it on the page-level.",
      componentId: "ColorPicker",
      width: "180px",
      value: get(authentication, "authBgColorPrimary"),
    },
    {
      id: "authBgColorSecondary",
      label: "Background Color Secondary",
      hint: "This will apply as a default unless you override it on the page-level.",
      componentId: "ColorPicker",
      width: "180px",
      value: get(authentication, "authBgColorSecondary"),
    },
    {
      id: "signupLinkTextColor",
      label: "Sign Up Text Color",
      hint: "This will apply as a default unless you override it on the page-level.",
      componentId: "ColorPicker",
      width: "180px",
      value: get(authentication, "signupLinkTextColor"),
    },
    {
      id: "forgotPasswordTextColor",
      label: "Forgot Password Text Color",
      hint: "This will apply as a default unless you override it on the page-level.",
      componentId: "ColorPicker",
      width: "180px",
      value: get(authentication, "forgotPasswordTextColor"),
    },
  ];

  const pageFields = [
    {
      id: "pageBackgroundColor",
      label: "Background Color",
      hint: "This will apply as a default unless you override it on the page-level.",
      componentId: "ColorPicker",
      width: "180px",
      value: get(styling, "pageBackgroundColor"),
    },
  ];

  const buttonFields = [
    {
      id: "buttonBorderRadius",
      label: "Border Radius",
      componentId: "Input",
      type: "number",
      defaultValue: 8,
      width: "180px",
      hint: "Set the border radius (how round the edges are) for all buttons in your app",
      value: get(styling, "buttonBorderRadius"),
    },
  ];

  let blockContainerFields = [
    {
      id: "blockBorder",
      label: "Border",
      componentId: "Switch",
      width: "180px",
      value: get(styling, "blockBorder"),
      hint: "Set whether blocks with a background should have a border",
    },
    {
      id: "blockBorderRadius",
      label: "Border Radius",
      componentId: "Input",
      type: "number",
      defaultValue: 8,
      width: "180px",
      hint: "Set the border radius (how round the edges are) for all blocks with a background",
      value: get(styling, "blockBorderRadius"),
    },
    {
      id: "blockPadding",
      label: "Padding",
      componentId: "Input",
      type: "number",
      defaultValue: 20,
      width: "180px",
      hint: "Set the padding for all blocks with a background",
      value: get(styling, "blockPadding"),
    },
    {
      id: "blockBoxShadow",
      label: "Box Shadow",
      componentId: "Switch",
      width: "180px",
      value: defaultTrue(get(styling, "blockBoxShadow")),
      hint: "Set whether blocks with a background should have a box shadow",
    },
  ];

  if (!darkMode) {
    blockContainerFields.push({
      id: "blockBackgroundColor",
      label: "Background Color",
      componentId: "ColorPicker",
      defaultValue: "#ffffff",
      width: "180px",
      value: get(styling, "blockBackgroundColor"),
    });
  }

  if (get(styling, "blockBorder") && !darkMode) {
    blockContainerFields.push({
      id: "blockBorderColor",
      label: "Border Color",
      componentId: "ColorPicker",
      defaultValue: colors.pitchBorder,
      width: "180px",
      value: get(styling, "blockBorderColor"),
    });
  }

  let fields = [
    {
      id: "formInputSize",
      label: "Input Size",
      componentId: "Select",
      width: "180px",
      value: get(styling, "formInputSize"),
      defaultValue: "medium",
      hideEmptyItem: true,
      options: [
        // {
        //   label: "Small",
        //   value: "small",
        // },
        {
          label: "Medium",
          value: "medium",
        },
        {
          label: "Large",
          value: "large",
        },
      ],
    },
    {
      id: "formInputRadius",
      label: "Input Radius",
      componentId: "Input",
      type: "number",
      defaultValue: 8,
      width: "180px",
      hint: "Set the border radius (how round the edges are) for all blocks with a background",
      value: get(styling, "formInputRadius"),
    },
  ];

  if (!darkMode) {
    fields.push({
      id: "formInputBorderColor",
      label: "Input Border Color",
      componentId: "ColorPicker",
      defaultValue: colors.pitchBorder,
      width: "180px",
      value: get(styling, "formInputBorderColor"),
    });
  }

  let presetFields = [
    {
      id: "rounding",
      label: "Rounding",
      componentId: "Select",
      width: "180px",
      options: [
        {
          label: "Square Edges",
          value: "square",
        },
        {
          label: "Slighly Rounded Edges",
          value: "slightly-rounded",
        },
        {
          label: "Rounded Edges",
          value: "rounded",
        },
      ],
    },
  ];

  if (!darkMode) {
    presetFields.push({
      id: "theme",
      label: "Theme",
      componentId: "Select",
      width: "180px",
      options: [
        {
          label: "Box Shadow, No Border, Grey Background, White Card",
          value: "noBorder",
        },
        {
          label: "No Box Shadow, Grey Border, White Background",
          value: "border",
        },
      ],
    });
  }

  const roundingMap = {
    square: "0",
    "slightly-rounded": 8,
    rounded: 15,
  };

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="Styling"
      description="Customize your app's styling settings"
    >
      <Row gap="30px">
        <ColumnsRow>
          <CardsColumn>
            <SettingsCard
              label="Color Theme"
              description="Toggle light mode / dark mode for your app"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding={"0px"}
                borderBottom={true}
                fields={[
                  {
                    id: "theme",
                    label: "Color Theme",
                    componentId: "SelectToggle",
                    // width: "180px",
                    value: get(styling, "theme"),
                    tabs: [
                      {
                        label: "Light",
                        value: "light",
                        active: get(styling, "theme", "light") === "light",
                      },
                      {
                        label: "Dark",
                        value: "dark",
                        active: get(styling, "theme") === "dark",
                      },
                    ],
                  },
                ]}
                submitText={"Save Changes"}
                submit={themeChanges ? () => save("theme") : null}
                width="220px"
                orientation={"horizontal"}
                onChange={(k, v) => {
                  setThemeChanges(true);
                  setApp({
                    styling: {
                      ...styling,
                      [k]: v,
                      blockBorder:
                        v === "dark" ? true : get(styling, "blockBorder"),
                      pageBackgroundColor: v === "dark" ? "#1c1c1c" : null,
                      blockBackgroundColor:
                        v === "dark" ? "#232323" : "#ffffff",
                      blockBorderColor:
                        v === "dark"
                          ? colors.darkModeLightGrey
                          : colors.pitchBorder,
                      formInputBorderColor: v === "dark" ? "#3a3a3a" : null,
                    },
                  });
                }}
              />
            </SettingsCard>

            <SettingsCard
              label="Brand Colors"
              description="Save your brand colors for easy access"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding={"0px"}
                borderBottom={true}
                fields={[
                  {
                    id: "colors",
                    componentId: "MultiColorPicker",
                    value: get(app, ["styling", "colors"], []),
                  },
                ]}
                submitText={"Save Changes"}
                submit={brandColorsChanges ? () => save("brandColors") : null}
                onChange={(k, v) => {
                  setBrandColorsChanges(true);
                  setApp({
                    styling: {
                      ...styling,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>

            <SettingsCard
              label="Style Presets"
              description="Select a preset to apply several related styles at once. Note that these dropdowns will not save, they are just for applying styles."
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding={"0px"}
                borderBottom={true}
                fields={presetFields}
                submitText={"Save Changes"}
                submit={
                  presetChanged
                    ? () => {
                        successNotification("All Changes Saved");
                        apiRequest.post("/app_settings/", {
                          styling,
                        });
                        setPresetChanged(false);
                      }
                    : null
                }
                width="220px"
                orientation={"horizontal"}
                onChange={(k, v) => {
                  let newStyling = {};

                  if (k === "rounding") {
                    newStyling = {
                      ...styling,
                      blockBorderRadius: get(roundingMap, v),
                      formInputRadius: get(roundingMap, v),
                      buttonBorderRadius: get(roundingMap, v),
                    };
                  } else if (k === "theme") {
                    let pageBackgroundColor = null;
                    let blockBorder = false;
                    let blockBoxShadow = false;
                    let blockBorderColor = null;
                    let formInputSize = "medium";
                    let blockBackgroundColor = "#ffffff";
                    let theme = "light";

                    if (v === "noBorder") {
                      blockBoxShadow = true;
                    } else if (v === "border") {
                      pageBackgroundColor = "#ffffff";
                      blockBorder = true;
                      blockBoxShadow = false;
                      blockBorderColor = colors.pitchBorder;
                    }

                    newStyling = {
                      ...styling,
                      pageBackgroundColor,
                      blockBorder,
                      blockBoxShadow,
                      blockBorderColor,
                      theme,
                      formInputBorderColor: colors.pitchBorder,
                      formInputSize,
                      blockBackgroundColor,
                    };
                  }

                  setApp({
                    styling: newStyling,
                  });

                  setPresetChanged(true);
                }}
              />
            </SettingsCard>

            <SettingsCard label="Login / Signup Page" description="">
              <AdminForm
                labelStyle="headingSm"
                sectionPadding={"0px"}
                borderBottom={true}
                fields={authFields}
                submitText={"Save Changes"}
                submit={pageChanges ? () => save("authentication") : null}
                width="220px"
                orientation={"horizontal"}
                onChange={(k, v) => {
                  setPageChanges(true);

                  setApp({
                    authentication: {
                      ...app.authentication,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>
          </CardsColumn>

          <CardsColumn>
            <SettingsCard label="Page" description="">
              <AdminForm
                labelStyle="headingSm"
                sectionPadding={"0px"}
                borderBottom={true}
                fields={pageFields}
                submitText={"Save Changes"}
                submit={pageChanges ? () => save("page") : null}
                width="220px"
                orientation={"horizontal"}
                onChange={(k, v) => {
                  setPageChanges(true);
                  setApp({
                    styling: {
                      ...styling,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>

            <SettingsCard label="Block Container" description="">
              <AdminForm
                labelStyle="headingSm"
                sectionPadding={"0px"}
                borderBottom={true}
                fields={blockContainerFields}
                submitText={"Save Changes"}
                submit={blockChanges ? () => save("block") : null}
                width="220px"
                orientation={"horizontal"}
                onChange={(k, v) => {
                  setBlockChanges(true);
                  setApp({
                    styling: {
                      ...styling,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>

            <SettingsCard label="Form" description="">
              <AdminForm
                labelStyle="headingSm"
                sectionPadding={"0px"}
                borderBottom={true}
                fields={fields}
                submitText={"Save Changes"}
                submit={formChanges ? () => save("form") : null}
                width="220px"
                orientation={"horizontal"}
                onChange={(k, v) => {
                  setFormChanges(true);
                  setApp({
                    styling: {
                      ...styling,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>

            <SettingsCard label="Button" description="">
              <AdminForm
                labelStyle="headingSm"
                sectionPadding={"0px"}
                borderBottom={true}
                fields={buttonFields}
                submitText={"Save Changes"}
                submit={buttonChanges ? () => save("button") : null}
                width="220px"
                orientation={"horizontal"}
                onChange={(k, v) => {
                  setButtonChanges(true);
                  setApp({
                    styling: {
                      ...styling,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>
          </CardsColumn>
        </ColumnsRow>
        <Preview background={get(styling, "pageBackgroundColor")}>
          <Text
            data={{
              text: "Styling Preview",
              fontStyle: "headingLg",
              color: darkMode && "white",
            }}
          />

          <PreviewCard
            padding={get(styling, "blockPadding", 20)}
            borderRadius={get(styling, "blockBorderRadius", 8)}
            border={
              get(styling, "blockBorder")
                ? `1px solid ${get(styling, "blockBorderColor")}`
                : null
            }
            boxShadow={get(styling, "blockBoxShadow")}
            background={get(styling, "blockBackgroundColor", "#ffffff")}
          >
            <Input
              data={{
                value: "Example Input",
                onChange: null,
                background: darkMode && colors.darkModeInputBackground,
                border: darkMode && `1px solid ${colors.darkModeLightGrey}`,
                color: darkMode && "white",
                borderRadius: getPixels(get(styling, "formInputRadius")),
              }}
            />
            <Button
              data={{
                text: "Example Button",
                backgroundColor: primaryColor,
                borderRadius: getPixels(get(styling, "buttonBorderRadius")),
              }}
            />
          </PreviewCard>
        </Preview>
      </Row>
    </SettingsSection>
  );
};

export default Styling;

const Preview = styled.div`
  padding: 25px;
  background: ${(p) => p.background};
  border: 1px solid ${colors.grey2};
  display: flex;
  flex-direction: column;
  gap: 25px;
  min-width: 320px;
`;

const PreviewCard = styled.div`
  border: ${(p) => p.border};
  border-radius: ${(p) => getPixels(p.borderRadius)};
  padding: ${(p) => getPixels(p.padding)};
  box-shadow: ${(p) => (p.boxShadow === false ? null : boxShadow.card)};
  background: ${(p) => p.background};
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
