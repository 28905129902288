import { Breadcrumb } from "app/components";
import { isFrontlyAdmin } from "app/utils/utils";
import useModalStateData from "app/useModalStateData";

const StackBreadcrumbs = ({ label, margin, darkMode }) => {
  const { modalStack, setModalStack } = useModalStateData();
  const crumbs = modalStack.map((stackItem, i) => ({
    text: stackItem.label,
    onClick: () =>
      isFrontlyAdmin
        ? null
        : setModalStack(modalStack.filter((x) => x.id <= stackItem.id)),
  }));
  if (crumbs.length > 0) {
    return (
      <Breadcrumb
        whiteText={darkMode}
        margin={margin}
        items={[
          {
            text: label || "All Records",
            onClick: () => (isFrontlyAdmin ? null : setModalStack([])),
          },
          ...crumbs,
        ]}
        fontSize={18}
      />
    );
  }
  return <></>;
};

export default StackBreadcrumbs;
