import { colors } from "./theme";
import { get } from "lodash";
import { getPixels } from "./utils";
import { paddingObjToString } from "app/renderingApp/blocks/Text";

export const getInputStyles = (block, error = null, darkMode = false) => {
  const fontSize = getPixels(get(block, "fontSize", 14));
  const fontWeight = get(block, "fontWeight", 400);
  const padding = paddingObjToString(get(block, "padding"), "12px");
  const backgroundColor = darkMode
    ? colors.darkModeInputBackground
    : get(block, "backgroundColor", "#ffffff");
  const color = darkMode && "white";

  let border = null;
  if (darkMode) {
    border = `1px solid ${colors.darkModeLightGrey}`;
  }

  if (error) {
    border = "1px solid red";
  }

  return {
    border,
    color,
    fontSize,
    fontWeight,
    padding,
    backgroundColor,
  };
};
