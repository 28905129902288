import { Select } from "app/components";
import { get } from "lodash";
import { rForms } from "app/utils/recoil";
import { useRecoilValue } from "recoil";

const FormSelect = ({ data }) => {
  const forms = useRecoilValue(rForms);

  let options = forms.map((f) => ({
    label: get(f, "name"),
    value: parseInt(get(f, "id")),
  }));

  const value = get(data, "value");

  return (
    <Select
      data={{
        ...data,
        onChange: (v) => data.onChange(parseInt(v)),
        value,
        options,
      }}
    />
  );
};

export default FormSelect;
