import {
  Button,
  Column,
  FormInputWrapper,
  Input,
  Modal,
  Row,
  SearchBar,
  Spinner,
  Text,
} from "app/components";
import { get, isEmpty } from "lodash";
import { rConfirmationModalData, rForms } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import AdminWrapper from "../home/AdminWrapper";
import CardGrid from "../components/CardGrid";
import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import { Label } from "app/adminApp/home/Pages";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import mixpanel from "mixpanel-browser";
import { safeLower } from "app/utils/utils";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";

const Forms = () => {
  const [forms, setForms] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [showNewFormModal, setShowNewFormModal] = useState(false);

  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/forms/").then((res) => {
      setForms(res.data);
      setIsFetching(false);
    });
  }, []);

  const deleteForm = (formId) =>
    setConfirmationModalData({
      title: "Delete Form",
      text: "Are you sure you want to delete this form? This is irreversible.",
      confirm: () => {
        successNotification("Form Deleted");
        setForms(forms.filter((u) => u.id !== formId));
        apiRequest.delete("/forms/", {
          data: { id: formId },
        });
      },
    });

  const filteredForms = forms.filter(
    (f) =>
      search === "" ||
      safeLower(f.name).includes(safeLower(search)) ||
      safeLower(f.route).includes(safeLower(search))
  );

  return (
    <AdminWrapper>
      {showNewFormModal && (
        <NewFormModal hide={() => setShowNewFormModal(false)} />
      )}
      <Row
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 30px 0"
      >
        <Label>Forms</Label>
        <Row gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search forms",
              onChange: (v) => setSearch(v),
            }}
          />
          <Button
            data={{
              text: "New Form",
              icon: "FiPlus",
              onClick: () => setShowNewFormModal(true),
            }}
          />
        </Row>
      </Row>

      {isFetching && (
        <Spinner color={colors.grey3} size={30} padding={"15px"} />
      )}

      {!isFetching && forms.length === 0 && (
        <EmptyStateDashboard
          title="You don't have any forms"
          subtitle="Forms are a full-screen experience allowing you to collect data from users in a modern way, separate from a standard page."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716245898/Click_zpht60.svg"
          }
          buttonText="Add Form"
          onClick={() => setShowNewFormModal(true)}
        />
      )}

      {!isFetching && forms.length > 0 && (
        <CardGrid
          noResultsName={"forms"}
          items={filteredForms.map((f) => ({
            title: f.name,
            description: f.route,
            onClick: () => navigate(`/form/${f.id}`),
            badges: [
              {
                color: "#b8e7d2",
                text: "Published",
              },
            ],
            options: [
              {
                label: "Delete",
                onClick: () => deleteForm(f.id),
              },
              {
                label: "Duplicate",
                onClick: () => {
                  apiRequest
                    .post("/duplicate_form/", { id: f.id })
                    .then((r) => {
                      const newForm = get(r, "data");
                      setForms([...forms, newForm]);
                      successNotification("Form Duplicated");
                    });
                },
              },
            ],
          }))}
          itemsPerPage={8}
        />
      )}
    </AdminWrapper>
  );
};

export default Forms;

const NewFormModal = ({ hide }) => {
  const [forms, setForms] = useRecoilState(rForms);

  const [name, setName] = useState("");
  const [route, setRoute] = useState("new-form");
  const [isCreating, setIsCreating] = useState(false);
  const [customizeUrl, setCustomizeUrl] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    let errors = {};

    if (!name) {
      errors["name"] = "Please enter a valid form name";
    }

    if (route.length < 3) {
      errors["route"] = "Please enter a valid form URL";
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const createForm = () => {
    // Create form
    if (validate()) {
      setIsCreating(true);
      apiRequest
        .post("/forms/", {
          name: name,
          route,
          requireLogin: false,
          published: true,
        })
        .then((response) => {
          const data = get(response, "data");
          setForms([...forms, data]);
          mixpanel.track("Form Created");
          navigate(`/form/${data.id}`);
          setIsCreating(false);
        });
    }
  };

  function stripNonASCII(inputStr) {
    // Replace all non-ASCII characters with an empty string
    return inputStr.replace(/[^\x00-\x7F]/g, "").replace(" ", "");
  }

  return (
    <Modal
      minWidth="400px"
      hide={hide}
      header={{
        title: "Create A New Form",
      }}
    >
      <Column gap="15px">
        <FormInputWrapper
          label="Form Name"
          hint="The internal name for this form. This is not visible to end-users."
        >
          <Input
            data={{
              value: name,
              placeholder: "New Form",
              error: errors.name,
              onChange: (v) => {
                setName(v);
                if (!customizeUrl) {
                  setRoute(v.toLowerCase().replaceAll(" ", "-"));
                }
              },
            }}
          />
        </FormInputWrapper>

        <FormInputWrapper
          label="form URL"
          hint="The location of your form within your app, used in the browser's address bar to navigate to the form."
        >
          {customizeUrl ? (
            <Input
              data={{
                value: route,
                placeholder: "new-form",
                error: errors.route,
                onChange: (v) => {
                  let finalVal = v;
                  finalVal = finalVal.toLowerCase();
                  finalVal = stripNonASCII(finalVal);
                  setRoute(finalVal);
                },
              }}
            />
          ) : (
            <Text
              data={{
                text: route,
                onClick: () => setCustomizeUrl(true),
                link: true,
                margin: "10px 0 0 0",
              }}
            />
          )}

          {!customizeUrl && (
            <Text
              data={{
                text: "Customize URL",
                onClick: () => setCustomizeUrl(true),
                color: colors.primary,
                link: true,
                margin: "10px 0 0 0",
              }}
            />
          )}
        </FormInputWrapper>

        <Button
          data={{
            text: "Create Form",
            onClick: createForm,
            isFetching: isCreating,
          }}
        />
      </Column>
    </Modal>
  );
};
