import { Input, Menu, Text } from ".";

import CustomFilterButton from "./CustomFilterButton";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rTranslations } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const NumericRange = ({ data }) => {
  const translations = useRecoilValue(rTranslations);

  const { onChange } = data;

  const values = get(data, "value", "").split("|");

  const minValue = get(values, 0, "");
  const maxValue = get(values, 1, "");

  const [anchor, setAnchor] = useState(null);

  let backgroundColor = data.backgroundColor || data.background;

  return (
    <>
      <CustomFilterButton
        {...data.filterButton}
        onClick={(e) => setAnchor(e.currentTarget)}
      />

      <Menu
        anchorElement={anchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        padding={"0px"}
        hide={() => setAnchor(null)}
      >
        <Container>
          <div>
            <Text
              data={{
                text: get(translations, "min", "Min"),
                margin: "0 0 5px 0",
              }}
            />
            <Input
              data={{
                backgroundColor,
                value: minValue,
                width: "100px",
                onChange: (value) => onChange(`${value}|${maxValue}`),
              }}
            />
          </div>
          <div>
            <Text
              data={{
                text: get(translations, "max", "Max"),
                margin: "0 0 5px 0",
              }}
            />
            <Input
              data={{
                backgroundColor,
                value: maxValue,
                width: "100px",
                onChange: (value) => onChange(`${minValue}|${value}`),
              }}
            />
          </div>
        </Container>
      </Menu>
    </>
  );
};

export default NumericRange;

const Container = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  border: 1px solid ${colors.pitchBorder};
  background: white;
`;
