import { AdminForm, BackButton, HiddenFiltersConfig } from "../components";
import SettingsCard, {
  CardsColumn,
  ColumnsRow,
} from "app/adminApp/settings/components/SettingsCard";
import { rApp, rRefreshSheetData, rSavedSpreadsheets } from "app/utils/recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "../home/AdminWrapper";
import DataSourceFields from "../components/DataSourceFields";
import SettingsSection from "app/adminApp/settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const DataSourceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const activeApp = useRecoilValue(rApp);

  const [changes, setChanges] = useState(false);

  const supabaseProjectId = get(activeApp, "supabase_project_id");

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const activeSourceData = savedSpreadsheets.find((s) => s.id == id);

  const saveChanges = () => {
    successNotification("Saved");
    apiRequest.post("/sheet_management/", activeSourceData);
    setChanges(false);
  };

  const updateActiveSource = (k, v) => {
    setChanges(true);
    setSavedSpreadsheets(
      savedSpreadsheets.map((s) => {
        if (s.id == id) {
          return { ...s, [k]: v };
        }
        return s;
      })
    );
  };

  // Configure Buttons
  const service = get(activeSourceData, "service", "google");

  let openServiceLink = null;
  let openServiceText = "";

  if (service === "supabase") {
    openServiceLink = `https://supabase.com/dashboard/project/${supabaseProjectId}`;
    openServiceText = "Open In Supabase";
  } else if (service === "airtable") {
    const sheetConfig = get(activeSourceData, ["field_Data", "config"], {});
    const baseId = get(sheetConfig, "base_id", null);
    const tableId = get(sheetConfig, "table_id", null);
    openServiceLink = `https://airtable.com/${baseId}/${tableId}`;
    openServiceText = "Open In Airtable";
  } else if (service === "google") {
    openServiceLink = `https://docs.google.com/spreadsheets/d/${get(
      activeSourceData,
      "spreadsheet_id"
    )}/edit#gid=${get(activeSourceData, "tab_id")}`;
    openServiceText = "Open In Google";
  }

  const setRefreshSheetData = useSetRecoilState(rRefreshSheetData);

  const buttons = [
    {
      text: "Refresh Fields",
      onClick: () => setRefreshSheetData({ sheetId: id }),
      type: "basic",
      icon: "FiRefreshCcw",
    },
    {
      text: openServiceText,
      type: "basic",
      icon: "HiArrowTopRightOnSquare",
      onClick: () => window.open(openServiceLink),
    },
    {
      text: changes ? "Save Changes" : "Changes Saved",
      onClick: saveChanges,
      disabled: !changes,
    },
  ];

  return (
    <AdminWrapper>
      <BackButton
        width="180px"
        margin="0 0 20px 0"
        text="Data Sources"
        onClick={() => navigate("/spreadsheets")}
      />
      <SettingsSection
        hideCard
        width="100%"
        title={`Edit Data Source: ${get(activeSourceData, "title")}`}
        buttons={buttons}
      >
        <ColumnsRow>
          <CardsColumn>
            <SettingsCard
              label="Fields"
              description="Configure the types, styles, ordering and other settings for your data source fields"
              articleLink=""
            >
              <DataSourceFields setChanges={setChanges} />
            </SettingsCard>
          </CardsColumn>
          <CardsColumn>
            <SettingsCard
              label="Basics"
              description="Configure the basics of your data source"
              articleLink=""
            >
              <AdminForm
                fields={[
                  {
                    label: "Title",
                    id: "title",
                    componentId: "Input",
                    required: true,
                    value: get(activeSourceData, "title"),
                  },
                  {
                    label: "Allow Public Access",
                    description:
                      "This will allow anyone on the internet to view, edit and delete your sheet data depending on your settings.",
                    id: "allow_public_access",
                    componentId: "Switch",
                    value: get(activeSourceData, "allow_public_access"),
                  },
                ]}
                sectionPadding="0px"
                onChange={(k, v) => updateActiveSource(k, v)}
              />
            </SettingsCard>
            <SettingsCard
              label="Hidden Filters"
              description="Configure hidden data filters applied at the app-level"
              articleLink="https://help.frontly.ai/en/articles/7971258-hidden-filters"
            >
              <HiddenFiltersConfig
                data={{
                  hideConditionType: true,
                  value: get(activeSourceData, "hidden_filters"),
                  onChange: (newData) =>
                    updateActiveSource("hidden_filters", newData),
                  linkedSpreadsheet: activeSourceData,
                }}
              />
            </SettingsCard>
          </CardsColumn>
        </ColumnsRow>
      </SettingsSection>
    </AdminWrapper>
  );
};

export default DataSourceDetails;
