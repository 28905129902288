import { Breadcrumb, Button, Icon, Modal, Text } from "app/components";
import { get, startCase } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import { Header } from "./Header";
import MDEditor from "@uiw/react-md-editor";
import { colors } from "app/utils/theme";
import { demoApps } from "../appManagement/Templates";
import { resizeImage } from "app/utils/utils";
import styled from "styled-components";
import { useState } from "react";

const TemplateLandingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const template = demoApps.find((app) => app.key === id);

  return (
    <PageContainer>
      <Header />
      <TemplateDetails
        onClick={() => navigate(`/signup/?template=${id}`)}
        template={template}
        backFunction={() => navigate("/app_templates")}
      />
    </PageContainer>
  );
};

export const TemplateDetails = ({
  template,
  backFunction,
  onClick,
  inModal,
  hideBreadcrumb,
}) => {
  const name = template.label || startCase(template.key);

  const [previewImage, setPreviewImage] = useState(null);
  const categories = get(template, "categories", []);
  const features = get(template, "features", []);
  const screenshots = get(template, "screenshots", []);

  // Get the width of the video/screenshot
  let widthCount = 0;
  if (template.video) {
    widthCount++;
  }
  if (screenshots.length > 0) {
    widthCount += screenshots.length;
  }

  const renderVideoSection = () => {
    if (template.video || screenshots.length > 0) {
      return (
        <ScreenshotsContainer width={widthCount}>
          {template.video && (
            <Video
              width={widthCount}
              src={template.videoThumbnail}
              onClick={() => window.open(template.video)}
            >
              <PlayWrapper>
                <Icon data={{ icon: "BsFillPlayFill", color: "white" }} />
              </PlayWrapper>
            </Video>
          )}
          {screenshots.map((s) => (
            <Screenshot
              src={s}
              onClick={() => setPreviewImage(s)}
              width={widthCount}
            />
          ))}
        </ScreenshotsContainer>
      );
    }

    return <></>;
  };

  return (
    <div style={{ padding: inModal ? "10px 15px 15px 15px" : "30px" }}>
      {!hideBreadcrumb && (
        <Breadcrumb
          items={[{ text: "Templates", onClick: backFunction }, { text: name }]}
          fontSize={24}
        />
      )}
      {previewImage && (
        <Modal
          header={{ title: "Image Preview" }}
          hide={() => setPreviewImage(null)}
          minWidth="870px"
        >
          <PreviewImage src={previewImage} />
        </Modal>
      )}
      <Container>
        <LeftColumnDesktop>
          <ImageContainer hue={template.hue}>
            <Image
              src={resizeImage({
                url: template.image,
                height: 600,
                forceJpg: true,
                // extraMods: "ar_1.8,c_crop,g_north",
              })}
            />
          </ImageContainer>
          {renderVideoSection()}
          <Text
            data={{
              text: "About This Template",
              fontStyle: "headingXl",
              margin: "25px 0 10px 0",
            }}
          />
          <FullDescription>
            <div data-color-mode="light">
              <MDEditor.Markdown
                source={template.fullDescription}
                style={{ background: "transparent" }}
              />
            </div>
          </FullDescription>
        </LeftColumnDesktop>
        <DetailsColumn>
          <Name>{name}</Name>
          <Description>{template.description}</Description>
          <LeftColumnMobile>
            <ImageContainer hue={template.hue}>
              <Image
                src={resizeImage({
                  url: template.image,
                  height: 600,
                  forceJpg: true,
                })}
              />
            </ImageContainer>
          </LeftColumnMobile>

          <ButtonsRow>
            <Button
              data={{
                text: "Install this app",
                width: "100%",
                margin: "10px 0 10px 0",
                size: "large",
                onClick,
              }}
            />
            <Button
              data={{
                text: "Preview",
                type: "basic",
                size: "large",
                width: "100%",
                onClick: () => window.open(template.link),
              }}
            />
          </ButtonsRow>

          <LeftColumnMobile>
            {renderVideoSection()}{" "}
            <Text
              data={{
                text: "About This Template",
                fontStyle: "headingXl",
                margin: "25px 0 10px 0",
              }}
            />
            <FullDescription>
              <div data-color-mode="light">
                <MDEditor.Markdown
                  source={template.fullDescription}
                  style={{ background: "transparent" }}
                />
              </div>
            </FullDescription>
          </LeftColumnMobile>

          {categories.length > 0 && (
            <>
              <Text
                data={{
                  text: "Categories",
                  fontStyle: "headingXl",
                  margin: "25px 0 10px 0",
                }}
              />
              <Categories>
                {categories.map((c) => (
                  <Category>{c}</Category>
                ))}
              </Categories>
            </>
          )}
          {features.length > 0 && (
            <>
              <Text
                data={{
                  text: "Features",
                  fontStyle: "headingXl",
                  margin: "25px 0 10px 0",
                }}
              />
              <Categories>
                {features.map((f) => (
                  <Category>{f}</Category>
                ))}
              </Categories>
            </>
          )}
        </DetailsColumn>
      </Container>
    </div>
  );
};

export default TemplateLandingPage;

const PreviewImage = styled.img`
  height: 500px;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
  border: 1px solid ${colors.grey2};
  border-radius: 10px;
  @media (max-width: 850px) {
    height: 300px;
  }
`;

const PlayWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid rgba(255, 255, 255, 0.5);
`;

const Video = styled.div`
  width: calc(100% / ${(p) => p.width} - 15px);
  height: 150px;
  border: 1px solid ${colors.grey3};
  border-radius: 10px;
  cursor: pointer;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grey2};
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-position: contain;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 850px) {
    width: calc(100% / 2 - 15px);
  }
`;

const ScreenshotsContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
`;

const Screenshot = styled.img`
  width: calc(100% / ${(p) => p.width} - 15px);
  height: 150px;
  border: 1px solid ${colors.grey3};
  border-radius: 10px;
  cursor: pointer;
  object-fit: contain;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 850px) {
    width: calc(100% / 2 - 15px);
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Category = styled.div`
  background: ${colors.grey2};
  border-radius: 30px;
  padding: 10px;
  width: fit-content;
  font-size: 14px;
`;

const LeftColumnDesktop = styled.div`
  flex: 1;
  @media (max-width: 850px) {
    display: none;
  }
`;

const LeftColumnMobile = styled.div`
  flex: 1;
  display: none;
  @media (max-width: 850px) {
    display: initial;
  }
`;

const ImageContainer = styled.div`
  background-color: hsl(${(p) => p.hue}, 63%, 50%, 0.1);
  padding: 50px;
  border-radius: 20px;
`;

const FullDescription = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${colors.grey4};
  white-space: pre-wrap;
  @media (max-width: 850px) {
    margin-top: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: rgba(14, 13, 20, 0.1) 18px 18px 18px,
    rgba(14, 13, 20, 0.05) 12px 18px 48px;
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 50px;
  margin-top: 15px;
`;

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Name = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${colors.grey3};
  margin: 10px 0 10px 0;
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  @media (max-width: 850px) {
    width: 100%;
  }
`;
