import { Drawer, Modal } from "app/components";
import { rDarkMode, rModal } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import Custom from "app/renderingApp/blocks/Custom";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";
import useDynamicText from "app/renderingApp/useDynamicText";

const CustomModal = ({ page }) => {
  const [modal, setModal] = useRecoilState(rModal);
  const darkMode = useRecoilValue(rDarkMode);
  const { processDynamicText } = useDynamicText();

  const label = processDynamicText({
    text: get(modal, "label", "Custom Modal"),
  });
  const padding = get(modal, "padding", 0);
  const modalStyle = modal ? get(modal, "modalStyle", "modal") : null;

  const content = (
    <Content>{modal && <Custom block={modal} page={page} />}</Content>
  );

  return (
    <>
      <Drawer
        data={{
          background: darkMode ? "#222" : "white",
          open: modalStyle === "drawer",
          width: "fit-content",
          hide: () => setModal(null),
          label,
          darkMode,
          padding: getPixels(padding),
        }}
      >
        {content}
      </Drawer>

      {modalStyle === "modal" && (
        <Modal
          darkMode={darkMode}
          background={darkMode ? "#222" : "white"}
          minWidth="fit-content"
          contentPadding={getPixels(padding)}
          header={{
            title: label,
          }}
          hide={() => setModal(null)}
        >
          {content}
        </Modal>
      )}
    </>
  );
};

export default CustomModal;

const Content = styled.div`
  width: 100%;
  overflow: auto;
`;
