import { get } from "lodash";
import { layoutOptions } from "app/renderingApp/layouts";

export const Layout = {
  componentId: "Layout",
  resources: [
    {
      id: "label",
      label: "Reference Label",
      componentId: "Input",
      section: "content",
      hint: "An internal label for your own reference only.",
    },
    {
      id: "layout",
      label: "Layout",
      componentId: "LayoutBrowser",
      defaultValue: 8,
      options: layoutOptions,
      orientation: "horizontal",
      hint: "Select an advanced layout for your page to position blocks side-by-side or other configurations",
      section: "content",
    },
  ],
};
