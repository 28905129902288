export const Icon = {
  componentId: "Icon",
  resources: [
    {
      id: "icon",
      label: "Icon",
      componentId: "IconBrowser",
      section: "basics",
      orientation: "horizontal",
      defaultValue: "FiCompass",
      useDefault: true,
    },
    {
      id: "size",
      label: "Size",
      componentId: "Input",
      section: "basics",
      orientation: "horizontal",
      width: "100px",
      defaultValue: 20,
      useDefault: true,
    },
    {
      id: "color",
      label: "Color",
      section: "basics",
      componentId: "ColorPicker",
      orientation: "horizontal",
      defaultValue: "",
    },
    {
      id: "clickAction",
      label: "Click Action",
      componentId: "Action",
      section: "actions",
      isAction: true,
    },
  ],
};
