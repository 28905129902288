import { Icon, Text } from "app/components";

import { WarningBubble } from "./RenderBlockStyles";
import { useNavigate } from "react-router-dom";

export const PublicAccessWarning = () => {
  const navigate = useNavigate();

  return (
    <WarningBubble onClick={() => navigate("/spreadsheets")}>
      <Icon
        data={{
          icon: "FiAlertTriangle",
          color: "white",
          size: 14,
        }}
      />
      <Text
        data={{
          text: "This page allows public access, but this sheet does not. Click here to resolve the issue.",
          fontStyle: "bodySm",
          color: "white",
        }}
      />
    </WarningBubble>
  );
};
