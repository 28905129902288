import { Button, Modal, Spinner, Text } from "app/components";
import { rPageChanges, rPages } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import usePage from "app/utils/usePage";

const PageHistoryBrowser = ({}) => {
  const [showBrowser, setShowBrowser] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const page = usePage();
  const [pages, setPages] = useRecoilState(rPages);

  const [versions, setVersions] = useState([]);

  const pageChanges = useRecoilValue(rPageChanges);

  const navigate = useNavigate();

  const restoreVersion = (versionId) => {
    setIsFetching(true);
    apiRequest
      .post(`/page_history/`, {
        version_id: versionId,
        page_id: page.id,
      })
      .then((res) => {
        const newPage = get(res, ["data", "new_page"], {});

        // Set new page to recoil state
        setPages([...pages, newPage]);

        // Close the modal
        setShowBrowser(false);

        // Set fetching to false
        setIsFetching(false);

        // Show success message
        successNotification("Page restored successfully");

        // Navigate to new page by newPage.id
        navigate(`/page/${newPage.id}`);
      });
  };

  useEffect(() => {
    if (showBrowser) {
      setIsFetching(true);
      apiRequest.get(`/page_history/?id=${page.id}`).then((res) => {
        const savedVersion = get(res, ["data"], []);
        setVersions(savedVersion);
        setIsFetching(false);
      });
    }
  }, [showBrowser]);

  return (
    <>
      <Button
        data={{
          text: pageChanges
            ? "Save current changes to activate"
            : "Browse Versions",
          type: "basic",
          disabled: pageChanges,
          onClick: () => setShowBrowser(true),
        }}
      />

      {showBrowser && (
        <Modal
          minWidth="800px"
          header={{
            title: "Page History",
            description:
              "Restore previous versions of your page from the past 3 days",
          }}
          hide={() => setShowBrowser(false)}
        >
          {isFetching ? (
            <Spinner size={30} />
          ) : (
            <Grid>
              {versions.map((v) => (
                <Version onClick={() => restoreVersion(v.id)}>
                  <Text
                    data={{
                      text: moment(v.date).fromNow(),
                      fontStyle: "headingMd",
                      cursor: "pointer",
                    }}
                  />
                  <Text
                    data={{
                      text: `Version ID: ${v.id}`,
                      fontStyle: "bodySm",
                      cursor: "pointer",
                    }}
                  />
                </Version>
              ))}
            </Grid>
          )}
        </Modal>
      )}
    </>
  );
};

export default PageHistoryBrowser;

const Version = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  gap: 5px;
  cursor: pointer;
  background: ${colors.clickUpBackground};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  gap: 15px;
  margin-top: 20px;
`;
