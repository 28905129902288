import { parseDateWithFormatObject } from "app/utils/utils";

const processDataForChart = (records, options, appDateFormat) => {
  const {
    groupByField, // e.g., 'date'
    dateGroupingInterval, // null, 'day', 'month', or 'year'
    valueField, // Field to aggregate (e.g., 'sales')
    aggregation, // 'sum', 'count', 'average', 'min', 'max'
    seriesField, // Optional, field to separate series (e.g., 'country')
  } = options;

  const series = [];

  // If no dateGroupingInterval is provided, do not group the data by date
  if (!dateGroupingInterval) {
    const chartData = records.map((record) => {
      const dataPoint = {};

      if (groupByField) {
        if (groupByField === "date") {
          const date = parseDateWithFormatObject({
            value: record[groupByField],
            formatObject: appDateFormat,
            returnMoment: true,
          });

          if (!date.isValid()) return null; // Skip invalid dates
          dataPoint[groupByField] = date.format("YYYY-MM-DD");
        } else {
          dataPoint[groupByField] = record[groupByField];
        }
      }

      if (valueField) {
        const value = parseFloat(record[valueField]);
        dataPoint[valueField] = !isNaN(value) ? value : null;
      }

      if (seriesField) {
        dataPoint[seriesField] = record[seriesField];
        if (!series.some((s) => s.dataKey === record[seriesField])) {
          series.push({ dataKey: record[seriesField] });
        }
      }

      return dataPoint;
    });

    // Add a default series if seriesField is not set
    if (!seriesField) {
      series.push({ dataKey: valueField });
    }

    return {
      chartData: chartData.filter((dp) => dp !== null),
      series,
    };
  }

  // Ensure groupByField is set before proceeding with grouping logic
  if (!groupByField) {
    throw new Error(
      "groupByField must be set when dateGroupingInterval is provided"
    );
  }

  const groupedData = {};

  records.forEach((record) => {
    let groupKey;

    const date = parseDateWithFormatObject({
      value: record[groupByField],
      formatObject: appDateFormat,
      returnMoment: true,
    });

    if (!date.isValid()) return;

    switch (dateGroupingInterval) {
      case "day":
        groupKey = date.format("YYYY-MM-DD");
        break;
      case "month":
        groupKey = date.format("YYYY-MM");
        break;
      case "year":
        groupKey = date.format("YYYY");
        break;
      default:
        groupKey = date.format();
    }

    const seriesKey = seriesField ? record[seriesField] : "value";

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {};
    }

    if (!groupedData[groupKey][seriesKey]) {
      groupedData[groupKey][seriesKey] = {
        sum: 0,
        count: 0,
        values: [],
      };
    }

    const value = parseFloat(record[valueField]);
    if (!isNaN(value)) {
      groupedData[groupKey][seriesKey].sum += value;
      groupedData[groupKey][seriesKey].count += 1;
      groupedData[groupKey][seriesKey].values.push(value);
    }

    if (seriesField && !series.some((s) => s.dataKey === seriesKey)) {
      series.push({ dataKey: seriesKey });
    }
  });

  const chartData = Object.keys(groupedData).map((groupKey) => {
    const group = groupedData[groupKey];
    const dataPoint = {
      [groupByField]: groupKey,
    };

    Object.keys(group).forEach((seriesKey) => {
      const seriesGroup = group[seriesKey];
      let aggregatedValue;

      switch (aggregation) {
        case "sum":
          aggregatedValue = seriesGroup.sum;
          break;
        case "average":
          aggregatedValue = seriesGroup.sum / seriesGroup.count;
          break;
        case "count":
          aggregatedValue = seriesGroup.count;
          break;
        case "min":
          aggregatedValue = Math.min(...seriesGroup.values);
          break;
        case "max":
          aggregatedValue = Math.max(...seriesGroup.values);
          break;
        default:
          aggregatedValue = seriesGroup.sum;
      }

      dataPoint[seriesKey] = aggregatedValue;
    });

    return dataPoint;
  });

  // Add a default series if seriesField is not set
  if (!seriesField) {
    series.push({ dataKey: "value" });
  }

  return {
    chartData,
    series,
  };
};

export default processDataForChart;
