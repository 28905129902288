import { Row, Text } from "app/components";

import { colors } from "app/utils/theme";
import { getCurrentDomain } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";

const MWFContainer = styled.div`
  background: #222c40;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
`;

const MWFLogo = styled.img`
  height: 18px;
  margin-right: 2px;
`;

const MadeWithFrontly = () => {
  return (
    <MWFContainer
      onClick={() => {
        window.open("https://www.frontly.ai?r=madewithbadge");
        mixpanel.track("Made With Frontly Clicked", {
          domain: getCurrentDomain(),
        });
      }}
    >
      <MWFLogo src="https://res.cloudinary.com/frontly/image/upload/v1686848991/frontly_icon_white_b6yzsu.svg" />
      <Text
        data={{
          text: "Made with",
          color: "white",
          fontStyle: "headingXs",
          cursor: "pointer",
        }}
      />
      <Text
        data={{
          text: "Frontly",
          color: "#4287ff",
          fontStyle: "headingXs",
          cursor: "pointer",
        }}
      />
    </MWFContainer>
  );
};

export default MadeWithFrontly;
