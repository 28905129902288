import { Icon, Row, SearchBar, Spinner, Text } from ".";
import { colors, spacing } from "app/utils/theme";
import { debounce, get } from "lodash";
import { useCallback, useEffect, useState } from "react";

import MuiDropdown from "./MuiDropdown";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";

const Container = styled.div`
  position: relative;
`;

const InnerContainer = styled.div`
  padding: 10px;
  flex-direction: column;
  display: flex;
`;

const Option = styled.div`
  font-size: 16px;
  padding: ${(p) => p.padding || "10px 12px 10px 12px"};
  cursor: pointer;
  border-top: ${(p) => (p.showBorder ? "1px" : "0px")} solid ${colors.grey1};
  display: flex;
  gap: ${spacing.s2};
  min-width: ${(p) => p.minWidth || "fit-content"};
  align-items: center;
  background: ${(p) => p.background};
  &:hover {
    background: ${(p) =>
      p.darkMode ? colors.darkModeInputBackground : "#f8fafb"};
  }
`;

const StyledContainer = styled.div`
  padding: ${(p) => p.padding || "10px"};
  border: ${(p) => p.border || `1px solid ${colors.pitchBorder}`};
  border-width: ${(p) => p.borderWidth};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  font-size: ${(p) => getPixels(p.fontSize)};
  font-weight: ${(p) => p.fontWeight};
  background: ${(p) => p.background};
  color: ${(p) => p.color};
  width: ${(p) => p.width};
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 39px;
`;

const Autocomplete = ({ data }) => {
  const matchingRelation = get(data, "matchingRelation");

  const { getRecords, getRecord } = useSpreadsheetRequests();

  const darkMode = get(data, "darkMode");

  const [fetching, setFetching] = useState(false);
  const [fetchingDisplayValue, setFetchingDisplayValue] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [valueLabel, setValueLabel] = useState(false);

  const [closeCounter, setCloseCounter] = useState(0);

  const value = get(data, "value");
  const displayValue = valueLabel || value;

  const triggerComponent = (
    <StyledContainer>
      {fetchingDisplayValue ? (
        <Spinner padding="0 0 0 0" size={18} />
      ) : (
        <>
          <div>{displayValue}</div>

          <Row alignItems="center" gap="5px">
            {displayValue && (
              <Icon
                data={{
                  icon: "FiX",
                  onClick: (e) => {
                    e.stopPropagation();
                    data.onChange(null);
                    setValueLabel(null);
                  },
                  size: 20,
                  color: colors.grey3,
                  hover: true,
                }}
              />
            )}
            <Icon data={{ icon: "FiChevronDown" }} />
          </Row>
        </>
      )}
    </StyledContainer>
  );

  const labelColumn = get(matchingRelation, "display_column");

  const debouncedSearch = useCallback(
    debounce(async (v) => {
      if (v.length > 1) {
        if (matchingRelation && matchingRelation.sheet1) {
          setHasSearched(true);
          setFetching(true);
          getRecords({
            sheetId: matchingRelation.sheet1,
            filters: [
              {
                key: labelColumn,
                operator: "contains",
                value: v,
              },
            ],
            pagination: { page_size: 10 },
          }).then((sheetData) => {
            const records = get(sheetData, "data", []).map((r) => ({
              label: get(r, labelColumn),
              value: get(r, "frontly_id"),
            }));
            setOptions(records);
            setFetching(false);
          });
        }
      }
    }, 300), // Adjust the delay time as needed
    []
  );

  useEffect(() => {
    if (search.length > 1) {
      debouncedSearch(search);
    }
  }, [search, debouncedSearch]);

  useEffect(() => {
    if (value !== "" && !valueLabel) {
      setFetchingDisplayValue(true);
      getRecord({
        sheetId: matchingRelation.sheet1,
        recordId: value,
        rowIdColumn: "id",
      }).then((row) => {
        setValueLabel(get(row, labelColumn));
        setFetchingDisplayValue(false);
      });
    }

    // If value changes, update the label
    if (value) {
      const match = options.find((option) => option.value === value);
      if (match) {
        setValueLabel(get(match, "label"));
      }
    }
  }, [value]);

  return (
    <Container>
      <MuiDropdown
        triggerComponent={triggerComponent}
        closeCounter={closeCounter}
        background={darkMode ? colors.darkModeInputBackground : "white"}
      >
        <InnerContainer>
          <SearchBar
            data={{
              value: search,
              placeholder: "Search records",
              onChange: (v) => setSearch(v),
            }}
          />
          {fetching && <Spinner padding="20px 0 10px 5px" />}

          {options.length === 0 && hasSearched && !fetching && (
            <Text
              data={{ text: "No results match this search", margin: "10px" }}
            />
          )}

          {options.length === 0 && !hasSearched && !fetching && (
            <Text data={{ text: "Begin typing to search", margin: "10px" }} />
          )}

          {!fetching &&
            options.map((option, i) => (
              <Option
                id={`simpleDropdown-option-${i}`}
                key={i}
                darkMode={darkMode}
                background={
                  darkMode
                    ? colors.darkModeLightBackground
                    : option.active && "#eceef2"
                }
                padding={data.optionPadding}
                minWidth={data.optionMinWidth}
                onClick={() => {
                  data.onChange(option.value);
                  setCloseCounter(closeCounter + 1);
                }}
              >
                {option.label}
              </Option>
            ))}
        </InnerContainer>
      </MuiDropdown>
    </Container>
  );
};

export default Autocomplete;
