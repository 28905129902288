import { Select, Text } from "app/components";

import React from "react";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import { useRecoilValue } from "recoil";

const OpenAiModelSelect = ({ data }) => {
  const activeApp = useRecoilValue(rApp);

  let options = [
    {
      label: "GPT-4o Mini (good, less expensive)",
      value: "gpt-3.5-turbo-1106",
    },
  ];

  const hasApiKey = get(activeApp, "open_ai_api_key");
  if (hasApiKey) {
    options.push({
      label: "GPT-4o (better, more expensive)",
      value: "gpt-4-1106-preview",
    });
  }

  return (
    <>
      {!hasApiKey && (
        <Text
          data={{
            text: "Add your own OpenAI API Key on the Settings Integrations page to enable GPT-4",
            fontStyle: "headingXs",
          }}
        />
      )}
      {hasApiKey && (
        <Select
          data={{
            label: "AI Model",
            hideEmptyItem: true,
            options,
            onChange: (v) => data.onChange(v),
            defaultValue: "gpt-3.5-turbo-1106",
            value: data.value,
          }}
        />
      )}
    </>
  );
};

export default OpenAiModelSelect;
