import { FormInputWrapper, Input as InputComponent } from "app/components";
import { rDarkMode, rFormState, rFormValidation } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { get } from "lodash";
import { getInputStyles } from "app/utils/inputUtils";
import { isFrontlyAdmin } from "app/utils/utils";
import useUtils from "../useUtils";

const Input = ({ page, block }) => {
  const darkMode = useRecoilValue(rDarkMode);
  const [formState, setFormState] = useRecoilState(rFormState);

  const formValidation = useRecoilValue(rFormValidation);
  const { processDynamicText } = useUtils();

  const validationError = get(formValidation, block.key, null);

  const fieldId = get(block, "key");
  const concatId = `${page.id}-${fieldId}`;

  const onChange = (v) => {
    setFormState((prev) => ({
      ...prev,
      [concatId]: v,
    }));
  };

  const value = get(
    formState,
    concatId,
    processDynamicText({ text: get(block, "defaultValue", "") })
  );

  const handleKeyDown = (event) => {
    event.preventDefault();
  };

  const disabledProps = isFrontlyAdmin
    ? {
        readOnly: true,
        tabIndex: -1,
        onKeyDown: handleKeyDown,
      }
    : {};

  return (
    <FormInputWrapper error={validationError}>
      <InputComponent
        data={{
          id: `formInput__${block.id}`,
          onChange,
          value,
          disabled: block.disabled,
          placeholder: processDynamicText({ text: block.placeholder }),
          ...getInputStyles(block, validationError, darkMode),
          ...disabledProps,
          backgroundColor: block.disabled && "#F7F9FD",
        }}
      />
    </FormInputWrapper>
  );
};

export default Input;
