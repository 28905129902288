import { colors } from "app/utils/theme";
import styled from "styled-components";

export const ContinueButtonContainer = styled.div`
  background: rgb(0, 85, 255);
  color: white;
  font-weight: 500;
  font-size: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  pading: 15px;
  width: 150px;
  height: 50px;
  margin-top: 30px;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}
  &:hover {
    filter: brightness(90%);
  }
`;

export const AppButton = styled.div`
  outline: 10px solid #ffffff50;
  background: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  width: 300px;
  gap: 20px;
  cursor: pointer;
  &:hover {
    outline: 10px solid #ffffff80;
  }
  @media screen and (max-width: 800px) {
    outline: 5px solid #ffffff50;
    &:hover {
      outline: 5px solid #ffffff80;
    }
    width: 100%;
    padding: 20px;
    gap: 10px;
  }
`;

export const DeleteButton = styled.div`
  background: ${colors.grey2};
  color: white;
  font-weight: 500;
  font-size: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
  min-height: 26px;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}
  &:hover {
    filter: brightness(90%);
  }
`;

export const AppButtonsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  margin: 10px 0 0 0;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin: 20px 0 0 0;
    gap: 30px;
  }
`;

export const EditOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 10px 0 50px 0;
  flex-wrap: wrap;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin: 20px 0 0 0;
    gap: 30px;
  }
`;
