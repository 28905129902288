import { isFrontlyAdmin, safeArray } from "./utils/utils";
import { rLiveSpreadsheets, rSavedSpreadsheets } from "./utils/recoil";

import { get } from "lodash";
import { useRecoilValue } from "recoil";

const useListData = () => {
  const spreadsheets = useRecoilValue(rLiveSpreadsheets);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const getListData = (b) => {
    // SETUP MODE

    const sheetId = get(b, "spreadsheet");
    const sheet = savedSpreadsheets.find((s) => s.id === sheetId);

    // LIVE MODE
    const liveData = get(spreadsheets, get(b, "id"), []);

    // PICK SETUP OR LIVE MODE DATA
    let finalData = safeArray(
      isFrontlyAdmin ? get(sheet, "data", []) : liveData
    );

    return finalData;
  };

  return {
    getListData,
  };
};

export default useListData;
