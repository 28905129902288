import { Icon, Row, Text } from "app/components";
import { get, startCase } from "lodash";
import {
  rConfirmationModalData,
  rOrganization,
  rPreviewApp,
  rSubscription,
} from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { rCurrentStep } from "../state";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const steps = [
  "prompt",
  "spreadsheets",
  "sheet_columns",
  "sample_records",
  "pages",
  "page_blocks",
  "preview",
];

const Sidebar = ({ inAiFlow }) => {
  const [currentStep, setCurrentStep] = useRecoilState(rCurrentStep);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);
  const setPreviewApp = useSetRecoilState(rPreviewApp);

  const navigate = useNavigate();

  const subscription = useRecoilValue(rSubscription);

  let aiLimit = get(subscription, "ai_requests_limit", 5);

  const organization = useRecoilValue(rOrganization);

  const aiUsage = get(organization, "ai_usage", 0);

  const goToBilling = () => {
    setConfirmationModalData({
      title: "Leave AI session?",
      text: "You may lose unsaved work. Are you sure you want to continue?",
      confirm: () => {
        navigate("/billing");
      },
    });
  };

  const goToStep = (step) => {
    setConfirmationModalData({
      title: `Jump to the ${startCase(step)} step?`,
      text: `This will erase any data after the ${startCase(
        step
      )}. Are you sure you want to continue?`,
      confirm: () => {
        if (step === "prompt") {
          setCurrentStep(null);
          setPreviewApp(null);
        } else {
          setCurrentStep(step);
          setPreviewApp(null);
        }
      },
    });
  };

  const currentStepIndex = steps.indexOf(currentStep);

  return (
    <SidebarContainer>
      <div>
        <Row alignItems="center" gap="15px" margin="0 15px 15px 15px">
          {inAiFlow && (
            <IconWrapper
              background={"rgba(255,255,255,0.2)"}
              onClick={() => {
                if (currentStep) {
                  setConfirmationModalData({
                    title: "Leave AI Session?",
                    text: "This will erase any data from this app generation session. Are you sure you want to continue?",
                    confirm: () => {
                      navigate("/home");
                    },
                  });
                } else {
                  navigate("/home");
                }
              }}
            >
              <Icon
                data={{
                  icon: "FiArrowLeft",
                  color: "white",
                  hover: true,
                }}
              />
            </IconWrapper>
          )}
          <Logo src="https://res.cloudinary.com/frontly/image/upload/v1687922385/frontly_white_xmcwyt.svg" />
        </Row>
        <div>
          {steps.map((step, index) => {
            const active =
              step === currentStep || (!currentStep && step === "prompt");

            const isPreviousStep = index < currentStepIndex;

            return (
              <StepContainer
                onClick={() => (isPreviousStep ? goToStep(step) : null)}
                isPreviousStep={isPreviousStep}
                active={active}
              >
                <Text
                  data={{
                    text: `${index + 1}. ${startCase(step)}`,
                    cursor: "pointer",
                    color: "white",
                    fontStyle: active ? "headingMd" : "bodyLg",
                  }}
                />
                {isPreviousStep && (
                  <Icon
                    data={{
                      icon: "FiCheckCircle",
                      color: "rgba(255,255,255,0.4)",
                      size: 20,
                    }}
                  />
                )}
              </StepContainer>
            );
          })}
        </div>
      </div>
      <div>
        <TokenUsage>
          <Text
            data={{
              text: `${aiUsage} / ${aiLimit} Monthly AI Tokens`,
              fontStyle: "bodyLg",
              color: "#ffffff90",
            }}
          />
          <Text
            data={{
              text: "Buy More Tokens",
              fontStyle: "headingSm",
              onClick: goToBilling,
              color: "#ffffff",
            }}
          />
        </TokenUsage>
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;

const StepContainer = styled.div`
  padding: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  :last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  opacity: ${(p) => (p.isPreviousStep ? "0.7" : "1.0")};
  &:hover {
    ${(p) =>
      p.isPreviousStep &&
      `
      background: rgba(255, 255, 255, 0.1);
    `}
  }

  ${(p) => p.active && `background: rgba(255, 255, 255, 0.1);`}
`;

const SidebarContainer = styled.div`
  width: 250px;
  padding: 15px 0 15px 0;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.05);
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 46px;
  @media screen and (max-width: 800px) {
    margin-bottom: 0px;
  }
`;

const IconWrapper = styled.div`
  background: ${(p) => p.background || "#e4e5e7"};
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
`;

const TokenUsage = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  gap: 5px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px 0px 10px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
