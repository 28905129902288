import { EditOption, Main, NewOptionButton } from "../components";
import { rCurrentContext, rCurrentStep } from "../state";

import { EditOptions } from "../styles";
import { get } from "lodash";
import { useRecoilState } from "recoil";

// Spreadsheets
const Spreadsheets = () => {
  const [currentContext, setCurrentContext] = useRecoilState(rCurrentContext);
  const [currentStep, setCurrentStep] = useRecoilState(rCurrentStep);

  const sheets = get(currentContext, "spreadsheets", []);
  return (
    <Main
      label="Spreadsheets"
      description="Based on your prompt, we think these spreadsheets might work for your app. Feel free to make modifications, then submit to continue."
    >
      <EditOptions>
        {sheets.map((s, i) => (
          <EditOption
            key={i}
            value={s}
            placeholder={"Spreadsheet Name"}
            onChange={(v) => {
              setCurrentContext((prev) => {
                const newSheets = [...prev.spreadsheets];
                newSheets[i] = v;
                return { ...prev, spreadsheets: newSheets };
              });
            }}
            onDelete={() => {
              setCurrentContext((prev) => {
                const newSheets = [...prev.spreadsheets];
                newSheets.splice(i, 1);
                return { ...prev, spreadsheets: newSheets };
              });
            }}
          />
        ))}
        <NewOptionButton
          onClick={() =>
            setCurrentContext((prev) => {
              return {
                ...prev,
                [currentStep]: [...prev[currentStep], "New Spreadsheet"],
              };
            })
          }
        />
      </EditOptions>
    </Main>
  );
};

export default Spreadsheets;
