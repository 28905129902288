import { getPixels, isObjectEqualFromKeys } from "app/utils/utils";
import { memo, useMemo } from "react";

import { Icon } from "app/components";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { paddingObjToString } from "./Text";
import styled from "styled-components";

const StyledImage = styled.img`
  width: 100%;
  object-fit: ${(p) => p.objectFit};
  position: absolute;
  border-radius: ${(p) => p.borderRadius};
  height: 100%;
  ${(p) => p.objectPosition && `object-position: ${p.objectPosition};`}
`;

const Placeholder = styled.div`
  width: 100%;
  position: absolute;
  border-radius: ${(p) => p.borderRadius};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.grey2};
`;

const Image = ({ block, actionResolver, appStyling, processDynamicText }) => {
  const repeatingRecord = get(block, "repeatingRecord");
  const repeatingRecordId = get(repeatingRecord, "frontly_id");

  let blockId = get(block, "id");
  if (repeatingRecordId) {
    blockId = `${blockId}-${repeatingRecordId}`;
  }

  const url = processDynamicText({
    text: get(block, "url", ""),
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord },
  });

  const onClick = () =>
    actionResolver({
      rawAction: get(block, ["actionMap", "clickAction"]),
      reusableBlockId: block.reusableBlockId,
      actionId: get(block, "clickAction"),
      blockId,
      context: { repeatingRecord },
    });

  const imageHeight = get(block, "height", 400);

  const hasValidImageExtension = (url) => {
    if (!url) return false;

    return true;
    // const validExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
    // let hasValidExtension = false;
    // validExtensions.forEach((ext) => {
    //   if (url.includes(ext)) {
    //     hasValidExtension = true;
    //   }
    // });

    // return hasValidExtension;
  };

  const validatedUrl = hasValidImageExtension(url) ? url : null;

  const placeholderIconHeight = parseInt(imageHeight) > 200 ? 40 : 20;

  const borderRadius = paddingObjToString(
    block.borderRadius,
    getPixels(get(appStyling, "blockBorderRadius") || 8)
  );

  const renderImage = useMemo(
    () => (
      <div
        style={{
          height: getPixels(block.height) || "400px",
          width: "100%",
          position: "relative",
        }}
      >
        {!validatedUrl && get(block, "showEmptyPlaceholder") ? (
          <Placeholder borderRadius={borderRadius} height={imageHeight}>
            <Icon
              data={{
                icon: "FiImage",
                color: colors.grey3,
                size: placeholderIconHeight,
              }}
            />
          </Placeholder>
        ) : (
          <StyledImage
            src={url}
            // src={resizeImage({
            //   url: url,
            //   height: maxHeight * 1.2,
            // })}
            borderRadius={borderRadius}
            height={imageHeight}
            objectFit={get(block, "objectFit", "cover")}
            objectPosition={get(block, "objectPosition")}
            onClick={onClick}
          />
        )}
      </div>
    ),
    [block, imageHeight, url]
  );

  return renderImage;
};

export default Image;
