import ChartNew from "app/components/charts/Chart";
import ChartOld from "./ChartOld";
import { get } from "lodash";

const Chart = ({ block, page }) => {
  const chartType = get(block, "chartType", "Bar");

  if (["BarChart", "PieChart"].includes(chartType)) {
    return <ChartNew block={block} page={page} />;
  }

  return <ChartOld block={block} page={page} />;
};

export default Chart;
