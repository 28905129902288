import { useEffect, useState } from "react";

import { get } from "lodash";

const useContainerDimensions = (myRef, screenSizeMode) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    offsetTop: 0,
  });

  const getDimensions = (myRef) => {
    const ref = get(myRef, "current");
    return {
      offsetTop: get(ref, "offsetTop", 0),
      offsetLeft: get(ref, "offsetLeft", 0),
      width: get(ref, "offsetWidth", 0),
      height: get(ref, "offsetHeight", 0),
    };
  };

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(myRef));
    };
    setDimensions(getDimensions(myRef));
    handleResize();
    setTimeout(handleResize, 0);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef, screenSizeMode]);

  return dimensions;
};

export default useContainerDimensions;
