import { Column, Text } from ".";

import Spinner from "./Spinner";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rSpinner } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const FullScreenLoaderClient = () => {
  const spinner = useRecoilValue(rSpinner);
  const text = get(spinner, "text");

  if (!spinner) {
    return null;
  }

  return (
    <Loader>
      <Column gap="30px" alignItems="center" justifyContent="center">
        <Spinner color={"black"} size={40} />
        {text && (
          <Text
            data={{ text, fontSize: 20, fontWeight: 500, color: "black" }}
          />
        )}
      </Column>
    </Loader>
  );
};

export default FullScreenLoaderClient;

const Loader = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
