import { rApp, rSubscription } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "../components/AdminForm";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const Branding = () => {
  const [app, setApp] = useRecoilState(rApp);

  const [changes, setChanges] = useState(false);

  const subscription = useRecoilValue(rSubscription);

  const save = () => {
    setChanges(false);
    successNotification("Saved");
    apiRequest.post("/app_settings/", {
      primary_color: get(app, "primary_color"),
      logo: get(app, "logo"),
      navigation_logo: get(app, "navigation_logo"),
      favicon: get(app, "favicon"),
      logo_font: get(app, "logo_font"),
      hide_branding: get(app, "hide_branding"),
      seo_description: get(app, "seo_description"),
      seo_image: get(app, "seo_image"),
    });
  };

  let fields = [
    {
      id: "primary_color",
      label: "Primary Color",
      componentId: "ColorPicker",
      value: get(app, "primary_color"),
    },
    {
      id: "logo",
      label: "Logo",
      componentId: "ImageUpload",
      hint: "Upload a logo to be used in your app navigation and login / signup pages",
      value: get(app, "logo"),
    },
    // {
    //   id: "seo_description",
    //   label: "SEO Description",
    //   componentId: "Input",
    //   hint: "Write a description for your app's Search Engine Optimization.",
    //   value: get(app, "seo_description"),
    // },
    // {
    //   id: "seo_image",
    //   label: "SEO Image",
    //   componentId: "ImageUpload",
    //   hint: "Upload a logo to be used in your app's SEO preview when sharing the link.",
    //   value: get(app, "seo_image"),
    // },
    {
      id: "navigation_logo",
      label: "Navigation Logo",
      componentId: "ImageUpload",
      hint: "Upload a logo to be used in your app navigation. If left blank, your primary logo will be used.",
      value: get(app, "navigation_logo"),
    },
    {
      id: "favicon",
      label: "Favicon",
      hint: "Upload an image to be displayed in the browser tab",
      componentId: "ImageUpload",
      value: get(app, "favicon"),
    },
    {
      id: "hide_branding",
      label: "Hide Frontly Badge",
      componentId: "Switch",
      hint: "On published free apps, there is a Made With Frontly badge in the bottom corner. On paid plans, you can disable this badge.",
      disabled: !subscription,
      paidFeature: !subscription,
      value: get(app, "hide_branding"),
    },
  ];

  return (
    <SettingsSection
      title="Branding"
      description="Add your own brand including colors, logo and other settings"
    >
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="5px"
        fields={fields}
        submitText={"Save Changes"}
        submit={changes && save}
        width="220px"
        orientation={"horizontal"}
        onChange={(k, v) => {
          setChanges(true);
          setApp({
            [k]: v,
          });
        }}
      />
    </SettingsSection>
  );
};

export default Branding;
