// import * as AdminComponents from "app/adminApp/components";
import * as Components from "app/components";

import CustomFilterButton, { CustomFilterInput } from "./CustomFilterButton";
import { get, isNil } from "lodash";
import { rDarkMode, rTranslations } from "app/utils/recoil";

import { colors } from "app/utils/theme";
import { parseBoolean } from "app/utils/utils";
import { relativeDateOptions } from "./RelativeDate";
import { useRecoilValue } from "recoil";

const NewFilter = ({ data }) => {
  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const icon = get(data, "icon");
  const componentId = get(data, "componentId", "Input");
  const operator = get(data, "operator", "equals");
  const options = get(data, "options", []);

  const clear = () => data.onChange(null);

  const theme = get(data, "theme", "white");

  // GET BACKGROUND AND BORDER
  let background = "#eceef2";
  let border = `1px solid ${colors.grey2}`;
  let iconColor = colors.grey4;
  let textColor = colors.default;

  if (theme === "white") {
    background = "white";
  }

  if (darkMode) {
    background = colors.darkModeInputBackground;
    border = `1px solid ${colors.darkModeInputBorder}`;
    iconColor = colors.grey3;
    textColor = "white";
  }

  const obj = {
    icon,
    showLabel: data.showLabel,
    placeholder: data.placeholder,
    label: data.label,
    value: data.value,
    operator: operator,
    componentId,
    iconColor,
    darkMode,
    background,
    border,
    theme,
    width: data.width,
    displayValue: data.displayValue,
    textColor,
    onChange: (v) => data.onChange(v),
    clear,
  };

  // SELECT / MULTISELECT
  if (["Select", "MultiSelect"].includes(componentId)) {
    const updatedObj = {
      ...obj,
      value: options.find((o) => o.value === data.value)?.label,
    };

    // Options - SELECT
    let customOptions = options.map((o) => ({
      label: o.label,
      onClick: () => data.onChange(o.value),
      active: o.value === data.value,
    }));

    // Options - MULTI - SELECT
    if (componentId === "MultiSelect") {
      const splitValues = data.value
        ? data.value.split(",").map((v) => v.trim())
        : [];

      customOptions = options.map((o) => ({
        label: o.label,
        onClick: () => {
          let newSplitValues = [...splitValues];

          if (newSplitValues.includes(o.value)) {
            newSplitValues.splice(newSplitValues.indexOf(o.value), 1);
          } else {
            newSplitValues.push(o.value);
          }

          const newMergedValues = newSplitValues.join(",");

          data.onChange(newMergedValues);
        },
        active: splitValues.includes(o.value),
      }));
    }

    let finalOptions = [
      {
        label: get(translations, "filterAny", "any"),
        active: isNil(data.value),
        onClick: clear,
      },
      ...customOptions,
    ];

    // HANDLE MULTI SELECT
    return (
      <Components.SimpleDropdown
        data={{
          allowSearch: true,
          darkMode,
          optionMinWidth: "120px",
          options: finalOptions,
          component: <CustomFilterButton {...updatedObj} />,
        }}
      />
    );
  } else if (componentId === "DateTimePicker") {
    return (
      <Components.DateTimePicker
        data={{
          ...data,
          filterButton: obj,
        }}
      />
    );
  } else if (componentId === "NumericRange") {
    return (
      <Components.NumericRange
        data={{
          ...data,
          filterButton: obj,
        }}
      />
    );
  } else if (componentId === "DateRangePicker") {
    return (
      <Components.DateRangePicker
        data={{
          ...data,
          filterButton: obj,
        }}
      />
    );
  } else if (componentId === "RelativeDate") {
    const updatedObj = {
      ...obj,
      value: relativeDateOptions.find((o) => o.value === data.value)?.label,
    };

    return (
      <Components.SimpleDropdown
        data={{
          darkMode,
          optionMinWidth: "120px",
          options: [
            {
              label: get(translations, "filterAny", "Any"),
              active: isNil(data.value),
              onClick: clear,
            },
            ...relativeDateOptions.map((o) => ({
              label: o.label,
              onClick: () => data.onChange(o.value),
              active: o.value === data.value,
            })),
          ],
          component: <CustomFilterButton {...updatedObj} />,
        }}
      />
    );
  } else if (componentId === "Boolean") {
    return (
      <Components.SimpleDropdown
        data={{
          darkMode,
          optionMinWidth: "120px",
          options: [
            {
              label: get(translations, "filterAny", "Any"),
              active: isNil(data.value),
              onClick: clear,
            },
            {
              label: get(translations, "true", "True"),
              onClick: () => data.onChange("TRUE"),
              active: !isNil(data.value)
                ? parseBoolean(data.value) === true
                : false,
            },
            {
              label: get(translations, "false", "False"),
              onClick: () => data.onChange("FALSE"),
              active: !isNil(data.value)
                ? parseBoolean(data.value) === false
                : false,
            },
          ],
          component: <CustomFilterButton {...obj} />,
        }}
      />
    );
  } else if (componentId === "Input") {
    return <CustomFilterInput {...obj} onChange={(v) => data.onChange(v)} />;
  } else {
    return <Components.Input data={{ ...data, width: "200px" }} />;
  }
};

export default NewFilter;
