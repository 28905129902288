import "@mdxeditor/editor/style.css";

import MarkdownEditorNew from "./MarkdownEditorNew";
import MarkdownEditorOld from "./MarkdownEditorOld";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import { useRecoilValue } from "recoil";

const MarkdownEditor = ({ data }) => {
  const app = useRecoilValue(rApp);

  const newMarkdownEditor = get(app, "new_markdown_editor", false);

  if (newMarkdownEditor) {
    return <MarkdownEditorNew data={data} />;
  }

  return <MarkdownEditorOld data={data} />;
};

export default MarkdownEditor;
