const fieldKeys = [
  {
    key: "label",
    label: "Label",
    placeholder: "Label",
    componentId: "Input",
    width: "100%",
  },
  {
    key: "icon",
    label: "Icon",
    componentId: "IconBrowser",
    width: "100%",
  },
  {
    id: "clickAction",
    label: "Click Action",
    componentId: "Action",
    isAction: true,
    isMenuBlock: true,
  },
  {
    id: "conditions",
    label: "Display Conditions",
    componentId: "DisplayConditions",
    orientation: "vertical",
  },
];

export const Menu = {
  componentId: "Menu",
  resources: [
    {
      id: "items",
      label: "Menu Item",
      componentId: "MultiForm",
      labelSingular: "Menu Item",
      passIdToChild: true,
      width: "300px",
      fields: fieldKeys,
    },
    {
      id: "icon",
      label: "Icon",
      componentId: "Select",
      defaultValue: "FiMoreHorizontal",
      hideEmptyItem: true,
      options: [
        {
          label: "Horizontal Dots",
          value: "FiMoreHorizontal",
        },
        {
          label: "Vertical Dots",
          value: "FiMoreVertical",
        },
        {
          label: "Hamburger Menu",
          value: "FiMenu",
        },
      ],
    },
    {
      id: "color",
      label: "Color",
      componentId: "ColorPicker",
      isStyle: true,
    },
    {
      id: "height",
      label: "Height",
      section: "content",
      type: "number",
      hint: "The height of the icon in pixels",
      componentId: "Input",
      defaultValue: 25,
      isStyle: true,
    },
  ],
};
