import { Select } from ".";

// TODO - Allow users to add custom intervals with labels, and then sort by value
export const relativeDateOptions = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 7 Days",
    value: 7,
  },
  {
    label: "Last 30 Days",
    value: 30,
  },
  {
    label: "Last 3 Months",
    value: 90,
  },
  {
    label: "Last 6 Months",
    value: 180,
  },
  {
    label: "Last 12 Months",
    value: 365,
  },
];

const RelativeDate = ({ data }) => {
  return <Select data={{ ...data, options: relativeDateOptions }} />;
};

export default RelativeDate;
