export const Iframe = {
  componentId: "Iframe",
  resources: [
    {
      id: "url",
      label: "Url",
      section: "content",
      hint: "The URL of the external page you want to display",
      componentId: "DynamicString",
      defaultValue: "https://google.com",
      wizard: true,
    },
    {
      id: "height",
      label: "Height",
      section: "content",
      type: "number",
      hint: "The height of the Iframe in pixels",
      componentId: "Input",
      defaultValue: 300,
    },
  ],
};
