import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { Icon, Modal, Row, Switch, Text } from "app/components";
import { get, startCase } from "lodash";
import { rAppDateFormat, rSavedSpreadsheets } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "./AdminForm";
import { arrayMove } from "app/utils/utils";
import { colors } from "app/utils/theme";
import { getFormFields } from "app/renderingApp/blocks/Form/utils";
import styled from "styled-components";
import useDynamicText from "app/renderingApp/useDynamicText";
import { useParams } from "react-router-dom";
import { useState } from "react";

const typeIconMap = {
  text: "FiType",
  integer: "FiHash",
  float: "FiHash",
  date: "FiCalendar",
  time: "FiClock",
  boolean: "FiToggleRight",
  array: "FiList",
};

const DataSourceFields = ({ setChanges }) => {
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [activeFieldId, setActiveFieldId] = useState(null);

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const { processDynamicText } = useDynamicText();

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const { id } = useParams();

  const currentSheet = savedSpreadsheets.find((s) => s.id == id);

  // SHEET FIELD DATA
  const sheetFieldData = get(currentSheet, "field_data", {});
  const sheetConfig = get(sheetFieldData, "config", {});
  const sheetOrder = get(sheetFieldData, "order", []);

  const activeField = get(sheetConfig, activeFieldId, {});

  const headers = [...get(currentSheet, "headers", [])];

  const { displayFields, displayOrder } = getFormFields({
    appDateFormat,
    processDynamicText,
    sheetOrder,
    sheetHeaders: headers,
    sheetFieldData: sheetConfig,
    showInactive: true,
  });

  const setFieldConfig = (fieldId, key, value) => {
    const newSpreadsheets = savedSpreadsheets.map((s) => {
      if (s.id == id) {
        let newObj = {
          ...get(sheetConfig, fieldId, {}),
          [key]: value,
        };

        if (key === "type") {
          newObj = {
            ...newObj,
            componentId: get(
              {
                text: "Input",
                float: "Input",
                integer: "Input",
                date: "DateTimePicker",
                boolean: "Switch",
              },
              value,
              "Input"
            ),
          };
        }

        return {
          ...s,
          field_data: {
            ...sheetFieldData,
            config: {
              ...sheetConfig,
              [fieldId]: newObj,
            },
          },
        };
      }
      return s;
    });

    setSavedSpreadsheets(newSpreadsheets);
    setChanges(true);
  };

  const setOrder = (order) => {
    const newSpreadsheets = savedSpreadsheets.map((s) => {
      if (s.id == id) {
        return { ...s, field_data: { ...sheetFieldData, order } };
      }
      return s;
    });

    setSavedSpreadsheets(newSpreadsheets);
    setChanges(true);
  };

  const activeFieldType = get(activeField, "type", "text");

  const getComponentIdOptions = () => {
    if (activeFieldType === "text") {
      return [
        { label: "Basic Input", value: "Input" },
        { label: "Long Text Area", value: "TextArea" },
        { label: "Select", value: "Select" },
        { label: "Image Upload", value: "ImageUpload" },
        { label: "File Upload", value: "FileUpload" },
      ];
    } else if (["integer", "float"].includes(activeFieldType)) {
      return [
        { label: "Basic Input", value: "Input" },
        { label: "Select", value: "Select" },
        { label: "Multi Select", value: "MultiSelect" },
      ];
    } else if (activeFieldType === "boolean") {
      return [
        { label: "Toggle Switch", value: "Switch" },
        { label: "Checkbox", value: "Checkbox" },
      ];
    } else if (activeFieldType === "date") {
      return [{ label: "Date Picker", value: "DateTimePicker" }];
    }

    // Fallback
    return [{ label: "Basic Input", value: "Input" }];
  };

  const componentIdOptions = getComponentIdOptions();

  const fields = [
    // This is the actual data type of the raw data, not the UI component
    {
      id: "type",
      label: "Data Type",
      componentId: "LargeIconSelect",
      required: true,
      defaultValue: "text",
      hint: "The type of data stored in this field.",
      layout: "horizontal",
      options: [
        {
          label: "Text",
          value: "text",
          icon: "FiType",
        },
        {
          label: "Integer",
          value: "integer",
          icon: "FiHash",
        },
        {
          label: "Float",
          value: "float",
          icon: "FiHash",
        },
        {
          label: "Date",
          value: "date",
          icon: "FiCalendar",
        },
        {
          label: "True/False",
          value: "boolean",
          icon: "FiToggleRight",
        },
        // {
        //   label: "Array",
        //   value: "array",
        //   icon: "FiList",
        // },
      ],
    },
    // This is the UI component, which can often be directly inferred from the type
    {
      id: "componentId",
      label: "Default UI Component",
      hint: "The default visual component used to display this field throughout your forms and other blocks. This does not apply to all components and can be overridden on a per-block basis.",
      componentId: "Select",
      required: true,
      defaultValue: "Input",
      hideEmptyItem: true,
      options: componentIdOptions,
      displayCondition: () => componentIdOptions.length > 1,
    },
    {
      displayCondition: (field) =>
        get(field, "componentId") === "DateTimePicker",
      id: "dateFormat",
      label: "Date Format",
      componentId: "DateFormatConfig",
      enabledFields: [
        "inputDate",
        "outputDate",
        "inputTime",
        "outputTime",
        "showAmPm",
      ],
    },
    {
      id: "options",
      label: "Options",
      componentId: "DataGrid",
      orientation: "vertical",
      hint: "Define the options to appear in your select dropdown",
      requiresSheet: true,
      columns: [
        {
          key: "label",
          componentId: "Input",
        },
        {
          key: "value",
          componentId: "Input",
        },
      ],
      displayCondition: (field) =>
        ["Select", "MultiSelect"].includes(get(field, "componentId")),
    },
    {
      id: "label",
      label: "Custom Label",
      hint: "Override the default label, which is the field name",
      componentId: "Input",
      required: true,
      defaultValue: "",
      advanced: true,
    },
    {
      id: "validation",
      label: "Validation",
      componentId: "DataSourceValidation",
      dataType: activeFieldType,
    },
  ];

  const initialFields = fields.map((f) => ({
    ...f,
    value: get(activeField, f.id),
  }));

  const editFields = initialFields
    .filter((f) => !f.advanced || showAdvanced)
    .filter(
      (f) =>
        !f.displayCondition ||
        (f.displayCondition && f.displayCondition(activeField))
    );

  const hasAdvancedSettings =
    initialFields.filter((f) => f.advanced).length > 0;

  return (
    <div>
      {activeFieldId && (
        <Modal
          minWidth="450px"
          hide={() => setActiveFieldId(null)}
          header={{
            title: `Edit Field: ${startCase(activeFieldId)}`,
          }}
        >
          <AdminForm
            labelStyle={"headingSm"}
            sectionPadding="0px"
            fields={editFields}
            onChange={(k, v) => setFieldConfig(activeFieldId, k, v)}
          />

          {hasAdvancedSettings && (
            <Text
              data={{
                text: `${showAdvanced ? "Hide" : "Show"} Advanced Settings`,
                color: colors.primary,
                margin: "10px 0 0 0",
                fontStyle: "headingSm",
                onClick: () => setShowAdvanced(!showAdvanced),
              }}
            />
          )}
        </Modal>
      )}

      {displayFields.length > 0 && (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: `1px solid ${colors.grey2}`,
            background: "white",
          }}
          dragHandleSelector=".drag-item"
          lockAxis="y"
          onDrop={(e) => {
            const { addedIndex, removedIndex } = e;
            const movedItems = arrayMove(
              displayOrder,
              removedIndex,
              addedIndex
            );

            setOrder(movedItems);
          }}
        >
          {displayFields.map((f) => (
            <Draggable key={f.key}>
              <Field
                {...{
                  field: f,
                  setEditField: setActiveFieldId,
                  setFieldConfig,
                }}
              />
            </Draggable>
          ))}
        </Container>
      )}

      {displayFields.length === 0 && <Text data={{ text: "No Fields" }} />}
    </div>
  );
};

export default DataSourceFields;

const Field = ({ field, setEditField, setFieldConfig }) => {
  const [showEdit, setShowEdit] = useState(false);
  const type = get(field, "type", "text");

  let icon = get(typeIconMap, type);

  if (get(field, "componentId") === "ImageUpload") {
    icon = "FiImage";
  }

  return (
    <FieldContainer
      disabled={!field.active}
      onMouseEnter={() => {
        if (field.active && !showEdit) {
          setShowEdit(true);
        }
      }}
      onMouseLeave={() => {
        if (field.active && showEdit) {
          setShowEdit(false);
        }
      }}
      className="drag-item"
      onClick={() => {
        if (field.active) {
          setEditField(field.key);
        }
      }}
    >
      <Row alignItems="center">
        <Icon
          data={{
            icon,
            color: colors.grey3,
            size: 16,
          }}
        />
        <Text
          data={{
            text: startCase(field.key),
            cursor: "pointer",
            margin: "0 6px 0 10px",
            fontSize: 16,
            fontWeight: 500,
          }}
        />
        {showEdit && (
          <Icon
            data={{
              icon: "FiEdit",
              color: colors.grey3,
              size: 14,
            }}
          />
        )}
      </Row>
      <Switch
        data={{
          value: field.active,
          onChange: (v) => setFieldConfig(field.id, "active", v),
        }}
      />
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  padding: 12px 0px 12px 0px;
  border-bottom: 1px solid ${colors.grey2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: 200ms all;
  &:hover {
    background: ${colors.grey1};
  }
  :not(:last-child) {
    border-bottom: 1px solid ${colors.grey1};
  }
`;
