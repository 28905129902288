export const Progress = {
  componentId: "Progress",
  resources: [
    {
      id: "orientation",
      label: "Orientation",
      componentId: "Select",
      orientation: "horizontal",
      defaultValue: "responsive",
      hideEmptyItem: true,
      options: [
        { value: "horizontal", label: "Horizontal" },
        { value: "vertical", label: "Vertical" },
        { value: "responsive", label: "Responsive" },
      ],
    },
    {
      id: "hideTextHorizontal",
      label: "Hide Text When Horizontal",
      componentId: "Switch",
      orientation: "horizontal",
      defaultValue: false,
    },
    {
      id: "steps",
      label: "Steps",
      componentId: "MultiForm",
      labelSingular: "Step",
      passIdToChild: true,
      section: "badge",
      width: "300px",
      fields: [
        {
          key: "label",
          label: "Label",
          placeholder: "Label",
          componentId: "DynamicString",
          width: "100%",
        },
        {
          key: "description",
          label: "Description",
          placeholder: "Description",
          componentId: "DynamicString",
          width: "100%",
        },
        {
          id: "conditions",
          label: "Conditions",
          componentId: "DisplayConditions",
          orientation: "vertical",
        },
      ],
    },
  ],
};
