export const Switch = {
  componentId: "Switch",
  resources: [
    {
      id: "key",
      label: "Field ID",
      componentId: "Input",
      hint: "This ID is used to reference the field in the submit action form variable",
    },
    {
      id: "backgroundWidth",
      label: "Background Width",
      section: "size",
      type: "number",
      hint: "The width of the switch background in pixels",
      componentId: "Input",
      defaultValue: 28,
      isStyle: true,
    },
    {
      id: "ballHeight",
      label: "Ball Height",
      section: "size",
      type: "number",
      hint: "The size of the switch ball in pixels",
      componentId: "Input",
      defaultValue: 12,
      isStyle: true,
      min: 10,
    },
    {
      id: "defaultValue",
      label: "Default Value",
      hint: "Enter a default value for this field",
      componentId: "Input",
      required: false,
      defaultValue: "",
    },
    {
      id: "validation",
      label: "Validation",
      componentId: "FormValidationSimple",
    },
  ],
};
