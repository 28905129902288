import { get } from "lodash";
import { isFrontlyAdmin } from "./utils";
import { useLocation } from "react-router-dom";

const useIsCustomBlock = () => {
  const location = useLocation();
  const path = get(location, "pathname");

  return isFrontlyAdmin && path.includes("custom-block/");
};

export default useIsCustomBlock;
