import {
  cardFieldKeys,
  cardKeys,
  listBlockResources,
  resultsPerPage,
  sortingColumn,
  sortingDirection,
} from "./shared";

export const Grid = {
  componentId: "Grid",
  resources: [
    ...listBlockResources,
    {
      id: "fields",
      label: "Grid Card Fields",
      componentId: "DynamicFields",
      keys: cardFieldKeys,
      requiresSheet: true,
      section: "card",
      hint: "Add multiple text fields to display on the grid card",
    },
    {
      id: "image",
      label: "Image",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      section: "card",
      hint: "The spreadsheet column to use for the card image (optional)",
    },
    {
      id: "imageFillCard",
      label: "Image Fills Card Width",
      section: "image",
      isStyle: true,
      orientation: "horizontal",
      componentId: "Switch",
      requiresSheet: true,
    },
    {
      id: "imageHeight",
      label: "Image Height",
      section: "image",
      isStyle: true,
      requiresSheet: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "150px",
      options: [
        { label: "50px", value: "50px" },
        { label: "100px", value: "100px" },
        { label: "150px", value: "150px" },
        { label: "200px", value: "200px" },
        { label: "250px", value: "250px" },
        { label: "300px", value: "300px" },
      ],
    },
    {
      id: "objectFit",
      label: "Image Fit",
      section: "image",
      isStyle: true,
      requiresSheet: true,
      hint: "Determine how your images are sized within the card. You can set them to stretch or fill the card, or to fit inside the card with blank space around them.",
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "cover",
      options: [
        { label: "Cover", value: "cover" },
        { label: "Contain", value: "contain" },
        { label: "Fill", value: "fill" },
      ],
    },
    {
      id: "gridMode",
      label: "Resize",
      section: "size",
      isStyle: true,
      hint: "Determine whether your grid items should flex in size to fit the screen (dynamic) or stay a fixed size (static)",
      requiresSheet: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "dynamic",
      options: [
        { label: "Dynamic", value: "dynamic" },
        { label: "Static", value: "static" },
      ],
    },
    {
      id: "cardWidth",
      label: "Card Width",
      section: "size",
      isStyle: true,
      requiresSheet: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "220px",
      options: [
        { label: "150px", value: "150px" },
        { label: "220px", value: "220px" },
        { label: "250px", value: "250px" },
        { label: "300px", value: "300px" },
        { label: "350px", value: "350px" },
        { label: "400px", value: "400px" },
      ],
    },
    resultsPerPage,
    ...cardKeys,
    {
      id: "customBadgeColors",
      label: "Custom Colors",
      componentId: "MultiForm",
      labelSingular: "Custom Color",
      passIdToChild: true,
      section: "badge",
      width: "300px",
      fields: [
        {
          key: "label",
          label: "Label",
          placeholder: "Label",
          componentId: "Input",
          width: "100%",
        },
        {
          key: "color",
          label: "Color",
          componentId: "ColorPicker",
          width: "100%",
        },
        {
          id: "displayConditions",
          label: "Display Conditions",
          componentId: "DisplayConditions",
          orientation: "vertical",
        },
      ],
    },
    sortingColumn,
    sortingDirection,
  ],
};
