import { Autocomplete, TextField } from "@mui/material";
import { colors, fontObject } from "app/utils/theme";
import { getPixels, isFrontlyAdmin, parseIntFromPixels } from "app/utils/utils";

import InputBase from "@mui/material/InputBase";
import { get } from "lodash";
import { rTranslations } from "app/utils/recoil";
import { styled } from "@mui/material/styles";
import { useRecoilValue } from "recoil";

export const BootstrapInput = styled(InputBase)(({ theme, ...props }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    height: `${parseIntFromPixels(props.height) - 31}px`,
    borderRadius: getPixels(props.borderRadius || 8),
    position: "relative",
    backgroundColor: props.backgroundColor || "white",
    border: props.border || `1px solid ${colors.pitchBorder}`,
    fontSize: props.fontSize || 14,
    color: props.color,
    padding: props.padding || "7px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: getPixels(props.borderRadius || 8),
      borderColor: "1px solid rgb(225, 228, 232)",
      boxShadow: "none",
    },
  },
}));

const Select = ({ data }) => {
  const translations = useRecoilValue(rTranslations);

  const { onChange } = data;

  const isAdmin = isFrontlyAdmin;

  let options = get(data, "options", []);

  const selectText =
    get(data, "selectText") ||
    data.placeholder ||
    get(translations, "selectText") ||
    data.noneText ||
    "None";

  if (!data.hideEmptyItem && !data.required) {
    options = [
      {
        label: selectText,
        value: "",
      },
      ...options,
    ];
  }

  // HANDLE OBJECT OR STRING VALUE
  let value = get(data, "value") || get(data, "defaultValue") || "";

  const fontStyle = get(data, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);

  let backgroundColor = data.backgroundColor || data.background;

  return (
    <div
      style={{
        width: data.width || "100%",
        margin: data.margin,
        pointerEvents: data.disabled ? "none" : "auto",
      }}
    >
      <style>{`
          .MuiAutocomplete-inputRoot {
            border: ${
              data.border || `1px solid ${colors.pitchBorder}`
            } !important;
            background-color: ${backgroundColor} !important;
            border-radius: ${getPixels(data.borderRadius || 8)} !important;
            color: ${data.color};
            height: 39px;
            padding: 0 0 0 5px !important;
            margin: 0px !important;
            font-size: ${getPixels(get(styleObject, "fontSize"))} !important;
          }
        `}</style>
      <Autocomplete
        disabled={data.disabled}
        options={options}
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              sx={{
                "& fieldset": {
                  border: "0px",
                },
              }}
              placeholder={selectText}
              inputProps={{
                ...params.inputProps,
                readOnly: isAdmin || !data.allowSearch,
              }}
            />
          );
        }}
        value={options.find((o) => o.value === value) || null}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        onChange={(event, newValue) => onChange(newValue ? newValue.value : "")}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        size="small"
        sx={{
          width: "100%",
        }}
        disableClearable={!data.allowSearch}
      />
    </div>
  );
};

export default Select;
