import { Button } from "app/components";
import styled from "styled-components";

const Container = styled.div`
  background: #222c40;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  height: 35px;
  @media (max-width: 800px) {
    height: 25px;
  }
`;

const FreeTrialText = styled.div`
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  color: white;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

const UpgradeBanner = () => {
  return (
    <Container
      onClick={() => {
        window.open("https://app.frontly.ai/billing");
      }}
    >
      <Logo src="https://res.cloudinary.com/frontly/image/upload/v1687922385/frontly_white_xmcwyt.svg" />
      <Row>
        <FreeTrialText>
          This app is on a free trial. Upgrade to remove this banner.
        </FreeTrialText>
        <Button data={{ text: "Upgrade" }} />
      </Row>
    </Container>
  );
};

export default UpgradeBanner;
