import { Select } from "app/components";
import { get } from "lodash";
import { rPages } from "app/utils/recoil";
import { useRecoilValue } from "recoil";

const PageSelect = ({ data }) => {
  const pages = useRecoilValue(rPages);

  let options = pages.map((p) => ({
    label: get(p, "name"),
    value: parseInt(get(p, "id")),
  }));

  if (data.showExternalLink) {
    options.push({ label: "External Link", value: "externalLink" });
  }

  let value = get(data, "value");
  if (get(data, "value") !== "externalLink") {
    value = parseInt(value);
  }

  return (
    <Select
      data={{
        ...data,
        onChange: (v) =>
          data.onChange(v === "externalLink" ? "externalLink" : parseInt(v)),
        value,
        options,
      }}
    />
  );
};

export default PageSelect;
