const mixpanelColors = [
  "hsla(252, 100%, 67%, 1)",
  "hsla(11, 100%, 67%, 1)",
  "hsla(175, 62%, 69%, 1)",
  "hsla(41, 93%, 60%, 1)",
  "hsla(354, 37%, 52%, 1)",
  "hsla(205, 86%, 70%, 1)",
  "hsla(194, 85%, 34%, 1)",
  "hsla(151, 48%, 45%, 1)",
  "hsla(7, 97%, 85%, 1)",
];

const defaultScheme = [
  "#003f5c",
  // "#374c80",
  "#7a5195",
  // "#bc5090",
  "#ef5675",
  // "#ff764a",
  "#ffa600",
];

const blues = [
  "#003a5c",
  // "#004d70",
  "#006183",
  // "#007594",
  "#008aa4",
  // "#009fb2",
  "#00b5be",
];

const flower = [
  "#ffe926",
  "#ffbe29",
  "#ff9442",
  "#ff6d5c",
  "#f34e73",
  "#cb3d85",
  "#993a8e",
];

const greens = [
  "#216d54",
  "#33886b",
  "#45a482",
  "#58c09b",
  "#6bdeb4",
  "#7efcce",
];

export const chartColors = {
  default: defaultScheme,
  blues,
  greens,
  flower,
  mixpanelColors,
};

export const getColorByIndex = (index, colors) => {
  return colors[index % colors.length];
};
