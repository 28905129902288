import { chartColors, getColorByIndex } from "./chartColors";

import Area from "./Area";
import Bar from "./Bar";
import Line from "./Line";
import Pie from "./Pie";
import { get } from "lodash";
import processDataForChart from "./utils";
import { rAppDateFormat } from "app/utils/recoil";
import useListData from "app/useListData";
import { useRecoilValue } from "recoil";

const Chart2 = ({ block, page }) => {
  const { getListData } = useListData();

  const data = getListData(block);

  const appDateFormat = useRecoilValue(rAppDateFormat);

  // const records = [
  //   { date: "2024-01-04", sales: "100", country: "US" },
  //   { date: "2024-01-05", sales: "150", country: "Canada" },
  //   { date: "2024-02-06", sales: "200", country: "US" },
  //   { date: "2024-02-07", sales: "250", country: "Canada" },
  //   { date: "2024-03-08", sales: "300", country: "US" },
  //   { date: "2024-03-09", sales: "350", country: "Canada" },
  //   { date: "2024-04-10", sales: "400", country: "US" },
  //   { date: "2024-04-11", sales: "450", country: "Canada" },
  //   { date: "2024-05-12", sales: "500", country: "US" },
  //   { date: "2024-05-13", sales: "550", country: "Canada" },
  //   { date: "2024-06-14", sales: "600", country: "US" },
  //   { date: "2024-06-15", sales: "650", country: "Canada" },
  //   { date: "2024-07-16", sales: "700", country: "US" },
  //   { date: "2024-07-17", sales: "750", country: "Canada" },
  // ];

  const options = {
    valueField: get(block, "valueField"),
    aggregation: get(block, "aggregation"),
    seriesField: get(block, "seriesField"),
    groupByField: get(block, "groupByField"),
    dateGroupingInterval: get(block, "dateGroupingInterval"),
  };

  // const options = {
  //   valueField: "total",
  //   aggregation: "sum",
  //   // Time series
  //   // seriesField: "country",
  //   groupByField: "date",
  //   // Optional - Group data into multiple series
  //   // dateGroupingInterval: "month",
  // };

  const chartData = processDataForChart(data, options, appDateFormat);

  const colorTheme = get(chartColors, get(block, "colorTheme", "default"), []);

  const items = get(chartData, "chartData", []);
  const series = get(chartData, "series", []).map((item, index) => {
    return {
      ...item,
      fill: getColorByIndex(index, colorTheme),
    };
  });

  const chartType = get(block, "chartType", "area");

  // AREA
  if (chartType === "area") {
    return <Area data={items} series={series} />;
  }

  // BAR
  else if (chartType === "bar") {
    return <Bar data={items} series={series} />;
  }

  // LINE
  else if (chartType === "line") {
    return <Line data={items} series={series} />;
  }

  // PIE
  else if (chartType === "pie") {
    return <Pie data={items} series={series} />;
  }

  return <div>Chart2</div>;
};

export default Chart2;
