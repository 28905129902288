import {
  filterBasedOnUserGroups,
  getPixels,
  isFrontlyAdmin,
  isObjectEqualFromKeys,
} from "app/utils/utils";
import { rApp, rUser } from "app/utils/recoil";

import RenderBlockRouter from "../RenderBlock/RenderBlockRouter";
import { get } from "lodash";
import { memo } from "react";
import styled from "styled-components";
import useDynamicText from "../useDynamicText";
import { useRecoilValue } from "recoil";
import useUtils from "../useUtils";

const Row = ({ block, page, isColumn }) => {
  const user = useRecoilValue(rUser);
  const app = useRecoilValue(rApp);
  const { processDynamicText } = useDynamicText();
  const { passesDisplayConditions } = useUtils();

  const blocks = filterBasedOnUserGroups({
    items: get(page, "blocks", []),
    app,
    user,
    passesDisplayConditions,
  }).filter((b) =>
    passesDisplayConditions({
      conditions: get(b, "displayConditions", []),
      reusableBlockId: block.reusableBlockId,
      context: {
        repeatingRecord: get(block, "repeatingRecord"),
      },
    })
  );

  const correctedBlockId = block.originalBlockId || block.id;

  const layoutBlocks = blocks.filter(
    (b) => b.layoutParent === correctedBlockId
  );

  if (!isFrontlyAdmin && layoutBlocks.length === 0) {
    return <></>;
  }

  const gap = get(block, "gap") || 20;
  const flexWrap = get(block, "flexWrap") ? "wrap" : "nowrap";
  const alignItems = get(block, "verticalAlignment", "flex-start");
  const justifyContent = get(block, "horizontalAlignment", "flex-start");

  const customCss = get(block, "customCss", {});

  return (
    <Container
      backgroundImage={get(block, "backgroundImage", null)}
      hasAction={get(block, "clickAction", null) ? true : false}
      isColumn={isColumn}
      gap={gap}
      flexWrap={flexWrap}
      alignItems={alignItems}
      justifyContent={justifyContent}
      customCss={processDynamicText({
        text: get(customCss, "container"),
        reusableBlockId: block.reusableBlockId,
        context: { repeatingRecord: get(block, "repeatingRecord") },
      })}
    >
      {isFrontlyAdmin && layoutBlocks.length === 0 && (
        <div style={{ padding: "20px" }}>
          Empty {isColumn ? "Column" : "Row"} - Add blocks here{" "}
        </div>
      )}
      {layoutBlocks.map((b, bi) => {
        const layoutFullWidth = [
          "Row",
          "Map",
          "Iframe",
          "Image",
          "Video",
        ].includes(b.componentId);

        const layoutWidth = b.layoutWidth
          ? getPixels(b.layoutWidth)
          : layoutFullWidth
          ? "100%"
          : "fit-content";

        // Add layout width to block
        const finalBlock = {
          ...b,
          layoutWidth,
          reusableBlockId: block.reusableBlockId,
          parentComponentId: block.componentId,
          repeatingRecord: block.repeatingRecord,
          index: bi,
        };

        return (
          <RenderBlockRouter
            key={b.id}
            data={{
              inLayout: true,
              page,
              block: finalBlock,
              setupMode: isFrontlyAdmin,
              isPreview: block.isPreview,
              parentType: isColumn ? "Column" : "Row",
              index: bi,
            }}
          />
        );
      })}
    </Container>
  );
};

// Custom comparison function
const areEqual = (prevProps, nextProps) => {
  // Get just the child blocks
  const getChildBlocks = (props) => {
    const correctedBlockId = props.block.originalBlockId || props.block.id;
    const pageBlocks = get(props.page, "blocks", []);
    const layoutBlocks = pageBlocks.filter(
      (b) => b.layoutParent === correctedBlockId
    );
    return layoutBlocks;
  };

  const getBlocksWithRelevantFields = (props) => {
    const pageBlocks = get(props.page, "blocks", []).map((b) => ({
      id: b.id,
      layoutParent: b.layoutParent,
    }));
    return pageBlocks;
  };

  const arePropsEqual = isObjectEqualFromKeys(
    {
      ...prevProps,
      childBlocks: getChildBlocks(prevProps),
      blocksWithRelevantFields: getBlocksWithRelevantFields(prevProps),
    },
    {
      ...nextProps,
      childBlocks: getChildBlocks(nextProps),
      blocksWithRelevantFields: getBlocksWithRelevantFields(nextProps),
    },
    [
      "block",
      "isColumn",
      "user",
      "activeUserGroups",
      "activeAppUserGroups",
      "page",
      "childBlocks",
      "blocksWithRelevantFields",
    ]
  );

  return arePropsEqual;
};

// Memoize the Row component with custom comparison function
const MemoizedRow = memo(Row, areEqual);

export default MemoizedRow;

const Container = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.isColumn ? "column" : "row")};
  flex-wrap: ${(p) => p.flexWrap};
  gap: ${(p) => getPixels(p.gap)};
  align-items: ${(p) => p.alignItems};
  justify-content: ${(p) => p.justifyContent};
  cursor: ${(p) => (p.hasAction || isFrontlyAdmin ? "pointer" : "default")};
  height: 100%;
  ${(p) => p.customCss}
`;
