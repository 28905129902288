import { apiRequest, webUrl } from "app/utils/apiRequests";
import { errorNotification, successNotification } from "app/utils/Notification";

import { Button } from "app/components";
import { get } from "lodash";
import { getUrlParameter } from "app/utils/utils";
import pkceChallenge from "pkce-challenge";
import { rApp } from "app/utils/recoil";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

const AirtableAuth = () => {
  const [app, setApp] = useRecoilState(rApp);

  const clientId = process.env.REACT_APP_AIRTABLE_CLIENT_ID;
  const redirectUri = `${webUrl}/spreadsheets`;

  const location = useLocation();

  const getValidChallenge = async () => {
    const validCharsRegex = /^[A-Za-z0-9\-_\.]+$/;

    for (let attempt = 0; attempt < 4; attempt++) {
      // Generate code_verifier and code_challenge
      const { code_verifier, code_challenge } = await pkceChallenge();

      // Validate the code_verifier with regex
      if (validCharsRegex.test(code_verifier)) {
        // If valid, return the pair
        return { code_verifier, code_challenge };
      }

      // Log each invalid attempt for debugging purposes
      // console.log(
      //   `Attempt ${attempt + 1}: Invalid code_verifier`,
      //   code_verifier
      // );
    }

    // If all attempts fail, return null
    return null;
  };

  const handleAirtableAuth = async () => {
    const challengeResponse = await getValidChallenge();

    if (!challengeResponse) {
      errorNotification(
        "Error connecting to Airtable. Please try again or contact support if the issue persists."
      );
      console.error(
        "Failed to generate valid code_verifier and code_challenge"
      );
      return;
    }

    const { code_verifier, code_challenge } = challengeResponse;
    localStorage.setItem("code_verifier", code_verifier);

    // TODO - Generate this?
    const mockState = "1234123412341abcd";

    const authUrl = `https://airtable.com/oauth2/v1/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${mockState}&scope=schema.bases:read data.records:read data.records:write&response_type=code&code_challenge=${code_challenge}&code_challenge_method=S256`;

    window.location.href = authUrl;
  };

  const handleAirtableDisconnect = async () => {
    // Disconnect from Airtable
    apiRequest.post("/app_settings/", {
      airtable_token: null,
    });
    setApp({
      ...app,
      airtable_token: null,
    });
  };

  useEffect(() => {
    const code = getUrlParameter("code", location);
    const code_verifier = localStorage.getItem("code_verifier");

    if (code && code_verifier) {
      apiRequest
        .post("/airtable_auth/", { code, code_verifier })
        .then((response) => {
          // Handle successful response
          successNotification("Airtable Connected");
          setApp({
            ...app,
            airtable_token: true,
          });
        })
        .catch((error) => {
          // Handle error
          console.error("Error exchanging code:", error);
        });

      localStorage.removeItem("code_verifier");
    }
  }, [location]);

  const airtableToken = get(app, "airtable_token");

  return (
    <Button
      data={{
        text: airtableToken
          ? "Airtable Connected - Click To Disconnect"
          : "Connect to Airtable",
        onClick: airtableToken ? handleAirtableDisconnect : handleAirtableAuth,
        type: "basic",
        margin: "20px 0 0 0",
      }}
    />
  );
};

export default AirtableAuth;
