import { Icon, Text } from "app/components";
import { boxShadow, spacing } from "app/utils/theme";

import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s5};
  @media (max-width: 800px) {
    gap: ${spacing.s4};
  }
`;

const Question = styled.div`
  border-radius: 8px;
  padding: ${spacing.s6};
  display: flex;
  justify-content: space-between;
  align-items: ${(p) => (p.expanded ? "flex-start" : "center")};
  cursor: pointer;
  background: white;
  box-shadow: ${boxShadow.card};
  @media (max-width: 800px) {
    padding: ${spacing.s5};
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const FAQ = ({ data }) => {
  const [activeItem, setActiveItem] = useState(null);
  const questions = get(data, "questions", []);

  return (
    <Container>
      {questions.map((question, i) => {
        const expanded = activeItem === i;
        return (
          <Question
            lightWithBorder={data.lightWithBorder}
            key={i}
            expanded={expanded}
            index={i}
            onClick={() => setActiveItem(expanded ? null : i)}
          >
            <div style={{ marginTop: expanded ? "4px" : "0px" }}>
              <Text
                data={{
                  text: question.question,
                  fontStyle: "bodyXl",
                  cursor: "pointer",
                  color: "#151523",
                }}
              />
              {expanded && (
                <Text
                  data={{
                    text: question.answer,
                    fontStyle: "bodyLg",
                    margin: "20px 0 0 0",
                    cursor: "pointer",
                    color: "#6b7b99",
                  }}
                />
              )}
            </div>
            <IconContainer>
              <Icon
                data={{
                  icon: "FiChevronDown",
                  color: "#151523",
                  size: 30,
                  hover: true,
                }}
              />
            </IconContainer>
          </Question>
        );
      })}
    </Container>
  );
};

export default FAQ;
