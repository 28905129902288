import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, getHighest } from "app/utils/utils";
import { rActiveBlockId, rPage } from "app/utils/recoil";

import Action from "./Action/Action";
import { Button } from "app/components";
import { get } from "lodash";
import { spacing } from "app/utils/theme";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";

const MultiActions = ({ data }) => {
  const { setPage } = useSetPage();

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const actions = get(page, "actions", []);

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const actionObjects = get(data, "value", []).map((a) => ({
    ...a,
    dynamicSources: get(data, "dynamicSources", []),
    excludedSources: get(data, "excludedSources", []),
  }));

  const addAction = () => {
    const newId = getHighest(actions, "id") + 1;
    setPage({
      actions: [
        ...actions,
        {
          id: newId,
          label: `Action ${newId}`,
          steps: [
            {
              id: 1,
              type: null,
            },
          ],
        },
      ],
      blocks: get(page, "blocks", []).map((b) => {
        if (b.id === activeBlockId) {
          return {
            ...b,
            [data.id]: [
              ...actionObjects,
              {
                value: newId,
                label: "New Action",
              },
            ],
          };
        }
        return b;
      }),
    });
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: spacing.s4,
      }}
      dragHandleSelector=".drag-item"
      lockAxis="y"
      onDrop={(e) => {
        const { addedIndex, removedIndex } = e;
        const movedActions = arrayMove(actionObjects, removedIndex, addedIndex);
        setPage({
          blocks: get(page, "blocks", []).map((b) => {
            if (b.id === activeBlockId) {
              return {
                ...b,
                [data.id]: movedActions,
              };
            }
            return b;
          }),
        });
      }}
    >
      {actionObjects.map((a) => (
        <Draggable key={a.id}>
          <Action data={a} multiResourceId={data.id} titleIsEditable />
        </Draggable>
      ))}
      <Button
        data={{
          text: "Add Action",
          onClick: addAction,
          size: "small",
          type: "basic",
          icon: "FiPlus",
        }}
      />
    </Container>
  );
};

export default MultiActions;
