import { Icon, Row, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";

import styled from "styled-components";
import { useState } from "react";

const ItemBubble = ({ onDelete, onClick, icon, label }) => {
  const [showDelete, setShowDelete] = useState(false);
  return (
    <Container
      onClick={onClick}
      onMouseEnter={() => {
        if (!showDelete) {
          setShowDelete(true);
        }
      }}
      onMouseLeave={() => {
        if (showDelete) {
          setShowDelete(false);
        }
      }}
    >
      {icon && (
        <Icon
          data={{
            icon,
            color: "#5D5F62",
            size: 18,
          }}
        />
      )}
      <Row justifyContent="space-between" alignItems="center" width="100%">
        <Text
          data={{
            cursor: "pointer",
            text: label,
            fontStyle: "bodySm",
          }}
        />
        {showDelete && (
          <Icon
            data={{
              icon: "FiTrash",
              hover: true,
              onClick: onDelete,
              color: colors.grey3,
              size: 18,
            }}
          />
        )}
      </Row>
    </Container>
  );
};

export default ItemBubble;

const Container = styled.div`
  border: 1px solid ${colors.grey2};
  padding: ${spacing.s3};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  background: white;
  &:hover {
    background: ${colors.grey1};
  }
`;
