import { CardSection, Label } from "./Pages";

import { Icon } from "app/components";
import styled from "styled-components";

const resources = [
  {
    title: "Getting Started With Frontly",
    url: "https://res.cloudinary.com/frontly/image/upload/v1716593651/gettingstarted_xtt776.png",
    videoLink: "https://youtu.be/FUaEeDg8vRs",
  },
  {
    title: "Detail View Customization",
    url: "https://res.cloudinary.com/frontly/image/upload/v1693546439/Video%20Thumbnails/maxresdefault_dl5yvv.webp",
    videoLink: "https://www.youtube.com/watch?v=WwudJ3Alrds",
  },
  {
    url: "https://res.cloudinary.com/frontly/image/upload/v1715919599/frontly-drag-drop-cover_lzsimb.png",
    videoLink: "https://youtu.be/GWd4QUDbi5k",
    title: "How To Drag & Drop Blocks",
  },
  {
    title: "Generate Blocks With AI",
    url: "https://res.cloudinary.com/frontly/image/upload/v1716446778/generate-thumb_qtrudx.png",
    videoLink: "https://www.youtube.com/watch?v=Jz0LtBFbDCo",
  },
  {
    title: "Data Filters",
    url: "https://res.cloudinary.com/frontly/image/upload/v1693546451/Video%20Thumbnails/maxresdefault_ky4igd.webp",
    videoLink: "https://www.youtube.com/watch?v=KjP2mBN40SY",
  },
  {
    title: "OpenAI Action",
    url: "https://res.cloudinary.com/frontly/image/upload/v1703889105/maxresdefault_cf8qvt.webp",
    videoLink: "https://youtu.be/eUA2w6P4nAI",
  },
  {
    title: "User Groups & Permissions",
    url: "https://res.cloudinary.com/frontly/image/upload/v1693546405/Video%20Thumbnails/maxresdefault_gzwumy.webp",
    videoLink: "https://www.youtube.com/watch?v=zBj4kuyj8yc",
  },
  {
    url: "https://res.cloudinary.com/frontly/image/upload/v1715406526/reusableblocks-cover_b1oozh.jpg",
    videoLink: "https://youtu.be/GWd4QUDbi5k",
    title: "Custom Blocks",
  },
  {
    title: "Full Screen Forms",
    url: "https://res.cloudinary.com/frontly/image/upload/v1703889055/maxresdefault_xjepda.webp",
    videoLink: "https://youtu.be/Vb80J8sMPzw",
  },
  {
    title: "Local State Variables (Part 1)",
    url: "https://res.cloudinary.com/frontly/image/upload/v1703889215/maxresdefault_mhtqki.webp",
    videoLink: "https://youtu.be/43mXodLl-Ls",
  },
  {
    title: "Local State Variables (Part 2)",
    url: "https://res.cloudinary.com/frontly/image/upload/v1703889194/maxresdefault_jozu5m.webp",
    videoLink: "https://youtu.be/pulKfBASpkA",
  },
  {
    title: "App Styling",
    url: "https://res.cloudinary.com/frontly/image/upload/v1703889285/maxresdefault_xi2wqd.webp",
    videoLink: "https://youtu.be/a0eJmfB4J6g",
  },
  {
    title: "4 Common Issues & Fixes",
    url: "https://res.cloudinary.com/frontly/image/upload/v1700791021/maxresdefault_kmjodv.webp",
    videoLink: "https://www.youtube.com/watch?v=2sUyobRzwWE",
  },
  {
    title: "Form Fields & Validation",
    url: "https://res.cloudinary.com/frontly/image/upload/v1693546363/Video%20Thumbnails/maxresdefault_fwrmfl.webp",
    videoLink: "https://www.youtube.com/watch?v=Zq88W0Z7Uys",
  },
  {
    title: "Date Formatting",
    url: "https://res.cloudinary.com/frontly/image/upload/v1693546386/Video%20Thumbnails/maxresdefault_goc7rl.webp",
    videoLink: "https://www.youtube.com/watch?v=jLQAItQEwT8",
  },
  {
    title: "Using Relational Data",
    url: "https://res.cloudinary.com/frontly/image/upload/v1693546459/Video%20Thumbnails/maxresdefault_ppzxty.webp",
    videoLink: "https://www.youtube.com/watch?v=Ik4YiBHqEVA",
  },
  {
    title: "Mastering AI Prompts",
    url: "https://res.cloudinary.com/frontly/image/upload/v1693546508/Video%20Thumbnails/maxresdefault_b6nndm.webp",
    videoLink: "https://www.youtube.com/watch?v=UQQ7ORJywh8",
  },
];

const Resources = () => {
  return (
    <div>
      <Label>Tutorials</Label>
      <CardSection style={{ margin: "25px 0 0 0" }}>
        {resources.map((r) => (
          <Resource resource={r} />
        ))}
      </CardSection>
    </div>
  );
};

export default Resources;

const Resource = ({ resource }) => {
  return (
    <ResourceContainer onClick={() => window.open(resource.videoLink)}>
      <ResourceImage url={resource.url}>
        <PlayButton>
          <Icon data={{ icon: "BsPlayFill", color: "white", hover: true }} />
        </PlayButton>
      </ResourceImage>
      <ResourceTitle>{resource.title}</ResourceTitle>
    </ResourceContainer>
  );
};

const PlayButton = styled.div`
  background: rgba(0, 0, 0, 0.4);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  transition: background 0.2s;
`;

const ResourceTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
`;

const ResourceContainer = styled.div``;

const ResourceImage = styled.div`
  background-image: url(${(p) => p.url});
  height: 150px;
  width: 255px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
  transition: 0.2s;
`;
