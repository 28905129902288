import { getHighest } from "app/utils/utils";

import { DataGrid } from "app/components";
import { get } from "lodash";

const InfoListItemEditor = ({ data }) => {
  const listItems = get(data, "value", []);

  const addListItem = (newListItems) => {
    data.onChange(newListItems);
  };

  const valueField = {
    key: "itemValue",
    label: "Value",
    width: "150px",
    componentId: "DynamicString",
    value: get(data, "itemValue"),
  };

  const labelField = {
    key: "itemLabel",
    label: "Label",
    width: "150px",
    componentId: "DynamicString",
    value: get(data, "itemLabel"),
  };

  const linkField = {
    key: "isLink",
    label: "Link",
    componentId: "Switch",
    defaultValue: false,
  };

  return (
    <DataGrid
      data={{
        newObject: {
          id: getHighest(listItems, "id") + 1,
        },
        onChange: (newListItems) => addListItem(newListItems),
        value: listItems,
        columns: [labelField, valueField, linkField],
      }}
    />
  );
};

export default InfoListItemEditor;
