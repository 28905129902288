import { atom, selector } from "recoil";

import { get as lodashGet } from "lodash";

export const rRefreshSheetData = atom({
  key: "refreshSheetId",
  default: null,
});

export const rDateFieldsModalData = atom({
  key: "dateFieldsModalData",
  default: null,
});

export const rNewDataSourceModalData = atom({
  key: "newDataSourceModalData",
  default: null,
});

export const rToast = atom({
  key: "toast",
  default: {
    type: "",
    show: false,
    message: "",
  },
});

export const rModal = atom({
  key: "modal",
  default: null,
});

export const rPendingFileUploads = atom({
  key: "pendingFileUploads",
  default: {},
});

// This stores the value of all form blocks instead of local state
export const rAllFormState = atom({
  key: "allFormState",
  default: {},
});

export const rFormState = atom({
  key: "formState",
  default: {},
});

export const rSpinner = atom({
  key: "spinner",
  default: null,
});

export const rFormValidation = atom({
  key: "formValidation",
  default: {},
});

export const rBranding = atom({
  key: "branding",
  default: null,
});

export const rActiveRequest = atom({
  key: "activeRequest",
  default: null,
});

export const rIsIntercomBooted = atom({
  key: "isIntercomBooted",
  default: false,
});

export const rPageConfigTab = atom({
  key: "pageConfigTab",
  default: "basics",
});

export const rAdminPreview = atom({
  key: "adminPreview",
  default: null,
});

export const rUser = atom({
  key: "user",
  default: null,
});

export const rUserColors = atom({
  key: "userColors",
  default: [],
});

export const rAiBlockData = atom({
  key: "aiBlockData",
  default: {},
});

export const rActiveBlockId = atom({
  key: "activeBlockId",
  default: null,
});

export const rCopiedBlockId = atom({
  key: "copiedBlockId",
  default: null,
});

export const rActiveVersionMap = atom({
  key: "activeVersionMap",
  default: {},
});

export const rActiveDetailViewId = atom({
  key: "activeDetailViewId",
  default: null,
});

export const rHoverBlockId = atom({
  key: "hoverBlockId",
  default: null,
});

export const rIsFetchingConfig = atom({
  key: "isFetchingConfig",
  default: false,
});

export const rCustomBlockChanges = atom({
  key: "customBlockChanges",
  default: false,
});

export const rScreenPreviewSize = atom({
  key: "screenPreviewSize",
  default: "desktop",
});

export const rPageChanges = atom({
  key: "pageChanges",
  default: false,
});

export const rActiveEditField = atom({
  key: "activeEditField",
  default: null,
});

export const rModalStack = atom({
  key: "modalStack",
  default: [],
});

export const rFilters = atom({
  key: "filters",
  default: {},
});

export const rSorting = atom({
  key: "sorting",
  default: {},
});

export const rPageFilters = atom({
  key: "pageFilters",
  default: {},
});

export const rPreviewApp = atom({
  key: "previewApp",
  default: null,
});

export const rShowSettingsPanel = atom({
  key: "showSettingsPanel",
  default: true,
});

export const rShowStylesPanel = atom({
  key: "showStylesPanel",
  default: true,
});

export const rFetchingVariables = atom({
  key: "isFetchingVariables",
  default: false,
});

export const rRefreshBlockIds = atom({
  key: "refreshBlockIds",
  default: {
    count: 0,
    ids: [],
  },
});

export const rPagination = atom({
  key: "pagination",
  default: {}, // a map of block ids and their current respective pages
});

export const rLocalState = atom({
  key: "localState",
  default: {},
});

export const rFetchingBlockIds = atom({
  key: "fetchingBlockIds",
  default: [],
});

export const rAiView = atom({
  key: "aiView",
  default: null,
});

export const rConfirmationModalData = atom({
  key: "confirmationModalData",
  default: null,
});

export const rIsMixpanelInitialized = atom({
  key: "isMixpanelInitialized",
  default: false,
});

export const rWebsocketRequests = atom({
  key: "websocketRequests",
  default: [],
});

// TODO - turn into selector that merges old and new sate
export const rActiveApp = atom({
  key: "activeApp",
  default: {},
});

export const rFormIsFetching = atom({
  key: "formisFetching",
  default: null,
});

export const rPagesRoot = atom({
  key: "pagesRoot",
  default: [],
});

export const rCustomBlocks = atom({
  key: "customBlocks",
  default: [],
});

export const rForms = atom({
  key: "forms",
  default: [],
});

export const rContentLibrary = atom({
  key: "contentLibrary",
  default: [],
});

export const rAppGenerationSessions = atom({
  key: "appGenerationSessions",
  default: [],
});

export const rExpertProfile = atom({
  key: "expertProfile",
  default: {},
});

export const rOrganization = atom({
  key: "organization",
  default: {},
});

export const rSetOrganization = selector({
  key: "setOrganizationSelector",
  get: ({ get }) => {
    return get(rOrganization);
  },
  set: ({ set, get }, newObj) => {
    const current = get(rOrganization);
    set(rOrganization, { ...current, ...newObj });
  },
});

export const rApps = atom({
  key: "apps",
  default: [],
});

export const rButtonSpinnerIds = atom({
  key: "buttonSpinnerIds",
  default: [],
});

// The saved spreadsheets used in admin mode and for structure
export const rSavedSpreadsheetsRoot = atom({
  key: "savedSpreadsheets",
  default: [],
});

export const rSavedSpreadsheets = selector({
  key: "savedSpreadsheetsSelector",
  get: ({ get }) => {
    const previewApp = get(rPreviewApp);

    // PREVIEW APP STUFF
    if (previewApp) {
      const previewSampleData = lodashGet(previewApp, "sample_records", []);
      const previewData = lodashGet(previewApp, "data", {});
      const previewSheets = Object.keys(previewData).map((k) => {
        const headers = Object.keys(lodashGet(previewData, k));
        return { id: k, data: lodashGet(previewSampleData, k, []), headers };
      });

      return previewSheets;
    }

    return get(rSavedSpreadsheetsRoot);
  },
  set: ({ set, get }, newSheets) => {
    set(rSavedSpreadsheetsRoot, newSheets);
  },
});

// The live spreadsheets, but the root storage
export const rSpreadsheets = atom({
  key: "spreadsheets",
  default: {},
});

// ALL ATOMS ABOVE HERE

export const rTranslations = selector({
  key: "translations",
  get: ({ get }) => {
    const activeApp = get(rActiveApp);
    return lodashGet(activeApp, "translations", {});
  },
});

export const rDarkMode = selector({
  key: "darkMode",
  get: ({ get }) => {
    const app = get(rApp);
    const styling = lodashGet(app, "styling", {});
    return lodashGet(styling, "theme") === "dark";
  },
});

// ALL BASIC SELECTORS ABOVE HERE

export const userStateSelector = selector({
  key: "userStateSelector",
  get: ({ get }) => {
    return get(rUser);
  },
  set: ({ set, get }, newValue) => {
    const currentUser = get(rUser);
    set(rUser, { ...currentUser, ...newValue });
  },
});

export const spreadsheetsSelector = selector({
  key: "spreadsheetsSelector",
  get: ({ get }) => {
    return get(rSpreadsheets);
  },
  set: ({ set, get }, newValue) => {
    const current = get(rSpreadsheets);
    set(rSpreadsheets, { ...current, ...newValue });
  },
});

export const localStateSelector = selector({
  key: "localStateSelector",
  get: ({ get }) => {
    return get(rLocalState);
  },
  set: ({ set, get }, newValue) => {
    const current = get(rLocalState);
    set(rLocalState, { ...current, ...newValue });
  },
});

export const rApp = selector({
  key: "app",
  get: ({ get }) => {
    let app = get(rActiveApp) || {};

    // Handle dark mode styles
    const appStyling = lodashGet(app, "styling", {});
    const darkMode = lodashGet(appStyling, "theme") === "dark";
    if (darkMode) {
      app = {
        ...app,
        styling: {
          ...appStyling,
          blockBorderColor: "#2E2E2E",
          formInputBorderColor: "#343434",
        },
      };
    }

    const previewApp = get(rPreviewApp);

    // PREVIEW APP STUFF
    if (previewApp) {
      const previewAppFields = lodashGet(previewApp, "custom_app_object", {});

      app = {
        ...app,
        ...previewAppFields,
        name: lodashGet(previewApp, "name"),
        styling: {
          ...lodashGet(app, ["app", "styling"]),
          // force light mode during AI app preview
          theme: "light",
          blockBorderColor: "#d5d7da",
        },
      };
    }

    return app;
  },
  set: ({ set, get }, newObj) => {
    const currentApp = get(rActiveApp) || {};
    const updatedApp = { ...currentApp, ...newObj };
    set(rActiveApp, updatedApp);
  },
});

export const rAppDateFormat = selector({
  key: "appDateFormat",
  get: ({ get }) => {
    const activeApp = get(rActiveApp) || {};

    // DOUBLE CHECK THIS
    const defaultDateFormatObject = {
      inputDate: "YYYY/MM/DD",
      outputDate: null,
      inputTime: null,
      outputTime: null,
      showAmPm: false,
    };

    const dateFormat = activeApp.date_format || {};

    // Combine default object with user's app date format
    const appDateFormat = {
      ...defaultDateFormatObject,
      ...dateFormat,
    };

    return appDateFormat;
  },
});

export const rPages = selector({
  key: "pages",
  get: ({ get }) => {
    // If we're previewing the AI app, override with the preview pages
    const previewApp = get(rPreviewApp);
    if (previewApp) {
      const previewPages = lodashGet(previewApp, "pages", []);
      return previewPages;
    }

    return get(rPagesRoot);
  },
  set: ({ set }, newPages) => {
    set(rPagesRoot, newPages);
  },
});

export const rSubscription = selector({
  key: "subscription",
  get: ({ get }) => {
    const organization = get(rOrganization);
    return lodashGet(organization, "subscription", {});
  },
});

// For live spreadsheets, but using the selector
export const rLiveSpreadsheets = selector({
  key: "liveSpreadsheets",
  get: ({ get }) => {
    const spreadsheets = get(rSpreadsheets);
    return spreadsheets;
  },
});

export const refreshBlockIdsSelector = selector({
  key: "refreshBlockIdsSelector",
  get: ({ get }) => {
    return get(rRefreshBlockIds);
  },
  set: ({ set, get }, newIds) => {
    const current = get(rRefreshBlockIds);
    const count = current.count || 0;
    set(rRefreshBlockIds, {
      count: count + 1,
      ids: newIds,
    });
  },
});

export const addFetchingBlocks = selector({
  key: "addFetchingBlocks",
  get: ({ get }) => {
    return get(rFetchingBlockIds);
  },
  set: ({ set, get }, newIds) => {
    const current = get(rFetchingBlockIds);
    const newList = [...current, ...newIds];
    set(rFetchingBlockIds, newList);
  },
});

export const removeFetchingBlocks = selector({
  key: "removeFetchingBlocks",
  get: ({ get }) => {
    return get(rFetchingBlockIds);
  },
  set: ({ set, get }, newIds) => {
    // TODO - THiS SEEMS BROKEN - not referencing the new ids. maybe it's just clearing it.
    const current = get(rFetchingBlockIds);
    const newList = current.filter((c) => !newList.includes(c));
    set(rFetchingBlockIds, newList);
  },
});

// SET EVERYTHING
export const rSetConfig = selector({
  key: "setConfig",
  get: ({ get }) => {},
  set: ({ set }, data) => {
    set(rContentLibrary, lodashGet(data, "content_library", []));
    set(rApp, lodashGet(data, "app", {}));
    set(rPages, lodashGet(data, "pages", []));
    set(rSavedSpreadsheets, lodashGet(data, "spreadsheets", []));
    set(rOrganization, lodashGet(data, "organization", {}));
    set(rApps, lodashGet(data, "apps", []));
    set(rForms, lodashGet(data, "forms", []));
    set(rCustomBlocks, lodashGet(data, "custom_blocks", []));
    set(rAppGenerationSessions, lodashGet(data, "app_generation_sessions", []));
    const responseUser = lodashGet(data, "user");
    if (responseUser) {
      set(rUser, responseUser);
    }
  },
});

// CLEAR EVERYTHING
export const rClearConfig = selector({
  key: "clearConfig",
  get: ({ get }) => {},
  set: ({ set }) => {
    set(rApp, {});
    set(rActiveApp, {});
    set(rPages, []);
    set(rSavedSpreadsheets, []);
    set(rOrganization, {});
    set(rApps, []);
    set(rForms, []);
    set(rAppGenerationSessions, []);
    set(rUser, {});
  },
});
