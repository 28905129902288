import { rPendingFileUploads, rTranslations } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { get } from "lodash";
import { safeString } from "app/utils/utils";
import { successNotification } from "app/utils/Notification";
import { uploadFiles } from "./documentParsing";
import useDynamicText from "app/renderingApp/useDynamicText";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";

export const useFormActions = ({
  submitAction,
  block,
  formValues,
  rowId,
  displayFields,
  hiddenFields,
  config,
  page,
  setIsSaving,
  setIsUploadingFiles,
}) => {
  const translations = useRecoilValue(rTranslations);

  const { updateRecord, createRecord } = useSpreadsheetRequests();

  const { processDynamicText } = useDynamicText();

  const [pendingFileUploads, setPendingFileUploads] =
    useRecoilState(rPendingFileUploads);

  // The Create Row function for the Form block
  const createRow = async () => {
    const filesToUpload = displayFields
      .filter((f) =>
        ["DocumentUpload", "Signature"].includes(get(f, "componentId"))
      )
      .map((f) => {
        const componentId = get(f, "componentId");
        return {
          key: f.key,
          componentId,
          file: get(pendingFileUploads, `${page.id}-${block.id}-${f.key}`),
        };
      })
      .filter((f) => f.file);

    // Get changed values
    let updateValues = [];
    let uploadKeys = [];

    if (filesToUpload.length > 0) {
      setIsUploadingFiles(true);

      const uploadedFiles = await uploadFiles(filesToUpload);

      setIsUploadingFiles(false);

      // Add the uploaded files to the update values
      uploadedFiles.forEach((file) => {
        updateValues.push({
          key: file.key,
          value: file.data,
        });
        uploadKeys.push(file.key);
      });

      setPendingFileUploads({});
    }

    setIsSaving(true);

    Object.keys(formValues).forEach((k) => {
      if (!uploadKeys.includes(k)) {
        const v = get(formValues, k, "");
        if (!safeString(v).trim().startsWith("=")) {
          updateValues.push({
            key: k,
            value: processDynamicText({
              text: v,
              reusableBlockId: block.reusableBlockId,
              context: { repeatingRecord: get(block, "repeatingRecord") },
            }),
          });
        }
      }
    });

    hiddenFields.forEach((f) => {
      const hiddenValue = get(f, "hiddenValue");
      if (hiddenValue) {
        updateValues.push({
          key: f.key,
          value: processDynamicText({
            text: hiddenValue,
            reusableBlockId: block.reusableBlockId,
            context: { repeatingRecord: get(block, "repeatingRecord") },
          }),
        });
      }
    });

    createRecord({
      sheetId: block.spreadsheet,
      values: updateValues,
    }).then((newRecord) => {
      setIsSaving(false);
      successNotification(get(translations, "recordCreated", "Record Created"));
      submitAction(newRecord);
    });
  };

  const updateRow = () => {
    setIsSaving(true);

    const disabledFieldKeys = Object.keys(config).filter((k) => {
      const isDisabled = get(config, [k, "active"]) === false;
      return isDisabled;
    });

    // Get changed values
    let updateValues = [];
    Object.keys(formValues)
      .filter((k) => !["frontly_id", "frontly_data"].includes(k))
      .forEach((k) => {
        const v = get(formValues, k, "");
        if (!safeString(v).trim().startsWith("=")) {
          updateValues.push({ key: k, value: v });
        }
      });

    hiddenFields.forEach((f) => {
      const hiddenValue = get(f, "hiddenValue");
      if (hiddenValue) {
        updateValues.push({
          key: f.key,
          value: processDynamicText({
            text: hiddenValue,
            reusableBlockId: block.reusableBlockId,
            context: { repeatingRecord: get(block, "repeatingRecord") },
          }),
        });
      }
    });

    // One last final filter to make sure inactive fields don't send data
    updateValues = updateValues.filter((v) => {
      if (v.key.includes("__")) {
        return false;
      }

      return !disabledFieldKeys.includes(v.key);
    });

    updateRecord({
      sheetId: block.spreadsheet,
      rowIdColumn: block.rowIdColumn,
      recordId: rowId,
      values: updateValues,
    }).then(() => {
      setIsSaving(false);
      successNotification(get(translations, "savedNotification", "Saved"));
      submitAction({});
    });
  };

  return { createRow, updateRow };
};
