import { Icon, Text } from "app/components";

import { colors } from "app/utils/theme";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: ${(p) => p.margin};
`;

const Crumb = styled.div`
  display: flex;
  align-items: center;
`;

const Breadcrumb = ({
  fontStyle = "headingMd",
  items,
  margin = "0px",
  whiteText,
}) => {
  return (
    <Container margin={margin}>
      {items.map((item, i) => {
        const active = i === items.length - 1;

        return (
          <Crumb key={i}>
            <Text
              data={{
                text: item.text,
                fontStyle,
                // fontWeight: active ? 500 : 400, // normal
                onClick: item.onClick,
                cursor: "pointer",
                color: active
                  ? whiteText
                    ? "white"
                    : colors.grey5
                  : whiteText
                  ? colors.grey2
                  : colors.grey3,
              }}
            />
            {i < items.length - 1 && (
              <Icon
                data={{
                  icon: "FiChevronRight",
                  color: whiteText ? colors.grey2 : colors.grey3,
                  size: 20,
                  margin: "2px 5px 0 5px",
                }}
              />
            )}
          </Crumb>
        );
      })}
    </Container>
  );
};

export default Breadcrumb;
