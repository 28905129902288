import Profile, { RenderProfile } from "../Profile";

import AdminWrapper from "../../home/AdminWrapper";
import { BackButton } from "../../components";
import { Card } from "app/components";
import { useNavigate } from "react-router-dom";

const ExpertProfile = () => {
  const navigate = useNavigate();

  return (
    <AdminWrapper contentWidth="100%">
      <BackButton
        width="auto"
        margin="0px"
        text="All Experts"
        onClick={() => navigate("/experts-marketplace")}
      />
      <Card margin="25px 0 0 0">
        <RenderProfile internal />
      </Card>
    </AdminWrapper>
  );
};

export default ExpertProfile;
