import { label, labelColor, spreadsheetSelect } from "./shared";

export const QuoteCalculator = {
  componentId: "QuoteCalculator",
  resources: [
    label,
    labelColor,
    {
      id: "hideHeader",
      componentId: "Switch",
      section: "hideHeader",
      orientation: "horizontal",
      requiresSheet: true,
      advanced: true,
      sectionHint:
        "Hide the block header entirely so it doesn't take up any space",
    },
    {
      ...spreadsheetSelect,
      hint: "Select the spreadsheet to create new rows in with this Form",
    },
    {
      id: "inputs",
      section: "Inputs",
      componentId: "GoogleSheetCellPicker",
    },
    {
      id: "results",
      section: "Results",
      componentId: "GoogleSheetCellPicker",
    },
    {
      id: "inputsLabel",
      label: "Inputs Label",
      section: "labels",
      orientation: "horizontal",
      width: "150px",
      componentId: "DynamicString",
    },
    {
      id: "resultsLabel",
      label: "Results Label",
      section: "labels",
      orientation: "horizontal",
      width: "150px",
      componentId: "DynamicString",
    },
    {
      id: "buttonLabel",
      label: "Button Label",
      section: "labels",
      orientation: "horizontal",
      width: "150px",
      componentId: "DynamicString",
    },
    {
      label: "Submit Action",
      id: "submitAction",
      section: "actions",
      dynamicSources: ["form"],
      hint: "This action runs in addition to the default create or update action",
      isAction: true,
      componentId: "Action",
      requiresSheet: true,
      advanced: true,
    },
  ],
};
