import { rApp, rDarkMode } from "app/utils/recoil";

import ClientNavigation from "app/adminApp/components/Navigation/ClientNavigation";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import { paddingObjToString } from "./blocks/Text";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const getPageWidth = (contentWidth) => {
  if (!contentWidth || contentWidth === "100%") {
    return "100%";
  }
  return getPixels(contentWidth);
};

const ClientNavigationWrapper = ({
  children,
  hasTemplateBanner,
  isPublicRoute,
  page,
}) => {
  const darkMode = useRecoilValue(rDarkMode);

  const activeApp = useRecoilValue(rApp);

  const navigation = get(activeApp, "navigation", {});
  const navigationType = get(navigation, "type", "sidebar");

  const contentWidth = get(page, "contentWidth");

  const defaultPageBackgroundColor = get(activeApp, [
    "styling",
    "pageBackgroundColor",
  ]);

  const backgroundColor = darkMode
    ? colors.darkModeBackground
    : get(page, "backgroundColor") || defaultPageBackgroundColor;

  const defaultPagePadding = window.innerWidth < 800 ? "15px" : "32px";

  const padding = paddingObjToString(
    get(page, "padding", {}),
    defaultPagePadding
  );
  const blockGap = getPixels(get(page, "rootBlockGap", 32));

  return (
    <MainContainer hasTemplateBanner={hasTemplateBanner}>
      <Body
        navigationType={navigationType}
        isGridLayout={get(page, "layoutType") === "grid"}
        backgroundColor={backgroundColor}
      >
        <ClientNavigation isPublicRoute={isPublicRoute} />
        <LiveContainer>
          <WidthContainer
            newGridSystem={get(page, "newGridSystem", false)}
            gap={blockGap}
            padding={padding}
            maxWidth={getPageWidth(contentWidth)}
            id="renderBlocksContainer"
          >
            {children}
          </WidthContainer>
        </LiveContainer>
      </Body>
    </MainContainer>
  );
};

export default ClientNavigationWrapper;

const MainContainer = styled.div`
  padding: 0px;
  position: fixed;
  top: ${(p) => (p.hasTemplateBanner ? "60px" : "0px")};
  right: 0;
  left: 0;
  bottom: 0;
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
  ${(p) => p.navigationType === "header" && "flex-direction: column;"}
  ${(p) => p.isGridLayout && `padding-bottom: 30px;`}
  background: ${(p) => p.backgroundColor};
`;

const LiveContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const WidthContainer = styled.div`
  gap: ${(p) => p.gap};
  padding: ${(p) => p.padding};

  ${(p) =>
    !p.newGridSystem
      ? `
    display: flex;
    flex-direction: column;
    max-width: ${p.maxWidth};
    margin: 0 auto;
    `
      : `
    display: grid;
    background: #f0f0f0;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: auto;
    }
  `}
`;
