import {
  defaultTrue,
  formatEuro,
  formatNumber,
  getPixels,
  truncateText,
} from "app/utils/utils";
import { rApp, rDarkMode } from "app/utils/recoil";

import ChartHoverPopup from "./ChartHoverPopup";
import { SkeletonLoader } from "..";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getColor } from "./utils";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const BarHorizontal = ({ data }) => {
  const activeApp = useRecoilValue(rApp);

  const values = get(data, "values", []);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [mouseLocation, setMouseLocation] = useState({ top: 100, left: 100 });
  const hoveredItem = get(values, hoveredIndex);

  const darkMode = useRecoilValue(rDarkMode);

  const itemClickAction = get(data, "itemClickAction");

  const colorScheme = get(data, "colorScheme");

  const handleMouseMove = (e) => {
    setMouseLocation({ top: e.clientY, left: e.clientX });
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const max = Math.max(...values.map((item) => parseFloat(item["value"])));

  const showLabels = defaultTrue(get(data, "showLabels"));
  const showValues = defaultTrue(get(data, "showValues"));
  const decimalPlaces = get(data, "decimalPlaces", 0);

  const barGap = get(data, "barGap", 15);
  const barBorderRadius = get(data, "barBorderRadius", 10);
  const barThickness = get(data, "barThickness", 20);
  const customColorScheme = get(data, "customColorScheme");

  const isEuroDecimal = get(activeApp, "number_format") === "euro";
  const valuePrefix = get(data, "valuePrefix") || "";
  const valueSuffix = get(data, "valueSuffix") || "";

  return (
    <Container
      onMouseMove={handleMouseMove}
      gap={barGap}
      height={get(data, "height")}
    >
      {hoveredIndex !== null && (
        <ChartHoverPopup
          item={hoveredItem}
          location={mouseLocation}
          index={hoveredIndex}
          colorScheme={colorScheme}
          data={data}
        />
      )}

      {data.isFetching &&
        [1, 2, 3, 4, 5, 6, 7, 8].map((r, i) => {
          return <SkeletonLoader height="20px" widthRange={[60, 400]} />;
        })}

      {!data.isFetching &&
        values.map((item, index) => {
          const widthPercent = (parseFloat(item.value) / max) * 100;

          return (
            <ItemContainer
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => itemClickAction(item)}
            >
              {showLabels && (
                <ItemLabel darkMode={darkMode}>
                  {truncateText(item.label, 12)}
                </ItemLabel>
              )}
              <ValueContainer>
                <ValueBar
                  thickness={barThickness}
                  color={getColor(index, colorScheme, customColorScheme)}
                  width={widthPercent}
                  borderRadius={barBorderRadius}
                >
                  &nbsp;
                </ValueBar>
                {showValues && (
                  <Value darkMode={darkMode}>
                    {valuePrefix}
                    {isEuroDecimal
                      ? formatEuro(item.value, decimalPlaces)
                      : formatNumber(item.value, decimalPlaces)}
                    {valueSuffix}
                  </Value>
                )}
              </ValueContainer>
            </ItemContainer>
          );
        })}
    </Container>
  );
};

export default BarHorizontal;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(p) => getPixels(p.gap)};
  flex: 1;
  max-height: ${(p) => getPixels(p.height || 264)};
  overflow-y: auto;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`;

const ItemLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  min-width: 100px;
  max-width: 100px;
  text-align: right;
  color: ${(p) => (p.darkMode ? colors.grey1 : colors.grey4)};
`;

const ValueBar = styled.div`
  width: ${(p) => p.width}%;
  background: ${(p) => p.color || "blue"};
  border-radius: ${(p) => getPixels(p.borderRadius)};
  height: ${(p) => getPixels(p.thickness)};
  &:hover {
    filter: brightness(90%);
  }
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  gap: 8px;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => (p.darkMode ? colors.grey1 : colors.grey4)};
`;
