import { DragPreviewImage, useDrag } from "react-dnd";
import { Icon, Row } from "app/components";
import { allBlocks, allowedInsideCustomReusable } from "app/utils/utils";
import { colors, spacing } from "app/utils/theme";
import { useRef, useState } from "react";

import { NewBlock } from "../components/BlockManager/NewBlock";
import { Tooltip } from "react-tooltip";
import dragIcon from "app/assets/images/move-icon-2.png";
import styled from "styled-components";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import useAddBlock from "app/utils/useAddBlock";

const BlocksHeader = () => {
  const addBlock = useAddBlock();

  const { activeCustomBlock } = useActiveCustomBlock();

  const [showNewBlock, setShowNewBlock] = useState(false);

  let highlighted = [
    "Table",
    "Grid",
    "Form",
    "Kanban",
    "Calendar",
    "Chart",
    "Stat",
    "Text",
    "Button",
    "Row",
    "Column",
  ];

  // If repeating reusable block, filter options
  if (activeCustomBlock && activeCustomBlock.repeating) {
    highlighted = allowedInsideCustomReusable.filter((b) => b !== "RichText");
  }

  return (
    <Row
      alignItems="center"
      justifyContent="center"
      gap="10px"
      style={{ flex: 1 }}
    >
      {showNewBlock && <NewBlock hide={() => setShowNewBlock(false)} />}
      {allBlocks
        .filter((b) => highlighted.includes(b.type))
        .map((b) => (
          <BlockOption block={b} addBlock={addBlock} />
        ))}
      <Tooltip
        anchorSelect={".moreBlocks"}
        place="bottom"
        style={{ zIndex: 9999 }}
      >
        More
      </Tooltip>
      <BlockIcon className={"moreBlocks"} onClick={() => setShowNewBlock(true)}>
        <Icon
          data={{
            icon: "FiMoreHorizontal",
            size: 23,
            color: colors.primary,
            hover: true,
          }}
        />
      </BlockIcon>
    </Row>
  );
};

export default BlocksHeader;

const BlockOption = ({ block, addBlock }) => {
  const blockType = block.type;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "element",
      item: { type: "element", blockType },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  const containerRef = useRef();

  return (
    <>
      <Tooltip
        anchorSelect={`.${blockType}`}
        place="bottom"
        style={{ zIndex: 9999 }}
      >
        {blockType}
      </Tooltip>
      <DragPreviewImage connect={preview} src={dragIcon} />
      <BlockIcon
        ref={drag(containerRef)}
        className={blockType}
        key={blockType}
        onClick={() => addBlock({ type: blockType })}
      >
        <Icon
          data={{
            icon: block.icon,
            size: 23,
            color: colors.primary,
            hover: true,
            isBlue: true,
          }}
        />
      </BlockIcon>
    </>
  );
};

const BlockIcon = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${spacing.s1};
  cursor: pointer;
  margin: ${(p) => p.margin};
  &:hover {
    background: ${colors.grey1};
  }
`;
