import { get, startCase } from "lodash";
import {
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
  rSavedSpreadsheets,
} from "app/utils/recoil";

import { Select } from "app/components";
import { getRelationHeaders } from "./StaticFields";
import useActiveBlock from "app/utils/useActiveBlock";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";

const SpreadsheetColumnSelect = ({ data }) => {
  const { handleKanban } = useSpreadsheetRequests();

  const activeEditField = useRecoilValue(rActiveEditField);

  const refreshColumns = get(data, "refreshColumns", false);

  const page = usePage();

  const activeBlock = useActiveBlock();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const app = useRecoilValue(rApp);

  const blocks = get(page, "blocks", []);
  const activeDetailBlock = blocks.find((b) => b.id === activeDetailViewId);

  const currentBlock = activeBlock || activeDetailBlock;

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const activeBlockSheet =
    get(data, "linkedSpreadsheet") ||
    savedSpreadsheets.find((s) => s.id === get(currentBlock, "spreadsheet"));

  const columns = get(activeBlockSheet, "headers", []);
  const sheetId = get(activeBlockSheet, "id");

  const relatedHeaders = getRelationHeaders(
    activeBlockSheet,
    app,
    savedSpreadsheets,
    get(activeEditField, "id"),
    data.showOnlyRelationFields
  );

  const onChange = (val) => {
    if (refreshColumns) {
      handleKanban(sheetId, val);
    } else {
      data?.onChange(val);
    }
  };

  let options = [];

  if (columns.length === 0) {
    options = [{ label: "Select Column", value: null }];
  }

  // If showOnlyRelationFields is true, don't show the base columns
  if (columns.length > 0 && !data.showOnlyRelationFields) {
    options = columns.map((c) => ({ label: startCase(c), value: c }));
  }

  if (relatedHeaders.length > 0) {
    options = [
      ...options,
      ...relatedHeaders.map((c) => ({ label: startCase(c), value: c })),
    ];
  }

  return (
    <Select
      data={{
        ...data,
        onChange: onChange,
        options,
      }}
    />
  );
};

export default SpreadsheetColumnSelect;
