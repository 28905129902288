import { rActiveBlockId, rActiveDetailViewId } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Tile from "../Tile";

const DetailViewConfig = () => {
  const activeBlockId = useRecoilValue(rActiveBlockId);
  const setActiveDetailViewId = useSetRecoilState(rActiveDetailViewId);
  const setActiveBlockId = useSetRecoilState(rActiveBlockId);

  return (
    <Tile
      icon="FiEdit"
      label={"Edit Detail View"}
      onClick={() => {
        setActiveDetailViewId(activeBlockId);
        setActiveBlockId(null);
      }}
    />
  );
};

export default DetailViewConfig;
