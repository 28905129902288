import { Button, Row } from "app/components";

import AdminWrapper from "../../home/AdminWrapper";
import { ExpertsList } from "..";
import { Label } from "../../home/Pages";

const ExpertsInternal = () => {
  return (
    <AdminWrapper contentWidth="100%">
      <Row
        alignItems="center"
        justifyContent="space-between"
        margin="0 0 30px 0"
      >
        <Label>Hire A Frontly Expert</Label>
      </Row>
      <ExpertsList internal />
    </AdminWrapper>
  );
};

export default ExpertsInternal;
