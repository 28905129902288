import { colors, fontObject } from "app/utils/theme";

import React from "react";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";

const TextArea = ({ data }) => {
  const { value, onChange, placeholder, border } = data;

  const fontStyle = get(data, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);

  let backgroundColor = data.backgroundColor || data.background;
  return (
    <StyledTextArea
      onKeyDown={(e) => e.stopPropagation()}
      {...styleObject}
      {...data}
      defaultValue=""
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={placeholder}
      disabled={data.disabled}
      border={border}
      minHeight={data.minHeight}
      maxLength={data.maxLength}
      background={backgroundColor}
    />
  );
};

export default TextArea;

const StyledTextArea = styled.textarea`
  color: ${(p) => p.color};
  padding: ${(p) => p.padding || "10px"};
  border: ${(p) => p.border || `1px solid ${colors.grey2}`};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  border-width: ${(p) => p.borderWidth};
  width: ${(p) => p.width || "100%"};
  min-height: ${(p) => p.minHeight || "auto"};
  background: ${(p) => p.background};
  font-size: ${(p) => getPixels(p.fontSize)};
  font-family: ${(p) => (p.monospace ? "monospace" : "inherit")};
  resize: ${(p) => p.resize || "vertical"};
  &:focus {
    outline: none;
  }
  ${(p) =>
    p.placeholderColor &&
    `
    ::placeholder {
      color: ${p.placeholderColor};
    }
    
    ::-ms-input-placeholder { /* Edge 12 -18 */
    color: ${p.placeholderColor};
    }
  `}
`;
