import * as React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker as MUITimePicker } from "@mui/x-date-pickers/TimePicker";
import { get } from "lodash";

const TimePicker = ({ data }) => {
  const value = get(data, "value");
  const onChange = get(data, "onChange");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUITimePicker
        label={data.label}
        value={value}
        onChange={(v) => onChange && onChange(v)}
        slotProps={{
          textField: {
            size: "small",
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default TimePicker;
