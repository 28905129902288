import RenderBlockLiveMode from "./RenderBlockLiveMode";
import RenderBlockSetupMode from "./RenderBlockSetupMode";
import { isFrontlyAdmin } from "app/utils/utils";

// This file is a simple router to determine which version of the RenderBlock function we should render
const RenderBlockRouter = ({ data }) => {
  if (isFrontlyAdmin) {
    return <RenderBlockSetupMode data={data} />;
  }

  return <RenderBlockLiveMode data={data} />;
};

export default RenderBlockRouter;
