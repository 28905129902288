import {
  filterBasedOnUserGroups,
  fixCustomBlocks,
  safeArray,
} from "app/utils/utils";
import {
  rApp,
  rAppDateFormat,
  rCustomBlocks,
  rDarkMode,
  rSavedSpreadsheets,
  rUser,
} from "app/utils/recoil";

import { CustomBlockContainer } from "./CustomBlockContainer";
import { PaginationWrapper } from "app/components";
import RenderBlockRouter from "../RenderBlock/RenderBlockRouter";
import { blockConfigs } from "app/adminApp/blockConfigs";
import { get } from "lodash";
import { sortRecords } from "./Table";
import useActionResolver from "../useActionResolver";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import { useRecoilValue } from "recoil";
import useUtils from "../useUtils";

const Custom = ({ page, block }) => {
  const { actionResolver } = useActionResolver(page);

  const { processObjects } = useProcessObjects();

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const { getListData } = useListData();

  const darkMode = useRecoilValue(rDarkMode);

  const { recordClick, processDynamicText, passesDisplayConditions } =
    useUtils();

  const user = useRecoilValue(rUser);

  const app = useRecoilValue(rApp);

  const customBlocks = useRecoilValue(rCustomBlocks);

  const matchingBlock = safeArray(customBlocks).find(
    (b) => b.id === block.customBlock
  );

  const inputs = get(block, "inputs", []);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const b = get(matchingBlock, "blocks", []).map((b) => {
    let newBlock = { ...b };

    let actionMap = {};

    get(blockConfigs, [b.componentId, "resources"], [])
      .filter((r) => r.isAction)
      .forEach((r) => {
        const concat = `action-${b.id}-${r.id}`;
        const matchingCustomBlockAction = get(block, concat);
        const match = get(page, "actions", []).find(
          (a) => a.id === matchingCustomBlockAction
        );
        actionMap[r.id] = match;
      });

    return {
      ...newBlock,
      actionMap,
      inputMap: inputs,
    };
  });

  // const activeUserGroups = get(user, ["user_groups", app.id], []);

  const blocks = fixCustomBlocks(
    filterBasedOnUserGroups({
      items: b,
      app,
      user,
      passesDisplayConditions,
    })
    // TODO - LOOK INTO THIS
    // Patrick removed this on May 15, 2024
    // It was breaking the ability to apply display conditions to the sub-blocks, and I'm not sure if this even really does anyting useful
    // .filter((b) =>
    //   passesDisplayConditions({
    //     conditions: get(b, "displayConditions", []),
    //     reusableBlockId: block.id,
    //   })
    // )
  );

  const recordClickAction = get(block, "recordClickAction");

  const mockPage = {
    requireLogin: get(page, "requireLogin", false),
    newLayoutSystem: true,
    blocks: blocks,
    actions: get(matchingBlock, "actions", []),
  };

  const rootBlock = blocks.find((b) => !b.layoutParent);

  // Handle repeating block
  if (get(matchingBlock, "repeating")) {
    const data = getListData(block);
    const resultsPerPage = get(block, "resultsPerPage", 10);
    let items = processObjects(page, block, [], data, processDynamicText);

    const sortingColumn = get(block, "sortingColumn");
    const sortingDirection = get(block, "sortingDirection", "asc");

    if (sortingColumn) {
      const spreadsheet = savedSpreadsheets.find(
        (s) => s.id === block.spreadsheet
      );

      const fieldData = get(
        spreadsheet,
        ["field_data", "config", sortingColumn],
        {}
      );

      let columnType = "text";

      if (get(fieldData, "componentId") === "DateTimePicker") {
        columnType = "date";
      }

      // Sort rows
      items = sortRecords(
        items,
        sortingColumn,
        sortingDirection,
        columnType,
        get(fieldData, "dateFormat", {}),
        appDateFormat
      );
    }

    return (
      <PaginationWrapper
        itemsPerPage={resultsPerPage}
        items={items}
        hideBorder={true}
        padding="16px 0 0 0"
        noResultsPadding="0 0 16px 0"
        darkMode={darkMode}
        isFetching={block.isFetching}
        block={block}
      >
        {(paginatedItems) => {
          return (
            <CustomBlockContainer
              itemCount={paginatedItems.length}
              {...matchingBlock}
              containerType={get(matchingBlock, "containerType", "grid")}
            >
              {paginatedItems.map((item, i) => {
                const customBlockOnClick =
                  recordClickAction === "none"
                    ? null
                    : () => recordClick(block, item, actionResolver);

                return (
                  <RenderBlockRouter
                    key={i}
                    data={{
                      page: mockPage,
                      customBlockOnClick,
                      block: {
                        ...rootBlock,
                        reusableBlockId: block.id,
                        id: block.id,
                        originalBlockId: get(rootBlock, "id"),
                        parentComponentId: block.parentComponentId,
                        repeatingRecord: item,
                      },
                    }}
                  />
                );
              })}
            </CustomBlockContainer>
          );
        }}
      </PaginationWrapper>
    );
  }

  return (
    <RenderBlockRouter
      data={{
        page: mockPage,
        block: {
          ...rootBlock,
          reusableBlockId: block.id,
          id: block.id,
          originalBlockId: get(rootBlock, "id"),
          parentComponentId: block.parentComponentId,
        },
      }}
    />
  );
};

export default Custom;
