import { layoutOptions, layouts } from "app/renderingApp/layouts";

import { Select } from "app/components";
import { get } from "lodash";
import usePage from "app/utils/usePage";
import useSetPage from "app/utils/useSetPage";

const LayoutSelect = ({ data }) => {
  const { setPage } = useSetPage();

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const resetLayout = (layoutId) => {
    const layout = get(layouts, layoutId);
    const layoutItems = get(layout, "items", []).map((i) => i.i);
    const newBlocks = get(page, "blocks", []).map((b, i) => ({
      ...b,
      layoutPosition: get(layoutItems, i),
    }));

    setPage({ ...page, layout: layoutId, blocks: newBlocks });
  };

  return (
    <>
      <Select
        data={{
          ...data,
          options: layoutOptions,
          onChange: resetLayout,
        }}
      />
    </>
  );
};

export default LayoutSelect;
