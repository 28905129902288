import { backgroundImageStyles } from "./shared";

export const Column = {
  componentId: "Column",
  resources: [
    {
      id: "label",
      label: "Reference Label",
      componentId: "Input",
      section: "content",
      hint: "An internal label for your own reference only.",
    },
    {
      id: "gap",
      label: "Item Spacing",
      hint: "The space between the items in the row",
      section: "spacing",
      componentId: "Input",
      type: "number",
      defaultValue: 20,
      isStyle: true,
      orientation: "horizontal",
      width: "120px",
    },
    ...backgroundImageStyles,
    {
      id: "blockHierarchy",
      hint: "Configure the blocks inside this Row",
      section: "blocks",
      componentId: "BlockHierarchy",
    },
    {
      id: "verticalAlignment",
      label: "Horizontal Alignment",
      hint: "Define how the items align horizontally",
      section: "spacing",
      defaultValue: "flex-start",
      componentId: "SelectToggle",
      isStyle: true,
      tabs: [
        {
          label: "Start",
          value: "flex-start",
          icon: "Custom-VAlignBottom",
          rotate: 90,
        },
        {
          label: "Center",
          value: "center",
          icon: "Custom-VAlignCenter",
          rotate: 90,
        },
        {
          label: "End",
          value: "flex-end",
          icon: "Custom-VAlignTop",
          rotate: 90,
        },
      ],
    },
    {
      id: "horizontalAlignment",
      label: "Vertical Alignment",
      hint: "Define how the items align vertically within a column with a specified height.",
      section: "spacing",
      defaultValue: "flex-start",
      componentId: "SelectToggle",
      isStyle: true,
      tabs: [
        {
          label: "Start",
          value: "flex-start",
          icon: "Custom-VAlignBottom",
        },
        {
          label: "Center",
          value: "center",
          icon: "Custom-VAlignCenter",
        },
        {
          label: "End",
          value: "flex-end",
          icon: "Custom-VAlignTop",
        },
        {
          label: "Space Between",
          value: "space-between",
          icon: "Custom-VSpaceBetween",
        },
        {
          label: "Space Around",
          value: "space-around",
          icon: "Custom-VSpaceAround",
        },
      ],
    },
    {
      id: "clickAction",
      label: "Click Action",
      componentId: "Action",
      section: "actions",
      isAction: true,
    },
    {
      id: "switchToRow",
      componentId: "SwitchComponent",
      type: "Row",
      section: "switchToRow",
    },
  ],
};
