import { useEffect, useMemo, useRef, useState } from "react";

import Marker from "./Marker";
import { Spinner } from "app/components";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import { parseCoordinates } from "./mapUtils";
import useActionResolver from "../../useActionResolver";
import { useJsApiLoader } from "@react-google-maps/api";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import useUtils from "app/renderingApp/useUtils";

const Map = ({ block, page }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  if (!isLoaded) {
    return <Spinner />;
  }

  if (loadError) {
    return <p>Uh Oh! Something went wrong. Please try again.</p>;
  }

  return <MapComponent block={block} page={page} />;
};

const MapComponent = ({ block, page }) => {
  const { processObjects } = useProcessObjects();
  const { processDynamicText, recordClick } = useUtils();

  const { getListData } = useListData();

  const data = getListData(block);

  const fields = [
    {
      mapKey: "latitude",
      key: get(block, "latitude"),
    },
    {
      mapKey: "longitude",
      key: get(block, "longitude"),
    },
  ];

  const markers = processObjects(page, block, fields, data, processDynamicText);

  const mapRef = useRef(null);

  const [map, setMap] = useState(null);

  // memoized value
  const center = useMemo(() => ({ lat: 40.7128, lng: -74.006 }), []); // NY City

  useEffect(() => {
    if (mapRef.current && !map && window.google.maps) {
      setMap(
        new window.google.maps.Map(mapRef.current, {
          center,
          zoom: 12, // from 0 to 22
        })
      );
    }
  }, [mapRef, map]);

  useEffect(() => {
    if (markers && markers.length > 0 && map) {
      // Create an empty LatLngBounds object
      const bounds = new window.google.maps.LatLngBounds();

      // Extend the bounds to include each marker's position
      for (const marker of markers) {
        if (!marker.latitude && !marker.longitude) {
          continue;
        } else if (!marker.latitude || !marker.longitude) {
          continue;
        } else if (marker.latitude && marker.longitude) {
          const coords = parseCoordinates({
            lat: marker.latitude,
            lng: marker.longitude,
          });
          bounds.extend(coords);
        }
      }

      if (
        markers.length === 1 &&
        !isNaN(parseFloat(markers[0].latitude)) &&
        !isNaN(parseFloat(markers[0].longitude))
      ) {
        map.setCenter(
          parseCoordinates({
            lat: markers[0].latitude,
            lng: markers[0].longitude,
          })
        );
      } else if (markers.length > 1) {
        // Now fit the map to the newly inclusive bounds
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers, map]);

  const { actionResolver } = useActionResolver(page);

  return (
    <div
      ref={mapRef}
      style={{
        height: getPixels(block.height || 400),
        width: "100%",
        borderRadius: getPixels(get(block, "borderRadius", 8)),
      }}
    >
      {markers.map((marker, index) => {
        const position = parseCoordinates({
          lat: marker.latitude,
          lng: marker.longitude,
        });
        const onMarkerClick = () => recordClick(block, marker, actionResolver);

        return (
          <Marker
            key={index}
            map={map}
            position={position}
            onClick={onMarkerClick}
          />
        );
      })}
    </div>
  );
};

export default Map;
