import { Row, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";

import AdminForm from "../components/AdminForm";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { rApp } from "app/utils/recoil";
import { useRecoilState } from "recoil";
import { useState } from "react";

const General = () => {
  const [app, setApp] = useRecoilState(rApp);

  const [changes, setChanges] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const save = () => {
    setIsSaving(true);

    apiRequest.post("/app_settings/", changes).then((r) => {
      const error = get(r, ["data", "error"]);

      setIsSaving(false);

      if (error) {
        errorNotification(error);

        const errorType = get(r, ["data", "error_type"]);

        if (errorType === "subdomain") {
          console.log("SUBDOMAIN ERROR");
          // TODO - handle subdomain error
        }
      } else {
        successNotification("Saved");
      }
    });
    setChanges({});
  };

  let fields = [
    {
      id: "name",
      label: "App Name",
      placeholder: "App Name",
      componentId: "Input",
      value: get(app, "name"),
    },
    {
      id: "default_page",
      label: "Default Page",
      hint: "The page that users are redirected to when they login",
      componentId: "PageSelect",
      value: get(app, "default_page"),
    },
    {
      id: "subdomain",
      label: "Frontly Subdomain",
      placeholder: "Subdomain",
      hint: "Most companies set their subdomain to their business name with no spaces",
      componentId: "Input",
      value: get(app, "subdomain"),
    },
    {
      id: "support_email",
      label: "Support Email",
      placeholder: "support@yourcompany.com",
      hint: "Define the email address used in outbound email from your app.",
      componentId: "Input",
      value: get(app, "support_email"),
    },
    {
      id: "date_format",
      label: "Default Date Format",
      hint: "Set a default date format to be used in all date-related settings throughout the app. This can be overridden on a block or setting level.",
      componentId: "DateFormatConfig",
      value: get(app, "date_format"),
      enabledFields: [
        "inputDate",
        "outputDate",
        "inputTime",
        "outputTime",
        "showAmPm",
      ],
    },
  ];

  function stripNonASCII(inputStr) {
    // Replace all non-ASCII characters with an empty string
    return inputStr.replace(/[^\x00-\x7F]/g, "").replace(" ", "");
  }

  const customDomain = get(app, ["custom_domain", "custom_domain"]);

  const domain = customDomain || get(app, "subdomain");

  const appUrl = customDomain
    ? `https://${customDomain}`
    : `https://${domain}.frontly.ai`;

  return (
    <SettingsSection
      title="General Settings"
      description="Configure your high-level app settings like name, default page and branding"
    >
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="5px"
        fields={fields}
        orientation="horizontal"
        submitText={"Save Changes"}
        submit={!isEmpty(changes) && save}
        width="220px"
        isFetching={isSaving}
        onChange={(k, v) => {
          let finalVal = v;
          if (k === "subdomain") {
            finalVal = v.toLowerCase();
            finalVal = stripNonASCII(finalVal);
          }
          setChanges({ ...changes, [k]: finalVal });
          setApp({
            [k]: finalVal,
          });
        }}
      />
      <Row margin="15px 0 0 5px" justifyContent="space-between">
        <Text
          data={{
            text: "Your App URL:",
            fontStyle: "headingSm",
          }}
        />
        <Text
          data={{
            text: appUrl,
            fontStyle: "headingSm",
            color: colors.primary,
            cursor: "pointer",
            onClick: () => window.open(appUrl, "_blank"),
          }}
        />
      </Row>
    </SettingsSection>
  );
};

export default General;
