import { colors, spacing } from "app/utils/theme";
import { getPixels, isFrontlyAdmin, parseBoolean } from "app/utils/utils";

import styled from "styled-components";

const SwitchWrapper = styled.div`
  min-width: ${(p) => p.backgroundWidth};
  max-width: ${(p) => p.backgroundWidth};
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid ${(p) => (p.active ? colors.primary : colors.grey2)};
  padding: ${spacing.s025};
  display: flex;
  justify-content: ${(p) => (p.active ? "flex-end" : "flex-start")};
  transition: 0.4s;

  background: ${(p) => (p.active ? colors.primary : colors.grey2)};

  ${(p) =>
    p.darkMode &&
    `
    background: ${p.active ? colors.primary : colors.darkModeLightGrey};
    border: 1px solid ${p.active ? colors.primary : colors.darkModeLightGrey};
    `}

  ${(p) =>
    p.disabled &&
    !isFrontlyAdmin &&
    `background: ${colors.grey2}; cursor: not-allowed; border: 1px solid ${colors.grey2};`}

  ${(p) =>
    !p.disabled &&
    `
      &:hover {
        filter: brightness(90%);
      }
  `}
`;

const SwitchBall = styled.div`
  background: white;
  border-radius: 100px;
  height: ${(p) => p.ballHeight};
  min-width: ${(p) => p.ballHeight};
  max-width: ${(p) => p.ballHeight};
  padding: ${spacing.s025};
`;

const Switch = ({ data }) => {
  const { disabled, value, onChange } = data;

  // this is using 'isDarkMode' so that it doesn't conflict wit hthe system wide setting
  const darkMode = data.isDarkMode;

  const isActive = parseBoolean(value);

  // Handle Switch Ball Height
  let ballHeight = data.ballHeight || 12;
  if (ballHeight < 10) {
    ballHeight = 10;
  }
  const resolvedBallHeight = getPixels(ballHeight);

  let backgroundWidth = data.backgroundWidth || 28;
  if (backgroundWidth < 28) {
    backgroundWidth = 28;
  }
  const resolvedBackgroundWidth = getPixels(backgroundWidth);

  return (
    <SwitchWrapper
      darkMode={darkMode}
      backgroundWidth={resolvedBackgroundWidth}
      disabled={disabled}
      onClick={
        disabled
          ? null
          : (e) => {
              e.stopPropagation();
              onChange(!isActive);
            }
      }
      active={isActive}
    >
      <SwitchBall
        darkMode={darkMode}
        active={isActive}
        ballHeight={resolvedBallHeight}
      />
    </SwitchWrapper>
  );
};

export default Switch;
