import { Button, Modal } from "app/components";
import { get, isNil } from "lodash";
import { useEffect, useState } from "react";

import { AdminForm } from "app/adminApp/components";
import { blockConfigs } from "app/adminApp/blockConfigs";
import { colors } from "app/utils/theme";
import { rCustomBlocks } from "app/utils/recoil";
import { safeArray } from "app/utils/utils";
import { spreadsheetSelect } from "app/adminApp/blockConfigs/shared";
import useActiveBlock from "app/utils/useActiveBlock";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";

const BlockWizard = () => {
  const { setBlock } = useSetPage();

  const activeBlock = useActiveBlock();

  const activeBlockConfig = get(blockConfigs, get(activeBlock, "componentId"));

  const [hasIncompleteResources, setHasIncompleteResources] = useState(false);

  let wizardResources = get(activeBlockConfig, "resources", [])
    .filter((r) => r.wizard)
    .map((r) => ({
      ...r,
      section: null,
      orientation: "vertical",
      width: "100%",
      value: get(activeBlock, r.id),
    }))
    .filter(
      (f) => !f.requiresSheet || (f.requiresSheet && activeBlock.spreadsheet)
    )
    .filter(
      (f) =>
        !f.displayCondition ||
        (f.displayCondition && f.displayCondition(activeBlock))
    );

  const customBlocks = useRecoilValue(rCustomBlocks);
  const matchingCustomBlock = safeArray(customBlocks).find(
    (b) => b.id === get(activeBlock, "customBlock")
  );
  const repeatingCustomBlock = get(matchingCustomBlock, "repeating");
  if (repeatingCustomBlock) {
    wizardResources.push({
      ...spreadsheetSelect,
      section: null,
      value: get(activeBlock, "spreadsheet"),
    });
  }

  const incompleteResources = wizardResources.filter((r) =>
    isNil(get(activeBlock, r.id))
  );

  useEffect(() => {
    if (incompleteResources.length > 0) {
      setHasIncompleteResources(true);
    }
  }, [activeBlock.id]);

  if (hasIncompleteResources) {
    return (
      <Modal
        hide={() => setHasIncompleteResources(false)}
        background={colors.grey1}
        minWidth="350px"
        header={{
          title: `Finalize ${get(activeBlock, "componentId")} Setup`,
          description: "Complete the required fields below",
        }}
      >
        <AdminForm
          labelStyle={"headingSm"}
          margin="10px 0 0 0"
          sectionPadding={"0px"}
          onChange={(k, v) => setBlock({ ...activeBlock, [k]: v })}
          fields={wizardResources}
        />
        <Button
          data={{
            text: "Complete Setup",
            width: "100%",
            disabled: incompleteResources.length > 0,
            margin: "15px 0 0 0",
            onClick: () => setHasIncompleteResources(false),
          }}
        />
      </Modal>
    );
  }

  return <></>;
};

export default BlockWizard;
