import { Button, Modal, Row, Spinner } from "app/components";
import { rApp, rSetConfig } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { TemplateDetails } from "../landing/TemplateLandingPage";
import { colors } from "app/utils/theme";
import { copyApp } from "../home/duplicateUtils";
import { demoApps } from "../appManagement/Templates";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const TemplateModal = ({ activeTemplateId, setActiveTemplateId }) => {
  const navigate = useNavigate();

  const setConfig = useSetRecoilState(rSetConfig);

  const [copyingStatus, setCopyingStatus] = useState(false);
  const [copyAppSubdomain, setCopyAppSubdomain] = useState(null);

  const activeTemplate = demoApps.find((app) => app.key === activeTemplateId);

  const app = useRecoilValue(rApp);

  const createDemoApp = async (subdomain, type) => {
    setCopyAppSubdomain(null);
    try {
      const newAppData = await copyApp(subdomain, setCopyingStatus, type);
      setConfig(newAppData);

      successNotification("App Created");
      navigate("/pages");
      localStorage.setItem("appId", get(newAppData, ["app", "id"]));
      mixpanel.track("Demo App Created", { template: subdomain });
    } catch (error) {
      console.error("Error creating demo app:", error);
      // Handle error accordingly. Maybe set an error state or show a notification to the user.
    }
  };

  if (copyAppSubdomain) {
    return (
      <Modal
        hide={() => setCopyAppSubdomain(null)}
        minWidth="600px"
        header={{
          title: "Install Template",
          description:
            "Do you want a brand-new app, or do you want the spreadsheets and pages to copy into your current app?",
        }}
      >
        <Row gap={"15px"} margin="10px 0 0 0">
          <Button
            data={{
              text: "Create New App",
              onClick: () => createDemoApp(copyAppSubdomain, "new"),
            }}
          />
          <Button
            data={{
              text: "Copy Content Into Current App",
              onClick: () => createDemoApp(copyAppSubdomain, "current"),
            }}
          />
        </Row>
      </Modal>
    );
  }

  const googleConnected = get(app, "google_sheets_connected");

  return (
    <Modal
      minWidth="900px"
      header={{
        title: "Template Details",
      }}
      hide={() => setActiveTemplateId(null)}
    >
      {copyingStatus && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <Spinner color={colors.primary} />
        </div>
      )}

      {!copyingStatus && (
        <TemplateDetails
          hideBreadcrumb
          inModal
          template={activeTemplate}
          backFunction={() => setActiveTemplateId(null)}
          onClick={() => {
            if (!googleConnected) {
              successNotification(
                "Please connect your Google Sheets account to install this template."
              );
              navigate("/spreadsheets");
            } else {
              const activeTemplateSubdomain = get(activeTemplate, "link", "")
                .split("https://")[1]
                .split(".frontly.ai")[0];
              setCopyAppSubdomain(activeTemplateSubdomain);
            }
          }}
        />
      )}
    </Modal>
  );
};

export default TemplateModal;
