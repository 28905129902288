import classes from "./RichText.module.css";
import { get } from "lodash";
import parse from "html-react-parser";
import useUtils from "app/renderingApp/useUtils";

function wrapLinesInPTags(str) {
  if (str) {
    // Split the string by line breaks
    const lines = str.split("\n");

    // Wrap each line in a <p> tag
    const wrappedLines = lines.map((line) => `<p>${line}</p>`);

    // Join the wrapped lines back into a single string
    return wrappedLines.join("");
  }

  return "";
}

const RichText = ({ block, noValuesText }) => {
  const content = get(block, "content");
  const { processDynamicText } = useUtils();
  const processedContent = wrapLinesInPTags(
    processDynamicText({
      text: content,
      reusableBlockId: block.reusableBlockId,
      context: { repeatingRecord: get(block, "repeatingRecord") },
    })
  );

  if (!content) {
    return (
      <div>
        {noValuesText || "Rich Text data will show here once configured"}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {processedContent && parse(processedContent)}
    </div>
  );
};

export default RichText;
