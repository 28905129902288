import { CardSection, Label } from "../home/Pages";

import { Template } from "./Template";
import TemplateModal from "../components/TemplateModal";
import { useState } from "react";

const aiClientPortalDescription = `
Introducing the '**Customer Portal with AI Onboarding**' Template for Frontly.

This innovative template is crafted to redefine the way businesses onboard their customers, by seamlessly blending the sophistication of OpenAI with user-friendly design. Here’s why this template stands out as a pivotal tool for your business:

**Intelligent AI Onboarding Experience** 
Kickstart your customer's journey with an AI-driven onboarding process. Utilizing Frontly’s integration with OpenAI, this feature offers personalized responses based on user inputs during the onboarding phase, ensuring each customer feels uniquely valued right from the start.

**Engaging Customer Portal with Messaging System**
Elevate customer interaction through a dedicated portal that not only enhances engagement but also provides a direct line of communication. This feature allows for direct messaging, feedback collection, and continuous support, establishing a robust relationship with your clientele.

**Robust Admin Portal for Seamless Management**
Equip your team with an admin portal that encapsulates efficiency and control.

It includes: 
- **Essential Dashboard**: View key insights and metrics at a glance.
- **Customer Pipeline Kanban Board**: Effortlessly manage your customer onboarding pipeline with a visually intuitive Kanban board.
- **Detailed Customer Profiles**: Get immediate access to full customer details.
- **Integrated Messaging**: Communicate with customers directly through the admin portal for streamlined and effective interactions.

The 'Customer Portal with AI Onboarding' template is engineered for businesses eager to modernize their customer onboarding experience and build lasting relationships through enhanced engagement and personalized interaction.

Its intuitive design, combined with powerful AI capabilities, removes the need for complex coding skills, making it an indispensable tool for your business’s growth and customer satisfaction.
`;

const blogPostGeneratorDescription = `
Introducing the '**Blog Post Generator**' Template for Frontly.

This template shows the power of Frontly's new Markdown formatting editor and OpenAI integration. It's designed to help you generate blog posts in Markdown format with ease, using the capabilities of OpenAI to enhance your content creation process.

This template can be modified to suit your specific needs, and used for almost any use-case that requires generating content with a formatted text output, like emails, articles, or social media posts.

Here's what the 'Blog Post Generator' template offers:

**1. Markdown Formatting Editor**
Create and edit blog posts in Markdown format with a user-friendly editor. This feature allows you to structure your content effectively and format it according to your preferences.

**2. OpenAI Integration**
Leverage the power of OpenAI to generate content ideas, outlines, and even full blog posts. This integration enhances your content creation process by providing AI-generated suggestions and insights.

**3. Custom Actions**
Define custom actions to automate specific tasks within the template. This feature allows you to streamline your workflow and create a more efficient content creation process.

`;

const realEstateListingsDescription = `
Introducing the '**Real Estate Listings**' Frontly App Template.

This app showcases the power of Frontly's Custom Blocks, enabling you to create fully customized, professional user interface designs.

No more need to compromise your UI design just because you're using a no-code platform!

**This template includes:**
 - Modern, airbnb-style real estate listing cards, which can be customized for use with any industry or use-case like product listings, marketplaces, etc.
 - A stunning banner image
 - A 'Map View' page allowing listings to be viewed using the Google Map block for location-based browsing.

Install this template today and start customizing it for your own use case. The possibilities are endless!
`;

const webPageSummaryDescription = `
This app highlights the power of Frontly’s AI-driven features, allowing you to generate concise summaries of any web page with a simple URL input.

Say goodbye to information overload and let the AI summarize web content in seconds!

**This template includes:**
	- A clean and intuitive user interface where users can enter a URL and receive an instant summary.
	- Customizable summary format options to tailor the output to different use cases, such as quick notes, detailed briefs, or bullet-point highlights.
	- A responsive design ensuring the app looks great on both desktop and mobile devices.

Install this template today and start transforming web pages into easy-to-digest summaries. The possibilities are endless!
`;

export const demoApps = [
  {
    key: "web-page-summary",
    label: "Web Page Summary",
    description:
      "Scrape the text content of a web page and provide an AI-powered summary",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1724267919/Screenshot_2024-08-21_at_12.15.02_PM_jzueon.png",
    screenshots: [],
    video: "https://youtu.be/HaUO2gT300A",
    videoThumbnail:
      "https://res.cloudinary.com/frontly/image/upload/v1724267796/webscraper_heyv9c.png",
    link: "https://web-page-summary.frontly.ai",
    hue: 200,
    categories: ["Web Scraping", "AI"],
    features: [
      "Web Scraper",
      "OpenAI Request",
      "Custom Actions",
      "Local State",
    ],
    fullDescription: webPageSummaryDescription,
  },

  {
    key: "real-estate-listings",
    label: "Real Estate Listings",
    description:
      "A beautiful, modern real estate listings app that can be customized for any industry",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1715283518/Screenshot_2024-05-08_at_2.19.59_PM_xtnbcc.png",
    screenshots: [
      "https://res.cloudinary.com/frontly/image/upload/v1715283812/Screenshot_2024-05-09_at_12.43.21_PM_t43cjx.png",
    ],
    video: "https://youtu.be/GWd4QUDbi5k",
    videoThumbnail:
      "https://res.cloudinary.com/frontly/image/upload/v1715406526/reusableblocks-cover_b1oozh.jpg",
    link: "https://real-estate-listings.frontly.ai",
    hue: 150,
    categories: ["Listings", "Agencies", "Contractors", "Marketing"],
    features: [
      "Custom Blocks",
      "Block Versions",
      "Custom Actions",
      "Local State",
    ],
    fullDescription: realEstateListingsDescription,
  },
  {
    key: "blog-post-generator",
    label: "AI Blog Post Generator",
    description:
      "Generate blog posts in Markdown format with Frontly's OpenAI Integration",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1716862962/Screenshot_2024-05-27_at_7.22.23_PM_zjfkqd.png",
    screenshots: [
      "https://res.cloudinary.com/frontly/image/upload/v1716862539/Screenshot_2024-05-27_at_2.15.34_PM_rzyaoq.png",
      "https://res.cloudinary.com/frontly/image/upload/v1716862749/Screenshot_2024-05-27_at_7.18.45_PM_ijhgyg.png",
    ],
    link: "https://blog-post-generator.frontly.ai",
    hue: 232,
    categories: [
      "Artificial Intelligence",
      "Content Creation",
      "Agencies",
      "Contractors",
      "Marketing",
    ],
    features: [
      "OpenAI Request",
      "Custom Actions",
      "Local State",
      "Display Conditions",
    ],
    fullDescription: blogPostGeneratorDescription,
  },
  {
    key: "responsive-analytics-dashboard",
    description: "A mobile-responsive analytics dashboard for your data",
    categories: ["Analytics", "Data Visualization", "Charts"],
    features: ["Charts", "Analytics", "Stats", "Tables"],
    fullDescription:
      "This template is a mobile-responsive analytics dashboard that can be used to visualize and analyze data. It includes various charts and graphs to help you understand your data better. The dashboard is fully customizable and can be used to display any type of data you want.",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1716866534/Screenshot_2024-05-27_at_8.20.04_PM_n9n3c2.png",
    link: "https://responsive-analytics-dashboard.frontly.ai",
    hue: 40,
  },
  {
    key: "applicant-tracker",
    description: "Job applicant tracker for HR team with analytics",
    categories: ["Human Resources", "Recruitment"],
    features: [
      "Applicant Tracking",
      "Interview Scheduling",
      "Collaborative Hiring",
    ],
    fullDescription:
      "Transform your recruitment process with an Applicant Tracker designed for HR teams. This platform streamlines applicant tracking from initial submission to final decision, facilitates interview scheduling, and supports collaborative hiring decisions. With comprehensive candidate profiles and a centralized communication hub, it ensures a smooth and efficient hiring process.",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1695277815/demo-apps/Screenshot_2023-09-20_at_11.30.05_PM_g1qfnz.png",
    link: "https://applicant-tracker.frontly.ai",
    hue: 259,
  },
  {
    key: "ai-client-portal",
    label: "AI Client Portal",
    description: "Onboard clients with full-screen forms and AI",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1712340901/ai-portal-1_kys7ma.png",
    screenshots: [
      "https://res.cloudinary.com/frontly/image/upload/v1712340901/ai-portal-2_xyidqb.png",
    ],
    video: "https://www.youtube.com/watch?v=CirRD9DEsJc",
    videoThumbnail:
      "https://res.cloudinary.com/frontly/image/upload/v1712270273/maxresdefault_ar2la9.jpg",
    link: "https://ai-client-portal.frontly.ai",
    hue: 200,
    categories: [
      "Artificial Intelligence",
      "Client Portal",
      "Agencies",
      "Contractors",
    ],
    features: [
      "OpenAI Request",
      "Custom Actions",
      "Local State",
      "Display Conditions",
    ],
    fullDescription: aiClientPortalDescription,
  },
  {
    key: "business-idea-generator",
    label: "AI Business Idea Generator",
    description: "Generate and refine business ideas with AI",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1709689463/Screenshot_2024-03-05_at_5.44.12_PM_b08vne.png",
    link: "https://business-idea-generator.frontly.ai",
    hue: 150,
    categories: ["Artificial Intelligence", "Internal Tools"],
    features: ["OpenAI Request", "Custom Actions"],
    fullDescription:
      "Streamline idea generation and refinement with a custom AI-powered workflow. In this template, we use AI to generate business ideas based on the user's input, then refine further by generating multiple customer acquisition strategies for each business idea.",
  },
  {
    key: "content-pipeline-manager",
    description: "Produce marketing content at scale with AI",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1710896521/Screenshot_2024-03-19_at_6.01.39_PM_horuzg.png",
    screenshots: [
      "https://res.cloudinary.com/frontly/image/upload/v1710959963/Screenshot_2024-03-20_at_11.39.02_AM_z40van.png",
    ],
    video: "https://youtu.be/1oVAAH4WLdA",
    videoThumbnail:
      "https://res.cloudinary.com/frontly/image/upload/v1710959869/maxresdefault_z3746w.jpg",
    link: "https://content-pipeline-manager.frontly.ai",
    hue: 200,
    categories: ["Artificial Intelligence", "Internal Tools", "Marketing"],
    features: ["OpenAI Request", "Custom Actions"],
    fullDescription:
      "Introducing the 'AI Content Pipeline Manager' template for Frontly: a no-code solution designed to streamline your content creation process. This template leverages the power of Frontly's OpenAI integration to enhance your content strategy. Here’s what it offers:\n\n1. Content Idea Generation: Utilize Frontly's OpenAI integration to automatically generate fresh and relevant content ideas. This feature is designed to keep your content calendar innovative and engaging.\n\n2. Team Assignment: Easily assign new content ideas to your team members. This function allows for a smooth distribution of tasks, ensuring everyone knows what they need to work on.\n\n3. Production Process Tracking: Monitor the progress of content creation using a Kanban board. This visual tool provides clear insights into each stage of the content production process, from ideation to publication, making it easier to manage deadlines and workflow.\n\n4. Content Library Browsing and Filtering: Access your entire content library in one place. This feature offers powerful filtering capabilities, making it simple to find and organize your content based on categories, publication status, or any other criteria you set.\n\n5. Team Expansion: Add new content creators to your team directly within the platform. This functionality supports team growth, allowing you to scale your content production efforts efficiently.\n\nThe 'AI Content Pipeline Manager' template is designed for teams looking for an efficient way to generate, assign, track, and manage their content creation process. With its straightforward interface and comprehensive feature set, it’s an ideal tool for enhancing your content strategy without the need for complex coding or technical expertise.",
  },
  {
    key: "client-project-portal",
    description: "Give clients a secure login to review project updates",
    categories: ["Client Management", "Project Management"],
    features: ["Secure Login", "Project Tracking", "Client Communication"],
    fullDescription:
      "Optimize client engagement and streamline project management with the Client Project Portal. This tool provides a secure login for clients to access real-time updates, documents, and communication channels. Designed to enhance transparency and collaboration, it features intuitive project tracking, milestone updates, and a dedicated space for feedback, ensuring clients are fully integrated into the project lifecycle.",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1695278075/demo-apps/Screenshot_2023-09-20_at_11.34.20_PM_grkzaq.png",
    link: "https://agency-one.frontly.ai",
    hue: 221,
  },
  {
    key: "product-returns",
    description: "A portal for customers to manage product returns",
    categories: ["E-commerce", "Customer Service"],
    features: [
      "Automated Return Process",
      "Customer Account Integration",
      "Return Status Tracking",
    ],
    fullDescription:
      "Start with this simple returns system where customers enter their order information to find and manage their order. This platform automates return requests and tracks the status of returned items. By simplifying returns, it enhances customer satisfaction, encourages repeat business, and streamlines inventory management.",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1695278155/demo-apps/Screenshot_2023-09-20_at_11.35.46_PM_jskeu0.png",
    link: "https://returns.frontly.ai",
    hue: 165,
  },
  {
    key: "quote-calculator",
    description: "Give clients an instant quote from spreadsheet calculations",
    categories: ["Sales", "Client Services"],
    features: [
      "Instant Quotes",
      "Customizable Calculation Formulas",
      "Integration with CRM",
    ],
    fullDescription:
      "Empower your sales team with a simple Quote Calculator that generates accurate quotes based on customizable calculation formulas in your Google Sheet. This tool seamlessly integrates with your spreadsheet data, providing clients with instant price estimates for services or products. It's an invaluable asset for improving sales efficiency and customer satisfaction.",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1695236460/demo-apps/quote-calculator_yz8t2g.png",
    link: "https://nyc-moving.frontly.ai",
    hue: 0,
  },
  {
    key: "bike-rentals",
    description: "Manage appointments, track inventory and analytics",
    categories: ["Rental Services", "Inventory Management"],
    features: [
      "Appointment Management",
      "Inventory Tracking",
      "Analytics Dashboard",
    ],
    fullDescription:
      "Streamline the operation of your bike rental service with a comprehensive management app. This platform offers appointment scheduling, inventory tracking, and a robust analytics dashboard to monitor business performance. It enhances customer service, optimizes inventory utilization, and provides valuable insights for strategic decision-making.",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1690089600/demo-apps/bikes-screen_e2lqxo.png",
    link: "https://bike-rental.frontly.ai",
    hue: 174,
  },
  {
    key: "dog-walking",
    description: "An app for managing a dog walking business",
    categories: ["Service Industry", "Pet Care", "Internal Tools"],
    features: ["Scheduling", "Customer Management", "Map"],
    fullDescription:
      "Manage your dog walking business with ease, featuring profiles for clients and their pets and walk tracking and scheduling calendar.",
    image:
      "https://res.cloudinary.com/frontly/image/upload/v1695277387/demo-apps/Screenshot_2023-09-20_at_11.22.44_PM_wjb80m.png",
    link: "https://dog-walking.frontly.ai",
    hue: 0,
  },
];

export const demoSubdomains = demoApps.map(
  (a) => a.link.split("https://")[1].split(".frontly.ai")[0]
);

export const TemplatesSection = () => {
  const [activeTemplateId, setActiveTemplateId] = useState(null);

  return (
    <div>
      {activeTemplateId && (
        <TemplateModal
          activeTemplateId={activeTemplateId}
          setActiveTemplateId={setActiveTemplateId}
        />
      )}
      <Label>App Templates</Label>
      <CardSection style={{ margin: "25px 0 0 0" }}>
        {demoApps.map((app) => (
          <Template
            small
            isDashboard
            data={app}
            onClick={() => setActiveTemplateId(app.key)}
          />
        ))}
      </CardSection>
    </div>
  );
};
