import { get } from "lodash";
import { rSavedSpreadsheets } from "./recoil";
import useActiveBlock from "./useActiveBlock";
import { useRecoilValue } from "recoil";

const useActiveBlockSheet = () => {
  const activeBlock = useActiveBlock();

  const sheetId = get(activeBlock, "spreadsheet");

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  // This is only used in the admin
  const activeBlockSheet = savedSpreadsheets.find((s) => {
    let id1 = null;
    if (s.id) {
      if (s.id.toString().includes("db__")) {
        id1 = s.id;
      } else {
        id1 = parseInt(s.id);
      }
    }

    if (!id1) {
      return false;
    }

    let id2 = null;
    if (sheetId) {
      if (sheetId.toString().includes("db__")) {
        id2 = sheetId;
      } else {
        id2 = parseInt(sheetId);
      }
    }

    if (!id2) {
      return false;
    }

    return id1 === id2;
  });

  return activeBlockSheet;
};

export default useActiveBlockSheet;
