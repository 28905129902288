import { hiddenFilters, spreadsheetSelect } from "./shared";

export const AIBlock = {
  componentId: "AIBlock",
  resources: [
    {
      id: "label",
      label: "Label",
      componentId: "DynamicString",
      section: "text",
      defaultValue: "Ask anything about your data",
    },
    {
      id: "submitButtonText",
      label: "Submit Button Text",
      componentId: "DynamicString",
      section: "text",
      defaultValue: "Submit",
    },
    {
      ...spreadsheetSelect,
      preventExtras: true,
      wizard: false,
      hint: "Limit the query to a specific data source",
    },
    {
      id: "maxCharacters",
      label: "Max Context Characters",
      componentId: "Input",
      section: "costControl",
      preventExtras: true,
      type: "number",
      defaultValue: 5000,
      hint: "The maximum number of characters of data to include in the context for the AI. This sets an upper limit for the cost of the request.",
    },
    {
      id: "excludedColumns",
      label: "Excluded Columns",
      section: "costControl",
      componentId: "SpreadsheetColumnMultiSelect",
      hint: "Any columns selected here will always be excluded from the AI prompt, no matter what the user asks. This helps ensure irrelevant, large or unwanted data is not included in the AI prompt and reduces cost.",
    },
    {
      id: "info",
      section: "Learn More",
      componentId: "Button",
      text: "Read the AI Block article",
      icon: "FiHelpCircle",
      onClick: () =>
        window.open(
          "https://help.frontly.ai/en/articles/9409373-ai-block-beta"
        ),
      type: "basic",
    },
    hiddenFilters,
  ],
};
