import CryptoJS from "crypto-js";

export const decryptConfig = (encryptedConfigBase64) => {
  // Decode the base64 encoded encrypted config
  const encryptedConfig = CryptoJS.enc.Base64.parse(encryptedConfigBase64);

  // Correct encryption key format
  const rawKey = process.env.REACT_APP_CONFIG_ENCRYPTION_KEY;
  const encryptionKey = CryptoJS.enc.Hex.parse(rawKey);

  // Decrypt the config
  const bytes = CryptoJS.AES.decrypt(
    { ciphertext: encryptedConfig },
    encryptionKey,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  const decryptedConfig = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedConfig;
};
