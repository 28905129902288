import * as AdminComponents from "app/adminApp/components";
import * as Components from "app/components";

import { Button, Icon, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";
import { get, startCase } from "lodash";

import styled from "styled-components";

const EditorGrid = ({ data }) => {
  const columns = get(data, "columns", []);
  const rows = get(data, "value", []);

  const addRow = () => {
    data.onChange([...rows, get(data, "newObject", {})]);
  };

  const updateRow = (rowId, k, v) => {
    data.onChange(
      rows.map((r) => {
        if (r.frontly_id === rowId) {
          return {
            ...r,
            [k]: v,
            // __changed: true
          };
        }
        return r;
      })
    );
  };

  // Make this toggle-able?
  const showDelete = true;

  return (
    <>
      <TableBorderContainer>
        <TableContainer>
          {columns.map((column) => {
            const FormInput = get(
              { ...Components, ...AdminComponents },
              column.componentId
            );

            return (
              <Column width={column.width}>
                <TableHeader>
                  <Text
                    data={{
                      text: startCase(column.label || column.key),
                      fontStyle: "headingXs",
                      cursor: "pointer",
                    }}
                  />
                </TableHeader>
                {rows.map((row) => {
                  const disabled =
                    column.disabled ||
                    row.disabled ||
                    get(column, "disableOperators", []).includes(
                      get(row, "operator")
                    );

                  return (
                    <CellContainer>
                      <FormInput
                        data={{
                          ...column,
                          excludedSources: get(data, "excludedSources", {}),
                          dynamicSources: get(data, "dynamicSources", {}),
                          value: get(row, column.key),
                          border: "0px",
                          disabled,
                          padding:
                            column.componentId === "Select"
                              ? "0px 0px 0px 6px"
                              : spacing.s2,
                          onChange: (v) =>
                            updateRow(row.frontly_id, column.key, v),
                        }}
                      />
                    </CellContainer>
                  );
                })}
              </Column>
            );
          })}

          {showDelete && rows.length > 0 && (
            <DeleteColumn>
              <TableHeader></TableHeader>
              {rows.map((row) => (
                <CellContainer style={{ padding: "5px" }}>
                  <Icon
                    data={{
                      icon: "FiTrash",
                      hover: true,
                      onClick: () =>
                        data.onChange(
                          rows.filter((r) => r.frontly_id !== row.frontly_id)

                          // rows.map((r) => {
                          //   if (r.frontly_id === row.frontly_id) {
                          //     return { ...r, __deleted: true };
                          //   }
                          //   return r;
                          // })
                        ),
                      color: colors.grey3,
                      size: 18,
                    }}
                  />
                </CellContainer>
              ))}
            </DeleteColumn>
          )}
        </TableContainer>

        {rows.length === 0 && (
          <Text
            data={{
              text: "No Data",
              fontStyle: "bodySm",
              cursor: "pointer",
              padding: "8px 5px 8px 8px",
            }}
          />
        )}
      </TableBorderContainer>

      <Button
        data={{
          text: "Add",
          onClick: addRow,
          size: "small",
          type: "hollowWhite",
          icon: "FiPlus",
          margin: "10px 0 0 0",
        }}
      />
    </>
  );
};

export default EditorGrid;

const TableBorderContainer = styled.div`
  // border: 1px solid ${colors.grey2};
  border-radius: 10px;
  overflow: auto;
  background: white;
`;

const TableContainer = styled.div`
  display: flex;
`;

const DeleteColumn = styled.div`
  width: 30px;
  :not(:last-child) {
    border-right: 1px solid ${colors.grey2};
  }
`;

const Column = styled.div`
  width: ${(p) => p.width || "100px"};
  flex: 1;
  :not(:last-child) {
    border-right: 1px solid ${colors.grey2};
  }
`;

const CellContainer = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  overflow: hidden;
  :not(:last-child) {
    border-bottom: 1px solid ${colors.grey2};
  }
`;

const TableHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 8px;
  border-bottom: 1px solid ${colors.grey2};
`;
