import { useEffect, useRef, useState } from "react";

import { Button } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { isFrontlyAdmin } from "app/utils/utils";
import moment from "moment";
import styled from "styled-components";
import useDynamicText from "../useDynamicText";

const Chatbot = ({ block }) => {
  const [threadId, setThreadId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputContent, setInputContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const systemPrompt = block.systemPrompt || "";
  const initialMessage = block.initialMessage || "";
  const placeholder = block.placeholder || "Type a message...";
  const sendButtonText = block.sendButtonText || "Send";
  const assistantName = block.assistantName || "Assistant";
  const userName = block.userName || "You";
  const showTimestamps = block.showTimestamps !== false;
  const chatHistoryLimit = block.chatHistoryLimit || 0;
  const backgroundColor = block.backgroundColor || "#ffffff";
  const textColor = block.textColor || "#000000";
  const assistantBubbleColor = block.assistantBubbleColor || "#EDEDED";
  const userBubbleColor = block.userBubbleColor || "#E5F2FF";

  const { processDynamicText } = useDynamicText();

  useEffect(() => {
    // Create a new thread when the component mounts
    createThread();
    // Scroll to bottom whenever messages update
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createThread = async () => {
    try {
      const response = await apiRequest.post(`/chatbot/create_ai_thread/`, {
        system_prompt: systemPrompt,
        initial_message: initialMessage,
      });

      const thread = get(response, "data", {});
      const messages = get(thread, "messages", []);

      setMessages(messages);
      setThreadId(thread.id);
    } catch (error) {
      console.error("Error creating thread:", error);
    }
  };

  const sendMessage = async (content) => {
    if (!content.trim()) return;

    // Add the user's message to the chat
    const userMessage = {
      role: "user",
      content: content,
      created_at: moment(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputContent("");
    setIsLoading(true);

    try {
      const response = await apiRequest.post(`/chatbot/ai_chat_message/`, {
        content: content,
        thread_id: threadId,
      });

      const assistantMessage = get(response, "data", {});

      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
      // Optionally display an error message in the chat
      const errorMessage = {
        role: "assistant",
        content: "Sorry, there was an error processing your request.",
        created_at: moment(),
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage(inputContent);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  console.log("messages", messages);

  // Limit chat history if chatHistoryLimit is set
  let displayedMessages =
    chatHistoryLimit > 0 ? messages.slice(-chatHistoryLimit) : messages;

  if (isFrontlyAdmin) {
    displayedMessages = [
      {
        role: "assistant",
        content: initialMessage,
        created_at: moment(),
      },
      {
        role: "user",
        content: "Example user message",
        created_at: moment(),
      },
    ];
  }

  return (
    <ChatContainer backgroundColor={backgroundColor}>
      <MessagesContainer>
        {displayedMessages.map((message, index) => (
          <MessageBubble
            key={index}
            isUser={message.role === "user"}
            bubbleColor={
              message.role === "user" ? userBubbleColor : assistantBubbleColor
            }
            textColor={textColor}
          >
            <MessageHeader>
              {message.role === "user" ? userName : assistantName}
              {showTimestamps && (
                <Timestamp>{moment(message.created_at).fromNow()}</Timestamp>
              )}
            </MessageHeader>
            <MessageContent>
              {processDynamicText({ text: message.content })}
            </MessageContent>
          </MessageBubble>
        ))}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <FormContainer onSubmit={handleSubmit}>
        <InputField
          value={inputContent}
          onChange={(e) => setInputContent(e.target.value)}
          placeholder={placeholder}
          disabled={isLoading}
        />
        <Button
          data={{
            text: sendButtonText,
            disabled: isLoading,
            onClick: handleSubmit,
          }}
        />
      </FormContainer>
    </ChatContainer>
  );
};

export default Chatbot;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
`;

const MessageBubble = styled.div`
  background-color: ${(p) => p.bubbleColor};
  color: ${(p) => p.textColor};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
  align-self: ${(p) =>
    p.isUser
      ? "flex-end"
      : "flex-start"}; // Align user messages to the right and assistant messages to the left
  max-width: 80%;
`;

const MessageHeader = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
`;

const Timestamp = styled.span`
  font-weight: normal;
  font-size: 0.8em;
  margin-left: 8px;
`;

const MessageContent = styled.div`
  white-space: pre-wrap;
`;

const FormContainer = styled.form`
  display: flex;
  padding: 16px;
  border-top: 1px solid ${colors.grey2};
`;

const InputField = styled.input`
  flex: 1;
  margin-right: 8px;
  border: 1px solid ${colors.grey2};
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  outline: none;
`;
