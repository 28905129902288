import MDEditor from "@uiw/react-md-editor";
import styled from "styled-components";

const MarkdownEditorOld = ({ data }) => {
  const { value = "", onChange } = data;

  return (
    <Container data-color-mode="light">
      <MDEditor value={value} onChange={onChange} />
    </Container>
  );
};

export default MarkdownEditorOld;

const Container = styled.div``;
