import { Button, ViewOnlyList } from "app/components";
import { get, startCase } from "lodash";

import RechartArea from "app/components/recharts/AreaChart";
import RechartBar from "app/components/recharts/BarChart";
import { TableComponent } from "./Table";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import useDynamicText from "../useDynamicText";
import { useState } from "react";

const SmartBlock = ({ block, page }) => {
  const [prompt, setPrompt] = useState("");

  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(null);

  const submit = () => {
    setIsFetching(true);
    apiRequest
      .post("/ai_block_action_new/", {
        prompt,
      })
      .then((r) => {
        const responseData = get(r, "data", {});
        console.log("response", responseData);
        setIsFetching(false);
        setData(responseData);
      });
  };

  return (
    <div>
      {data && (
        <>
          <Title>{get(data, "prompt") || prompt}</Title>
          <RenderData blockData={data} />
          <Button
            data={{
              onClick: () => setData(null),
              text: "Ask Another Question",
              type: "basic",
              icon: "FiRefreshCcw",
              size: "large",
              margin: "30px 0 0 0",
            }}
          />
        </>
      )}

      {!data && (
        <>
          <Title>{get(block, "label", "Ask anything about your data")}</Title>
          <StyledTextArea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <Button
            data={{
              onClick: submit,
              isFetching,
              disabled: !prompt || isFetching,
              text: get(block, "submitButtonText", "Submit"),
              size: "large",
            }}
          />
        </>
      )}
    </div>
  );
};

export default SmartBlock;

const RenderData = ({ blockData }) => {
  const outputFormat = get(blockData, "output_format");
  const result = get(blockData, "result");
  const subtitle = get(blockData, "subtitle");

  const { processDynamicText } = useDynamicText();

  if (outputFormat === "TEXT") {
    return <Response>{result}</Response>;
  }

  if (outputFormat === "AREA_CHART") {
    return (
      <div>
        {subtitle && <Response>{subtitle}</Response>}
        <RechartArea data={{ data: result }} />
      </div>
    );
  }

  if (outputFormat === "BAR_CHART") {
    return (
      <div>
        {subtitle && <Response>{subtitle}</Response>}
        <RechartBar data={{ data: result }} />
      </div>
    );
  }

  if (outputFormat === "TABLE") {
    const firstRow = get(result, 0, {});

    // remove 'frontly_data' key from firstRow
    if (get(firstRow, "frontly_data")) {
      delete firstRow["frontly_data"];
    }

    const columns = Object.keys(firstRow).map((key) => ({
      key,
      columnType: "text",
    }));

    return (
      <div>
        {subtitle && <Response>{subtitle}</Response>}
        <TableComponent
          data={{
            preventDarkMode: true,
            resultsPerPage: 5,
            rows: result,
            columns,
            block: {},
          }}
        />
      </div>
    );
  }

  if (outputFormat === "SINGLE_OBJECT") {
    // TODO - Right now this is assuming the single object is always an array with 1 object
    const firstRow = get(result, 0, {});

    // remove 'frontly_data' key from firstRow
    if (get(firstRow, "frontly_data")) {
      delete firstRow["frontly_data"];
    }

    const displayFields = Object.keys(firstRow).map((key) => ({
      key,
      label: startCase(key),
      active: true,
      type: "text",
    }));

    return (
      <div>
        {subtitle && <Response>{subtitle}</Response>}
        <ViewOnlyList
          gridLayout={true}
          formState={firstRow}
          displayFields={displayFields}
          processDynamicText={processDynamicText}
        />
      </div>
    );
  }
};

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: ${colors.default};
  margin: 0 0 30px 0;
`;

const Response = styled.div`
  font-size: 30px;
  font-weight: 300;
  color: ${colors.default};
  margin: 20px 0 20px 0;
`;

const StyledTextArea = styled.textarea`
  margin: 20px 0 15px 0;
  resize: none;
  min-height: 120px;
  height: fit-content;
  background: white;
  border: 0px;
  width: 100%;
  padding: 20px;
  font-size: 18px;
  outline: none;
  font-weight: 400;
  border-radius: 15px;
  color: ${colors.default};
  min-width: 400px;
  border: 1px solid ${colors.divider};
  width: 100%;
  cursor: pointer;
  &::placeholder {
    color: ${colors.grey3};
  }
  @media screen and (max-width: 800px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
  }
`;

// const reset = () => {
//   setData(null);
//   // setAiBlockData((prevState) => ({ ...prevState, [block.id]: null }));
// };

// const example1 = {
//   prompt: "Compare total sales in January and February 2024",
//   output_format: "TEXT",
//   result: 865,
// };

// const example2 = {
//   prompt: "What's the average age of users in Canada?",
//   output_format: "TEXT",
//   result: 26.67,
// };

// const example3 = {
//   prompt: "Count of orders in 2024 over 100 dollars, broken down by month",
//   output_format: "CHART",
//   result: [
//     { name: "01", Orders: 1 },
//     { name: "02", Orders: 1 },
//     { name: "03", Orders: 0 },
//     { name: "04", Orders: 3 },
//     { name: "05", Orders: 1 },
//     { name: "06", Orders: 0 },
//     { name: "07", Orders: 0 },
//     { name: "08", Orders: 0 },
//     { name: "09", Orders: 0 },
//     { name: "10", Orders: 0 },
//     { name: "11", Orders: 0 },
//     { name: "12", Orders: 0 },
//   ],
// };

// const example4 = {
//   prompt:
//     "Show the top 3 most frequent customers based on the number of orders placed",
//   output_format: "TABLE",
//   result: [
//     { customer_name: "Diana", order_count: 4 },
//     { customer_name: "Eve", order_count: 4 },
//     { customer_name: "Alice", order_count: 2 },
//   ],
// };

// const example5 = {
//   prompt: "Display the performance scores for each employee",
//   output_format: "CHART",
//   result: [
//     { name: "John", Scores: 80 },
//     { name: "Jane", Scores: 90 },
//     { name: "Alice", Scores: 70 },
//     { name: "Bob", Scores: 85 },
//     { name: "Charlie", Scores: 95 },
//   ],
// };

// const example6 = {
//   prompt: "Which employee is performing the best?",
//   output_format: "SINGLE_OBJECT",
//   result: [
//     {
//       id: 5,
//       employee_name: "Charlie",
//       task: "Task B",
//       performance_score: 95,
//       date: "2024-05-09",
//     },
//   ],
// };

// const example7 = {
//   prompt: "Show a chart comparing monthly sales throughout 2024 and 2025",
//   output_format: "CHART",
//   result: [
//     {
//       name: "01",
//       2024: 1450,
//       2025: 0,
//     },
//     {
//       name: "02",
//       2024: 585,
//       2025: 1350,
//     },
//     {
//       name: "03",
//       2024: 0,
//       2025: 585,
//     },
//     {
//       name: "04",
//       2024: 0,
//       2025: 0,
//     },
//     {
//       name: "05",
//       2024: 0,
//       2025: 0,
//     },
//     {
//       name: "06",
//       2024: 0,
//       2025: 0,
//     },
//     {
//       name: "07",
//       2024: 0,
//       2025: 0,
//     },
//     {
//       name: "08",
//       2024: 0,
//       2025: 0,
//     },
//     {
//       name: "09",
//       2024: 0,
//       2025: 0,
//     },
//     {
//       name: "10",
//       2024: 0,
//       2025: 50,
//     },
//     {
//       name: "11",
//       2024: 0,
//       2025: 50,
//     },
//     {
//       name: "12",
//       2024: 0,
//       2025: 0,
//     },
//   ],
// };

// const example8 = {
//   prompt: "Show a chart comparing sales and marketing expenses throughout 2024",
//   output_format: "CHART",
//   result: [
//     {
//       name: "01",
//       Sales: 150,
//       Marketing: 50,
//     },
//     {
//       name: "02",
//       Sales: 200,
//       Marketing: 300,
//     },
//     {
//       name: "03",
//       Sales: 50,
//       Marketing: 20,
//     },
//     {
//       name: "04",
//       Sales: 1004,
//       Marketing: 350,
//     },
//     {
//       name: "05",
//       Sales: 120,
//       Marketing: 150,
//     },
//     {
//       name: "06",
//       Sales: 0,
//       Marketing: 50,
//     },
//     {
//       name: "07",
//       Sales: 0,
//       Marketing: 650,
//     },
//     {
//       name: "08",
//       Sales: 0,
//       Marketing: 250,
//     },
//     {
//       name: "09",
//       Sales: 0,
//       Marketing: 572,
//     },
//     {
//       name: "10",
//       Sales: 0,
//       Marketing: 830,
//     },
//     {
//       name: "11",
//       Sales: 0,
//       Marketing: 300,
//     },
//     {
//       name: "12",
//       Sales: 0,
//       Marketing: 250,
//     },
//   ],
// };

// OLD CODE
// const [websocketRequests, setWebsocketRequests] =
//   useRecoilState(rWebsocketRequests);
// const [aiBlockData, setAiBlockData] = useRecoilState(rAiBlockData);
// const blockData = get(aiBlockData, block.id, {});
// const blockData = example8;
// const isFetching = get(blockData, "isFetching", false);
// const hiddenFilters = get(block, "hiddenFilters", []);
// const [aiBlockData, setAiBlockData] = useRecoilState(rAiBlockData);

// const excludedColumns = get(block, "excludedColumns", "")
//   .split(",")
//   .map((x) => x.trim());

// const submit = () => {
//   setAiBlockData((prevState) => ({
//     ...prevState,
//     [block.id]: { isFetching: true, data: null },
//   }));

//   const instanceId = (
//     Math.random().toString(36) + Math.random().toString(36)
//   ).substr(2, 12);

//   setWebsocketRequests([
//     ...websocketRequests,
//     {
//       instanceId,
//       blockId: block.id,
//     },
//   ]);

//   apiRequest.post("/ai_block_action/", {
//     prompt,
//     data_source_id: block.spreadsheet,
//     max_characters: block.maxCharacters,
//     data_source_context_filters: hiddenFilters,
//     instance_id: instanceId,
//     excluded_columns: excludedColumns,
//   });
// };
