import { get } from "lodash";

export const getViewLabel = (block) => {
  let label = "Record Details";

  // Custom Label
  const customViewLabel = get(block, "customViewLabel");
  if (customViewLabel) {
    return customViewLabel;
  }

  const recordLabel = get(block, "labelSingular");
  if (recordLabel) {
    return `${recordLabel} Details`;
  }

  const editModeLabel = get(block, "editModeLabel");
  if (editModeLabel) {
    return editModeLabel;
  }

  return label;
};
