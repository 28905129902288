import { Button, FullScreenLoader, Row, Text } from "app/components";
import { badges, colors } from "app/utils/theme";
import { get, startCase } from "lodash";
import { useEffect, useState } from "react";

import { AdminForm } from "../../components";
import AdminWrapper from "../../home/AdminWrapper";
import { Label } from "../../home/Pages";
import { RenderProfile } from "../Profile";
import { Tooltip } from "react-tooltip";
import { apiRequest } from "app/utils/apiRequests";
import { rConfirmationModalData } from "app/utils/recoil";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useSetRecoilState } from "recoil";

const EditExpertProfile = () => {
  const [profile, setProfile] = useState(null);
  const [changes, setChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const fields = [
    {
      id: "name",
      label: "Name",
      placeholder: "Company or Expert Name",
      hint: "The public name to display on your profile. This can be your company name or your own name.",
      componentId: "Input",
      value: get(profile, "name"),
    },
    {
      id: "image",
      label: "Image",
      placeholder: "Image URL",
      hint: "The image to display on your profile. This can be your company logo or your own photo.",
      componentId: "ImageUpload",
      value: get(profile, "image"),
    },
    {
      id: "location",
      label: "Location",
      placeholder: "Location",
      hint: "Where you are located in the world.",
      componentId: "Input",
      value: get(profile, "location"),
    },
    {
      id: "languages",
      label: "Languages",
      placeholder: "Languages",
      componentId: "MultiSelect",
      options: languages,
      hint: "The languages you speak, for communicating with clients.",
      value: get(profile, "languages", ""),
    },
    {
      id: "hourlyRate",
      label: "Hourly Rate (USD)",
      placeholder: "Hourly Rate",
      hint: "Your hourly rate in USD.",
      componentId: "Input",
      value: get(profile, "hourlyRate"),
    },
    {
      id: "minBudget",
      label: "Minimum Budget",
      placeholder: "Minimum Budget",
      hint: "The minimum budget you would accept for a contract. The higher this value, the fewer projects you will qualify for.",
      componentId: "Select",
      options: [
        { label: "$500", value: 500 },
        { label: "$1000", value: 1000 },
        { label: "$5,000", value: 5000 },
        { label: "$10,000", value: 10000 },
      ],
      value: get(profile, "minBudget"),
    },
    {
      id: "short_description",
      label: "Short Description",
      placeholder: "Short Description",
      hint: "The short description to display on your minified profile card.",
      componentId: "TextArea",
      value: get(profile, "short_description"),
    },
    {
      id: "description",
      label: "Description",
      placeholder: "Description",
      hint: "The full description to display on your detailed profile.",
      componentId: "RichTextEditor",
      value: get(profile, "description"),
    },
    {
      id: "specializations",
      label: "Specializations",
      componentId: "MultiSelect",
      placeholder: "Specializations",
      hint: "The specializations to display on your profile.",
      value: get(profile, "specializations"),
      hint: "The skills you possess that you can potentially offer to clients.",
      options: specializations,
    },
    {
      id: "demoApps",
      label: "Work Samples",
      text: "Showcase Pre",
      componentId: "DemoAppsEditor",
      profile,
      setProfile: (obj) => {
        setProfile({ ...profile, ...obj });
        setChanges(true);
      },
      hint: "Select the apps you would like to display on your profile for potential clients to see your work.",
    },
  ];

  const save = () => {
    setIsSaving(true);
    apiRequest.post("/edit_expert_profile/", profile).then(() => {
      setChanges(false);
      setIsSaving(false);
    });
  };

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/get_expert_profile/").then((r) => {
      const res = get(r, "data", {});
      setIsFetching(false);
      setProfile(res);
    });
  }, []);

  const createProfile = () => {
    setIsSaving(true);
    apiRequest.post("/create_expert_profile/").then((r) => {
      const res = get(r, "data", {});
      setChanges(false);
      setIsSaving(false);
      setProfile(res);
    });
  };

  const statusColorMap = {
    in_progress: badges.grey,
    pending: badges.yellow,
    approved: badges.orange,
    published: badges.green,
    changes_requested: badges.red,
    denied: badges.red,
  };

  const statusMessageMap = {
    in_progress: "Your profile is in progress. You can edit it below.",
    pending:
      "Your profile is pending review by the Frontly team. You will be notified when it's been reviewed.",
    approved:
      "Your profile has been approved by the Frontly team, and is ready to publish when you're ready to be listed.",
    published: "Your profile is published on the public experts marketplace",
    changes_requested:
      "Your submission was reviewed and changes have been requested in order to complete your profile.",
    denied:
      "Your profile was denied by the Frontly team. You should have already received feedback.",
  };

  const status = get(profile, "status", "pending");

  const submitForApproval = () => {
    apiRequest.post("/expert_approval_submission/");
    setProfile({ ...profile, status: "pending" });
    successNotification("Profile Submitted For Approval");
  };

  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <AdminWrapper contentWidth="100%">
      <Row
        alignItems="center"
        justifyContent="space-between"
        margin="0 0 30px 0"
      >
        <Row alignItems="center" gap="20px">
          <Label>Manage Your Expert Profile</Label>

          <Tooltip
            anchorSelect={`.statusBadge`}
            place="bottom"
            style={{ zIndex: 9999 }}
          >
            {get(statusMessageMap, status)}
          </Tooltip>

          <StatusBadge
            className="statusBadge"
            color={get(statusColorMap, status)}
          >
            {startCase(status)}
          </StatusBadge>
        </Row>

        <Button
          data={{
            disabled: status !== "in_progress",
            text: status === "pending" ? "Under Review" : "Submit For Approval",
            onClick: () =>
              setConfirmationModalData({
                title: "Submit For Approval?",
                text: "Once submitted, the Frontly team will review your profile and approve it for publishing in the marketplace. Be sure that you have completed all the required fields, including 3 demo apps to showcase on your profile.",
                confirm: submitForApproval,
              }),
          }}
        />
      </Row>

      {!profile && (
        <div>
          <Text
            data={{
              text: "You haven't created an Expert Profile. Create one to get started.",
            }}
          />
          <Button
            data={{
              text: "Create Expert Profile",
              onClick: createProfile,
              isFetching: isSaving,
              margin: "15px 0 0 0",
            }}
          />
        </div>
      )}

      {profile && (
        <Row gap="30px">
          <EditPanel>
            <Text
              data={{
                text: "Profile Details",
                fontStyle: "headingLg",
                margin: "0 0 20px 0",
              }}
            />
            <AdminForm
              labelStyle="headingSm"
              sectionPadding="0px"
              fields={fields}
              submitText={"Save Changes"}
              submit={save}
              isFetching={isSaving}
              disableSubmit={!changes}
              onChange={(k, v) => {
                setChanges(true);
                setProfile({
                  ...profile,
                  [k]: v,
                });
              }}
            />
          </EditPanel>
          <PreviewPanel>
            <RenderProfile customData={profile} />
          </PreviewPanel>
        </Row>
      )}
    </AdminWrapper>
  );
};

export default EditExpertProfile;

const EditPanel = styled.div`
  min-width: 270px;
  padding: 20px;
  border: 1px solid ${colors.grey2};
  background: white;
  border-radius: 15px;
`;

const PreviewPanel = styled.div`
  border: 1px solid ${colors.grey2};
  background: white;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
`;

const StatusBadge = styled.div`
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: ${(p) => p.color};
  color: white;
  font-size: 15px;
  font-weight: 500;
`;

const languages = [
  { label: "English", value: "English" },
  { label: "Mandarin Chinese", value: "Mandarin Chinese" },
  { label: "Hindi", value: "Hindi" },
  { label: "Spanish", value: "Spanish" },
  { label: "French", value: "French" },
  { label: "Arabic", value: "Arabic" },
  { label: "Bengali", value: "Bengali" },
  { label: "Russian", value: "Russian" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Urdu", value: "Urdu" },
  { label: "German", value: "German" },
  { label: "Japanese", value: "Japanese" },
  { label: "Swahili", value: "Swahili" },
  { label: "Marathi", value: "Marathi" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Telugu", value: "Telugu" },
  { label: "Wu Chinese", value: "Wu Chinese" },
  { label: "Javanese", value: "Javanese" },
  { label: "Malay/Indonesian", value: "Malay/Indonesian" },
  { label: "Korean", value: "Korean" },
  { label: "Tamil", value: "Tamil" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Turkish", value: "Turkish" },
  { label: "Italian", value: "Italian" },
  { label: "Thai", value: "Thai" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Jin Chinese", value: "Jin Chinese" },
  { label: "Cantonese", value: "Cantonese" },
  { label: "Burmese", value: "Burmese" },
  { label: "Polish", value: "Polish" },
];

const specializations = [
  { label: "Frontly App Development", value: "Frontly App Development" },
  { label: "Data Integration", value: "Data Integration" },
  { label: "Workflow Automation", value: "Workflow Automation" },
  { label: "Database Design", value: "Database Design" },
  { label: "API Integration", value: "API Integration" },
  { label: "UI/UX Design", value: "UI/UX Design" },
  { label: "Custom Reporting", value: "Custom Reporting" },
  { label: "SEO Optimization", value: "SEO Optimization" },
  { label: "Content Management", value: "Content Management" },
  {
    label: "Payment Gateway Integration",
    value: "Payment Gateway Integration",
  },
  { label: "User Onboarding", value: "User Onboarding" },
  {
    label: "Social Media Integration",
    value: "Social Media Integration",
  },
  { label: "E-commerce Solutions", value: "E-commerce Solutions" },
  {
    label: "Localization and Translation",
    value: "Localization and Translation",
  },
  { label: "Analytics and Metrics", value: "Analytics and Metrics" },
  { label: "Business Logic Planning", value: "Business Logic Planning" },
  { label: "User Authentication", value: "User Authentication" },
  {
    label: "Customer Support Systems",
    value: "Customer Support Systems",
  },
  { label: "Community Building", value: "Community Building" },
  { label: "Real-time Communication", value: "Real-time Communication" },
  {
    label: "Performance Optimization",
    value: "Performance Optimization",
  },
  { label: "Security Audits", value: "Security Audits" },
  { label: "Legal Compliance", value: "Legal Compliance" },
  { label: "Progressive Web Apps", value: "Progressive Web Apps" },
  {
    label: "Email Marketing Automation",
    value: "Email Marketing Automation",
  },
  { label: "Asset Management", value: "Asset Management" },
  { label: "Brand Strategy", value: "Brand Strategy" },
  { label: "Subscription Management", value: "Subscription Management" },
  { label: "Backup and Recovery", value: "Backup and Recovery" },
  { label: "AI and Machine Learning", value: "AI and Machine Learning" },
];
