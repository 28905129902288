import { get, isEmpty } from "lodash";
import {
  getFormDataWithRelatedFields,
  getRelationColumnsFromBlock,
} from "./relationUtils";
import {
  getFormFields,
  gridColumnGapObject,
  gridRowGapObject,
  labelStyleObject,
  paddingObject,
  sizeObject,
} from "./utils";
import {
  getInputBackgroundColor,
  getPixels,
  isFrontlyAdmin,
  safeArray,
} from "app/utils/utils";
import {
  rActiveEditField,
  rAllFormState,
  rApp,
  rAppDateFormat,
  rDarkMode,
  rFetchingBlockIds,
  rFormState,
  rPendingFileUploads,
  rSavedSpreadsheets,
  rSpinner,
  spreadsheetsSelector,
} from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import useValidateFields, { getValidationObject } from "app/utils/validation";

import { Button } from "app/components";
import FormField from "app/renderingApp/blocks/Form/FormField";
import { InfoBox } from "app/adminApp/settings/InfoBox";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import useActionResolver from "app/renderingApp/useActionResolver";
import useDynamicText from "app/renderingApp/useDynamicText";
import { useFormActions } from "./useFormActions";
import { useState } from "react";
import useUtils from "app/renderingApp/useUtils";

const Form = ({ page, block }) => {
  const [formErrors, setFormErrors] = useState({});

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const darkMode = useRecoilValue(rDarkMode);

  const app = useRecoilValue(rApp);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const [spreadsheets, setSpreadsheets] = useRecoilState(spreadsheetsSelector);

  const fetchingBlockIds = useRecoilValue(rFetchingBlockIds);

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const spinner = useRecoilValue(rSpinner);
  const isFetching =
    isUploadingFiles ||
    (!spinner && fetchingBlockIds.includes(get(block, "id")));

  const { passesDisplayConditions } = useUtils();

  const { processDynamicText } = useDynamicText();

  const { validateFields } = useValidateFields();

  const sheet = savedSpreadsheets.find((s) => s.id === block.spreadsheet);

  // Spreadsheet-level field overrides
  const sheetFieldData = get(sheet, "field_data", {});
  const sheetHeaders = get(sheet, "headers", []);
  const sheetOrder = get(sheetFieldData, "order", []);
  const sheetConfig = get(sheetFieldData, "config", {});

  // Block-level field overrides
  const fieldData = get(block, "fieldData", {});
  const config = get(fieldData, "config", {});
  const localOrder = get(fieldData, "order", []);

  const mode = get(block, "mode", "create");

  // The current form state saved in recoil
  const [allFormState, setAllFormState] = useRecoilState(rAllFormState);

  // Get the form values for the current block
  const formValues = get(allFormState, block.id, {});

  // Get values from the block config
  const alignment = get(block, "alignment", "left");

  // Set the 'all form state' in recoil
  const setFormValues = (newValues) => {
    setAllFormState({
      ...allFormState,
      [block.id]: {
        ...formValues,
        ...newValues,
      },
    });
  };

  // The current form state saved in recoil
  const [recoilFormState, setRecoilFormState] = useRecoilState(rFormState);

  const clearForm = () => {
    setFormValues({});

    const clearedObject = Object.keys(formValues).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});

    // Update in recoil too
    setRecoilFormState({
      ...recoilFormState,
      ...clearedObject,
    });

    setSpreadsheets({
      ...spreadsheets,
      [block.id]: {},
    });
    setHasSubmitted(false);
  };

  const { relationColumns } = getRelationColumnsFromBlock({
    block,
    app,
    spreadsheets,
  });

  const formDataWithRelatedFields = getFormDataWithRelatedFields({
    block,
    app,
    spreadsheets,
    formData: formValues,
  });

  const { displayFields, hiddenFields } = getFormFields({
    relations: safeArray(app, "data_relations"),
    dataSourceId: get(sheet, "id"),
    processDynamicText,
    appDateFormat,
    relationColumns,
    localOrder,
    sheetOrder,
    sheetHeaders,
    block,
    sheetFieldData: get(sheetFieldData, "config"),
    localFieldData: config,
    valuesObject: formValues,
    conditionsObject: {
      passesDisplayConditions,
      conditionKey: "conditions",
    },
  });

  const validationObject = getValidationObject(displayFields);

  const rowId = get(block, "rowId");

  const disableDefaultAction = get(block, "disableDefaultAction", false);

  const gridLayout = get(block, "gridLayout");

  // Prepare custom submit action
  const submitAction = (newRecord) =>
    actionResolver({
      rawAction: get(block, ["actionMap", "submitAction"]),
      actionId: get(block, "submitAction"),
      context: {
        form: {
          ...formValues,
          ...formDataWithRelatedFields,
          ...newRecord,
        },
      },
      blockId: block.id,
    });

  const { createRow, updateRow } = useFormActions({
    submitAction,
    block,
    formValues,
    rowId,
    displayFields,
    hiddenFields,
    config,
    page,
    setIsSaving,
    setIsUploadingFiles,
  });

  const { actionResolver } = useActionResolver(page);

  const getSubmitBtnText = () => {
    const submitBtnText = get(block, "submitText");
    if (submitBtnText) {
      return submitBtnText;
    } else {
      if (mode === "create") {
        return "Save New Record";
      } else {
        return "Save Changes";
      }
    }
  };

  const onSubmitClick = async () => {
    if (isFrontlyAdmin) {
      return null;
    }

    setHasSubmitted(true);

    if (
      isEmpty(
        validateFields({
          dataSourceConfig: sheetConfig,
          validationSchema: validationObject,
          values: formValues,
          hasSubmitted: true,
          setErrors: setFormErrors,
        })
      )
    ) {
      if (!disableDefaultAction) {
        if (mode === "create") {
          createRow({ submitAction, block, displayFields, hiddenFields });
        }
        if (mode === "edit") {
          updateRow(submitAction);
        }
      } else {
        submitAction(formValues);
      }

      // Clear form if turned on
      if (get(block, "clearOnSubmit")) {
        clearForm();
      }
    }
  };

  const runValidation = (fieldKey, newValues) => {
    // Ensure the field is in the validation object
    if (
      !isEmpty(newValues) &&
      !isEmpty(validationObject) &&
      get(validationObject, fieldKey)
    ) {
      validateFields({
        dataSourceConfig: sheetConfig,
        validationSchema: validationObject,
        values: newValues,
        hasSubmitted,
        setErrors: setFormErrors,
      });
    }
  };

  const [pendingFileUploads, setPendingFileUploads] =
    useRecoilState(rPendingFileUploads);

  const incomplete = mode === "edit" && !rowId;
  if (incomplete) {
    return (
      <InfoBox
        darkMode={darkMode}
        warning
        margin="15px 5px 5px 5px"
        helpLink="https://help.frontly.ai/en/articles/7971088-form-block"
      >
        To complete Form setup, please add a 'Row ID' or change the 'Mode' to
        Create.
      </InfoBox>
    );
  }

  const inputSize = get(app, ["styling", "formInputSize"], "medium");
  const inputRadius = get(app, ["styling", "formInputRadius"]);
  const size = get(sizeObject, inputSize);
  const padding = get(paddingObject, inputSize);
  const labelStyle = get(labelStyleObject, inputSize);
  const gridRowGap = get(gridRowGapObject, inputSize);
  const gridColumnGap = get(gridColumnGapObject, inputSize);
  const formInputBorderColor = getPixels(
    get(app, ["styling", "formInputBorderColor"])
  );

  const buttonBorderRadius = get(app, ["styling", "buttonBorderRadius"]);

  const resolvedRowId = processDynamicText({
    text: rowId,
    reusableBlockId: block.reusableBlockId,
    context: {
      repeatingRecord: get(block, "repeatingRecord"),
    },
  });

  return (
    <div>
      <FieldsContainer
        minGridSize={250}
        gridLayout={gridLayout}
        count={displayFields.length}
        gridRowGap={getPixels(gridRowGap)}
        gridColumnGap={getPixels(gridColumnGap)}
      >
        {displayFields.map((field, i) => {
          // GET THE VALUE FOR THE FIELD
          const value = get(formValues, field.key) || "";
          let componentId = field.componentId;
          if (
            field.disabled &&
            ["DateTimePicker", "FileUpload"].includes(componentId)
          ) {
            componentId = "Input";
          }

          // Adding this (particularly the row ID) should force a re-render when the row ID changes
          const uniqueFormFieldKey = `${page.id}-${block.id}-${field.key}-${resolvedRowId}`;
          const uniqueKey = `${page.id}-${block.id}-${field.key}`;

          return (
            <FormField
              key={uniqueFormFieldKey}
              data={{
                ...field,
                required: get(validationObject, [field.key, "required"], false),
                skeleton: block.isFetching,
                fontStyle: size,
                labelStyle,
                padding,
                darkMode,
                labelColor: darkMode && colors.darkModeLightText2,
                background: getInputBackgroundColor({
                  darkMode,
                  disabled: field.disabled,
                }),
                borderRadius: getPixels(inputRadius),
                border: darkMode
                  ? `1px solid ${colors.darkModeLightGrey}`
                  : formInputBorderColor
                  ? `1px solid ${formInputBorderColor}`
                  : null,
                color: darkMode && "white",
                borderRadius: getPixels(inputRadius),
                borderColor: formInputBorderColor,
                value,
                componentId,
                handleFieldClick: (obj) =>
                  setActiveEditField({ ...obj, blockId: block.id }),
                width: "100%",
                onChange: (value) => {
                  // If document upload field, set to pending uploads in recoil instead
                  if (componentId === "DocumentUpload") {
                    setPendingFileUploads({
                      ...pendingFileUploads,
                      [uniqueKey]: value,
                    });
                    value = get(value, "name");
                  }

                  // handle signature upload
                  if (componentId === "SignatureUpload") {
                    // setPendingFileUploads({
                    //   ...pendingFileUploads,
                    //   [uniqueKey]: value,
                    // });
                    // value = get(value, "name");
                  }

                  const newValues = {
                    ...formValues,
                    [field.key]: value,
                  };

                  setFormValues(newValues);

                  // Update in recoil too
                  setRecoilFormState({
                    ...recoilFormState,
                    ...newValues,
                  });

                  runValidation(field.key, newValues);
                },
                error: get(formErrors, field.key),
              }}
            />
          );
        })}
      </FieldsContainer>
      {!get(block, "hideSubmitButton") && (
        <Button
          data={{
            onClick: onSubmitClick,
            disabled: isFetching,
            text: getSubmitBtnText(),
            isFetching: isSaving,
            backgroundColor: get(app, "primary_color"),
            borderRadius: buttonBorderRadius
              ? getPixels(buttonBorderRadius)
              : null,
            alignment,
          }}
        />
      )}
    </div>
  );
};

export default Form;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: ${(p) => p.gridRowGap};
  grid-column-gap: ${(p) => p.gridColumnGap};
  margin: 0 0 20px 0;
  ${(p) =>
    p.gridLayout &&
    `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${getPixels(
      p.minGridSize
    )}, 1fr));
  `}
`;
