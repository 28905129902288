export const RichText = {
  componentId: "RichText",
  resources: [
    {
      id: "content",
      label: "Content",
      componentId: "RichTextEditor",
      section: "content",
    },
  ],
};
