import {
  customRecordClickAction,
  detailViewSettings,
  fetchIfHidden,
  label,
  labelColor,
  listFilterResources,
  recordClickAction,
  spreadsheetSelect,
} from "./shared";

import { get } from "lodash";

export const Chart2 = {
  componentId: "Chart2",
  resources: [
    label,
    labelColor,
    spreadsheetSelect,
    ...listFilterResources,
    { ...recordClickAction, displayCondition: (field) => !field.groupByField },
    {
      ...customRecordClickAction,
      displayCondition: (field) =>
        !field.groupByField && field.recordClickAction === "action",
    },
    {
      ...detailViewSettings,
      displayCondition: (field) =>
        !field.groupByField &&
        ["custom", "default"].includes(field.recordClickAction),
    },
    {
      id: "chartType",
      label: "Chart Style",
      section: "chartType",
      componentId: "Select",
      defaultValue: "bar",
      wizard: true,
      hideEmptyItem: true,
      options: [
        { label: "Bar", value: "bar" },
        { label: "Pie", value: "pie" },
        { label: "Line", value: "line" },
        { label: "Area", value: "area" },
      ],
      requiresSheet: true,
    },
    {
      id: "valueField",
      label: "Value Field",
      section: "setup",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      wizard: true,
      hint: "Select the spreadsheet column that contains the numeric value to use in your chart segments",
    },
    {
      id: "labelField",
      label: "Label Field",
      section: "setup",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      hint: "The spreadsheet field to use for the chart segment label",
    },
    {
      id: "groupByField",
      label: "Group By Field",
      section: "grouping",
      hint: "Optional - Group results by one of your record fields",
      componentId: "SpreadsheetColumnSelect",
      required: false,
      requiresSheet: true,
      displayCondition: (field) => field.chartType !== "pie",
    },
    {
      id: "groupByMetric",
      label: "Grouping Metric",
      section: "grouping",
      hint: "The metric to display for your grouped results.",
      componentId: "Select",
      required: false,
      options: [
        { label: "Sum", value: "sum" },
        { label: "Average", value: "average" },
        { label: "Count", value: "count" },
        { label: "Percent", value: "percent" },
      ],
      defaultValue: "count",
      requiresSheet: true,
      displayCondition: (field) => field.groupByField,
    },
    {
      id: "dateGroupingInterval",
      section: "grouping",
      label: "Date Grouping Interval",
      componentId: "Select",
      defaultValue: null,
      options: [
        { label: "None", value: null },
        { label: "Day", value: "day" },
        { label: "Month", value: "month" },
        { label: "Year", value: "year" },
      ],
      requiresSheet: true,
      displayCondition: (field) => field.groupByField,
    },
    // {
    //   id: "valuePrefix",
    //   key: "valuePrefix",
    //   label: "Value Prefix",
    //   componentId: "DynamicString",
    //   required: true,
    //   defaultValue: "",
    //   orientation: "horizontal",
    //   width: "150px",
    //   section: "display",
    //   hint: "Text to display before the value",
    //   advanced: true,
    // },
    // {
    //   id: "valueSuffix",
    //   key: "valueSuffix",
    //   label: "Value Suffix",
    //   componentId: "DynamicString",
    //   required: true,
    //   defaultValue: "",
    //   orientation: "horizontal",
    //   width: "150px",
    //   section: "display",
    //   hint: "Text to display before the value",
    //   advanced: true,
    // },

    {
      id: "colorTheme",
      section: "colorTheme",
      label: "Color Theme",
      required: true,
      isStyle: true,
      componentId: "Select",
      defaultValue: "default",
      options: [
        { label: "Default", value: "default" },
        { label: "Contrast", value: "contrast" },
        { label: "Blues", value: "blues" },
        { label: "Greens", value: "greens" },
        { label: "Flower", value: "flower" },
      ],
      requiresSheet: true,
      displayCondition: (field) =>
        get(field, "customColorScheme", []).length === 0,
    },
    {
      id: "customColorScheme",
      label: "Custom Color Theme",
      section: "colorTheme",
      required: true,
      isStyle: true,
      componentId: "ColorThemeEditor",
      requiresSheet: true,
    },
    {
      id: "showValues",
      label: "Show Values",
      section: "options",
      componentId: "Switch",
      requiresSheet: true,
      defaultValue: true,
      isStyle: true,
      orientation: "horizontal",
      displayCondition: (field) => get(field, "chartType") !== "PieChart",
    },
    // {
    //   id: "barGap",
    //   label: "Bar Gap",
    //   componentId: "Input",
    //   defaultValue: 15,
    //   requiresSheet: true,
    //   section: "options",
    //   orientation: "horizontal",
    //   width: "120px",
    //   isStyle: true,
    //   displayCondition: (field) => get(field, "chartType") === "BarChart",
    // },
    // {
    //   id: "barBorderRadius",
    //   label: "Bar Border Radius",
    //   componentId: "Input",
    //   defaultValue: 12,
    //   requiresSheet: true,
    //   section: "options",
    //   orientation: "horizontal",
    //   width: "120px",
    //   isStyle: true,
    //   displayCondition: (field) => get(field, "chartType") === "BarChart",
    // },
    // {
    //   id: "flexContainerWidth",
    //   label: "Flex Container Width",
    //   componentId: "Switch",
    //   defaultValue: true,
    //   requiresSheet: true,
    //   section: "options",
    //   orientation: "horizontal",
    //   isStyle: true,
    //   displayCondition: (field) =>
    //     get(field, "chartType") === "BarChart" &&
    //     get(field, "variant", "Horizontal") === "Vertical",
    // },
    // {
    //   id: "flexBarThickness",
    //   label: "Flex Bar Width",
    //   componentId: "Switch",
    //   defaultValue: true,
    //   requiresSheet: true,
    //   section: "options",
    //   orientation: "horizontal",
    //   isStyle: true,
    //   displayCondition: (field) =>
    //     get(field, "chartType") === "BarChart" &&
    //     get(field, "variant", "Horizontal") === "Vertical",
    // },
    // {
    //   id: "barThickness",
    //   label: "Bar Thickness",
    //   componentId: "Input",
    //   defaultValue: 10,
    //   requiresSheet: true,
    //   section: "options",
    //   orientation: "horizontal",
    //   width: "120px",
    //   isStyle: true,
    //   displayCondition: (field) => get(field, "chartType") === "BarChart",
    // },
    {
      label: "Show Legend",
      id: "showLegend",
      componentId: "Switch",
      defaultValue: true,
      requiresSheet: true,
      section: "options",
      orientation: "horizontal",
      isStyle: true,
      displayCondition: (field) => get(field, "chartType") === "PieChart",
    },
    {
      label: "Show Labels",
      id: "showLabels",
      componentId: "Switch",
      defaultValue: true,
      requiresSheet: true,
      section: "options",
      isStyle: true,
      orientation: "horizontal",
      displayCondition: (field) => ["BarChart"].includes(field.chartType),
    },
    {
      id: "decimalPlaces",
      label: "Decimal Places",
      componentId: "Input",
      defaultValue: 0,
      section: "setup",
      advanced: true,
      hint: "The number of decimal places to display in chart values",
    },
    {
      id: "sorting",
      label: "Sorting",
      section: "sorting",
      hint: "Sort the results in the chart",
      requiresSheet: true,
      componentId: "Select",
      hideEmptyItem: true,
      options: [
        { label: "None", value: null },
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
      advanced: true,
    },
    {
      id: "sortBy",
      label: "Sort By",
      componentId: "Select",
      section: "sorting",
      requiresSheet: true,
      advanced: true,
      defaultValue: "value",
      options: [
        { label: "Value", value: "value" },
        { label: "Grouping Field", value: "key" },
      ],
      displayCondition: (field) => field.groupByField,
    },
    fetchIfHidden,
  ],
};
