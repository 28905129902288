import { Store } from "react-notifications-component";

export const successNotification = (message) => {
  createNotification({
    type: "success",
    // title: "Success",
    message: message,
  });
};

export const errorNotification = (message, duration = 3000) => {
  createNotification({
    type: "danger",
    // title: "Error",
    message: message,
    duration,
  });
};

const createNotification = ({
  id = null,
  duration = 2000,
  type,
  title,
  message,
}) => {
  // success - GREEN
  // danger - RED
  // info - TEAL BLUE
  // default - BLUE
  // warning - YELLOW

  Store.addNotification({
    id,
    title,
    message,
    type,
    width: 200,
    insert: "top",
    container: "bottom-left",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration,
      onScreen: true,
    },
  });
};

export default createNotification;
