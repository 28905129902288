import { Modal, Select, Spinner, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { rNewDataSourceModalData, rSavedSpreadsheets } from "app/utils/recoil";
import { useEffect, useState } from "react";

import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import { useRecoilState } from "recoil";

const Airtable = () => {
  const [data, setData] = useRecoilState(rNewDataSourceModalData);
  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const [isImporting, setIsImporting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [bases, setBases] = useState([]);
  const [tables, setTables] = useState([]);
  const [baseId, setBaseId] = useState(null);
  const [tableId, setTableId] = useState(null);

  // Fetch all bases when the component mounts
  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/airtable/bases/").then((response) => {
      setBases(get(response, "data", []));
      setIsFetching(false);
    });
  }, []);

  // Once base ID is selected, fetch the tables for that base
  useEffect(() => {
    if (baseId && !tableId) {
      setIsFetching(true);
      apiRequest
        .get(`/airtable/base_schema/?base_id=${baseId}`)
        .then((response) => {
          setTables(get(response, "data", []));
          setIsFetching(false);
        });
    }
  }, [baseId]);

  const importAirtableBase = () => {
    setIsImporting(true);

    apiRequest
      .post("/airtable/import_base/", {
        base_id: baseId,
        base_name: get(
          bases.find((b) => b.id === baseId),
          "name"
        ),
        table_id: tableId,
        table_data: tables.find((t) => t.id === tableId),
      })
      .then((response) => {
        const resData = get(response, "data", {});
        const error = get(resData, "error");

        if (error) {
          errorNotification(error);
          setIsImporting(false);
          return;
        }

        const newTable = get(resData, "data_source");

        setSavedSpreadsheets([...savedSpreadsheets, newTable]);

        successNotification("Import successful");
        setIsImporting(false);
        mixpanel.track("Import Airtable Base");
        setData(null);
      });
  };

  return (
    <Modal
      minWidth="400px"
      header={{ title: "Add Airtable Base" }}
      buttons={[
        {
          text: "Import Base",
          onClick: importAirtableBase,
          isFetching: isImporting,
        },
      ]}
      hide={() => setData(null)}
    >
      <Text
        data={{
          text: "Select the Airtable base you want to import",
          margin: "0 0 20px 0",
        }}
      />
      {isFetching ? (
        <Spinner color="#000" />
      ) : (
        <div>
          <Text data={{ text: "Airtable Base" }} />
          <Select
            data={{
              required: true,
              options: bases.map((b) => ({
                label: b.name,
                value: b.id,
              })),
              margin: "5px 0 0 0",
              width: "100%",
              value: baseId,
              onChange: (v) => setBaseId(v),
            }}
          />
          {baseId && (
            <>
              <Text data={{ text: "Base Table", margin: "10px 0 0 0" }} />
              <Select
                data={{
                  required: true,
                  options: tables.map((b) => ({
                    label: b.name,
                    value: b.id,
                  })),
                  margin: "5px 0 0 0",
                  width: "100%",
                  value: tableId,
                  onChange: (v) => setTableId(v),
                }}
              />
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default Airtable;
