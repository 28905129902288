import { Button, Row, Select, SimpleDropdown } from "app/components";
import { openPageInNewTab, safeArray } from "app/utils/utils";
import {
  rActiveBlockId,
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
  rCustomBlockChanges,
  rCustomBlocks,
  rPageChanges,
  rPages,
  rScreenPreviewSize,
} from "app/utils/recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import BackSection from "./configPanel/components/BackSection";
import BlocksHeader from "./BlocksHeader";
import Cookies from "js-cookie";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import useIsCustomBlock from "app/utils/useIsCustomBlock";
import usePage from "app/utils/usePage";
import { useState } from "react";

const Header = () => {
  const navigate = useNavigate();
  const activeBlock = useActiveBlock();
  const page = usePage();

  const pages = useRecoilValue(rPages);

  const isCustomBlock = useIsCustomBlock();

  const app = useRecoilValue(rApp);

  const { id } = useParams();

  const setActiveBlockId = useSetRecoilState(rActiveBlockId);
  const setActiveDetailViewId = useSetRecoilState(rActiveDetailViewId);
  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const [screenPreviewSize, setScreenPreviewSize] =
    useRecoilState(rScreenPreviewSize);

  const [isSaving, setIsSaving] = useState(false);

  const [pageChanges, setPageChanges] = useRecoilState(rPageChanges);

  const [customBlockChanges, setCustomBlockChanges] =
    useRecoilState(rCustomBlockChanges);

  const customBlocks = useRecoilValue(rCustomBlocks);

  const activeCustomBlock = safeArray(customBlocks).find((b) => b.id == id);

  // Save page to API
  const savePage = (preview) => {
    setIsSaving(true);
    apiRequest.post("/page/", page).then(() => {
      setPageChanges(false);
      setIsSaving(false);
      successNotification("Saved");
      if (preview) {
        mixpanel.track("Preview Page");
        openPageInNewTab({
          page,
          app,
          token: Cookies.get("accessToken"),
        });
      } else {
        mixpanel.track("Save Page");
      }
    });
  };

  const hideBlocksHeader =
    activeCustomBlock &&
    !["Column", "Row"].includes(get(activeBlock, "componentId"));

  return (
    <Container>
      <Row style={{ height: "100%" }} alignItems="center">
        <BackSection />
        {!isCustomBlock && (
          <Select
            data={{
              hideEmptyItem: true,
              value: get(page, "id"),
              width: "180px",
              onChange: (pageId) => {
                console.log("CHANGE pageId in header", pageId);
                setActiveBlockId(null);
                setActiveEditField(null);
                setActiveDetailViewId(null);
                navigate(`/page/${pageId}`);
              },
              options: pages.map((p) => ({
                label: p.name,
                value: p.id,
              })),
            }}
          />
        )}
        {isCustomBlock && (
          <Select
            data={{
              hideEmptyItem: true,
              value: get(activeCustomBlock, "id"),
              width: "180px",
              onChange: (customBlockId) => {
                setActiveBlockId(null);
                setActiveEditField(null);
                setActiveDetailViewId(null);
                navigate(`/custom-block/${customBlockId}`);
              },
              options: safeArray(customBlocks).map((p) => ({
                label: p.name,
                value: p.id,
              })),
            }}
          />
        )}
      </Row>

      {!hideBlocksHeader && (!isCustomBlock || activeBlock) ? (
        <BlocksHeader />
      ) : (
        <div>&nbsp;</div>
      )}

      <Row
        alignItems="center"
        justifyContent="flex-end"
        gap="10px"
        margin="0 20px 0 0"
      >
        <SimpleDropdown
          data={{
            options: [
              {
                label: "Desktop",
                value: "desktop",
                onClick: () => setScreenPreviewSize("desktop"),
              },
              {
                label: "Tablet",
                value: "tablet",
                onClick: () => setScreenPreviewSize("tablet"),
              },
              {
                label: "Phone",
                value: "phone",
                onClick: () => setScreenPreviewSize("mobile"),
              },
            ],
            button: {
              type: "basic",
              icon: get(
                {
                  desktop: "FiMonitor",
                  tablet: "FiTablet",
                  mobile: "FiSmartphone",
                },
                screenPreviewSize
              ),
            },
          }}
        />

        {!isCustomBlock && (
          <>
            <Button
              data={{
                onClick: () => savePage(true),
                text: "Preview",
                isFetching: isSaving,
                type: "basic",
                icon: "FiEye",
              }}
            />
            <Button
              data={{
                disabled: !pageChanges,
                onClick: () => savePage(false),
                text: pageChanges ? "Save Page" : "Changes Saved",
                isFetching: isSaving,
              }}
            />
          </>
        )}
        {isCustomBlock && (
          <Button
            data={{
              disabled: !customBlockChanges,
              onClick: () => {
                apiRequest.post("/app_settings/", {
                  custom_blocks: customBlocks,
                });
                successNotification("Saved");
                setCustomBlockChanges(false);
              },
              text: customBlockChanges ? "Save Changes" : "Changes Saved",
              isFetching: isSaving,
            }}
          />
        )}
      </Row>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  background: white;
  border-bottom: 1px solid ${colors.grey2};
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
