import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import React from "react";

const RechartArea = ({ data, series }) => {
  return (
    <AreaChart width={600} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      {series.map((item) => (
        <Area
          type="monotone"
          dataKey={item.dataKey}
          stroke={item.stroke}
          fill={item.fill}
        />
      ))}
    </AreaChart>
  );
};

export default RechartArea;
