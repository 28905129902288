import { Button, Spinner, Text } from "app/components";
import { FormContainer, Logo } from "./Login";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AdminForm } from "../components";
import Cookies from "js-cookie";
import { apiRequest } from "app/utils/apiRequests";
import { errorNotification } from "app/utils/Notification";
import { frontlyLogoDark } from "app/utils/utils";
import styled from "styled-components";

const AppSumoSignup = () => {
  const [searchParams] = useSearchParams();
  const invoice_item_uuid = searchParams.get("invoice_item_uuid");
  const [error, setError] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [formState, setFormState] = useState({
    first_name: "",
    last_name: "",
    password: "",
  });
  const [email, setEmail] = useState(false);

  const navigate = useNavigate();

  const errorMap = {
    DUPLICATE_EMAIL:
      "It looks like you already have an account with that email.",
  };

  const [errors, setErrors] = useState({});

  let fields = [
    {
      id: "email",
      label: "Email",
      placeholder: "Email",
      componentId: "Input",
      disabled: true,
      value: email,
    },
    {
      id: "first_name",
      label: "First Name",
      placeholder: "First Name",
      componentId: "Input",
    },
    {
      id: "last_name",
      label: "Last Name",
      placeholder: "Last Name",
      componentId: "Input",
    },
    {
      id: "password",
      label: "Password",
      placeholder: "Password",
      type: "password",
      componentId: "Input",
    },
  ];

  const validate = () => {
    // Validate form values

    let errors = {};

    // if (formState.first_name.length < 6) {
    // }

    if (formState.password.length < 6) {
      errors["password"] = "Please enter a more secure password";
      errorNotification("Please enter a more secure password");
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  useEffect(() => {
    if (invoice_item_uuid) {
      setIsVerifying(true);

      apiRequest
        .get("/verify_appsumo_transaction/", {
          params: {
            invoice_item_uuid,
          },
        })
        .then((r) => {
          const response = get(r, "data");
          const error = get(response, "error");
          const transactionData = get(response, "transaction_data");

          if (error) {
            setError(error);
          } else {
            setEmail(get(transactionData, "activation_email"));
          }

          setIsVerifying(false);
        });
    } else {
      setError(
        "We are unable to process your invoice item uuid at the moment. Please contact support for resolution."
      );
    }
  }, []);

  const createAccount = () => {
    setIsCreatingAccount(true);

    // Make login request
    if (validate()) {
      apiRequest
        .post("/appsumo_signup/", {
          ...formState,
          email,
          invoice_item_uuid,
        })
        .then((response) => {
          const token = get(response, ["data", "token"]);
          Cookies.set("accessToken", token, { expires: 30 });
          setIsCreatingAccount(false);
          navigate("/chat/new");
        })
        .catch((e) => {
          setIsCreatingAccount(false);
        });
    }
  };

  return (
    <Container>
      <Logo src={frontlyLogoDark} />
      {isVerifying && <Spinner />}
      {error && (
        <FormContainer width="500px">
          <Text data={{ text: "Hey Sumo-ling!", fontStyle: "headingXl" }} />
          <Text
            data={{
              text: get(
                errorMap,
                error,
                "We are unable to process your invoice item uuid at the moment. Please contact support for resolution."
              ),
              fontStyle: "bodyXl",
            }}
          />
          {error === "DUPLICATE_EMAIL" ? (
            <Button
              data={{ text: "Login Now", onClick: () => navigate("/login") }}
            />
          ) : (
            <Button
              data={{
                text: "Contact Support",
                onClick: () =>
                  (window.location.href = "mailto:support@frontlyapp.com"),
              }}
            />
          )}
        </FormContainer>
      )}
      {email && (
        <FormContainer width="500px">
          <Text data={{ text: "Hey Sumo-ling!", fontStyle: "headingLg" }} />
          <Text
            data={{
              text: "You are almost there. Continue registration now & get access to your exclusive AppSumo's plan.",
              fontStyle: "bodyXl",
            }}
          />
          <AdminForm
            sectionPadding="0px"
            fields={fields}
            submitText={"Finalize Account"}
            submit={createAccount}
            // errors={errors}
            isFetching={isCreatingAccount}
            onChange={(k, v) => setFormState((s) => ({ ...s, [k]: v }))}
          />
        </FormContainer>
      )}
    </Container>
  );
};

export default AppSumoSignup;

const Container = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    position: initial;
    height: 100%;
  }
`;
