import { Hint, Icon, Row, Text } from "app/components";

import { colors } from "app/utils/theme";
import { isFrontlyAdmin } from "app/utils/utils";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const FormInputWrapper = ({
  id,
  label,
  labelColor,
  children,
  hint,
  error,
  description,
  margin,
  link,
  orientation = "vertical",
  paidFeature,
  labelStyle,
  labelWidth = "auto",
  columnSpan = 1,
  handleFieldClick,
}) => {
  return (
    <Container
      columnSpan={columnSpan}
      onClick={(e) => {
        if (isFrontlyAdmin && handleFieldClick) {
          handleFieldClick({ id, target: e.currentTarget });
        }
      }}
      handleFieldClick={isFrontlyAdmin && handleFieldClick}
      margin={margin}
    >
      <Wrapper flexDirection={orientation === "vertical" ? "column" : "row"}>
        {label && (
          <Row alignItems="center" gap="5px" width={labelWidth}>
            <Text
              data={{
                text: label,
                margin: "0 0 5px 0",
                fontStyle: labelStyle || "bodySm",
                color: labelColor,
              }}
            />
            {hint && <Hint hint={hint} link={link} />}
            {paidFeature && <PaidBadge />}
          </Row>
        )}
        {description && (
          <Text
            data={{
              text: description,
              margin: "0 0 5px 0",
              fontStyle: "bodySm",
              color: colors.grey3,
            }}
          />
        )}
        {children}
      </Wrapper>
      {error && (
        <Text
          data={{
            text: error,
            margin: "2px 0 0 0",
            color: "red",
            fontStyle: "bodySm",
          }}
        />
      )}
    </Container>
  );
};

export default FormInputWrapper;

const PaidBadge = () => {
  const navigate = useNavigate();

  return (
    <PaidWrapper onClick={() => navigate("/billing")}>
      <Icon
        data={{
          color: colors.primary,
          icon: "FiArrowUpCircle",
          size: 12,
          hover: true,
        }}
      />
      <Text
        data={{
          text: "Upgrade",
          color: colors.primary,
          fontStyle: "heading2Xs",
          cursor: "pointer",
        }}
      />
    </PaidWrapper>
  );
};

const Container = styled.div`
  margin: ${(p) => p.margin};
  grid-column: span ${(p) => p.columnSpan};

  @media screen and (max-width: 800px) {
    grid-column: span 1;
  }

  ${(p) =>
    p.handleFieldClick &&
    `
    &:hover{
      outline: 1px solid ${colors.primary};
      outline-offset: -2px;
      cursor: pointer;
      border-radius: 4px;
    }
`};
`;

const PaidWrapper = styled.div`
  background: #e4ebf7;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => p.flexDirection || "column"};
  gap: 0px;
  ${(p) =>
    p.flexDirection === "row" &&
    "justify-content: space-between; align-items: center; gap: 20px;"}
`;
