import { Button, Row, Text } from "app/components";
import React, { useRef } from "react";

import SignaturePad from "react-signature-pad-wrapper";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import { rApp } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const Signature = ({ data }) => {
  // const translations = useRecoilValue(rTranslations);

  const { onChange } = data;

  const app = useRecoilValue(rApp);
  const primaryColor = get(app, "primary_color") || colors.primary;

  const ref = useRef();

  const onClear = () => {
    ref.current.signaturePad.clear();
  };

  const saveSignature = () => {
    apiRequest
      .post("/upload_signature/", {
        file: ref.current.signaturePad.toDataURL(),
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Container borderRadius={data.borderRadius}>
        <SignaturePad
          ref={ref}
          options={{ penColor: "#000000", backgroundColor: "#ffffff" }}
          height={80}
        />
      </Container>
      <Row gap="10px">
        <Text
          data={{
            text: "Save",
            onClick: saveSignature,
            color: primaryColor,
            fontWeight: 500,
          }}
        />
        <Text
          data={{
            text: "Clear",
            onClick: onClear,
            color: primaryColor,
            fontWeight: 500,
          }}
        />
      </Row>
    </div>
  );
};

export default Signature;

const Container = styled.div`
  border: ${(p) => p.border || `1px solid ${colors.pitchBorder}`};
  border-width: ${(p) => p.borderWidth};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  margin-bottom: 5px;
  overflow: hidden;
`;
