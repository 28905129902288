import {
  Badge,
  CardBase,
  CardLabel,
  CardRow,
  CardText,
  Label,
} from "../users/UsersList";
import { Container, Header } from "app/adminApp/home/Pages";
import {
  FileUpload,
  Pagination,
  Row,
  SearchBar,
  Select,
  SimpleDropdown,
  Spinner,
  Tabs,
} from "app/components";
import { getUrlParameter, resizeImage, truncateText } from "app/utils/utils";
import { rApp, rConfirmationModalData } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "../home/AdminWrapper";
import Thumbnail from "app/components/Thumbnail";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";

export const getFileFormat = (a) => {
  const split = get(a, "url", "").split(".");
  const format = get(split, split.length - 1);
  return format.toUpperCase();
};

const Assets = () => {
  const [assets, setAssets] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const activeApp = useRecoilValue(rApp);

  const totalAssetStorageInBytes = get(activeApp, "total_asset_storage", 0);

  const getTotalAssetStorageDisplay = () => {
    const totalAssetStorageInMB = totalAssetStorageInBytes / 1024 / 1024;
    if (totalAssetStorageInMB < 1024) {
      return `${totalAssetStorageInMB.toFixed(2)} MB`;
    }

    const totalAssetStorageInGB = totalAssetStorageInMB / 1024;
    return `${totalAssetStorageInGB.toFixed(2)} GB`;
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(1);

  const sorting = getUrlParameter("sorting", location) || "default";
  const type = getUrlParameter("type", location) || "admin";
  const page = parseInt(getUrlParameter("page", location) || 1);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  useEffect(() => {
    getAssets(page, search, type, sorting, true);
  }, []);

  const getAssets = (p, s, t, sort, initial = false) => {
    setIsFetching(true);

    const newRoute = `/assets/?page=${p}&search=${s}&type=${t}&sorting=${sort}`;

    if (!initial) {
      navigate(newRoute);
    }

    apiRequest.get(newRoute).then((r) => {
      const res = get(r, "data", {});
      const assetsRes = get(res, "assets", []);
      const totalPages = get(res, "total_pages", 1);

      setAssets(assetsRes);
      setPageCount(totalPages);

      setIsFetching(false);
    });
  };

  const deleteAsset = (assetId) =>
    setConfirmationModalData({
      title: "Delete Asset",
      text: "Are you sure you want to delete this asset? This is irreversible.",
      confirm: () => {
        successNotification("Asset Deleted");
        setAssets(assets.filter((u) => u.id !== assetId));
        apiRequest.delete("/assets/", {
          data: { id: assetId },
        });
      },
    });

  const copyLink = (a) => {
    navigator.clipboard.writeText(a.url);
    successNotification("Asset URL copied to clipboard");
  };

  return (
    <AdminWrapper>
      <Row justifyContent="space-between" alignItems="center">
        <Label>Assets ({getTotalAssetStorageDisplay()})</Label>
        <FileUpload
          data={{
            value: "",
            hideFilename: true,
            text: "New Asset",
            icon: "FiPlus",
            size: "medium",
            buttonType: "primary",
            isAdmin: true,
            onAssetUploaded: (newAsset) => setAssets([...assets, newAsset]),
          }}
        />
      </Row>
      <Row
        margin="20px 0 20px 0"
        alignItems="center"
        justifyContent="space-between"
      >
        <Tabs
          data={{
            tabs: [
              {
                label: "All",
                active: type === "all",
                onClick: () => {
                  getAssets(page, search, "all", sorting);
                },
              },
              {
                label: "Admin Assets",
                active: type === "admin",
                onClick: () => {
                  getAssets(page, search, "admin", sorting);
                },
              },
              {
                label: "User Assets",
                active: type === "user",
                onClick: () => {
                  getAssets(page, search, "user", sorting);
                },
              },
            ],
          }}
        />
        <Select
          data={{
            width: "180px",
            label: "Sorting",
            value: sorting,
            onChange: (newSorting) => getAssets(page, search, type, newSorting),
            options: [
              {
                label: "Default",
                value: "default",
              },
              {
                label: "Size (descending)",
                value: "size_desc",
              },
            ],
          }}
        />
      </Row>

      <Container>
        <Header>
          <SearchBar
            data={{
              value: search,
              placeholder: "Search assets",
              onChange: (v) => setSearch(v),
              submit: () => getAssets(page, search, type, sorting),
            }}
          />
        </Header>

        {isFetching && (
          <Spinner color={colors.grey3} size={30} padding={"15px"} />
        )}

        {!isFetching && (
          <>
            {assets.map((a) => {
              const format = getFileFormat(a);

              let displayName = a.url.split("/").pop();

              return (
                <CardBase onClick={() => copyLink(a)}>
                  <CardRow>
                    <Thumbnail
                      src={
                        ["PNG", "JPG", "JPEG", "GIF", "SVG"].includes(format)
                          ? resizeImage({
                              url: a.url,
                              height: 300,
                              quality: 60,
                              forceJpg: true,
                            })
                          : "https://res.cloudinary.com/frontly/image/upload/v1687564767/file-grey_hicntb.svg"
                      }
                      size="small"
                    />
                    <CardLabel style={{ width: "320px" }}>
                      {truncateText(displayName, 25)}
                    </CardLabel>
                    <CardText>
                      {`${(parseInt(a.file_size) / 1024).toFixed(1)}KB`}
                    </CardText>
                    <Badge color={"#b8e7d2"}>{format}</Badge>
                  </CardRow>
                  <SimpleDropdown
                    data={{
                      options: [
                        {
                          label: "Delete",
                          onClick: () => deleteAsset(a.id),
                        },
                        {
                          label: "Copy Link",
                          onClick: () => copyLink(a),
                        },
                      ],
                      icon: {
                        icon: "FiMoreHorizontal",
                        size: 25,
                        hover: true,
                        color: colors.grey3,
                      },
                    }}
                  />
                </CardBase>
              );
            })}
            <Pagination
              margin={"15px"}
              currentPage={page}
              pageCount={pageCount}
              onChange={(p) => getAssets(p, search, type, sorting)}
            />
          </>
        )}
      </Container>
    </AdminWrapper>
  );
};

export default Assets;
