import { Button } from "app/components";
import { get } from "lodash";
import useSetPage from "app/utils/useSetPage";

const SwitchComponent = ({ data }) => {
  const { setBlock } = useSetPage();

  const newComponentId = get(data, "type");

  const switchComponent = () => {
    setBlock({
      componentId: newComponentId,
      horizontalAlignment: undefined,
      verticalAlignment: undefined,
      flexWrap: undefined,
    });
  };

  return (
    <Button
      data={{
        onClick: () => switchComponent(),
        type: "basic",
        text: "Switch To " + newComponentId,
      }}
    />
  );
};

export default SwitchComponent;
