import { rActiveDetailViewId, rPage, rShowStylesPanel } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "app/adminApp/components/AdminForm";
import { PanelHeader } from "./PageStyles";
import { get } from "lodash";
import usePage from "app/utils/usePage";
import useSetPage from "app/utils/useSetPage";

const DetailViewStyles = () => {
  const { setBlock } = useSetPage();

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const blocks = get(page, "blocks", []);
  const detailViewBlock = blocks.find((b) => b.id === activeDetailViewId);
  const parentBlock = blocks.find(
    (b) => b.id === get(detailViewBlock, "parent")
  );

  const [showStylesPanel, setShowStylesPanel] =
    useRecoilState(rShowStylesPanel);

  const detailViewMode = get(detailViewBlock, "detailViewMode", "modal");

  const fields = [
    {
      id: "detailViewMode",
      componentId: "SelectToggle",
      section: "windowStyle",
      defaultValue: "modal",
      requiresSheet: false,
      tabs: [
        {
          label: "Modal",
          value: "modal",
          active: !detailViewMode || detailViewMode === "modal",
        },
        {
          label: "Full Page",
          value: "fullPage",
          active: detailViewMode === "fullPage",
        },
      ],
      sectionHint:
        "Choose whether the detail view should open in a modal (popup) or in a full page view",
      link: "https://help.frontly.ai/en/articles/7971235-record-click-action",
      displayCondition: () => !parentBlock,
    },
    {
      id: "detailViewWidth",
      section: "detailViewWidth",
      componentId: "Select",
      requiresSheet: true,
      hideEmptyItem: true,
      options: [
        {
          label: "400px",
          value: "400px",
        },
        {
          label: "600px",
          value: "600px",
        },
        {
          label: "900px",
          value: "900px",
        },
      ],
      value: get(detailViewBlock, "detailViewWidth"),
      defaultValue: "600px",
      displayCondition: () => detailViewMode === "modal",
    },
  ];

  return (
    <div>
      <PanelHeader
        label="Detail View Styles"
        expanded={showStylesPanel}
        setExpanded={setShowStylesPanel}
      />
      <AdminForm
        fields={fields.filter(
          (f) =>
            !f.displayCondition || (f.displayCondition && f.displayCondition())
        )}
        orientation={"horizontal"}
        borderBottom={true}
        onChange={(key, value) =>
          setBlock({ [key]: value }, {}, activeDetailViewId)
        }
      />
    </div>
  );
};

export default DetailViewStyles;
