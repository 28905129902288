import { DataGrid } from "app/components";
import { get } from "lodash";
import { getHighest } from "app/utils/utils";

const KeyValuePairs = ({ data }) => {
  const filters = get(data, "value", []);

  const addFilter = (newFilters) => {
    data.onChange(newFilters);
  };

  return (
    <DataGrid
      data={{
        excludedSources: get(data, "excludedSources", []),
        dynamicSources: get(data, "dynamicSources", []),
        previousSteps: get(data, "previousSteps"),
        newObject: {
          id: getHighest(filters, "id") + 1,
        },
        onChange: (newFilters) => addFilter(newFilters),
        value: filters,
        columns: [
          {
            key: "key",
            label: "Key",
            width: "100%",
            componentId: "DynamicString",
            value: get(data, "key"),
          },
          {
            key: "value",
            label: data.valueLabel || "Value",
            width: "100%",
            componentId: "DynamicString",
            value: get(data, "value"),
          },
        ],
      }}
    />
  );
};

export default KeyValuePairs;
