import { Button, Hint, Modal, Row, Select, Text } from "app/components";

import { AdminForm } from "app/adminApp/components";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getHighest } from "app/utils/utils";
import { rActiveVersionMap } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import { useRecoilState } from "recoil";
import useSetPage from "app/utils/useSetPage";
import { useState } from "react";

const BlockVersionConfig = () => {
  const [showModal, setShowModal] = useState(false);

  const activeBlock = useActiveBlock();
  const { setBlock } = useSetPage();

  const [activeVersionMap, setActiveVersionMap] =
    useRecoilState(rActiveVersionMap);

  const versions = get(activeBlock, "versions", []);

  const activeVersionId = get(activeVersionMap, activeBlock.id);

  const activeVersion = versions.find((v) => v.id === activeVersionId);

  const addVersion = () => {
    const newVersionId = getHighest(versions, "id") + 1;
    setBlock(
      {
        versions: [
          ...versions,
          {
            id: newVersionId,
            name: "New Version",
            data: get(activeVersion, "data", {}),
          },
        ],
      },
      {},
      null,
      true
    );
    successNotification("New Block Version Created");
    setActiveVersionMap({
      ...activeVersionMap,
      [activeBlock.id]: newVersionId,
    });
  };

  const updateVersion = (key, value) => {
    const newVersions = versions.map((version) => {
      if (version.id === activeVersionId) {
        return {
          ...version,
          [key]: value,
        };
      }
      return version;
    });

    setBlock(
      {
        versions: newVersions,
      },
      {},
      null,
      true
    );
  };

  const deleteVersion = () => {
    const newVersions = versions.filter((v) => v.id !== activeVersionId);
    setBlock(
      {
        versions: newVersions,
      },
      {},
      null,
      true
    );
    setActiveVersionMap({
      ...activeVersionMap,
      [activeBlock.id]: null,
    });
    setShowModal(false);

    // TODO - IMPORTANT - Clean up version actions here
  };

  const modalFields = [
    {
      id: "activeVersionName",
      label: "Version Name",
      componentId: "Input",
      orientation: "vertical",
      value: get(activeVersion, "name", ""),
      onChange: (k, v) => updateVersion("name", v),
      advanced: true,
    },
    {
      id: "activeVersionConditions",
      label: "Version Conditions",
      componentId: "DisplayConditions",
      hideConditionType: true,
      orientation: "vertical",
      value: get(activeVersion, "conditions", []),
      hint: "Define conditions for when this version should be active",
      onChange: (k, v) => updateVersion("conditions", v),
      advanced: true,
    },
    {
      id: "conditionType",
      key: "conditionType",
      label: "Condition Type",
      hint: "How you want your condition to be applied, relative to the other conditions.",
      componentId: "Select",
      value: get(activeVersion, "conditionType", "and"),
      onChange: (k, v) => updateVersion("conditionType", v),
      options: [
        {
          label: "And",
          value: "and",
        },
        {
          label: "Or",
          value: "or",
        },
      ],
    },
  ];

  return (
    <div
      style={{
        padding: "15px",
        borderBottom: `1px solid ${colors.pitchBorder}`,
      }}
    >
      <Row alignItems="center" gap="5px">
        <Text
          data={{
            text: "Block Versions",
            margin: "0 0 5px 0",
            fontStyle: "headingSm",
          }}
        />
        <Hint
          hint={
            "Manage different versions of your block that render based on conditions"
          }
        />
      </Row>
      <Row gap="5px">
        <Select
          data={{
            id: "version",
            label: "Version",
            componentId: "Select",
            options: [
              {
                label: "Default",
                value: null,
              },
              ...versions.map((v) => ({
                label: v.name,
                value: v.id,
              })),
            ],
            value: activeVersionId,
            hideEmptyItem: true,
            noneText: "Default",
            onChange: (v) =>
              setActiveVersionMap({
                ...activeVersionMap,
                [activeBlock.id]: v,
              }),
            advanced: true,
          }}
        />
        {activeVersionId && (
          <Button
            data={{
              icon: "FiEdit",
              height: "37px",
              type: "basic",
              onClick: () => setShowModal(true),
            }}
          />
        )}
        <Button
          data={{
            icon: "FiPlus",
            height: "37px",
            type: "basic",
            onClick: addVersion,
          }}
        />
      </Row>

      {showModal && (
        <Modal
          header={{ title: "Edit Version" }}
          hide={() => setShowModal(false)}
          minWidth="350px"
        >
          <AdminForm
            sectionPadding="0px"
            fields={modalFields}
            onChange={(k, v) => {
              console.log("OBJ", k, v);
              // data.onChange({ ...cssObject, [k]: v });
            }}
          />
          <Button
            data={{
              text: "Delete Version",
              type: "basic",
              icon: "FiTrash",
              margin: "15px 0 0 0",
              onClick: deleteVersion,
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default BlockVersionConfig;
