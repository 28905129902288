import { Button, Text } from "app/components";

import { AdminForm } from "../components";
import SettingsSection from "../settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import { useRecoilState } from "recoil";
import { useState } from "react";

const UsersList = () => {
  const [app, setApp] = useRecoilState(rApp);

  // USER GROUPS
  const [changes, setChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const usersSheetConfig = get(app, "users_sheet_config", {});

  const save = () => {
    setIsSaving(true);
    apiRequest
      .post("/app_settings/", {
        users_sheet_config: usersSheetConfig,
      })
      .then(() => {
        successNotification("Users Spreadsheet Settings Saved");
        setIsSaving(false);
      });
  };

  return (
    <SettingsSection
      title="Users Spreadsheet"
      description="Sync a specific spreadsheet containing your users with the active user's session automatically"
    >
      <Text
        data={{
          text: "Click to learn more about the Users Spreadsheet setting",
          onClick: () =>
            window.open(
              "https://help.frontly.ai/en/articles/8768132-users-spreadsheet"
            ),
          fontStyle: "headingSm",
          color: colors.primary,
          margin: "0 0 15px 0",
        }}
      />
      <AdminForm
        labelStyle={"headingXs"}
        onChange={(k, v) => {
          setChanges(true);
          setApp({
            users_sheet_config: { ...usersSheetConfig, [k]: v },
          });
        }}
        sectionPadding="0px"
        fields={[
          {
            id: "spreadsheet_id",
            label: "Spreadsheet",
            componentId: "SpreadsheetSelect",
            allowEmpty: true,
            value: get(usersSheetConfig, "spreadsheet_id"),
          },
        ]}
      />

      {changes && (
        <Button
          data={{
            margin: "20px 0 0 0",
            text: "Save",
            onClick: save,
            isFetching: isSaving,
          }}
        />
      )}
    </SettingsSection>
  );
};

export default UsersList;
