import { useCallback, useEffect, useState } from "react";

export const useWebsocketCustom = (onResponse) => {
  const [websocket, setWebsocket] = useState(null);

  // Function to initialize WebSocket connection
  const connect = useCallback(() => {
    if (!websocket) {
      const wsURL = `wss://o0v9pmvz0g.execute-api.ca-central-1.amazonaws.com/production`;

      const ws = new WebSocket(wsURL);

      ws.onopen = () => {
        console.log("WebSocket Connected");
        setWebsocket(ws);
      };

      ws.onmessage = (event) => {
        const wsResponse = JSON.parse(event.data);
        onResponse(wsResponse);
      };

      ws.onclose = () => {
        console.log("WebSocket Disconnected");
        setWebsocket(null);
      };

      return () => ws.close();
    }
  }, [websocket]);

  // Function to close WebSocket connection
  const disconnect = useCallback(() => {
    if (websocket) {
      websocket.close();
    }
  }, [websocket]);

  useEffect(() => {
    connect();
    return () => disconnect();
  }, [connect, disconnect]);

  const generate = (aiObject) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      // Set 30 second timer to prevent infinite loading
      websocket.send(JSON.stringify(aiObject));
    } else {
      console.error("WebSocket is not open.");
    }
  };

  return { generate };
};
