import { getPixels, isFrontlyAdmin } from "app/utils/utils";
import { rActiveBlockId, rDarkMode } from "app/utils/recoil";

import { Row } from "app/components";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const Grid = ({ items, setPosition, screenPreviewSize }) => {
  const [dragItem, setDragItem] = useState(null);
  const [startPosition, setStartPosition] = useState({});
  const [startHandle, setStartHandle] = useState(null);
  const [currentMousePosition, setCurrentMousePosition] = useState(null);

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const darkMode = useRecoilValue(rDarkMode);

  const highestValue = getHighestGridPosition(items, screenPreviewSize);

  const twelveItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  let guideItems = [
    ...twelveItems,
    ...twelveItems,
    ...twelveItems,
    ...twelveItems,
  ];

  const handleMouseEnter = (rowIndex, index) => {
    if (startPosition && startHandle) {
      setCurrentMousePosition({ rowIndex, index });
    }
  };

  const handleDragEnd = () => {
    if (startPosition && startHandle) {
      // Calculate new position of the item based on the start position, the startHandle and the drag end rowIndex and index
      // The startHandle will be topRight, bottomLeft, bottomRight, topLeft, and will determine which corner of the item is being dragged
      let newPosition = {};

      const rowIndex = currentMousePosition?.rowIndex;
      const index = currentMousePosition?.index;

      if (startHandle === "move") {
        newPosition = {
          top: currentMousePosition?.rowIndex,
          left: currentMousePosition?.index,
          width: startPosition.width,
          height: startPosition.height,
        };
      } else if (startHandle === "bottomRight") {
        newPosition = {
          top: startPosition.top,
          left: startPosition.left,
          width: index - startPosition.left + 1,
          height: rowIndex - startPosition.top + 1,
        };
      } else if (startHandle === "bottomLeft") {
        newPosition = {
          top: startPosition.top,
          left: index,
          width: startPosition.left - index + startPosition.width,
          height: rowIndex - startPosition.top + 1,
        };
      } else if (startHandle === "topRight") {
        newPosition = {
          top: rowIndex,
          left: startPosition.left,
          width: index - startPosition.left + 1,
          height: startPosition.top - rowIndex + startPosition.height,
        };
      } else if (startHandle === "topLeft") {
        newPosition = {
          top: rowIndex,
          left: index,
          width: startPosition.left - index + startPosition.width,
          height: startPosition.top - rowIndex + startPosition.height,
        };
      }

      setPosition(newPosition, dragItem);
      setStartHandle(null);
      setStartPosition({});
      setCurrentMousePosition(null);
      setDragItem(null);
    }
  };

  const gridSize = get(
    { desktop: 25, tablet: 20, mobile: 15 },
    screenPreviewSize,
    25
  );

  return (
    <Container darkMode={darkMode}>
      {isFrontlyAdmin &&
        Array.from({ length: highestValue + 20 }).map((_, rowIndex) => (
          <Row>
            {guideItems.map((i, index) => {
              let isActive = false;

              if (startHandle === "move") {
                const newLeft = currentMousePosition?.index;
                const newTop = currentMousePosition?.rowIndex;

                if (
                  rowIndex >= newTop &&
                  index >= newLeft &&
                  rowIndex < newTop + startPosition.height &&
                  index < newLeft + startPosition.width
                ) {
                  isActive = true;
                }
              } else if (startHandle === "bottomRight") {
                if (
                  rowIndex >= startPosition.top &&
                  index >= startPosition.left &&
                  rowIndex <= currentMousePosition?.rowIndex &&
                  index <= currentMousePosition?.index
                ) {
                  isActive = true;
                }
              } else if (startHandle === "bottomLeft") {
                if (
                  rowIndex >= startPosition.top &&
                  index < startPosition.left + startPosition.width &&
                  rowIndex <= currentMousePosition?.rowIndex &&
                  index >= currentMousePosition?.index
                ) {
                  isActive = true;
                }
              } else if (startHandle === "topRight") {
                if (
                  rowIndex <= startPosition.top + startPosition.height &&
                  index >= startPosition.left &&
                  rowIndex >= currentMousePosition?.rowIndex &&
                  index <= currentMousePosition?.index
                ) {
                  isActive = true;
                }
              } else if (startHandle === "topLeft") {
                if (
                  rowIndex < startPosition.top + startPosition.height &&
                  index < startPosition.left + startPosition.width &&
                  rowIndex >= currentMousePosition?.rowIndex &&
                  index >= currentMousePosition?.index
                ) {
                  isActive = true;
                }
              }

              return (
                <Guide
                  darkMode={darkMode}
                  gridSize={gridSize}
                  active={isActive}
                  onClick={() => handleDragEnd()}
                  onMouseEnter={() => handleMouseEnter(rowIndex, index)}
                />
              );
            })}
          </Row>
        ))}

      {items
        .filter((item) => item.id !== dragItem)
        .map((item) => (
          <GridItem
            gridSize={gridSize}
            active={isFrontlyAdmin && activeBlockId === item.id}
            setStartPosition={setStartPosition}
            setStartHandle={setStartHandle}
            setDragItem={setDragItem}
            key={item.id}
            item={{
              ...item,
              gridPosition: get(item, ["gridPosition", screenPreviewSize]),
            }}
            handleDragEnd={handleDragEnd}
            allPositions={items.filter((i) => i.id !== item.id)}
          >
            {item.content}
          </GridItem>
        ))}
    </Container>
  );
};

const GridItem = ({
  active,
  item,
  setStartPosition,
  setStartHandle,
  setDragItem,
  children,
  handleDragEnd,
  gridSize,
}) => {
  const position = get(item, "gridPosition");

  return (
    <Item
      width={parseInt(position.width)}
      height={position}
      left={position.left}
      style={{
        height: position.height * gridSize,
        top: position.top * gridSize,
      }}
    >
      {active && (
        <>
          {/* MOVE HANDLE */}
          <Handle
            draggable
            onDragStart={() => {
              setStartPosition(position);
              setStartHandle("move");
              setDragItem(item.id);
            }}
            onDragEnd={(e) => {
              console.log("DRAG END IS NOT WORKING!");
              // handleDragEnd();
            }}
            onClick={() => {
              setStartPosition(position);
              console.log("ON CLICK");
              setStartHandle("move");
              setDragItem(item.id);
            }}
            style={{
              cursor: "move",
              left: 8,
              top: 8,
            }}
          />
          {/* TOP LEFT */}
          <Handle
            draggable
            onDragStart={() => {
              setStartPosition(position);
              setStartHandle("topLeft");
              setDragItem(item.id);
            }}
            onClick={() => {
              setStartPosition(position);
              setStartHandle("topLeft");
              setDragItem(item.id);
            }}
            style={{
              cursor: "nwse-resize",
              left: -4,
              top: -4,
            }}
          />
          {/* TOP RIGHT */}
          <Handle
            draggable
            onDragStart={() => {
              setStartPosition(position);
              setStartHandle("topRight");
              setDragItem(item.id);
            }}
            onClick={() => {
              setStartPosition(position);
              setStartHandle("topRight");
              setDragItem(item.id);
            }}
            style={{
              cursor: "nesw-resize",
              right: -4,
              top: -4,
            }}
          />
          {/* BOTTOM LEFT */}
          <Handle
            draggable
            onDragStart={() => {
              setStartPosition(position);
              setStartHandle("bottomLeft");
              setDragItem(item.id);
            }}
            onClick={() => {
              setStartPosition(position);
              setStartHandle("bottomLeft");
              setDragItem(item.id);
            }}
            style={{
              cursor: "nesw-resize",
              left: -4,
              bottom: -4,
            }}
          />
          {/* BOTTOM RIGHT */}
          <Handle
            draggable
            onDragStart={() => {
              setStartPosition(position);
              setStartHandle("bottomRight");
              setDragItem(item.id);
            }}
            onClick={() => {
              setStartPosition(position);
              setStartHandle("bottomRight");
              setDragItem(item.id);
            }}
            style={{
              cursor: "nwse-resize",
              right: -4,
              bottom: -4,
            }}
          />
        </>
      )}

      {children}
    </Item>
  );
};

export default Grid;

const getHighestGridPosition = (arr, screenPreviewSize) => {
  if (arr.length === 0) {
    return 0;
  }

  return Math.max(
    ...arr.map(
      (b) =>
        get(b, ["gridPosition", screenPreviewSize, "top"], 0) +
        get(b, ["gridPosition", screenPreviewSize, "height"], 0)
    )
  );
};

const Container = styled.div`
  position: relative;
  ${(p) =>
    isFrontlyAdmin &&
    `border: 1px solid ${
      p.darkMode ? colors.darkModeLightBackground : colors.grey2
    };`}
`;

const Item = styled.div`
  position: absolute;
  cursor: pointer;
  top: ${(p) => getPixels(p.top)};
  left: calc((100% / 48) * ${(p) => p.left});
  min-width: calc((100% / 48) * ${(p) => p.width});
  max-width: calc((100% / 48) * ${(p) => p.width});
`;

const Guide = styled.div`
  min-height: ${(p) => p.gridSize}px;
  max-height: ${(p) => p.gridSize}px;
  min-width: calc(100% / 48);
  max-width: calc(100% / 48);
  border-right: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightBackground : colors.grey2)};
  border-bottom: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightBackground : colors.grey2)};
  background: ${(p) => (p.active ? "#3c78e120" : "transparent")};
`;

const Handle = styled.div`
  position: absolute;
  background: ${colors.primary};
  height: 8px;
  width: 8px;
  border-radius: 10px;
  z-index: 9999;
`;
