import { SkeletonLoader, Text as TextComponent } from "app/components";
import {
  cssStringToObject,
  getPixels,
  isObjectEqualFromKeys,
} from "app/utils/utils";

import TextareaAutosize from "@mui/base/TextareaAutosize";
import { fontObject } from "app/utils/theme";
import { get } from "lodash";
import { memo } from "react";
import { rActiveBlockId } from "app/utils/recoil";
import useActionResolver from "app/renderingApp/useActionResolver";
import useDynamicText from "app/renderingApp/useDynamicText";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";

const isSet = (i) => {
  return i !== undefined && i !== null && i !== "";
};

export const paddingObjToString = (
  padding,
  defaultPadding = "0px 0px 0px 0px"
) => {
  // If no padding set or padding is not an object, return default padding
  if (!padding || typeof padding !== "object") {
    return defaultPadding;
  }

  // If none of the padding values are set, return default padding
  let hasValidValue = false;
  ["all", "top", "bottom", "left", "right"].forEach((k) => {
    if (isSet(get(padding, k))) {
      hasValidValue = true;
    }
  });

  if (!hasValidValue) {
    return defaultPadding;
  }

  // Extract values from the valid padding object, with a fallback to `all` or `0`
  const { all, top, right, bottom, left } = padding;

  const allFallback = all !== undefined && all !== "" ? all : 0;
  const resolvedTop = isSet(top) ? top : allFallback;
  const resolvedRight = isSet(right) ? right : allFallback;
  const resolvedBottom = isSet(bottom) ? bottom : allFallback;
  const resolvedLeft = isSet(left) ? left : allFallback;

  const finalString = `${getPixels(resolvedTop)} ${getPixels(
    resolvedRight
  )} ${getPixels(resolvedBottom)} ${getPixels(resolvedLeft)}`;

  // Return the padding string in CSS format
  return finalString;
};

const Text = ({ block, page, setupMode }) => {
  const { setBlock } = useSetPage();
  const activeBlockId = useRecoilValue(rActiveBlockId);
  const { actionResolver } = useActionResolver(page);
  const { processDynamicText } = useDynamicText();

  const repeatingRecord = get(block, "repeatingRecord");
  const repeatingRecordId = get(repeatingRecord, "frontly_id");

  let blockId = get(block, "id");
  if (repeatingRecordId) {
    blockId = `${blockId}-${repeatingRecordId}`;
  }

  let { text } = block;

  // Handle Click Action
  let handleClick = null;
  const clickAction = get(block, "clickAction");
  if (!setupMode && clickAction) {
    handleClick = (event) => {
      event.stopPropagation();
      actionResolver({
        rawAction: get(block, ["actionMap", "clickAction"]),
        actionId: clickAction,
        reusableBlockId: block.reusableBlockId,
        blockId,
        context: { repeatingRecord },
      });
    };
  }

  const padding = paddingObjToString(get(block, "padding", {}));

  const customCss = get(block, "customCss", {});

  const cssObject = cssStringToObject(
    processDynamicText({
      text: get(customCss, "text"),
      reusableBlockId: block.reusableBlockId,
      context: { repeatingRecord },
    })
  );

  // In setup mode, render text input
  if (setupMode && activeBlockId === block.id && !block.reusableBlockId) {
    return (
      <TextareaAutosize
        value={text}
        onChange={(e) => setBlock({ ...block, text: e.target.value })}
        style={{
          color: get(block, "fontColor"),
          ...getFontObject(block),
          background: "transparent",
          border: "0px",
          resize: "none",
          width: "100%",
          padding,
          textAlign: get(block, "textAlign") || "left",
          ...cssObject,
        }}
      />
    );
  }

  if (block.isFetching) {
    return <SkeletonLoader width="150px" height="25px" />;
  }

  const resolvedText = processDynamicText({
    text,
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord: get(block, "repeatingRecord") },
  });

  return (
    <TextComponent
      data={{
        ...block,
        text: resolvedText,
        onClick: handleClick,
        allowSelect: true,
        padding,
        width: "100%",
        customCss: processDynamicText({
          text: get(customCss, "text"),
          reusableBlockId: block.reusableBlockId,
          context: { repeatingRecord: get(block, "repeatingRecord") },
        }),
      }}
    />
  );
};

const getFontObject = (block) => {
  const fontStyle = get(block, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);

  let fontObj = { ...styleObject };

  if (block.fontSize) {
    fontObj.fontSize = parseInt(block.fontSize);
  }

  if (block.fontWeight) {
    fontObj.fontWeight = parseInt(block.fontWeight);
  }
  return fontObj;
};
// Custom comparison function
const areEqual = (prevProps, nextProps) => {
  const arePropsEqual = isObjectEqualFromKeys(prevProps, nextProps, [
    "block",
    "activeBlockId",
  ]);

  return arePropsEqual;
};

// Memoize the Text component with custom comparison function
const MemoizedText = memo(Text, areEqual);

export default MemoizedText;
