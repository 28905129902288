import { useEffect, useState } from "react";

import { get } from "lodash";

function useHoverPosition(ref) {
  const [state, setState] = useState(null);

  useEffect(() => {
    function handleDragOver(event) {
      updatePosition(event);
    }

    function updatePosition(event) {
      const refCurrent = get(ref, "current", null);

      if (!refCurrent) return;

      const rect = refCurrent.getBoundingClientRect();
      const y = event.clientY - rect.top;
      const x = event.clientX - rect.left;

      const height = rect.height;
      const width = rect.width;

      const isInUpperHalf = y < height / 2;
      const isInLowerHalf = y >= height / 2;
      const isInLeftHalf = x < width / 2;
      const isInRightHalf = x >= width / 2;

      const isInMiddle50PercentVertically =
        y >= height / 4 && y <= (3 * height) / 4;
      const isInMiddle50PercentHorizontally =
        x >= width / 4 && x <= (3 * width) / 4;

      setState({
        isInLowerHalf,
        isInUpperHalf,
        isInRightHalf,
        isInLeftHalf,
        isInMiddle50PercentVertically,
        isInMiddle50PercentHorizontally,
      });
    }

    document.addEventListener("dragover", handleDragOver);

    return () => {
      document.removeEventListener("dragover", handleDragOver);
    };
  }, [ref]);

  return state;
}

export default useHoverPosition;
