import { Icon, Row, Text } from "app/components";
import { rCurrentStep, rDescription } from "../state";
import { useRecoilState, useSetRecoilState } from "recoil";

import { get } from "lodash";
import { rConfirmationModalData } from "app/utils/recoil";
import styled from "styled-components";

const prevStepMap = {
  sheet_columns: "spreadsheets",
  sample_records: "sheet_columns",
  pages: "sample_records",
  page_blocks: "pages",
  block_details: "page_blocks",
};

const Footer = ({ handleSubmit }) => {
  const [currentStep, setCurrentStep] = useRecoilState(rCurrentStep);
  const [description, setDescription] = useRecoilState(rDescription);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const charCount = get(description, "length", 0);

  const goToPreviousStep = () => {
    setConfirmationModalData({
      title: "Go To Previous Step",
      text: "This will erase any data from the current step. Are you sure you want to continue?",
      confirm: () => {
        const prevStep = get(prevStepMap, currentStep, null);
        setCurrentStep(prevStep);
      },
    });
  };

  const submitTextMap = {
    spreadsheets: "Next: Sheet Columns",
    sheet_columns: "Next: Sample Records",
    sample_records: "Next: Pages",
    pages: "Next: Page Blocks",
    page_blocks: "Preview App",
  };

  const submitText = get(submitTextMap, currentStep, "Next Step");

  return (
    <FooterContainer>
      <Row gap="20px">
        <BackButton>
          <Icon
            data={{
              icon: "FiArrowLeft",
              color: "white",
              hover: true,
              size: 22,
              margin: "2px 0 0 0",
            }}
          />
          <Text
            data={{
              text: "Previous Step",
              onClick: goToPreviousStep,
              fontStyle: "headingLg",
              color: "white",
              cursor: "pointer",
            }}
          />
        </BackButton>
        <NextButton onClick={handleSubmit}>
          <Text
            data={{
              text: submitText,
              fontStyle: "headingLg",
              color: "white",
              cursor: "pointer",
            }}
          />
          <Icon
            data={{
              icon: "FiArrowRight",
              color: "white",
              hover: true,
              size: 22,
              margin: "2px 0 0 0",
            }}
          />
        </NextButton>
      </Row>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ffffff40;
  position: fixed;
  bottom: 0;
  left: 250px;
  right: 0;
  @media screen and (max-width: 800px) {
    left: 0;
  }
`;

const NextButton = styled.div`
  background: rgb(0, 85, 255);
  display: flex;
  border: 2px solid white;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  width: fit-content;
  height: 60px;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}
  &:hover {
    filter: brightness(90%);
  }
`;

const BackButton = styled.div`
  border: 2px solid white;
  font-weight: 500;
  font-size: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  width: fit-content;
  height: 60px;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
`;
