import {
  hiddenFilters,
  label,
  labelColor,
  spreadsheetSelect,
  visibleFilters,
} from "./shared";

export const Timeline = {
  componentId: "Timeline",
  resources: [
    label,
    labelColor,
    {
      ...spreadsheetSelect,
      hint: "Select the spreadsheet to create new rows in with this Form",
    },
    {
      id: "leftContent",
      label: "Left Content",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      section: "timelineData",
      hint: "The spreadsheet column to use for left side content",
    },
    {
      id: "rightContent",
      label: "Right Content",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      section: "timelineData",
      hint: "The spreadsheet column to use for right side content",
    },
    {
      id: "sortingColumn",
      label: "Sorting Column",
      componentId: "SpreadsheetColumnSelect",
      section: "Sorting",
      requiresSheet: true,
    },
    {
      id: "sortingDirection",
      label: "Sorting Direction",
      section: "Sorting",
      requiresSheet: true,
      componentId: "Select",
      hideEmptyItem: true,
      defaultValue: "asc",
      options: [
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
    },
    visibleFilters,
    hiddenFilters,
  ],
};
