import { Button, Select, Text } from ".";

import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { useState } from "react";

const MakeScenarioSelect = ({ data }) => {
  const [scenarios, setScenarios] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchScenarios = () => {
    setIsFetching(true);
    apiRequest.get("/make_scenarios/").then((res) => {
      setScenarios(res.data);
      setIsFetching(false);
    });
  };

  return (
    <div>
      {scenarios.length > 0 ? (
        <Select
          data={{
            value: get(data, ["value", "id"], ""),
            label: "Scenario",
            onChange: (value) => {
              data.onChange(scenarios.find((s) => s.id === value));
            },
            hideEmptyItem: true,
            options: scenarios.map((s) => ({
              value: s.id,
              label: s.name,
            })),
          }}
        />
      ) : (
        <div>
          <Text
            data={{
              text: get(data, ["value", "name"], "Select Scenario"),
              fontStyle: "headingMd",
            }}
          />
          <Button
            data={{
              text: "Select Scenario",
              onClick: fetchScenarios,
              size: "small",
              type: "basic",
              margin: "5px 0 0 0",
              isFetching,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MakeScenarioSelect;
