import * as React from "react";

import { Menu } from ".";

const MuiDropdown = ({
  triggerComponent,
  setExpanded = null,
  children,
  closeCounter = 0,
  width,
  background,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setExpanded && setExpanded(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpanded && setExpanded(false);
  };

  // Prevent annoying click propagation
  const onClickAway = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  // Enable forced closes from parent
  React.useEffect(() => {
    if (closeCounter > 0) {
      setAnchorEl(null);
    }
  }, [closeCounter]);

  return (
    <div>
      <div onClick={(e) => e.stopPropagation()}>
        <div onClick={handleOpen}>{triggerComponent}</div>
      </div>
      <Menu
        background={background}
        hide={handleClose}
        padding="0px"
        anchorElement={anchorEl}
        width={width}
        onBlur={onClickAway}
        onClick={onClickAway}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {children}
      </Menu>
    </div>
  );
};

export default MuiDropdown;
