import { get, isEmpty } from "lodash";
import { getHighest, operatorLabelMap, operators } from "app/utils/utils";

import AdminForm from "../components/AdminForm";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { getConditionLabel } from "../components/DisplayConditions";
import { rApp } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import { useRecoilState } from "recoil";
import { useState } from "react";

const CustomVariables = () => {
  const [app, setApp] = useRecoilState(rApp);

  const [changes, setChanges] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const customVariables = get(app, "custom_variables", []) || [];

  const save = () => {
    setIsSaving(true);
    apiRequest.post("/app_settings/", changes).then((r) => {
      setIsSaving(false);
      successNotification("Saved");
      setChanges({});
    });
  };

  const newVarId = getHighest(customVariables, "id");

  let fields = [
    {
      id: "custom_variables",
      label: "Custom Variables",
      componentId: "MultiForm",
      labelSingular: "Variable",
      width: "300px",
      value: customVariables.map((v) => ({ ...v, label: get(v, "key") })),
      newObject: {
        key: `custom_variable${newVarId > 0 ? `_${newVarId}` : ""}`,
      },
      fields: [
        {
          key: "type",
          label: "Variable Type",
          componentId: "Select",
          hideEmptyItem: true,
          defaultValue: "exists",
          hint: "The type of value you want from the matching record",
          options: [
            {
              label: "Record exists (true/false)",
              value: "exists",
            },
            {
              label: "Get value from record",
              value: "recordField",
            },
          ],
        },
        {
          key: "sorting",
          label: "Priority",
          hint: "Determine how you want to prioritize records when there are more than one",
          componentId: "Select",
          defaultValue: "first",
          hideEmptyItem: true,
          options: [
            {
              label: "First Record",
              value: "first",
            },
            {
              label: "Last Record",
              value: "last",
            },
          ],
        },
        {
          key: "sortingColumn",
          label: "Sorting Column",
          hint: "Determine how you want to sort records when there are more than one",
          displayCondition: (f) => f.type === "recordField",
          componentId: "Select",
          isSpreadsheetSelect: true,
        },
        {
          key: "key",
          label: "Variable Key",
          componentId: "Input",
          hint: "The key you will reference this variable by, like {{ custom.your_key }}",
        },
        {
          key: "spreadsheet",
          label: "Spreadsheet",
          componentId: "SpreadsheetSelect",
          hint: "The spreadsheet to get the variable from",
        },
        {
          key: "conditions",
          label: "Conditions",
          componentId: "MultiForm",
          labelSingular: "Condition",
          getItemLabel: (item) =>
            getConditionLabel({
              value1: item.key,
              operator: item.operator,
              value2: item.value,
            }),
          width: "240px",
          value: [],
          newObject: {},
          fields: [
            {
              key: "key",
              label: "Field",
              hint: "Which column to use for matching the row",
              componentId: "Select",
              isSpreadsheetSelect: true,
            },
            {
              key: "operator",
              label: "Operator",
              componentId: "Select",
              defaultValue: "equals",
              options: operators.map((o) => ({
                label: get(operatorLabelMap, o),
                value: o,
              })),
            },
            {
              key: "value",
              label: "Value",
              hint: "The value to use in the row matchign condition",
              componentId: "DynamicString",
            },
          ],
        },
        {
          key: "recordField",
          label: "Record Field (Optional)",
          hint: "The field you want to display in the variable. If left blank, the entire record will be used and you can access fields like {{ custom.my_variable.field_name }} ",
          displayCondition: (f) => f.type === "recordField",
          componentId: "Select",
          isSpreadsheetSelect: true,
        },
      ],
    },
  ];

  return (
    <SettingsSection
      title="Custom Variables"
      description="Custom variables can be used for more complex situations"
    >
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="5px"
        fields={fields}
        submitText={"Save Changes"}
        submit={!isEmpty(changes) && save}
        width="220px"
        isFetching={isSaving}
        onChange={(k, v) => {
          setChanges({ ...changes, [k]: v });
          setApp({
            [k]: v,
          });
        }}
      />
    </SettingsSection>
  );
};

export default CustomVariables;
