import {
  Badge,
  Card,
  CardLabel,
  CardRow,
  CardText,
  Container,
  Header,
  Label,
} from "./Pages";
import {
  Button,
  Modal,
  PaginationWrapper,
  Row,
  SearchBar,
  SimpleDropdown,
} from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty, startCase } from "lodash";
import { rApps, rConfirmationModalData } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { AdminForm } from "../components";
import AdminWrapper from "./AdminWrapper";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import mixpanel from "mixpanel-browser";
import { truncateText } from "app/utils/utils";

export const Templates = () => {
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const [search, setSearch] = useState("");
  const [showNewTemplateModal, setShowNewTemplateModal] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    mixpanel.track("My Templates Page View");
    setIsFetching(true);
    apiRequest
      .get("/templates/")
      .then((response) => {
        const data = get(response, ["data", "templates"], []);
        setTemplates(data);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  const deleteTemplate = (templateId) =>
    setConfirmationModalData({
      title: "Delete Template",
      text: "Are you sure you want to delete this template? It will be removed from the marketplace immediately.",
      confirm: () => {
        successNotification("Template Deleted");
        setTemplates(templates.filter((a) => a.id !== templateId));
        apiRequest.delete("/templates/", {
          data: { id: templateId },
        });
      },
    });

  const filteredTemplates = templates.filter(
    (a) =>
      search === "" || a.name.includes(search) || a.subdomain.includes(search)
  );

  return (
    <AdminWrapper>
      {currentTemplate && (
        <TemplateModal
          template={currentTemplate}
          hide={() => setCurrentTemplate(null)}
          setTemplates={setTemplates}
          templates={templates}
        />
      )}
      {showNewTemplateModal && (
        <TemplateModal
          hide={() => setShowNewTemplateModal(false)}
          setTemplates={setTemplates}
          templates={templates}
        />
      )}
      <Row justifyContent="space-between">
        <Label>Templates</Label>
        <Button
          data={{
            text: "New Template",
            icon: "FiPlus",
            onClick: () => setShowNewTemplateModal(true),
          }}
        />
      </Row>
      <Container>
        <Header>
          <SearchBar
            data={{
              value: search,
              placeholder: "Search templates",
              onChange: (v) => setSearch(v),
            }}
          />
        </Header>

        <PaginationWrapper
          itemsPerPage={8}
          items={filteredTemplates}
          isFetching={false}
          noResultsName="templates"
          padding="16px"
          noResultsPadding="0 0 16px 16px"
        >
          {(paginatedTemplates) => {
            return paginatedTemplates.map((a) => {
              return (
                <Card onClick={() => setCurrentTemplate(a)}>
                  <CardRow>
                    <CardLabel>{a.name}</CardLabel>
                    <CardText>{truncateText(a.description, 30)}</CardText>
                    <Badge
                      color={"#AFE6F1"}
                      style={{ margin: "0 25px 0 15px" }}
                    >
                      {startCase(a.status)}
                    </Badge>
                  </CardRow>
                  <SimpleDropdown
                    data={{
                      options: [
                        {
                          label: "Delete",
                          onClick: () => deleteTemplate(a.id),
                        },
                      ],
                      icon: {
                        icon: "FiMoreHorizontal",
                        size: 25,
                        hover: true,
                        color: colors.grey3,
                      },
                    }}
                  />
                </Card>
              );
            });
          }}
        </PaginationWrapper>
      </Container>
    </AdminWrapper>
  );
};

export default Templates;

const TemplateModal = ({ hide, setTemplates, templates, template = null }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [errors, errorsSet] = useState({});

  const usedAppIds = templates.map((a) => a.app_id);

  const savedApps = useRecoilValue(rApps);

  const apps = savedApps
    .filter((a) => !usedAppIds.includes(a.id) || a.id === template?.app_id)
    .map((a) => ({
      value: a.id,
      label: a.name,
    }));

  const [formData, setFormData] = useState(
    template || {
      app_id: null,
      name: "New Template",
      description: "",
      image: "",
    }
  );

  const { app_id, name, description, image } = formData;

  const isFormValid = () => {
    let errors = {};

    if (!name) {
      errors["name"] = "Please enter a valid name.";
    } else if (name.length < 3) {
      errors["name"] = "Please enter a longer name.";
    }

    errors = { ...errors };

    errorsSet(errors);

    return isEmpty(errors);
  };

  const createTemplate = () => {
    if (!isFormValid()) return;

    setIsFetching(true);

    apiRequest
      .post("/templates/", formData)
      .then((response) => {
        const data = get(response, ["data", "templates"], []);
        const error = get(data, "error");

        if (error) {
          errorNotification(error);
        } else {
          successNotification("Template Created");
          setTemplates(data);
          hide();
          mixpanel.track("Template Created");
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  console.log("OPTIONS", {
    apps,
    app_id,
  });

  return (
    <Modal
      minWidth="400px"
      header={{ title: "Create New Template" }}
      hide={hide}
    >
      <AdminForm
        isFetching={isFetching}
        submit={createTemplate}
        errors={errors}
        submitText={template ? "Save Template" : "Create Template"}
        onChange={(k, v) =>
          setFormData({
            ...formData,
            [k]: v,
          })
        }
        sectionPadding="0px"
        fields={[
          {
            id: "app_id",
            componentId: "Select",
            label: "App",
            value: app_id,
            options: apps,
            hint: "The app to share as a Template",
          },
          {
            id: "name",
            componentId: "Input",
            label: "Name",
            value: name,
            hint: "A display name for your template",
          },
          {
            id: "description",
            componentId: "TextArea",
            label: "Description",
            value: description,
            hint: "A short description of your template",
          },
          {
            id: "image",
            componentId: "ImageUpload",
            label: "Image",
            value: image,
            hint: "An image to showcase your app in the templates marketplace",
          },
        ]}
      />
    </Modal>
  );
};
