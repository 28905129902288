export const Image = {
  componentId: "Image",
  resources: [
    {
      id: "url",
      key: "url",
      label: "Image URL",
      componentId: "DynamicString",
      required: true,
      section: "image",
      defaultValue: "",
    },
    {
      id: "height",
      label: "Height",
      type: "number",
      section: "size",
      isStyle: true,
      hint: "The height of the image in pixels",
      componentId: "Input",
      width: "120px",
      orientation: "horizontal",
    },
    {
      id: "showEmptyPlaceholder",
      label: "Show Empty Placeholder",
      section: "image",
      hint: "If there's no valid image URL, show a generic placeholder image",
      componentId: "Switch",
      orientation: "horizontal",
      defaultValue: true,
    },
    {
      id: "borderRadius",
      label: "Border Radius",
      type: "number",
      section: "border",
      isStyle: true,
      hint: "The border radius of the image in pixels (to create a curved edge)",
      componentId: "PaddingConfig",
    },
    {
      id: "objectFit",
      label: "Image Fit",
      type: "number",
      section: "image",
      isStyle: true,
      hint: "Determine how the image fits within the frame",
      componentId: "Select",
      defaultValue: "cover",
      options: [
        {
          label: "Contain",
          value: "contain",
        },
        {
          label: "Cover",
          value: "cover",
        },
        {
          label: "Fill",
          value: "fill",
        },
      ],
    },
    {
      id: "objectPosition",
      label: "Image Position",
      type: "number",
      section: "image",
      isStyle: true,
      hint: "Determine how the image fits within the frame",
      componentId: "Select",
      defaultValue: "cover",
      options: [
        {
          label: "Top",
          value: "top",
        },
        {
          label: "Center",
          value: "center",
        },
        {
          label: "Bottom",
          value: "bottom",
        },
        {
          label: "Right",
          value: "right",
        },
        {
          label: "Left",
          value: "left",
        },
      ],
    },
    {
      id: "clickAction",
      componentId: "Action",
      section: "clickAction",
      isAction: true,
    },
  ],
};
