import styled, { keyframes } from "styled-components";

import React from "react";
import { Spinner } from "app/components";

const Container = styled.div`
  position: relative;
  margin-top: 100px;
`;

const Block = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  opacity: 0;
  animation: ${(p) => moveBlock(p.x, p.y)} 5s forwards;
  animation-delay: ${(p) => p.delay}s;
  animation-iteration-count: infinite;
`;

const TinyDot = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: ${(p) => p.color || "#ffffff"};
  border-radius: 50%;
  opacity: 0;
  animation: ${(p) => moveDot(p.x, p.y)} 1s forwards;
  animation-delay: ${(p) => p.delay}s;
  animation-iteration-count: infinite;
`;

const moveBlock = (translateX, translateY) => keyframes`
  0% { transform: translate(${(p) =>
    p.direction === "left" ? "-1000px" : "1000px"}, 0); opacity: 0; }
  100% { transform: translate(${translateX}px, ${translateY}px); opacity: 1; }
`;

const moveDot = (translateX, translateY) => keyframes`
  0% { transform: translate(0, 0); opacity: 0; }
  100% { transform: translate(${translateX}px, ${translateY}px); opacity: 0.5; }
`;

const getNum = (x) => x * 40;

const blocksList = [
  { x: 0, y: 0 },
  { x: -1, y: 0 },
  { x: -1, y: -1 },
  { x: -2, y: 0 },
  { x: -2, y: -1 },
  { x: -2, y: -2 },
  { x: -2, y: 1 },
  { x: -1, y: 1 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },
];

const tinyDots = Array.from({ length: 50 }, (_, i) => ({
  x: Math.floor(Math.random() * 800) - 400,
  y: Math.floor(Math.random() * 600) - 300,
  delay: i * 0.1,
}));

export const LoadingAnimation = ({
  blocks,
  dots,
  spinner,
  color = "white",
}) => (
  <Container>
    {spinner && <Spinner color={color} size={50} padding="0 0 0 0" />}
    {blocks &&
      blocksList.map((block, i) => (
        <Block x={getNum(block.x)} y={getNum(block.y)} delay={i * 1.5} />
      ))}
    {dots &&
      tinyDots.map((dot, i) => (
        <TinyDot x={dot.x} y={dot.y} delay={dot.delay} color={color} />
      ))}
    {/* <SpaceShip src="https://res.cloudinary.com/frontly/image/upload/v1692924022/spaceship_nm62yd.svg" /> */}
  </Container>
);

export default LoadingAnimation;

const SpaceShip = styled.img`
  position: absolute;
  height: 50px;

  /* CSS animation for shaking */
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-2px, 1px) rotate(1deg);
    }
    30% {
      transform: translate(1px, -1px) rotate(0deg);
    }
    40% {
      transform: translate(1px, 2px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 1px) rotate(-1deg);
    }
    60% {
      transform: translate(-1px, 2px) rotate(0deg);
    }
    70% {
      transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  animation: shake 0.2s infinite;
`;
