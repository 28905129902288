import { Button } from "app/components";
import { fixBlocks } from "app/utils/utils";
import { get } from "lodash";
import { rActiveDetailViewId } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";

const RemoveFromLayout = () => {
  const activeBlock = useActiveBlock();
  const page = usePage();

  const { setPage } = useSetPage();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const parentId = get(activeBlock, "layoutParent", null);

  const blocks = fixBlocks(get(page, "blocks", []));

  const parentBlock = blocks.find((b) => b.id === parentId);

  const remove = () => {
    const newBlocks = blocks.map((b) => {
      if (b.id === activeBlock.id) {
        let newBlock = {
          ...b,
          layoutParent: null,
          parent: null,
        };

        if (activeDetailViewId) {
          newBlock["parent"] = activeDetailViewId;
        }

        return newBlock;
      }

      return b;
    });

    setPage({
      ...page,
      blocks: newBlocks,
    });

    successNotification("Removed from layout");
  };

  return (
    <Button
      data={{
        text: `Remove From ${get(parentBlock, "componentId")}`,
        onClick: remove,
        icon: "FiCopy",
        type: "basic",
        size: "small",
      }}
    />
  );
};

export default RemoveFromLayout;
