import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";

import { AdminForm } from "../components";
import { Button } from "app/components";
import SettingsSection from "../settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { rApp } from "app/utils/recoil";
import { useRecoilState } from "recoil";
import { useState } from "react";

const CustomFields = () => {
  const [app, setApp] = useRecoilState(rApp);

  const userSchema = get(app, "user_schema", []) || [];

  const [changes, setChanges] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const save = () => {
    const emptyFields = userSchema.filter(
      (field) => !field || isEmpty(field) || field.name === ""
    );

    if (emptyFields.length > 0) {
      errorNotification(
        "You cannot save with incomplete fields. Please correct this to save."
      );
    } else {
      if (
        userSchema.filter((s) =>
          [
            "id",
            "first_name",
            "last_name",
            "email",
            "is_owner",
            "role",
            "user_groups",
            "user_group",
            "app_id",
            "enabled_apps",
            "can_create_apps",
            "password",
            "organization_id",
            "organization",
            "custom_data",
          ].includes(s.key)
        ).length > 0
      ) {
        errorNotification(
          "Core User Fields are protected and cannot be used in custom fields. Try another field name."
        );
      } else {
        setChanges(false);
        setIsSaving(true);

        apiRequest
          .post("/app_settings/", {
            user_schema: userSchema,
          })
          .then(() => {
            setIsSaving(false);
          });
        successNotification("Custom User Fields Saved");
      }
    }
  };

  const fieldKeys = [
    {
      key: "name",
      label: "Name",
      placeholder: "Team",
      componentId: "Input",
      width: "100%",
    },
    {
      key: "type",
      componentId: "Select",
      label: "Type",
      width: "100%",
      hint: "This determines the type of input field that will be displayed if these fields are shown to the user.",
      defaultValue: "Input",
      options: [
        { label: "Plain Text", value: "Input" },
        { label: "Select", value: "Select" },
        { label: "Date", value: "DateTimePicker" },
        { label: "Boolean (true/false)", value: "Switch" },
      ],
    },
    {
      key: "options",
      label: "Select Options",
      componentId: "DataGrid",
      orientation: "vertical",
      hint: "Define the options to appear in your select dropdown.",
      requiresSheet: true,
      newObject: { label: "New Item", value: "New Value" },
      orientation: "vertical",
      columns: [
        {
          key: "label",
          componentId: "Input",
        },
        {
          key: "value",
          componentId: "Input",
        },
      ],
      displayCondition: (f) => f.type === "Select",
    },
    {
      key: "defaultValue",
      label: "Default Value",
      placeholder: "None",
      width: "100%",
      componentId: "Input",
    },
    {
      key: "includeInSignup",
      label: "Include in Signup Form",
      width: "100%",
      componentId: "Switch",
      defaultValue: false,
    },
    {
      key: "editableByUser",
      label: "Editable By User",
      hint: "This field will be visible and editable by the user in the 'Edit Profile' page.",
      width: "100%",
      componentId: "Switch",
      defaultValue: false,
    },
  ];

  const fields = [
    {
      id: "user_schema",
      label: "Custom User Fields",
      componentId: "MultiForm",
      labelSingular: "Field",
      width: "300px",
      getItemLabel: (item) => get(item, "name", "New Field"),
      value: userSchema,
      fields: fieldKeys,
    },
  ];

  return (
    <SettingsSection
      title="Custom User Fields"
      description="Add custom fields to your user profiles"
      width="400px"
    >
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="5px"
        fields={fields}
        submitText={"Save Changes"}
        submit={!isEmpty(changes) && save}
        width="220px"
        isFetching={isSaving}
        onChange={(k, v) => {
          setChanges(true);
          setApp({
            user_schema: v,
          });
        }}
      />

      {changes && (
        <Button
          data={{
            margin: "20px 0 0 0",
            text: "Save",
            onClick: save,
          }}
        />
      )}
    </SettingsSection>
  );
};

export default CustomFields;
