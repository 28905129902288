import { Button, Column, Modal, Row, SearchBar } from "app/components";
import { get, truncate } from "lodash";

import CardGrid from "../components/CardGrid";
import { AdminForm, CustomBlock } from "../components";
import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import { Label } from "../home/Pages";
import { rCustomBlocks } from "app/utils/recoil";
import { getHighest, safeArray } from "app/utils/utils";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useState } from "react";
import { successNotification } from "app/utils/Notification";
import { apiRequest } from "app/utils/apiRequests";

const CustomBlocks = () => {
  const navigate = useNavigate();

  const [customBlocks, setCustomBlocks] = useRecoilState(rCustomBlocks);

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  const [search, setSearch] = useState("");

  const blocks = safeArray(customBlocks);

  const filteredBlocks = blocks.filter(
    (b) =>
      get(b, "name", "").toLowerCase().includes(search.toLowerCase()) ||
      get(b, "description", "").toLowerCase().includes(search.toLowerCase())
  );

  let fields = [
    {
      id: "name",
      label: "Name",
      description: "Give your custom block a memorable name",
      value: get(formData, "name", ""),
    },
  ];

  const saveAsCustomBlock = () => {
    let newData = {
      blocks: [
        {
          id: 1,
          componentId: "Column",
        },
      ],
    };

    const newBlock = {
      id: getHighest(safeArray(customBlocks), "id") + 1,
      ...newData,
      ...formData,
    };

    const newBlocks = [...safeArray(customBlocks), newBlock];

    setCustomBlocks(newBlocks);

    setFormData({});

    successNotification("Saved as custom block");

    setShowModal(false);

    apiRequest.post("/app_settings/", {
      custom_blocks: newBlocks,
    });

    navigate(`/custom-block/${newBlock.id}`);
  };

  return (
    <div>
      {showModal && (
        <Modal
          minWidth="400px"
          hide={() => setShowModal(false)}
          header={{
            title: "New Custom Block",
          }}
        >
          <Column gap="15px">
            <AdminForm
              sectionPadding={"0px"}
              onChange={(k, v) =>
                setFormData({
                  ...formData,
                  [k]: v,
                })
              }
              fields={fields}
            />
            <Button
              data={{
                text: "Create Custom Block",
                onClick: saveAsCustomBlock,
              }}
            />
          </Column>
        </Modal>
      )}
      <Row
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 30px 0"
      >
        <Label>Custom Blocks</Label>
        <Row alignItems="center" gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search custom blocks",
              onChange: (v) => setSearch(v),
            }}
          />
          <CustomBlock isNew />
        </Row>
      </Row>

      {blocks.length === 0 && (
        <EmptyStateDashboard
          title="You don't have any custom blocks"
          subtitle="Custom blocks are reusable components that you can use in your app. Get started by clicking the button below."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716244997/Create_ljrvzs.svg"
          }
          buttonText="New Custom Block"
          onClick={() => setShowModal(true)}
        />
      )}

      {filteredBlocks.length > 0 && (
        <CardGrid
          noResultsName={"custom blocks"}
          items={filteredBlocks.map((b) => ({
            title: b.name,
            description: truncate(b.description, { length: 60 }),
            onClick: () => navigate(`/custom-block/${b.id}`),
            // badges: [
            //   {
            //     color: p.published ? "#b8e7d2" : "#AFE6F1",
            //     text: p.published ? "Published" : "Draft",
            //   },
            // ],
            options: [
              {
                label: "Edit Block",
                icon: "FiEdit",
                onClick: () => navigate(`/custom-block/${b.id}`),
              },
            ],
          }))}
          itemsPerPage={25}
        />
      )}
    </div>
  );
};

export default CustomBlocks;
