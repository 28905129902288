import { AppButton, AppButtonsRow } from "../styles";
import { Icon, Text } from "app/components";

import { Main } from "../components";
import { colors } from "app/utils/theme";
import { rDescription } from "../state";
import { useRecoilState } from "recoil";

// Error Page
const Error = ({ handleSubmit, resetApp, error }) => {
  const [description, setDescription] = useRecoilState(rDescription);

  return (
    <Main
      label="Oops! An error occurred."
      description={
        error ||
        "AI can be weird sometimes. Please try running the request again, or reset to start fresh."
      }
    >
      <AppButtonsRow>
        {description.length >= 10 && (
          <AppButton onClick={handleSubmit}>
            <Icon
              data={{
                icon: "FiPlay",
                color: "#d0d3d6",
                size: 45,
                hover: true,
              }}
            />
            <Text
              data={{
                text: "Run Request Again",
                fontStyle: "headingLg",
                color: colors.grey4,
                cursor: "pointer",
              }}
            />
          </AppButton>
        )}
        <AppButton onClick={resetApp}>
          <Icon
            data={{
              icon: "FiRefreshCcw",
              color: "#d0d3d6",
              size: 45,
              hover: true,
            }}
          />
          <Text
            data={{
              text: "Reset & Start Fresh",
              fontStyle: "headingLg",
              color: colors.grey4,
              cursor: "pointer",
            }}
          />
        </AppButton>
      </AppButtonsRow>
    </Main>
  );
};

export default Error;
