export const TextButtonRow = {
  componentId: "TextButtonRow",
  resources: [
    {
      id: "text",
      label: "Text",
      componentId: "DynamicString",
      section: "content",
      defaultValue: "New Button",
    },
    {
      id: "actions",
      label: "Buttons",
      componentId: "MultiActions",
      isAction: true,
      isMultiAction: true,
    },
  ],
};
