import { Icon } from ".";
import styled from "styled-components";

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(p) => p.color};
  cursor: pointer;
`;

const CheckIcon = ({ backgroundColor, icon, ...props }) => {
  return (
    <CheckIconWrapper color={backgroundColor}>
      <Icon data={{ icon, color: "white", size: "12px", hover: true }} />
    </CheckIconWrapper>
  );
};

export default CheckIcon;
