import { euroToFloat, parseNumber } from "app/utils/utils";
import { rApp, rAppDateFormat } from "app/utils/recoil";

import BarHorizontal from "./BarHorizontal";
import BarVertical from "./BarVertical";
import Pie from "./Pie";
import { get } from "lodash";
import { groupBy } from "./utils";
import { sortRecords } from "app/renderingApp/blocks/Table";
import useActionResolver from "app/renderingApp/useActionResolver";
import useDynamicText from "app/renderingApp/useDynamicText";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import { useRecoilValue } from "recoil";
import useUtils from "app/renderingApp/useUtils";

const Chart = ({ block, page }) => {
  const { processObjects } = useProcessObjects();

  const activeApp = useRecoilValue(rApp);

  const { getListData } = useListData();

  const data = getListData(block);

  const { recordClick } = useUtils();
  const { processDynamicText } = useDynamicText();
  const { actionResolver } = useActionResolver(page);

  // Disable click action entirely if groupByColumn is set?
  const itemClickAction = (item) =>
    groupByColumn ? null : recordClick(block, item, actionResolver);

  const finalFields = [
    {
      mapKey: "valueKey", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "valueKey"),
    },
    {
      mapKey: "labelKey", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "labelKey"),
    },
  ];

  let items = processObjects(
    page,
    block,
    finalFields,
    data,
    processDynamicText
  );

  const displayField = get(block, "labelKey");
  const field = get(block, "valueKey");
  const groupByMetric = get(block, "groupByMetric");
  const groupByColumn = get(block, "groupByColumn");
  const type = get(block, "chartType", "Bar");
  const isEuroDecimal = get(activeApp, "number_format") === "euro";

  const appDateFormat = useRecoilValue(rAppDateFormat);

  // Regular chart data
  items = items.map((v) => ({
    ...v,
    label: get(v, displayField),
    value: isEuroDecimal
      ? euroToFloat(get(v, field))
      : parseNumber(get(v, field)),
  }));

  // Grouped values
  if (groupByColumn) {
    const convertedValues = groupBy(items, groupByColumn, field).filter(
      (v) => get(v, "key") !== ""
    );
    items = convertedValues.map((v) => ({
      ...v,
      label: get(v, "key"),
      value: get(v, groupByMetric),
    }));
  }

  const sorting = get(block, "sorting");

  // If groupbyColumn is set, then allow value/key choice, otherwise only value
  const sortBy = groupByColumn ? get(block, "sortBy", "value") : "value";

  if (sorting) {
    // Sort rows
    items = sortRecords(items, sortBy, sorting, null, {}, appDateFormat);
  }

  if (["Bar", "BarChart"].includes(type)) {
    // HORIZONTAL BAR CHART
    const variant = get(block, "variant", "Horizontal");
    if (variant === "Horizontal") {
      return (
        <BarHorizontal data={{ ...block, values: items, itemClickAction }} />
      );
    }
    if (variant === "Vertical") {
      return (
        <BarVertical data={{ ...block, values: items, itemClickAction }} />
      );
    }
  }

  if (["Pie", "PieChart"].includes(type)) {
    return <Pie data={{ ...block, values: items, itemClickAction }} />;
  }
};

export default Chart;
