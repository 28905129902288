import { get } from "lodash";
import { rActiveBlockId } from "./recoil";
// import { useMemo } from "react";
import usePage from "./usePage";
import { useRecoilValue } from "recoil";

const useActiveBlock = () => {
  const page = usePage();
  const blocks = get(page, "blocks", []);
  const activeBlockId = useRecoilValue(rActiveBlockId);

  // const memoizedActiveBlock = useMemo(() => {
  //   const activeBlock = blocks.find((b) => b.id === activeBlockId);
  //   return activeBlock;
  // }, [activeBlockId]);

  // return memoizedActiveBlock;

  return blocks.find((b) => b.id === activeBlockId);
};

export default useActiveBlock;
