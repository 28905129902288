export const Placeholder = {
  componentId: "Placeholder",
  resources: [
    {
      id: "height",
      label: "Height",
      // type: "number",
      section: "size",
      isStyle: true,
      hint: "The height of the placeholder in pixels",
      componentId: "Input",
      width: "120px",
      orientation: "horizontal",
    },
  ],
};
