import { Drawer, Icon, Menu, Row, Switch, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";
import { get, startCase } from "lodash";
import {
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
  rAppDateFormat,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "./AdminForm";
import { getFormFields } from "app/renderingApp/blocks/Form/utils";
import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveBlockSheet from "app/utils/useActiveBlockSheet";
import useDynamicText from "app/renderingApp/useDynamicText";

const GenerateRecordsFieldEditor = ({ data }) => {
  const [editField, setEditField] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const menuStyle = "popup";

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const activeBlockSheet = useActiveBlockSheet();

  const activeBlock = useActiveBlock();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const app = useRecoilValue(rApp);

  const [activeEditField, setActiveEditField] =
    useRecoilState(rActiveEditField);

  useEffect(() => {
    setShowAdvanced(false);
  }, [activeEditField]);

  const { processDynamicText } = useDynamicText();

  const fieldBlockId = get(activeEditField, "blockId");

  const matchesFormContext = () => {
    const context1 = get(activeEditField, "formContext");
    const context2 = get(data, "formContext");

    if (context1 || context2) {
      return context1 === context2;
    }

    return true;
  };

  const matchesContext = matchesFormContext();

  const matchesBlock =
    matchesContext &&
    (fieldBlockId === activeDetailViewId ||
      (fieldBlockId === get(activeBlock, "id") &&
        fieldBlockId === get(data, "block")));

  useEffect(() => {
    if (activeEditField && matchesBlock) {
      setEditField(activeEditField.id);
      setAnchorElement(activeEditField.target);
    }
  }, [activeEditField]);

  useEffect(() => {
    if (activeEditField && !matchesBlock) {
      setActiveEditField(null);
    }
  }, [activeEditField]);

  // Pass in customSpreadsheetId to use instead of active block
  const customSpreadsheetId = get(data, "customSpreadsheetId");

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const sheet = savedSpreadsheets.find((s) => s.id === customSpreadsheetId);

  // LOCAL FIELD DATA
  const value = get(data, "value", {});
  const config = get(value, "config", {});
  const localOrder = get(value, "order", []);

  const headers = get(sheet, "headers", []);

  const { displayFields } = getFormFields({
    appDateFormat,
    processDynamicText,
    sheetOrder: headers,
    localOrder: headers,
    sheetHeaders: headers,
    sheetFieldData: config,
    localFieldData: config,
    showInactive: true,
  });

  const updateField = (fieldKey, key, value) => {
    let matchingField = { ...get(config, fieldKey, {}), [key]: value };

    data.onChange({
      config: { ...config, [fieldKey]: matchingField },
      order: localOrder,
    });
  };

  const activeField = get(config, editField);

  const fields = [
    {
      id: "value",
      label: "Value",
      componentId: "DynamicString",
      value: get(activeField, "value"),
    },
    {
      id: "description",
      label: "Description",
      componentId: "DynamicString",
      value: get(activeField, "description"),
    },
  ];

  const editFields = fields
    .filter((f) => !f.advanced || showAdvanced)
    .filter(
      (f) =>
        !f.displayCondition ||
        (f.displayCondition &&
          f.displayCondition(activeField, editField, {
            app,
            sheetId: get(activeBlockSheet, "id"),
          }))
    );

  const [expanded, setExpanded] = useState(data.expanded);

  const MenuContainer = menuStyle === "drawer" ? Drawer : Menu;

  // Drawer
  const drawerProps = {
    open: true,
    width: "300px",
    hide: () => {
      setEditField(null);
      setActiveEditField(null);
    },
    label: startCase(editField),
    background: colors.grey1,
  };

  // Menu
  const menuProps = {
    anchorElement,
    hide: () => {
      setAnchorElement(null);
      setEditField(null);
      setActiveEditField(null);
    },
    label: startCase(editField),
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  const popupProps = menuStyle === "drawer" ? { data: drawerProps } : menuProps;

  const hasAdvancedSettings = fields.filter((f) => f.advanced).length > 0;

  return (
    <div>
      {editField && (
        <MenuContainer {...popupProps}>
          <AdminForm
            sectionPadding="0px"
            fields={editFields}
            editField={editField}
            onChange={(k, v) => updateField(editField, k, v)}
          />

          {hasAdvancedSettings && (
            <Text
              data={{
                text: `${showAdvanced ? "Hide" : "Show"} Advanced Settings`,
                color: colors.primary,
                margin: "10px 0 0 0",
                fontStyle: "headingSm",
                onClick: () => setShowAdvanced(!showAdvanced),
              }}
            />
          )}
        </MenuContainer>
      )}

      {displayFields.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: `1px solid ${colors.grey2}`,
            background: "white",
          }}
        >
          {displayFields
            .filter((f, i) => expanded || i < 4)
            .map((f) => (
              <Field
                key={f.key}
                field={f}
                setAnchorElement={setAnchorElement}
                setEditField={setEditField}
                updateField={updateField}
                getDescription={data.getDescription}
              />
            ))}
          {displayFields.length > 4 && !expanded && (
            <FieldContainer onClick={() => setExpanded(true)}>
              <Row alignItems="center">
                <Icon
                  data={{
                    icon: "FiPlus",
                    color: colors.grey2,
                    size: 18,
                  }}
                />
                <Text
                  data={{
                    text: `Show ${displayFields.length - 4} More`,
                    cursor: "pointer",
                    margin: "0 3px 0 3px",
                    fontStyle: "headingSm",
                  }}
                />
              </Row>
            </FieldContainer>
          )}
        </div>
      )}
      {displayFields.length === 0 && <Text data={{ text: "No Fields" }} />}
    </div>
  );
};

export default GenerateRecordsFieldEditor;

const Field = ({
  field,
  setAnchorElement,
  setEditField,
  updateField,
  getDescription,
}) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <FieldContainer
      disabled={!field.active}
      onMouseEnter={() => {
        if (field.active && !showEdit) {
          setShowEdit(true);
        }
      }}
      onMouseLeave={() => {
        if (field.active && showEdit) {
          setShowEdit(false);
        }
      }}
      onClick={(e) => {
        if (field.active) {
          setAnchorElement(e.currentTarget);
          setEditField(field.key);
        }
      }}
    >
      <Row alignItems="center">
        <Icon
          data={{
            icon: "RxDragHandleDots2",
            color: colors.grey2,
            size: 18,
          }}
        />
        <Text
          data={{
            text: `${startCase(field.key)} ${
              field.customValue ? "(Custom Value)" : ""
            }`,
            cursor: "pointer",
            margin: "0 3px 0 3px",
          }}
        />
        {getDescription && (
          <Text
            data={{
              text: getDescription(field),
              cursor: "pointer",
              margin: "0 3px 0 3px",
              color: colors.grey3,
            }}
          />
        )}
        {showEdit && (
          <Icon
            data={{
              icon: "FiEdit",
              color: colors.grey3,
              size: 14,
            }}
          />
        )}
      </Row>
      <Switch
        data={{
          value: field.active,
          onChange: (v) => updateField(field.key, "active", v),
        }}
      />
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  padding: ${spacing.s2} 0px ${spacing.s2} 0px;
  border-bottom: 1px solid ${colors.grey2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: 200ms all;
  &:hover {
    background: ${colors.grey1};
  }
  :not(:last-child) {
    border-bottom: 1px solid ${colors.grey1};
  }
`;
