import { Input, Modal, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { rNewDataSourceModalData, rSavedSpreadsheets } from "app/utils/recoil";

import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import { useRecoilState } from "recoil";
import { useState } from "react";

const Supabase = () => {
  const [data, setData] = useRecoilState(rNewDataSourceModalData);
  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const [isImporting, setIsImporting] = useState(false);

  const importSupabaseTable = () => {
    setIsImporting(true);

    apiRequest
      .post("/import_supabase_table/", {
        table: get(data, "table"),
      })
      .then((response) => {
        const resData = get(response, "data", {});
        const error = get(resData, "error");

        if (error) {
          errorNotification(error);
          setIsImporting(false);
          return;
        }

        const newTable = get(resData, "data_source");

        setSavedSpreadsheets([...savedSpreadsheets, newTable]);

        successNotification("Import successful");
        setIsImporting(false);
        mixpanel.track("Import Supabase Table");
        setData(null);
      });
  };

  return (
    <Modal
      minWidth="400px"
      header={{ title: "Add Supabase Table" }}
      buttons={[
        {
          text: "Import Table",
          onClick: importSupabaseTable,
          isFetching: isImporting,
        },
      ]}
      hide={() => setData(null)}
    >
      <Text
        data={{
          text: "Enter the exact name of your Supabase table (case sensitive)",
          margin: "0 0 20px 0",
        }}
      />
      <Input
        data={{
          label: "Supabase Table Name",
          placeholder: "products",
          required: true,
          width: "100%",
          value: get(data, "table"),
          onChange: (value) => setData({ ...data, table: value }),
        }}
      />
    </Modal>
  );
};

export default Supabase;
