// OLD LAYOUTS

const layout1 = {
  cols12: "1fr 1fr",
  cols8: "1fr",
  items: [
    {
      i: 1,
    },
    { i: 2 },
    { i: 3 },
    { i: 4 },
    { i: 5 },
    { i: 6 },
  ],
};

const layout2 = {
  cols12: "1fr 1fr 1fr",
  cols8: "1fr 1fr",
  cols4: "1fr",
  items: [
    {
      i: 1,
    },
    { i: 2 },
    { i: 3 },
    { i: 4 },
    { i: 5 },
    { i: 6 },
  ],
};

// SIMPLE SIDE BY SIDE
const layout3 = {
  cols12: "1fr 1fr 1fr",
  cols8: "1fr 1fr",
  cols4: "1fr",
  items: [
    {
      i: 1,
      col12: "span 3",
      col8: "span 2",
      col4: "span 1",
    },
    {
      i: 2,
    },
    { i: 3 },
    {
      i: 4,
      col8: "span 2",
      col4: "span 1",
    },
  ],
};

const layout6 = {
  cols12: "1fr 1fr",
  cols8: "1fr",
  items: [{ i: 1 }, { i: 2 }, { i: 3, col12: "span 2" }],
};

// SIMPLE SIDE BY SIDE
const layout7 = {
  cols12: "1fr 1fr 1fr",
  cols8: "1fr 1fr",
  cols4: "1fr",
  items: [
    {
      i: 1,
    },
    { i: 2 },
    {
      i: 3,
      col8: "span 2",
      col4: "span 1",
    },
    {
      i: 4,
      col12: "span 3",
      col8: "span 2",
      col4: "span 1",
    },
  ],
};

// NEW LAYOUTS

const layout4 = {
  cols12: "1fr 1fr",
  cols8: "1fr",
  items: [{ i: 1, row12: "span 2" }, { i: 2 }, { i: 3 }],
};

const layout5 = {
  cols12: "1fr 1fr",
  cols8: "1fr",
  items: [{ i: 1 }, { i: 2, row12: "span 2" }, { i: 3 }],
};

const layout8 = {
  cols12: "1fr 1fr",
  cols4: "1fr",
  items: [
    {
      i: 1,
    },
    { i: 2 },
  ],
};

const layout9 = {
  cols12: "1fr 1fr 1fr",
  cols8: "1fr 1fr 1fr",
  cols4: "1fr",
  items: [
    {
      i: 1,
    },
    { i: 2 },
    { i: 3 },
  ],
};

const layout10 = {
  cols12: "1fr 1fr",
  cols8: "1fr",
  items: [{ i: 1, row12: "span 3" }, { i: 2 }, { i: 3 }, { i: 4 }],
};

const layout11 = {
  cols12: "1fr 1fr",
  cols8: "1fr",
  items: [{ i: 1 }, { i: 2, row12: "span 3" }, { i: 3 }, { i: 4 }],
};

export const layouts = {
  // OLD - UNUSED
  1: layout1,
  2: layout2,
  3: layout3,
  6: layout6,
  7: layout7,

  // GOOD - NEW LAYOUTS
  4: layout4, // Big one on the left, 2 small ones in a column on the right
  5: layout5, // Big one on the right, 2 small ones in a column on the left
  8: layout8, // Row of 2
  9: layout9, // Row of 3
  10: layout10, // Big one on the left, 3 small ones in a column on the right
  11: layout11, // Big one on the right, 3 small ones in a column on the left
};

export const layoutOptions = [
  {
    label: `Layout 1`,
    value: 8,
  },
  {
    label: `Layout 2`,
    value: 9,
  },
  {
    label: `Layout 3`,
    value: 4,
  },
  {
    label: `Layout 4`,
    value: 5,
  },
  {
    label: `Layout 5`,
    value: 10,
  },
  {
    label: `Layout 6`,
    value: 11,
  },
];
