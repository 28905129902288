import { Button, Row, Text } from "app/components";
import { rApp, rConfirmationModalData, rPreviewApp } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import ClientNavigation from "../components/Navigation/ClientNavigation";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SetupMode from "app/renderPage/SetupMode";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import styled from "styled-components";
import usePage from "app/utils/usePage";

const AIPreview = ({ finalize, reset }) => {
  const page = usePage();

  const [app, setApp] = useRecoilState(rApp);

  const [isCheckingConnection, setIsCheckingConnection] = useState(false);

  const navigation = get(app, "navigation", {});
  const navigationType = get(navigation, "type", "sidebar");

  const setPreviewApp = useSetRecoilState(rPreviewApp);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const googleConnected = get(app, "google_sheets_connected");

  useEffect(() => {
    if (googleConnected) {
      setIsCheckingConnection(true);
      apiRequest.post("/check_google_connection/").then((res) => {
        const resData = get(res, "data", {});
        const connectionStatus = get(resData, "status");
        setIsCheckingConnection(false);

        if (connectionStatus === "INVALID") {
          setApp({
            google_sheets_connected: false,
          });
        }
      });
    }
  }, []);

  return (
    <Container>
      <Body>
        <PreviewPanel>
          <div>
            <Text
              data={{
                text: "Preview Your App",
                fontStyle: "heading2xl",
                color: "white",
              }}
            />
            <Description>
              Some features are not functional in preview.
            </Description>
          </div>

          <Row gap="15px" alignItems="center">
            <DesktopButtons>
              {/* <PalettePicker data={{ onChange: () => console.log("test") }} /> */}
              <Button
                data={{
                  text: "Start Again",
                  size: "large",
                  icon: "FiRefreshCcw",
                  type: "hollowWhite",
                  border: "2px solid rgba(255, 255, 255, 0.5)",
                  onClick: () =>
                    setConfirmationModalData({
                      title: "Reset App And Start Again?",
                      text: "Are you sure you want to reset? This will clear your current app.",
                      confirm: () => {
                        reset();
                        setPreviewApp(null);
                      },
                    }),
                }}
              />
              <Button
                data={{
                  text: "Finalize App",
                  size: "large",
                  backgroundColor: "rgb(0, 85, 255)",
                  icon: "FiCheckCircle",
                  onClick: finalize,
                  flippedIcon: true,
                  border: "2px solid white",
                }}
              />
            </DesktopButtons>
            <MobileButtons>
              <Button
                data={{
                  text: "Start Again",
                  icon: "FiRefreshCcw",
                  type: "basic",
                  type: "hollowWhite",
                  border: "2px solid white",
                  onClick: () =>
                    setConfirmationModalData({
                      title: "Reset App?",
                      text: "Are you sure you want to reset? This will clear your current app.",
                      confirm: () => {
                        reset();
                        setPreviewApp(null);
                      },
                    }),
                }}
              />
              <Button
                data={{
                  text: "Finalize App",
                  icon: "FiSave",
                  onClick: finalize,
                  flippedIcon: true,
                  disabled: isCheckingConnection,
                  isFetching: isCheckingConnection,
                  border: "2px solid white",
                }}
              />
            </MobileButtons>
          </Row>
        </PreviewPanel>
        <PreviewContainer>
          <PreviewContent navigationType={navigationType}>
            <ClientNavigation isPreview={true} />
            <DndProvider backend={HTML5Backend}>
              <SetupMode
                page={page}
                availableWidth={"1000px"}
                isPreview={true}
              />
            </DndProvider>
          </PreviewContent>
        </PreviewContainer>
      </Body>
    </Container>
  );
};

export default AIPreview;

const DesktopButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const MobileButtons = styled.div`
  display: none;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 800px) {
    display: flex;
  }
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: #ffffff99;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const PreviewPanel = styled.div`
  width: 100%;
  height: 80px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 32px 15px 32px;
  border-bottom: 1px solid #ffffff40;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: 120px;
  }
`;

const Container = styled.div`
  padding: 0px;
  position: fixed;
  top: 0;
  right: 0;
  left: 250px;
  bottom: 0;
  background: linear-gradient(
    236deg,
    rgb(170, 0, 255) 0%,
    rgb(0, 85, 255) 100%
  );
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PreviewContainer = styled.div`
  padding: 32px 32px 32px 32px;
  height: calc(100% - 78px);
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: calc(100% - 140px);
  }
`;

const PreviewContent = styled.div`
  border-radius: 12px 12px 12px 12px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  background: #f6f6f7;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  ${(p) => p.navigationType === "header" && "flex-direction: column;"}
`;
