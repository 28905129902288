import { useLocation, useNavigate } from "react-router-dom";

import CustomFields from "./CustomFields";
import EditProfileSettings from "./EditProfileSettings";
import { SettingsWrapper } from "../settings/components/SettingsWrapper";
import SignupSettings from "./SignupSettings";
import UserGroups from "./UserGroups";
import UsersList from "./UsersList";
import UsersSpreadsheet from "./UsersSpreadsheet";
import { getUrlParameter } from "app/utils/utils";

const Settings = () => {
  const location = useLocation();
  const tab = getUrlParameter("tab", location) || "usersList";
  const navigate = useNavigate();

  let sections = [
    {
      id: "usersList",
      label: "Manage Users",
      component: UsersList,
      icon: "FiUsers",
    },
    {
      id: "signupSettings",
      label: "Signup Settings",
      component: SignupSettings,
      icon: "FiSettings",
    },
    {
      id: "userGroups",
      label: "User Groups",
      component: UserGroups,
      icon: "FiGrid",
    },
    {
      id: "usersSheet",
      label: "Users Spreadsheet",
      component: UsersSpreadsheet,
      icon: "FiFile",
    },
    {
      id: "customFields",
      label: "Custom Fields",
      component: CustomFields,
      icon: "FiList",
    },
    {
      id: "editProfile",
      label: "Edit Profile",
      component: EditProfileSettings,
      icon: "FiEdit",
    },
  ].map((s) => ({
    ...s,
    onClick: () => navigate(`/users?tab=${s.id}`),
  }));

  const currentSection = sections.find((section) => section.id === tab);
  const Component = currentSection.component;

  return (
    <SettingsWrapper sections={sections} tab={tab} label="Users">
      <Component />
    </SettingsWrapper>
  );
};

export default Settings;
