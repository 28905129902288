import Badge, { getUniqueBadgeColors } from "app/components/Badge";
import {
  Icon,
  PaginationWrapper,
  Row,
  SimpleDropdown,
  SkeletonLoader,
  Text,
} from "app/components";
import { colors, spacing } from "app/utils/theme";
import {
  euroToFloat,
  getBlockContentHeight,
  getLinkOnClick,
  getUniqueValues,
  isFrontlyAdmin,
  parseBoolean,
  parseDateWithFormatObject,
  safeArray,
  safeString,
  truncateText,
} from "app/utils/utils";
import { get, isEmpty, snakeCase, startCase } from "lodash";
import {
  rActiveEditField,
  rApp,
  rAppDateFormat,
  rDarkMode,
  rSavedSpreadsheets,
  rSorting,
  rTranslations,
  refreshBlockIdsSelector,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Thumbnail from "app/components/Thumbnail";
import { Tooltip } from "react-tooltip";
import { getFormFields } from "../Form/utils";
import { getRelationHeaders } from "app/adminApp/components/StaticFields";
import moment from "moment";
import styled from "styled-components";
import useActionResolver from "../../useActionResolver";
import useDynamicText from "app/renderingApp/useDynamicText";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import useUtils from "app/renderingApp/useUtils";

export const getBadgeColorMap = (definedColors) => {
  const badges = {
    grey: colors.grey2,
    red: "hsl(347, 95%, 88%)",
    orange: "hsl(30, 95%, 88%)",
    yellow: "hsl(50, 95%, 88%)",
    green: "hsl(139, 95%, 88%)",
    blue: "hsl(208, 95%, 88%)",
    purple: "hsl(253, 95%, 88%)",
  };

  // User Defined Color Map
  let colorMap = {};
  safeArray(definedColors).forEach((c) => {
    colorMap[c.value] = get(badges, c.color);
  });

  return colorMap;
};

const determineValueType = (value) => {
  // Check for string type first
  if (typeof value === "string") {
    // Clean the value by removing commas and currency symbols
    const cleanedValue = value.replace(/[,€£$¥₹]/g, "");

    // Date check using moment for ISO 8601 formats
    if (moment(value, moment.ISO_8601, true).isValid()) {
      return "date";
    }
    // Float check
    else if (/^\d+\.\d+$/.test(cleanedValue)) {
      return "float";
    }
    // Integer check
    else if (/^\d+$/.test(cleanedValue)) {
      return "integer";
    }
    // Numeric check (purely numeric including integers and floats without currency symbols or commas)
    else if (/^\d+(\.\d+)?$/.test(cleanedValue)) {
      return "numeric";
    }
    // Alpha check (letters only)
    else if (/^[A-Za-z]+$/.test(value)) {
      return "alpha";
    }
    // Alphanumeric check (letters and numbers only)
    else if (/^[A-Za-z0-9]+$/.test(value)) {
      return "alphanumeric";
    }
    // Default to string if none of the above conditions are met
    // This covers cases where the string may contain special characters or spaces
    return "string";
  }

  // If the input is not a string, you could either handle other types here or default to 'other'
  // This example defaults to 'other', but you could expand this to handle other types as needed
  return "other";
};

export const sortRecords = (
  rows,
  key,
  direction,
  columnType,
  localDateFormat = {},
  appDateFormat = {}
) => {
  let newRows = [...rows];

  return newRows.sort((a, b) => {
    const value1 = get(a, key);
    const value2 = get(b, key);
    const type1 = columnType || determineValueType(value1);
    const type2 = columnType || determineValueType(value2);
    const df = { ...appDateFormat, ...localDateFormat };

    const isValidValue = (value) => {
      if (typeof value === "string" && value.trim() === "") return false;
      return value !== null && value !== undefined;
    };

    if (columnType === "euroDecimal") {
      // process with commas and decimals swapped
      const numberValue1 = euroToFloat(value1);
      const numberValue2 = euroToFloat(value2);

      if (direction === "asc") {
        if (numberValue1 < numberValue2) {
          return -1;
        }
        if (numberValue1 > numberValue2) {
          return 1;
        }
      }
      if (direction === "desc") {
        if (numberValue1 < numberValue2) {
          return 1;
        }
        if (numberValue1 > numberValue2) {
          return -1;
        }
      }
    }

    // Handle invalid values (null, undefined, "")
    if (!isValidValue(value1) && isValidValue(value2))
      return direction === "asc" ? 1 : -1;
    if (isValidValue(value1) && !isValidValue(value2))
      return direction === "asc" ? -1 : 1;
    // if (!isValidValue(value1) && !isValidValue(value2)) return 0;

    if (type1 === "date" && type2 === "date") {
      const date1 = parseDateWithFormatObject({
        value: value1,
        formatObject: df,
        returnMoment: true,
      });
      const date2 = parseDateWithFormatObject({
        value: value2,
        formatObject: df,
        returnMoment: true,
      });

      if (date1 && date2) {
        if (direction === "asc") {
          return date1.isAfter(date2) ? 1 : date1.isBefore(date2) ? -1 : 0;
        } else {
          return date1.isBefore(date2) ? 1 : date1.isAfter(date2) ? -1 : 0;
        }
      }
      return 0; // Fall back to neutral for invalid date comparisons
    } else {
      const extractNumber = (str) => {
        const matches = str.match(/(\d+(\.\d+)?)/);
        return matches ? parseFloat(matches[0]) : null;
      };

      let numberValue1;
      let numberValue2;

      if (
        ["float", "integer"].includes(type1) &&
        ["float", "integer"].includes(type2)
      ) {
        numberValue1 = parseFloat(value1);
        numberValue2 = parseFloat(value2);
      } else {
        numberValue1 = value1 && extractNumber(safeString(value1));
        numberValue2 = value2 && extractNumber(safeString(value2));
      }

      if (
        type1 === "alphanumeric" ||
        (numberValue1 === null && numberValue2 === null)
      ) {
        // REGULAR STRINGS
        if (direction === "asc") {
          return safeString(value1).localeCompare(safeString(value2));
        } else {
          return safeString(value2).localeCompare(safeString(value1));
        }
      } else {
        // VALID NUMBERS
        if (direction === "asc") {
          return numberValue1 - numberValue2;
        } else {
          return numberValue2 - numberValue1;
        }
      }
    }
  });
};

const Table = ({ block, page }) => {
  const { processObjects } = useProcessObjects();
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const { getListData } = useListData();

  const app = useRecoilValue(rApp);

  const data = getListData(block);

  const { recordClick, passesDisplayConditions } = useUtils();

  const { processDynamicText } = useDynamicText();

  const { actionResolver } = useActionResolver(page);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const sheet = savedSpreadsheets.find((s) => s.id === block.spreadsheet);

  const sheetHeaders = get(sheet, "headers", []).filter(
    (h) => h != "frontly_id"
  );

  const sheetFieldData = get(sheet, "field_data", {});
  const sheetOrder = get(sheetFieldData, "order", []);

  const columnData = get(block, "columnData", {});
  const columnConfig = get(columnData, "config", {});
  const columnOrder = get(columnData, "order", []);

  const relatedHeaders = getRelationHeaders(sheet, app, savedSpreadsheets);

  const { displayFields: columns } = getFormFields({
    processDynamicText,
    appDateFormat,
    relatedHeaders,
    sheetOrder,
    localOrder: columnOrder,
    sheetHeaders,
    sheetFieldData: get(sheetFieldData, "config", {}),
    localFieldData: columnConfig,
    block,
    fieldDefault: {},
    valuesObject: {},
    conditionsObject: {
      passesDisplayConditions,
      conditionKey: "displayConditions",
    },
  });

  let rows = processObjects(page, block, columns, data, processDynamicText);
  const resultsPerPage = get(block, "resultsPerPage");

  const rowActions =
    get(block, "rowActions", []) || get(rows, [0, "actions"], []) || [];

  rows = rows.map((r) => ({
    ...r,
    onClick: () => recordClick(block, r, actionResolver),
    actions: get(r, "actions", []).map((a) => {
      return {
        ...a,
        label: processDynamicText({
          text: get(a, "label"),
          context: { record: r },
          reusableBlockId: get(block, "reusableBlockId"),
          context: { repeatingRecord: get(block, "repeatingRecord") },
        }),
        onClick: () =>
          isFrontlyAdmin
            ? null
            : actionResolver({
                // todo - figure out how the actionmap thing would work here?
                actionId: a.value,
                context: { record: r },
                blockId: block.id,
                preventFetching: true,
              }),
      };
    }),
  }));

  return (
    <TableComponent
      data={{
        resultsPerPage,
        rows,
        columns,
        rowActions,
        noResultsText: `No ${block.label}`,
        block,
        pageId: page.id,
      }}
    />
  );
};

export const TableComponent = ({ data }) => {
  let { resultsPerPage, rows, columns, noResultsText, block, pageId } = data;

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const appDarkMode = useRecoilValue(rDarkMode);

  const translations = useRecoilValue(rTranslations);

  const activeApp = useRecoilValue(rApp);

  const darkMode = appDarkMode && !data.preventDarkMode;

  const isDataBase = safeString(get(block, "spreadsheet", "")).includes("db__");

  const { processDynamicText, passesDisplayConditions } = useUtils();

  const rowActions = get(data, "rowActions", []) || [];
  const defaultSortColumn = get(block, "defaultSortColumn");
  const defaultSortDirection = get(block, "defaultSortDirection");

  const getDefaultColumnSorting = () => {
    // Default to asc

    if (defaultSortColumn) {
      if (defaultSortDirection) {
        return `${defaultSortColumn}___${defaultSortDirection}`;
      }
      return `${defaultSortColumn}___asc`;
    }
    return null;
  };

  const [dbSorting, setDbSorting] = useRecoilState(rSorting);

  const concatId = `${pageId}_${get(block, "id")}`;

  const currentBlockSorting = get(dbSorting, concatId);

  const [sorting, setSorting] = useState(getDefaultColumnSorting());

  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const setRefreshBlockIds = useSetRecoilState(refreshBlockIdsSelector);

  const toggleColumnSorting = (columnKey) => {
    let currentSorting = isDataBase ? currentBlockSorting : sorting;
    let newValue;

    if (currentSorting) {
      const currentSortingColumn = currentSorting.split("___")[0];

      if (currentSortingColumn === columnKey) {
        if (currentSorting.includes("___asc")) {
          // Was ascending, reverse to descending
          newValue = `${columnKey}___desc`;
        } else if (currentSorting.includes("___desc")) {
          // Was descending, removing sorting
          newValue = "";
        } else {
          // Default to ascending
          newValue = `${columnKey}___asc`;
        }
      } else {
        // If it's a different column, default to ascending
        newValue = `${columnKey}___asc`;
      }
    } else {
      // Default to ascending if no current sorting
      newValue = `${columnKey}___asc`;
    }

    if (isDataBase) {
      setDbSorting({ ...dbSorting, [concatId]: newValue });
      setRefreshBlockIds([block.id]);
    } else {
      setSorting(newValue);
    }
  };

  useEffect(() => {
    if (!isDataBase) {
      setSorting(getDefaultColumnSorting());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSortColumn, defaultSortDirection]);

  if (sorting && !isDataBase) {
    const key = sorting.split("___")[0];
    const c = columns.find((c) => c.key === key);
    const columnType = get(c, "columnType");
    const direction = sorting.split("___")[1];

    // Sort rows
    rows = sortRecords(
      rows,
      key,
      direction,
      columnType,
      get(c, "dateFormat", {}),
      appDateFormat
    );
  }

  const showAlternatingColors = get(block, "showAlternatingColors");

  const rowHeight = get(block, "rowHeight") === "condensed" ? "44px" : "56px";

  const height = getBlockContentHeight(activeApp, block, rows, resultsPerPage);

  const borderStyle = get(block, "borderStyle", "solid");

  const customCss = get(block, "customCss", {});

  return (
    <ContentContainer height={height}>
      <PaginationWrapper
        itemsPerPage={resultsPerPage}
        items={rows}
        isFetching={get(block, "isFetching")}
        hideBorder={showAlternatingColors}
        padding="16px 0 0 0"
        noResultsPadding={data.noResultsPadding || "0 0 16px 0"}
        darkMode={darkMode}
        block={block}
      >
        {(paginatedRows) => {
          return (
            <TableContainer>
              {columns.map((column, colIndex) => {
                let badgeColors = null;

                if (column.columnType === "badge") {
                  const isMulti = column.splitBadge ? "MultiSelect" : null;
                  const colors = getUniqueValues(rows, column.key, isMulti);
                  badgeColors = getUniqueBadgeColors(colors);
                }

                return (
                  <ColumnContainerNew
                    key={colIndex}
                    onClick={(e) => {
                      if (isFrontlyAdmin && get(block, "allowColumnClick")) {
                        setActiveEditField({
                          id: column.key,
                          target: e.currentTarget,
                          blockId: block.id,
                        });
                      }
                    }}
                    allowColumnClick={
                      isFrontlyAdmin && get(block, "allowColumnClick")
                    }
                  >
                    <CellContainer
                      darkMode={darkMode}
                      height={rowHeight}
                      borderStyle={borderStyle}
                      onClick={() => {
                        if (!isFrontlyAdmin) {
                          toggleColumnSorting(column.key);
                        }
                      }}
                    >
                      {get(block, "isFetching") ? (
                        <SkeletonLoader height="20px" width="60%" />
                      ) : (
                        <Header
                          text={column.label || startCase(column.key)}
                          customCss={processDynamicText({
                            text: get(customCss, "tableHeaderText"),
                            reusableBlockId: get(block, "reusableBlockId"),
                            context: {
                              repeatingRecord: get(block, "repeatingRecord"),
                            },
                          })}
                        />
                      )}
                      {!get(block, "isFetching") && (
                        <RenderSorting
                          isDataBase={isDataBase}
                          sorting={isDataBase ? currentBlockSorting : sorting}
                          column={column}
                          darkMode={darkMode}
                          colors={colors}
                        />
                      )}
                    </CellContainer>
                    {get(block, "isFetching") &&
                      [1, 2, 3, 4, 5].map((r, i) => {
                        const isOdd = i % 2 === 0;
                        return (
                          <CellContainer
                            darkMode={darkMode}
                            height={rowHeight}
                            showBorder={!showAlternatingColors}
                            borderStyle={borderStyle}
                            background={
                              showAlternatingColors && isOdd && colors.grey1
                            }
                            key={i}
                          >
                            <SkeletonLoader
                              height="20px"
                              widthRange={[100, 250]}
                            />
                          </CellContainer>
                        );
                      })}

                    {!get(block, "isFetching") &&
                      paginatedRows.map((r, i) => {
                        const isOdd = i % 2 === 0;
                        return (
                          <CellContainer
                            darkMode={darkMode}
                            height={rowHeight}
                            showBorder={!showAlternatingColors}
                            borderStyle={borderStyle}
                            background={
                              showAlternatingColors && isOdd && colors.grey1
                            }
                            key={i}
                            onClick={r.onClick}
                          >
                            <Cell
                              darkMode={darkMode}
                              cellIndex={i}
                              column={column}
                              value={get(r, column.key)}
                              badgeColors={badgeColors}
                              passesDisplayConditions={passesDisplayConditions}
                              isCondensed={
                                get(block, "rowHeight") === "condensed"
                              }
                            />
                          </CellContainer>
                        );
                      })}
                  </ColumnContainerNew>
                );
              })}
              {rowActions.length > 0 && (
                <ColumnContainerNew>
                  <CellContainer
                    alignEnd={false}
                    height={rowHeight}
                    darkMode={darkMode}
                    borderStyle={borderStyle}
                  >
                    <Header
                      text={get(translations, "actions", "Actions")}
                      customCss={processDynamicText({
                        text: get(customCss, "tableHeaderText"),
                        reusableBlockId: get(block, "reusableBlockId"),
                        context: {
                          repeatingRecord: get(block, "repeatingRecord"),
                        },
                      })}
                    />
                  </CellContainer>

                  {paginatedRows.map((r) => {
                    return (
                      <CellContainer
                        alignEnd={false}
                        height={rowHeight}
                        darkMode={darkMode}
                        borderStyle={borderStyle}
                      >
                        <SimpleDropdown
                          data={{
                            options: get(r, "actions", []),
                            icon: {
                              icon: "FiMoreHorizontal",
                              size: 25,
                              hover: true,
                              color: colors.grey3,
                            },
                          }}
                        />
                      </CellContainer>
                    );
                  })}
                </ColumnContainerNew>
              )}
              {!get(block, "isFetching") && paginatedRows.length === 0 && (
                <NoContent>{noResultsText || "No results"}</NoContent>
              )}
            </TableContainer>
          );
        }}
      </PaginationWrapper>
    </ContentContainer>
  );
};

const RenderSorting = ({ isDataBase, sorting, column, darkMode, colors }) => {
  // Is Supabase
  if (isDataBase && sorting && sorting.split("___")[0] === column.key) {
    return (
      <Icon
        data={{
          margin: "0 0 0 4px",
          color: darkMode ? "white" : colors.medGrey,
          hover: true,
          icon: sorting.includes("___asc") ? "FiChevronUp" : "FiChevronDown",
        }}
      />
    );
  }

  // Is NOT Supabase
  if (sorting && sorting.split("___")[0] === column.key) {
    return (
      <Icon
        data={{
          margin: "0 0 0 4px",
          color: darkMode ? "white" : colors.medGrey,
          hover: true,
          icon: sorting.includes("___asc") ? "FiChevronUp" : "FiChevronDown",
        }}
      />
    );
  }

  return <div></div>;
};

export const Header = ({ text, customCss }) => (
  <Text
    data={{
      text: text,
      fontStyle: "bodyMd",
      cursor: "pointer",
      color: colors.darkModeLightText,
      customCss,
      allowSelect: true,
    }}
  />
);

export default Table;

export const Cell = ({
  column,
  value,
  badgeColors,
  cellIndex,
  isCondensed,
  passesDisplayConditions,
  darkMode,
}) => {
  const type = column.columnType;

  const appDateFormat = useRecoilValue(rAppDateFormat);

  if (type === "image") {
    return (
      <Thumbnail
        src={value}
        size="small"
        darkMode={darkMode}
        forceSize={isCondensed ? "30px" : null}
      />
    );
  } else if (type === "date") {
    const df = { ...appDateFormat, ...get(column, "dateFormat", {}) };
    return (
      <div style={{ color: darkMode && "white" }}>
        {parseDateWithFormatObject({
          value,
          formatObject: df,
        })}
      </div>
    );
  } else if (type === "boolean") {
    const parsedValue = parseBoolean(value);

    return (
      <BooleanWrapper value={parsedValue}>
        <Icon
          data={{
            icon: parsedValue ? "FiCheck" : "FiX",
            size: 14,
            hover: true,
            color: parsedValue ? "#2F7D61" : "#CB401B",
          }}
        />
      </BooleanWrapper>
    );
  } else if (type === "badge") {
    const badgeColorMap = getBadgeColorMap(get(column, "badgeColors", []));
    const customColorMap = get(column, "customColors", []);

    const splitBadge = get(column, "splitBadge", false);

    if (value) {
      value = value.toString();
    }

    const badgeArray =
      splitBadge && value
        ? value.split(",").map((v) => v.trim())
        : value
        ? [value.trim()]
        : [];

    const grey = get(badgeColorMap, "grey");

    // Handles multiple condition. The first condition that matches the badge's value will use the set color.
    const handleCustomColor = (v) => {
      const colorMap = customColorMap?.find((item) => {
        const conditions = item?.displayConditions?.map((d) => ({
          ...d,
          value1: v,
        }));
        const isPass = passesDisplayConditions({
          conditions,
          showInAdmin: true,
        });
        return isPass;
      });
      if (colorMap) return colorMap?.color;
      return grey;
    };

    return (
      <Row gap="5px">
        {badgeArray.map((v) => {
          const color =
            !isEmpty(customColorMap) && customColorMap
              ? handleCustomColor(v)
              : !isEmpty(badgeColorMap)
              ? get(badgeColorMap, v, grey)
              : get(badgeColors, v, grey);
          return <Badge value={v || "No Value"} color={color} />;
        })}
      </Row>
    );
  } else if (type === "link") {
    const linkClick = getLinkOnClick(value);

    const truncateAmount = column.truncate || 35;

    const finalValue = get(column, "linkText") || value;

    if (linkClick) {
      return (
        <Text
          data={{
            text: truncateText(finalValue, truncateAmount),
            onClick: linkClick,
            color: column.fontColor || colors.primary,
            fontStyle: column.bold ? "headingMd" : "bodyLg",
            cursor: "pointer",
            allowSelect: true,
          }}
        />
      );
    }
  }

  const truncateAmount = column.truncate || 25;

  const tooltipKey = snakeCase(`${column.key}-${cellIndex}-${value}`);

  // Plain text
  return (
    <>
      {value && value.length > truncateAmount && (
        <Tooltip
          anchorSelect={`.${tooltipKey}`}
          place="bottom"
          style={{ zIndex: 9999, maxWidth: "400px" }}
        >
          {truncateText(value, 600)}
        </Tooltip>
      )}

      <div className={tooltipKey}>
        <Text
          data={{
            allowSelect: true,
            text: truncateText(value || "", truncateAmount),
            color: column.fontColor || (darkMode ? "white" : colors.default),
            fontStyle: column.bold ? "headingMd" : "bodyLg",
          }}
        />
      </div>
    </>
  );
};

const ContentContainer = styled.div`
  height: ${(p) => p.height};
  overflow-y: auto;
`;

const NoContent = styled.div`
  padding: 15px;
  border-top: 1px solid ${colors.divider};
`;

const ColumnContainerNew = styled.div`
  flex: 1 0 auto;
  min-width: fit-content;
  ${(p) =>
    p.allowColumnClick &&
    `
    &:hover{
      outline: 1px solid ${colors.primary};
      outline-offset: -2px;
      cursor: pointer;
      border-radius: 4px;
    }
`};
`;

const TableContainer = styled.div`
  overflow-x: auto;
  flex: 1;
  display: flex;
  cursor: pointer;
  overflow-y: auto;
  height: ${(p) => p.height};
`;

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.alignEnd ? "flex-end" : "flex-start")};
  height: ${(p) => p.height};
  flex: 1;
  min-width: fit-content;
  padding: 0 ${spacing.s5} 0 ${spacing.s5};
  :not(:last-child) {
    border-bottom: 1px ${(p) => p.borderStyle}
      ${(p) => (p.darkMode ? colors.darkModeLightBorder : colors.grey2)};
  }
  background: ${(p) => p.background};
`;

const BooleanWrapper = styled.div`
  display: flex;
  background: ${(p) => (p.value ? "#B8E7D2" : "#F9D5D2")};
  border-radius: 20px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
