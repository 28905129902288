import { Icon, Row, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";
import { get, startCase } from "lodash";
import { rActiveBlockId, rPage } from "app/utils/recoil";

import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { truncateText } from "app/utils/utils";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";
import { useState } from "react";

const ActionBubble = ({ action, onClick, multiResourceId, resourceId }) => {
  const [showDelete, setShowDelete] = useState(false);

  // const page = useRecoilValue(rPage);
  const page = usePage();
  const { setPage } = useSetPage();

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const deleteAction = (e) => {
    e.stopPropagation();
    setPage({
      blocks: get(page, "blocks", []).map((b) => {
        if (b.id === activeBlockId) {
          return {
            ...b,
            [multiResourceId]: get(b, multiResourceId, []).filter(
              (x) => x.value !== action.id
            ),
          };
        }
        return b;
      }),
      actions: get(page, "actions", []).filter((a) => a.id !== action.id),
    });
    successNotification("Action Deleted");
  };

  const getActionIcon = () => {
    // TODO - Grab the steps and use that for action icons
    return "ActionBullseye";
  };

  const actionLabel = multiResourceId
    ? get(action, "label")
    : get(action, "referenceLabel") || startCase(resourceId);

  return (
    <Container
      onClick={onClick}
      onMouseEnter={() => {
        if (!showDelete) {
          setShowDelete(true);
        }
      }}
      onMouseLeave={() => {
        if (showDelete) {
          setShowDelete(false);
        }
      }}
    >
      <Icon
        data={{
          icon: getActionIcon(),
          color: "#5D5F62",
          size: 18,
        }}
      />
      <Row justifyContent="space-between" alignItems="center" width="100%">
        <Text
          data={{
            cursor: "pointer",
            text: truncateText(actionLabel, 25),
            fontStyle: "bodySm",
          }}
        />
        {multiResourceId && showDelete && (
          <Icon
            data={{
              icon: "FiTrash",
              hover: true,
              onClick: deleteAction,
              color: colors.grey3,
              size: 18,
            }}
          />
        )}
      </Row>
    </Container>
  );
};

export default ActionBubble;

const Container = styled.div`
  border: 1px solid ${colors.grey2};
  padding: ${spacing.s3};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  background: white;
`;
