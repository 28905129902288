import { get, startCase } from "lodash";

import { MultiSelect } from "app/components";
import useActiveBlockSheet from "app/utils/useActiveBlockSheet";

const SpreadsheetColumnMultiSelect = ({ data }) => {
  const activeBlockSheet = useActiveBlockSheet();

  const columns = get(activeBlockSheet, "headers", []);

  let options = [];

  if (columns.length === 0) {
    options = [{ label: "Select Column", value: null }];
  }

  options = columns.map((c) => ({ label: startCase(c), value: c }));

  return (
    <MultiSelect
      data={{
        ...data,
        onChange: data?.onChange,
        options,
      }}
    />
  );
};

export default SpreadsheetColumnMultiSelect;
