import { Hint, Row, Text } from "app/components";

import { Menu as MuiMenu } from "@mui/material";
import { colors } from "app/utils/theme";
import styled from "styled-components";

const Container = styled.div`
  width: ${(p) => p.width || "fit-content"};
  min-width: ${(p) => p.minWidth || "fit-content"};
  background: ${(p) => p.background};
  height: 100%;
  padding: ${(p) => p.padding || "20px"};
`;

const HeaderRow = styled.div`
  margin-bottom: 15px;
`;

const Body = styled.div`
  width: 100%;
`;

const Menu = ({
  label,
  description,
  hint,
  hintLink,
  hide,
  width,
  minWidth,
  children,
  anchorElement,
  anchorOrigin,
  padding,
  background,
  darkMode,
  onBlur,
  onClick,
}) => {
  return (
    <MuiMenu
      anchorPosition={{ right: 10 }}
      anchorOrigin={anchorOrigin || { vertical: "bottom", horizontal: "left" }}
      open={Boolean(anchorElement)}
      onClose={hide}
      anchorEl={anchorElement}
      onBlur={onBlur}
      onClick={onClick}
      PaperProps={{
        style: {
          borderRadius: "18px",
          backgroundColor: darkMode
            ? colors.darkModeLightBackground
            : background || "#fff",
        },
      }}
      MenuListProps={{ sx: { py: 0 } }}
    >
      <Container
        width={width}
        minWidth={minWidth}
        padding={padding}
        background={darkMode ? colors.darkModeBackground : null}
      >
        {label && (
          <HeaderRow>
            <Row alignItems="center" gap="5px">
              {label && (
                <Text
                  data={{
                    color: darkMode && "white",
                    text: label,
                    fontStyle: "headingSm",
                  }}
                />
              )}
              {hint && <Hint hint={hint} link={hintLink} />}
            </Row>

            {description && (
              <Text
                data={{
                  text: description,
                  fontStyle: "bodyMd",
                  margin: "5px 0 0 0",
                  color: colors.grey4,
                }}
              />
            )}
          </HeaderRow>
        )}
        <Body padding={padding}>{children}</Body>
      </Container>
    </MuiMenu>
  );
};

export default Menu;
