import { get } from "lodash";
import { rModalStack } from "./utils/recoil";
import { useRecoilState } from "recoil";

const useModalStateData = () => {
  const [modalStack, setModalStack] = useRecoilState(rModalStack);

  const lastItem = get(modalStack, modalStack.length - 1);
  const stackId = get(lastItem, "id");
  const hideTopStackItem = () =>
    setModalStack(modalStack.filter((x) => x.id < stackId));

  return {
    lastItem,
    hideTopStackItem,
    setModalStack,
    modalStack,
  };
};

export default useModalStateData;
