import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";
import { rApp, rTranslations } from "app/utils/recoil";

import AdminForm from "../components/AdminForm";
import { Text } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useState } from "react";

export const ClientLogo = styled.img`
  height: 100px;
  width: 240px;
  object-fit: contain;
  margin: 0 0 30px 0;
  @media screen and (max-width: 600px) {
    margin: 0 0 15px 0;
    height: 60px;
    width: 100%;
  }
`;

const Container = styled.div`
  width: 600px;
  margin: 0 auto;
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
    padding: 15px;
  }
`;

const CustomerPasswordReset = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [state, setState] = useState({ password: "", confirmPassword: "" });
  const [errors, setErrors] = useState({});

  const translations = useRecoilValue(rTranslations);

  const activeApp = useRecoilValue(rApp);

  const navigate = useNavigate();

  const changes = state.password.length > 0 || state.confirmPassword.length > 0;

  const isValid = () => {
    const errors = {};

    if (!state.password.length)
      errors["password"] = "Please enter a valid password.";
    else if (state.password.length < 6)
      errors["password"] = "Please enter a more secure password.";
    else if (state.password !== state.confirmPassword)
      errors["confirmPassword"] = "Passwords do not match.";

    setErrors(errors);

    return isEmpty(errors);
  };

  const save = () => {
    if (!isValid()) return;
    setIsSaving(true);

    apiRequest
      .post("/reset_password/", { password: state.password })
      .then(() => {
        successNotification("Saved successfully");
      })
      .catch(() => {
        errorNotification("Something went wrong.");
      })
      .finally(() => {
        setIsSaving(false);
        navigate("/login");
      });
  };

  const fields = [
    {
      id: "password",
      label: get(translations, "password", "Password"),
      type: "password",
      componentId: "Input",
      value: get(state, "password"),
    },
    {
      id: "confirmPassword",
      label: get(translations, "confirmPassword", "Confirm Password"),
      type: "password",
      componentId: "Input",
      value: get(state, "confirmPassword"),
    },
  ];
  return (
    <Container>
      {activeApp.logo && <ClientLogo src={activeApp.logo} />}
      <Text
        data={{
          text: get(translations, "resetYourPassword", "Reset Your Password"),
          fontStyle: "headingXl",
          margin: "0 0 20px 0",
        }}
      />
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="0"
        fields={fields}
        submitText={get(translations, "updatePassword", "Update Password")}
        submit={changes && save}
        isFetching={isSaving}
        onChange={(k, v) => setState({ ...state, [k]: v })}
      />
    </Container>
  );
};

export default CustomerPasswordReset;
