import { Breadcrumb, Button, Icon, Input, Row, Text } from "app/components";
import { boxShadow, colors } from "app/utils/theme";
import { getHighest, getPixels, isFrontlyAdmin } from "app/utils/utils";

import Modal from "@mui/material/Modal";
import styled from "styled-components";
import useModalStateData from "app/useModalStateData";
import useWindowSize from "app/utils/useWindowSize";

const Header = styled.div`
  border-bottom: 1px solid ${colors.grey2};
  padding: 10px 15px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
`;

const Content = styled.div`
  padding: ${(p) => p.padding};
  overflow-y: auto;
  max-height: ${(p) => p.maxHeight};
  border-radius: 0 0 12px 12px;
`;

const Footer = styled.div`
  border-top: 1px solid ${colors.grey2};
  padding: 15px 15px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  gap: 13px;
`;

const ModalContainer = styled.div`
  background: ${(p) => p.background || "white"};
  border-radius: ${(p) => p.borderRadius};
  min-width: ${(p) => p.minWidth};
  max-width: ${(p) => p.maxWidth};
  max-height: ${(p) => p.maxHeight};
  &:focus-visible {
    outline: none;
  }

  @media screen and (max-width: 800px) {
    min-width: calc(100% - 20px);
  }

  ${(p) => p.modalPreview && `box-shadow: ${boxShadow.deep}`}
`;

const SimpleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
  background: ${(p) => p.background};
  border-radius: 12px 12px 0 0;
`;

const SimpleTitle = styled.div`
  font-size: ${(p) => p.titleFontSize || "20px"};
  font-weight: 600;
  flex: 1;
  color: ${(p) => p.color};
`;

const XSpacer = styled.div`
  width: 25px;
`;

const FullScreenModal = ({
  children,
  hide,
  header = {},
  useStack = false,
  stackId = null,
  background = "white",
  contentPadding = "0px 20px 20px 20px",
  minWidth = "600px",
  buttons = [],
  titleFontSize = "20px",
  modalPreview = false,
  borderRadius = "12px",
  darkMode = false,
}) => {
  const { modalStack, setModalStack } = useModalStateData();

  // header params
  const {
    title,
    description,
    customComponent = null, // a custom component to put on the right beside the X symbol
    onTitleChange,
    titleIsEditable,
    breadcrumbs,
  } = header;

  const renderTitle = () => {
    if (breadcrumbs) {
      return <Breadcrumb items={breadcrumbs} whiteText={darkMode} />;
    }

    if (onTitleChange && titleIsEditable) {
      return (
        <Row alignItems={"center"}>
          <Icon
            data={{
              icon: "FiEdit",
              color: colors.grey3,
              size: 18,
            }}
          />
          <Input
            data={{
              value: title,
              minimal: true,
              width: "200px",
              border: "0px",
              placeholder: "Action Label",
              padding: "5px",
              fontStyle: "headingLg",
              onChange: (v) => onTitleChange(v),
            }}
          />
        </Row>
      );
    }

    return (
      <Text
        data={{
          text: title || "Modal Label",
          fontStyle: "headingMd",
        }}
      />
    );
  };

  const verticalPadding = isFrontlyAdmin ? 150 : 40;

  const showFooter = buttons.length > 0;
  const footerHeight = showFooter ? 70 : 0;

  const { height: windowHeight, width: windowWidth } = useWindowSize();

  const maxHeight = getPixels(windowHeight - verticalPadding);
  const contentMaxHeight = getPixels(
    windowHeight - verticalPadding - 100 - footerHeight
  );

  // Prevent rendering if not matching the stack ID
  if (
    useStack &&
    (!stackId ||
      modalStack.length === 0 ||
      getHighest(modalStack, "id") !== stackId)
  ) {
    return <></>;
  }

  // If hide not defined, use default stack management
  if (!hide) {
    // CLEAR ENTIRE STACK
    hide = () => setModalStack([]);
  }

  const StackBreadcrumbs = () => {
    const crumbs = modalStack.map((stackItem) => ({
      text: stackItem.label,
      onClick: () =>
        setModalStack(modalStack.filter((x) => x.id <= stackItem.id)),
    }));
    if (crumbs.length > 0) {
      return <Breadcrumb items={crumbs} whiteText={darkMode} />;
    }
    return <></>;
  };

  const modalProps = modalPreview
    ? {}
    : {
        open: true,
        onClose: hide,
        style: {
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      };

  const maxWidthNum = windowWidth - 60 - (modalPreview ? 290 : 0);

  const resolvedMinWidth =
    parseInt(minWidth) > maxWidthNum ? getPixels(maxWidthNum) : minWidth;

  const modalContent = (
    <ModalContainer
      borderRadius={borderRadius}
      minWidth={
        parseInt(minWidth) > maxWidthNum ? getPixels(maxWidthNum) : minWidth
      }
      maxWidth={resolvedMinWidth}
      maxHeight={maxHeight}
      background={background}
      modalPreview={modalPreview}
    >
      {titleIsEditable ? (
        <Header>
          <StackBreadcrumbs darkMode={darkMode} />
          {!useStack && renderTitle()}
          <Row alignItems="center" gap="20px">
            {customComponent}
            <Icon
              data={{
                icon: "FiX",
                onClick: hide,
                color: colors.medGrey,
                hover: true,
                size: 24,
              }}
            />
          </Row>
        </Header>
      ) : (
        <SimpleHeader background={background}>
          <div>
            {breadcrumbs ? (
              <Breadcrumb items={breadcrumbs} whiteText={darkMode} />
            ) : (
              <SimpleTitle
                titleFontSize={titleFontSize}
                color={darkMode && "white"}
              >
                {title}
              </SimpleTitle>
            )}

            <Text
              data={{
                text: description,
                fontStyle: "bodyLg",
                margin: "10px 0 10px 0",
                color: colors.grey4,
              }}
            />
          </div>

          {!modalPreview && (
            <Row alignItems="center" gap="20px">
              {customComponent}
              <XSpacer>
                <Icon
                  data={{
                    icon: "FiX",
                    onClick: hide,
                    color: darkMode ? colors.darkModeLightGrey : colors.medGrey,
                    hover: true,
                    size: 24,
                  }}
                />
              </XSpacer>
            </Row>
          )}
        </SimpleHeader>
      )}

      <Content
        background={background}
        padding={contentPadding}
        maxHeight={contentMaxHeight}
      >
        {children}
      </Content>

      {showFooter && (
        <Footer>
          {buttons.map((b, i) => (
            <Button data={b} />
          ))}
        </Footer>
      )}
    </ModalContainer>
  );

  // PREVIEW ONLY - NO WRAPPER
  if (modalPreview) {
    return modalContent;
  }

  // FULL MODAL
  return <Modal {...modalProps}>{modalContent}</Modal>;
};

export default FullScreenModal;
