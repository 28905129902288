import { rCustomBlockChanges, rCustomBlocks } from "./recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import { safeArray } from "./utils";
import useIsCustomBlock from "./useIsCustomBlock";
import { useParams } from "react-router-dom";

const useActiveCustomBlock = () => {
  const { id } = useParams();

  const isCustomBlock = useIsCustomBlock();

  const [customBlocks, setCustomBlocks] = useRecoilState(rCustomBlocks);

  // GET ACTIVE CUSTOM BLOCK

  const activeCustomBlock = isCustomBlock
    ? safeArray(customBlocks).find((b) => b.id == id)
    : null;

  // SET ACTIVE CUSTOM BLOCK

  const setCustomBlockChanges = useSetRecoilState(rCustomBlockChanges);

  const setActiveCustomBlock = (obj) => {
    setCustomBlockChanges(true);

    const newCustomBlocks = safeArray(customBlocks).map((bl) => {
      if (bl.id == activeCustomBlock.id) {
        return {
          ...bl,
          ...obj,
        };
      }
      return bl;
    });

    setCustomBlocks(newCustomBlocks);
  };

  return { activeCustomBlock, setActiveCustomBlock };
};

export default useActiveCustomBlock;
