import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

import { get } from "lodash";
import { getColor } from "../charts/utils";

const RechartBar = ({ data }) => {
  const chartData = get(data, "data", []);
  const firstItem = get(chartData, 0, {});
  const keys = Object.keys(firstItem).filter((key) => key !== "name");

  const colorScheme = get(data, "colorScheme");
  const customColorScheme = get(data, "customColorScheme");

  return (
    <div style={{ width: "100%" }}>
      <BarChart width={500} height={400} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />

        {keys.map((key, index) => {
          const color = getColor(index, colorScheme, customColorScheme);
          return <Bar key={key} dataKey={key} fill={color} />;
        })}
      </BarChart>
    </div>
  );
};

export default RechartBar;
