import { boxShadow, colors, spacing } from "app/utils/theme";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";
import { isFrontlyAdmin, safeArray } from "app/utils/utils";
import {
  rActiveEditField,
  rApp,
  rAppDateFormat,
  rConfirmationModalData,
  rDarkMode,
  rLiveSpreadsheets,
  rSavedSpreadsheets,
  rSubscription,
  rTranslations,
} from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useValidateFields, { getValidationObject } from "app/utils/validation";

import AdminForm from "app/adminApp/components/AdminForm";
import { ViewOnlyList } from "app/components";
import { demoSubdomains } from "app/adminApp/appManagement/Templates";
import { getFormFields } from "../renderingApp/blocks/Form/utils";
import { getRelationColumnsFromBlock } from "app/renderingApp/blocks/Form/relationUtils";
import styled from "styled-components";
import useActionResolver from "app/renderingApp/useActionResolver";
import useDynamicText from "app/renderingApp/useDynamicText";
import useModalStateData from "app/useModalStateData";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import { useState } from "react";
import useUtils from "../renderingApp/useUtils";

const DetailView = ({
  setupBlock,
  previewMode = false,
  hideCard,
  setHasUnsavedChanges,
  minWidth,
  width = "100%",
  margin = "0px",
}) => {
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const spreadsheets = useRecoilValue(rLiveSpreadsheets);

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const app = useRecoilValue(rApp);
  const subscription = useRecoilValue(rSubscription);
  const currentPlan = get(subscription, "plan");
  const isDemoApp = demoSubdomains.includes(get(app, "subdomain"));
  const isUnpaid = !isDemoApp && !currentPlan;

  const [formErrors, setFormErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { passesDisplayConditions } = useUtils();

  const { processDynamicText } = useDynamicText();

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);
  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const { updateRecord, deleteRecord, createRecord } = useSpreadsheetRequests();
  const { hideTopStackItem, lastItem } = useModalStateData();

  const { handleCustomAction } = useActionResolver();

  const [isSaving, setIsSaving] = useState(false);

  const createModeDefaultValues = get(lastItem, "defaultValues", {});

  const activeBlockId = get(lastItem, "blockId");
  const activeItemId = get(lastItem, "itemId");
  const activeType = get(lastItem, "type");
  const showCreate = activeType === "create";

  const { validateFields } = useValidateFields();

  const [changedValues, setChangedValues] = useState(
    showCreate ? createModeDefaultValues : {}
  );

  let activeBlock = setupBlock;

  let recordClickAction = get(activeBlock, "recordClickAction");
  const validClickActionTypes = ["default", "custom", "action", "none"];
  if (!validClickActionTypes.includes(recordClickAction)) {
    recordClickAction = "default";
  }

  const activeSheet = get(spreadsheets, activeBlockId, []);

  const activeDetailItem = activeSheet.find(
    (x) => x.frontly_id === activeItemId
  );

  // Block-level field overrides
  const fieldData = get(activeBlock, "fieldData", {});
  const config = get(fieldData, "config", {});
  const localOrder = get(fieldData, "order", []);

  const sheetId = get(activeBlock, "spreadsheet");

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const sheet = savedSpreadsheets.find((s) => s.id === sheetId);

  const sheetHeaders = get(sheet, "headers", []);

  let defaultValuesObject = { ...activeDetailItem };

  if (!activeItemId) {
    sheetHeaders.forEach((h) => {
      const m = get(config, h);

      if (["Switch", "Checkbox"].includes(get(m, "componentId"))) {
        defaultValuesObject[h] = get(m, "defaultValue") || false;
      }

      if (get(m, "active") !== false && get(m, "defaultValue")) {
        defaultValuesObject[h] = processDynamicText({
          text: get(m, "defaultValue"),
        });
      }
    });
  }

  const [formValues, setFormValues] = useState(defaultValuesObject);

  const firstRecord = get(sheet, ["data", 0], {});

  // RELATION LOGIC ----------------------------------------
  const { relationColumns } = getRelationColumnsFromBlock({
    block: activeBlock,
    app,
    spreadsheets,
  });

  const editConditions = passesDisplayConditions({
    conditions: get(activeBlock, "editConditions", []),
  });

  const showEdit =
    !showCreate && (setupBlock || (activeDetailItem && activeType === "edit"));

  const showEdit2 = get(activeBlock, "showEdit") !== false && editConditions;

  // RELATION LOGIC --------------------------------------------------

  const mergedValues =
    isFrontlyAdmin && showEdit
      ? firstRecord
      : {
          ...formValues,
          ...changedValues,
        };

  // Spreadsheet-level field overrides
  const sheetFieldData = get(sheet, "field_data", {});
  const sheetConfig = get(sheetFieldData, "config");
  const sheetOrder = get(sheetFieldData, "order", []);

  const deleteConditions = passesDisplayConditions({
    conditions: get(activeBlock, "deleteConditions", []),
  });

  const showDelete =
    get(activeBlock, "showDelete") !== false && deleteConditions;

  const { displayFields: test, hiddenFields } = getFormFields({
    dataSourceId: get(sheet, "id"),
    relations: safeArray(app, "data_relations"),
    processDynamicText,
    appDateFormat,
    localOrder,
    sheetOrder,
    sheetHeaders,
    relationColumns,
    block: activeBlock,
    sheetFieldData: sheetConfig,
    localFieldData: config,
    valuesObject: mergedValues,
    conditionsObject: {
      passesDisplayConditions,
      conditionKey: "conditions",
    },
  });

  const displayFields = test.map((f) => {
    return {
      ...f,
      value: get(mergedValues, f.key),
    };
  });

  const validationObject = getValidationObject(displayFields);

  const updateSheet = (key, value) => {
    const newValues = { ...changedValues, [key]: value };

    validateFields({
      dataSourceConfig: sheetConfig,
      validationSchema: validationObject,
      values: { ...mergedValues, [key]: value },
      hasSubmitted,
      setErrors: setFormErrors,
    });

    setFormValues({
      ...formValues,
      [key]: value,
    });
    setChangedValues(newValues);

    // For tracking unsaved changes in parent component
    if (setHasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  const recordId = get(activeDetailItem, "frontly_id");

  const createRow = () => {
    if (isUnpaid) {
      errorNotification(
        "This app is unpaid. Please upgrade to create or edit records."
      );
      return;
    }

    setHasSubmitted(true);

    if (
      isEmpty(
        validateFields({
          dataSourceConfig: sheetConfig,
          validationSchema: validationObject,
          values: mergedValues,
          hasSubmitted: true,
          setErrors: setFormErrors,
        })
      )
    ) {
      setIsSaving(true);

      // Get changed values
      let updateValues = hiddenFields.map((f) => ({
        key: f.key,
        value: processDynamicText({
          text: f.hiddenValue,
          context: {
            record: mergedValues,
          },
          skipRecordFrontlyId: true,
        }),
      }));

      Object.keys(changedValues).forEach((k) => {
        const v = get(changedValues, k, "");
        if (v && !v.toString().trim().startsWith("=")) {
          updateValues.push({ key: k, value: v });
        }
        if (v === null) {
          updateValues.push({ key: k, value: null });
        }
      });

      // Apply default values
      Object.keys(formValues).forEach((k) => {
        if (!get(changedValues, k)) {
          updateValues.push({ key: k, value: get(formValues, k) });
        }
      });

      createRecord({
        sheetId,
        values: updateValues,
      }).then((newRecord) => {
        setIsSaving(false);
        setHasSubmitted(false);
        successNotification(
          get(translations, "recordCreated", "Record Created")
        );
        hideTopStackItem();

        // TRIGGER CREATE RECORD ACTION
        handleCustomAction({
          rawAction: get(activeBlock, "detailCreateSubmitAction"),
          action: get(activeBlock, "detailCreateSubmitAction"),
          context: {
            form: {
              ...formValues,
              ...newRecord,
            },
          },
          blockId: activeBlockId,
        });
      });
    }
  };

  const deleteRow = () => {
    if (isUnpaid) {
      errorNotification(
        "This app is unpaid. Please upgrade to delete records."
      );
      return;
    }

    setConfirmationModalData({
      title: get(
        translations,
        "detailViewDeleteConfirmationTitle",
        "Delete Record"
      ),
      text: get(
        translations,
        "detailViewDeleteConfirmationText",
        "Are you sure you want to delete this record?"
      ),
      confirm: () => {
        deleteRecord({
          sheetId,
          recordId,
        });

        hideTopStackItem();
        successNotification(
          get(translations, "deletedNotification", "Deleted")
        );
      },
    });
  };

  const update = () => {
    if (isUnpaid) {
      errorNotification(
        "This app is unpaid. Please upgrade to create or edit records."
      );
      return;
    }

    setHasSubmitted(true);

    if (
      isEmpty(
        validateFields({
          dataSourceConfig: sheetConfig,
          validationSchema: validationObject,
          values: mergedValues,
          hasSubmitted: true,
          setErrors: setFormErrors,
        })
      )
    ) {
      setIsSaving(true);

      // Get changed values
      let updateValues = hiddenFields.map((f) => ({
        key: f.key,
        value: processDynamicText({
          text: f.hiddenValue,
          context: {
            record: mergedValues,
          },
        }),
      }));

      Object.keys(changedValues).forEach((k) => {
        // Only update if the value is not a formula
        const v = get(changedValues, k, "");
        if (v && !v.toString().trim().startsWith("=")) {
          updateValues.push({ key: k, value: v });
        }
        if (v === null) {
          updateValues.push({ key: k, value: null });
        }
      });

      if (updateValues.length > 0) {
        updateRecord({
          sheetId,
          recordId,
          values: updateValues,
        }).then(() => {
          setChangedValues({});
          setIsSaving(false);
          successNotification(get(translations, "savedNotification", "Saved"));
          hideTopStackItem();

          // TRIGGER EDIT RECORD ACTION
          handleCustomAction({
            rawAction: get(activeBlock, "detailEditSubmitAction"),
            action: get(activeBlock, "detailEditSubmitAction"), // this may not be necessary
            context: {
              form: {
                ...formValues,
              },
            },
            blockId: activeBlockId,
          });
        });
      }
    }
  };

  if (activeType === "edit" && recordClickAction !== "default") {
    return <></>;
  }

  return (
    <PageContainer width={width} margin={margin}>
      <Container hideContainer={hideCard}>
        {showEdit && !showEdit2 && (
          <ViewOnlyList
            darkMode={darkMode}
            formState={mergedValues}
            displayFields={displayFields}
            processDynamicText={processDynamicText}
            gridLayout
            maxWidth={minWidth}
            deleteButton={
              showDelete
                ? {
                    text: get(translations, "detailViewDelete", "Delete"),
                    onClick: deleteRow,
                    type: "basic",
                    border:
                      darkMode && `1px solid ${colors.darkModeButtonBorder}`,
                    backgroundColor: darkMode && colors.darkModeLightBorder,
                    color: darkMode && colors.darkModeLightText,
                    disabled: previewMode,
                  }
                : null
            }
            handleFieldClick={(obj) =>
              setActiveEditField({
                ...obj,
                blockId: get(activeBlock, "id"),
                formContext: "detailView",
              })
            }
          />
        )}
        {showEdit && showEdit2 && (
          <>
            <AdminForm
              isLiveApp
              formContext="detailView"
              allowFieldClick
              activeBlock={activeBlock}
              sectionPadding="0px"
              fields={displayFields}
              errors={formErrors}
              buttonColor={get(app, "primary_color", colors.primary)}
              submitText={get(translations, "detailViewSave", "Save")}
              disableSubmit={previewMode}
              submit={previewMode || (!isEmpty(changedValues) && update)}
              gridLayout
              isFetching={isSaving}
              onChange={(k, v) => updateSheet(k, v)}
              buttons={
                showDelete
                  ? [
                      {
                        text: get(translations, "detailViewDelete", "Delete"),
                        onClick: deleteRow,
                        type: "basic",
                        border:
                          darkMode &&
                          `1px solid ${colors.darkModeButtonBorder}`,
                        backgroundColor: darkMode && colors.darkModeLightBorder,
                        color: darkMode && colors.darkModeLightText,
                        disabled: previewMode,
                      },
                    ]
                  : []
              }
            />
          </>
        )}
        {showCreate && (
          <AdminForm
            isLiveApp
            activeBlock={activeBlock}
            sectionPadding="0px"
            fields={displayFields}
            submitText={"Save"}
            submit={createRow}
            errors={formErrors}
            gridLayout
            isFetching={isSaving}
            buttonColor={get(app, "primary_color", colors.primary)}
            onChange={(k, v) => updateSheet(k, v)}
          />
        )}
      </Container>
    </PageContainer>
  );
};

export default DetailView;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s8};
  width: ${(p) => p.width};
  margin: ${(p) => p.margin};
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Container = styled.div`
  background: white;
  border-radius: 10px;
  padding: ${spacing.s5};
  box-shadow: ${boxShadow.card};
  ${(p) => (p.stretchHeight ? "height: fit-content" : "align-self: stretch")};
  width: 100%;
  display: block;
  overflow: auto;
  // RESET BACK TO NONE IF NO CARD MODULE
  border: 1px solid white;
  ${(p) =>
    p.hideContainer &&
    "background: transparent; padding:0px; border: 1px solid transparent; box-shadow: none; border-radius: 0px;"}
  ${(p) => p.hovering && `cursor: pointer;`}
  ${(p) => (p.hovering || p.active) && `border: 1px solid ${colors.primary};`}
`;
