import { atom } from "recoil";

export const rLocalData = atom({
  key: "localData",
  default: null,
});

export const rResetCounter = atom({
  key: "resetCounter",
  default: 0,
});

export const rDescription = atom({
  key: "description",
  default: "",
});

export const rError = atom({
  key: "error",
  default: false,
});

export const rFetching = atom({
  key: "fetching",
  default: null,
});

export const rFirstPage = atom({
  key: "firstPage",
  default: null,
});

export const rOptionTab = atom({
  key: "optionTab",
  default: null,
});

export const rCurrentStep = atom({
  key: "currentStep",
  default: null,
});

export const rCurrentContext = atom({
  key: "currentContext",
  default: null,
});
