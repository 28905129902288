import { Button, FormInputWrapper, Select, Switch } from "app/components";

import { Action } from "../components";
import SettingsSection from "../settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import { successNotification } from "app/utils/Notification";
import { useRecoilState } from "recoil";
import { useState } from "react";

const SignupSettings = () => {
  const [app, setApp] = useRecoilState(rApp);

  // USER GROUPS
  const defaultUserGroup = get(app, "default_user_group");
  const userGroups = get(app, "user_groups", []) || [];
  const [changes, setChanges] = useState(false);

  const userSignupAction = get(app, "user_signup_action", {});
  const userLoginAction = get(app, "user_login_action", {});

  const signupActionHasNavigateStep = get(userSignupAction, "steps", []).find(
    (s) => s.type === "NAVIGATE"
  );

  const loginActionHasNavigateStep = get(userLoginAction, "steps", []).find(
    (s) => s.type === "NAVIGATE"
  );

  const saveDefaultGroup = () => {
    setChanges(false);
    successNotification("User Groups Saved");
    apiRequest.post("/app_settings/", {
      default_user_group: defaultUserGroup,
      user_signup_action: userSignupAction,
      user_login_action: userLoginAction,
      disable_login_default_page: get(app, "disable_login_default_page"),
      disable_signup_default_page: get(app, "disable_signup_default_page"),
    });
  };

  return (
    <SettingsSection
      title="Signup Settings"
      description="Adjust User Signup Related Settings"
    >
      <FormInputWrapper
        label="Default User Group"
        hint={
          "Set the default user group for new users who sign up to your app"
        }
      >
        <Select
          data={{
            label: "Default User Group",
            value: defaultUserGroup,
            options: userGroups.map((g) => ({
              label: g.name,
              value: g.id,
            })),
            onChange: (value) => {
              setChanges(true);
              setApp({
                default_user_group: value,
              });
            },
          }}
        />
      </FormInputWrapper>

      <FormInputWrapper
        margin="20px 0 0 0"
        label="User Signup Action"
        hint={"Trigger a custom action when a new user registers for your app"}
      >
        <Action
          data={{ label: "Configure Signup Action" }}
          disabledTypes={["REFRESH_BLOCKS"]}
          customAction={userSignupAction}
          customOnChange={(newAction) => {
            setChanges(true);
            setApp({
              user_signup_action: {
                ...userSignupAction,
                steps: newAction,
              },
            });
          }}
        />
      </FormInputWrapper>

      {signupActionHasNavigateStep && (
        <FormInputWrapper
          margin="20px 0 0 0"
          label="Disable 'default' page setting for signup"
          hint={"Used when your signup action includes a 'navigate' step"}
        >
          <Switch
            data={{
              value: get(app, "disable_signup_default_page"),
              onChange: (v) => {
                setChanges(true);
                setApp({
                  disable_signup_default_page: v,
                });
              },
            }}
          />
        </FormInputWrapper>
      )}

      <FormInputWrapper
        margin="20px 0 0 0"
        label="User Login Action"
        hint={"Trigger a custom action when a user logs in to your app"}
      >
        <Action
          data={{ label: "Configure Login Action" }}
          disabledTypes={["REFRESH_BLOCKS"]}
          customAction={userLoginAction}
          customOnChange={(newAction) => {
            setChanges(true);
            setApp({
              user_login_action: {
                ...userLoginAction,
                steps: newAction,
              },
            });
          }}
        />
      </FormInputWrapper>

      {loginActionHasNavigateStep && (
        <FormInputWrapper
          margin="20px 0 0 0"
          label="Disable 'default' page setting for login"
          hint={"Used when your login action includes a 'navigate' step"}
        >
          <Switch
            data={{
              value: get(app, "disable_login_default_page"),
              onChange: (v) => {
                setChanges(true);
                setApp({
                  disable_login_default_page: v,
                });
              },
            }}
          />
        </FormInputWrapper>
      )}

      {changes && (
        <Button
          data={{
            margin: "20px 0 0 0",
            text: "Save",
            onClick: saveDefaultGroup,
          }}
        />
      )}
    </SettingsSection>
  );
};

export default SignupSettings;
