import { colors } from "app/utils/theme";
import { getColor } from "./utils";
import { rDarkMode } from "app/utils/recoil";
import styled from "styled-components";
import { truncateText } from "app/utils/utils";
import { useRecoilValue } from "recoil";

const Legend = ({ values, colorScheme, customColorScheme }) => {
  const darkMode = useRecoilValue(rDarkMode);
  const barThickness = values.length < 6 ? "20px" : "15px";

  return (
    <Container>
      {values
        .filter((v, i) => i < 9)
        .map((item, index) => {
          return (
            <ItemContainer>
              <ValueBar
                thickness={barThickness}
                color={getColor(index, colorScheme, customColorScheme)}
              >
                &nbsp;
              </ValueBar>
              <ItemLabel darkMode={darkMode}>
                {truncateText(item.label, 12)}
              </ItemLabel>
            </ItemContainer>
          );
        })}
      {values.length >= 9 && <ItemLabel>+ {values.length - 8} more</ItemLabel>}
    </Container>
  );
};

export default Legend;

const Container = styled.div`
  min-width: 140px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ItemLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  min-width: 100px;
  max-width: 100px;
  color: ${(p) => (p.darkMode ? "white" : colors.grey4)};
`;

const ValueBar = styled.div`
  width: 20px;
  background: ${(p) => p.color || "blue"};
  border-radius: 4px;
  height: ${(p) => p.thickness};
`;
