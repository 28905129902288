import {
  addSlash,
  filterBasedOnUserGroups,
  findDefaultPage,
  isFrontlyAdmin,
  resizeImage,
} from "app/utils/utils";
import { get, isEmpty, isNil } from "lodash";
import { rApp, rPages, rPreviewApp, rUser } from "app/utils/recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import Navigation from "./index";
import { Row } from "app/components";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import useModalStateData from "app/useModalStateData";
import usePage from "app/utils/usePage";
import useUtils from "app/renderingApp/useUtils";
import useWindowSize from "app/utils/useWindowSize";

export const sortPages = (pages, pageOrder) => {
  const hiddenPageIds = pageOrder.filter((p) => p.hidePage).map((p) => p.value);

  // Use a Set to keep track of page ids that are in pageOrder
  const pageOrderIds = new Set(pageOrder.map((p) => p.value));

  // Add pages that are in the pageOrder to sortedPages in the given order
  const sortedPages = pageOrder
    .map((po) => {
      return pages.find((p) => p.pageId === po.value) || null;
    })
    .filter((p) => p !== null); // Filter out any null entries

  // Add pages that are NOT in the pageOrder to sortedPages
  pages.forEach((page) => {
    if (page.isCustomLink) {
      if (!pageOrderIds.has(page.id)) {
        sortedPages.push(page);
      }
    } else {
      if (!pageOrderIds.has(page.pageId)) {
        sortedPages.push(page);
      }
    }
  });

  return sortedPages.filter((p) => !hiddenPageIds.includes(p.pageId));
};

const ClientNavigation = ({ isPreview = false, isPublicRoute = false }) => {
  const { passesDisplayConditions } = useUtils();

  const user = useRecoilValue(rUser);

  const page = usePage();

  const app = useRecoilValue(rApp);

  const hideNavigation = get(page, "hideNavigation", false);

  const { setModalStack } = useModalStateData();

  const [previewApp, setPreviewApp] = useRecoilState(rPreviewApp);

  const activePageRoute = get(previewApp, "activePageRoute");

  const location = useLocation();

  const nav = get(app, "navigation", {});

  const { width } = useWindowSize();

  const pages = useRecoilValue(rPages);

  const navigateToDefaultPage = () => {
    const fallback = get(pages, 0, {});
    const defaultPage =
      pages.find((p) => p.id === get(app, "default_page")) || fallback;
    const userGroups = get(app, "user_groups");

    const defPageRoute = findDefaultPage({
      user,
      userGroups,
      pages,
      defaultPage,
      app,
      passesDisplayConditions,
    });

    if (defPageRoute) {
      navigate(defPageRoute);
    }
  };

  const navigationPageOrder = get(nav, "pageOrder", []);
  const navigationSections = get(nav, "sections", []);
  const customLinks = get(nav, "customLinks", []);

  const path = get(location, "pathname");

  const navigate = useNavigate();

  let combinedItems = [
    ...pages,
    ...customLinks.map((l) => ({
      ...l,
      isCustomLink: true,
      name: l.label,
      published: true,
    })),
  ];

  // Filter based on user groups
  combinedItems = filterBasedOnUserGroups({
    items: combinedItems,
    app,
    user,
    passesDisplayConditions,
  });

  const loggedInUser = !isEmpty(user);

  const finalPages = combinedItems
    .filter((p) => loggedInUser || !p.requireLogin) // If user is not logged in, only show pages that don't require login
    .filter(
      (p) =>
        isPreview ||
        p.published ||
        ["owner", "admin"].includes(get(user, "role"))
    )
    .filter((p) =>
      passesDisplayConditions({ conditions: get(p, "displayConditions", []) })
    )
    .map((p) => {
      const matchingConfig = navigationPageOrder.find((v) => v.value === p.id);

      return {
        // type: get(p, ["blocks", 0, "componentId"]),
        icon: p.icon || "HiOutlineDocument",
        label: p.name,
        section: get(matchingConfig, "section"),
        onClick: () => {
          if (p.isCustomLink) {
            if (p.link.includes("http") || p.link.includes("www.")) {
              window.open(p.link);
              // if (p.openNewWindow) {
              // } else {
              //   window.location.href = p.link;
              // }
            } else {
              navigate(addSlash(p.link));
            }
          } else {
            if (isPreview) {
              setPreviewApp({ ...previewApp, activePageRoute: p.route });
            } else {
              setModalStack([]);
              navigate(addSlash(p.route));
              // , { replace: true }
            }
          }
        },
        pageId: p.id,
        active:
          !p.isCustomLink &&
          (addSlash(activePageRoute) === addSlash(p.route) ||
            addSlash(path) === addSlash(p.route)),
      };
    });

  const sortedPages = sortPages(finalPages, navigationPageOrder);

  let sections = navigationSections.map((s) => ({
    ...s,
    items: sortedPages.filter((p) => p.section === s.id),
  }));

  const noSectionPages = sortedPages.filter(
    (p) => p.section === "" || !p.section || isNil(p.section)
  );
  const noSection =
    noSectionPages.length > 0
      ? [
          {
            label: null,
            items: noSectionPages,
          },
        ]
      : [];

  const finalSections = [...noSection, ...sections];

  const navigation = get(app, "navigation", {});

  const lightMode =
    (!isFrontlyAdmin || isPreview) && get(navigation, "textColor") === "light";

  const fontFamily = get(app, "logo_font");

  const firstTwoChars = get(app, "name", "").slice(0, 2);

  const primaryColor = get(app, "primary_color", colors.primary);

  const logoIcon = true;

  const logoFile = get(app, "navigation_logo") || get(app, "logo");

  const minimalSidebar =
    get(navigation, "type", "sidebar") === "sidebar" &&
    width > 800 &&
    get(navigation, "sidebarStyle") === "minimal";

  const headerContent = logoFile ? (
    <Logo
      minimalSidebar={minimalSidebar}
      onClick={isFrontlyAdmin ? null : navigateToDefaultPage}
      src={resizeImage({
        url: logoFile,
        height: 100,
      })}
    />
  ) : (
    <Row gap="8px" alignItems="center">
      {logoIcon && (
        <RoundIconWrapper
          background={primaryColor}
          minimalSidebar={minimalSidebar}
        >
          <RoundIconText minimalSidebar={minimalSidebar}>
            {firstTwoChars}
          </RoundIconText>
        </RoundIconWrapper>
      )}
      {!minimalSidebar && (
        <AppName
          color={lightMode ? "white" : primaryColor}
          fontFamily={fontFamily}
        >
          {app.name}
        </AppName>
      )}
    </Row>
  );

  if (hideNavigation) {
    return <></>;
  }

  return (
    <Navigation
      isPreview={isPreview}
      sections={finalSections}
      header={headerContent}
      dropdownLinks={[]}
      lightMode={lightMode}
      primaryColor={primaryColor}
      isPublicRoute={isPublicRoute}
      width={width}
    />
  );
};

const Logo = styled.img`
  max-width: 200px;
  height: 38px;
  object-fit: contain;
  object-position: left;
  @media screen and (max-width: 800px) {
    margin: 5px 0 0 0;
    max-width: 150px;
  }
  ${(p) =>
    p.minimalSidebar &&
    `
    max-width: 40px;
    height: 40px;
  `}
`;

export const RoundIconWrapper = styled.div`
  height: 36px;
  min-width: 36px;
  border-radius: 18px;

  ${(p) =>
    p.minimalSidebar &&
    `
    height: 46px;
    min-width: 46px;
    border-radius: 23px;
  `}

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;
  background: ${(p) => p.background};
  @media screen and (max-width: 800px) {
    height: 28px;
    min-width: 28px;
    border-radius: 14px;
  }
`;

const AppName = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${(p) => p.color};
  ${(p) => p.fontFamily && `font-family: ${p.fontFamily};`};
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const RoundIconText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: white;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export default ClientNavigation;
