import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import React from "react";

const RechartBar = ({ data, series }) => {
  return (
    <BarChart width={600} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      {series.map((item) => (
        <Bar dataKey={item.dataKey} fill={item.fill} />
      ))}
    </BarChart>
  );
};

export default RechartBar;
