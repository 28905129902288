import { isFrontlyAdmin, passesCondition } from "./utils";

import { get } from "lodash";
import { rActiveVersionMap } from "./recoil";
import useDynamicText from "app/renderingApp/useDynamicText";
import { useRecoilValue } from "recoil";

const useBlockVersion = () => {
  const activeVersionMap = useRecoilValue(rActiveVersionMap);

  const { processDynamicText } = useDynamicText();

  const getBlockWithVersion = (b) => {
    if (!b) {
      return {};
    }

    let blockWithVersion = { ...b };
    let versionData = {};

    let activeVersionId = null;

    const repeatingRecord = get(b, "repeatingRecord");

    if (isFrontlyAdmin) {
      // HANDLE ADMIN
      const activeBlockVersionId = get(activeVersionMap, get(b, "id"));
      const matchingVersion = get(b, "versions", []).find(
        (v) => v.id === activeBlockVersionId
      );
      if (matchingVersion) {
        versionData = get(matchingVersion, "data", {});
        blockWithVersion = {
          ...b,
          ...versionData,
          version_id: matchingVersion.id,
        };
        activeVersionId = activeBlockVersionId;
      }
    } else {
      // HANDLE LIVE MODE
      const versions = get(b, "versions", []);

      const activeVersion = versions.find((v) => {
        const conditionResults = get(v, "conditions", []).map((condition) => {
          const { value1, value2 } = condition;
          const pass = passesCondition({
            value1: processDynamicText({
              text: value1,
              reusableBlockId: get(b, "reusableBlockId"),
              context: { record: repeatingRecord },
            }),
            value2: processDynamicText({
              text: value2,
              reusableBlockId: get(b, "reusableBlockId"),
              context: { record: repeatingRecord },
            }),
            operator: get(condition, "operator", "equals"),
          });
          return pass;
        });

        const conditionType = get(v, "conditionType", "all");
        const passVersionConditions =
          conditionType === "all"
            ? conditionResults.every((r) => r)
            : conditionResults.some((r) => r);

        return passVersionConditions;
      });

      if (activeVersion) {
        versionData = get(activeVersion, "data", {});
        blockWithVersion = {
          ...b,
          ...versionData,
          version_id: activeVersion.id,
        };
      }
    }

    return { versionData, blockWithVersion, activeVersionId };
  };

  return { getBlockWithVersion };
};

export default useBlockVersion;
