import { Button, ColorPicker, Icon, Input } from "app/components";

import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import { safeArray } from "app/utils/utils";
import styled from "styled-components";
import { colors as themeColors } from "app/utils/theme";
import { useRecoilState } from "recoil";

const MultiColorPicker = ({ data }) => {
  const { value, onChange } = data;

  const [app, setApp] = useRecoilState(rApp);

  const colors = onChange
    ? safeArray(value)
    : get(app, ["styling", "colors"], []);

  const updateColors = (newColors) => {
    if (onChange) {
      onChange(newColors);
    } else {
      setApp({
        styling: {
          ...get(app, "styling"),
          colors: newColors,
        },
      });
    }
  };

  const deleteColor = (index) => {
    updateColors(colors.filter((c, ci) => ci !== index));
  };

  const addColor = () => {
    updateColors([...colors, { name: "", color: "#000000" }]);
  };

  const update = (key, value, index) => {
    updateColors(
      colors.map((c, ci) => {
        if (index === ci) {
          return { ...c, [key]: value };
        }
        return c;
      })
    );
  };

  return (
    <Container>
      {colors.map((c, i) => (
        <ColorContainer>
          <Input
            data={{
              value: c.name,
              width: "150px",
              onChange: (v) => update("name", v, i),
            }}
          />
          <ColorPicker
            data={{
              value: c.color,
              onChange: (v) => update("color", v, i),
            }}
          />
          <Icon
            data={{
              icon: "FiX",
              onClick: () => deleteColor(i),
              size: 20,
              color: themeColors.grey3,
            }}
          />
        </ColorContainer>
      ))}
      <Button
        data={{
          text: "Add Color",
          size: "small",
          icon: "FiPlus",
          type: "basic",
          onClick: addColor,
        }}
      />
    </Container>
  );
};

export default MultiColorPicker;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ColorContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
