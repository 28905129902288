import { Button } from "app/components";

const FormButton = ({ data }) => (
  <Button
    data={{
      ...data,
      size: data.size || "small",
      type: data.type || "basic",
    }}
  />
);

export default FormButton;
