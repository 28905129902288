import { rAppDateFormat, rSavedSpreadsheets } from "app/utils/recoil";

import MuiTimeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Typography from "@mui/material/Typography";
import { get } from "lodash";
import { sortRecords } from "./Table";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import { useRecoilValue } from "recoil";
import useUtils from "../useUtils";

const Timeline = ({ page, block }) => {
  const { processObjects } = useProcessObjects();
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const { getListData } = useListData();

  const { processDynamicText } = useUtils();

  let timelineEvents = getListData(block);

  const items = processObjects(
    page,
    block,
    [],
    timelineEvents,
    processDynamicText
  );

  const sortingColumn = get(block, "sortingColumn");
  const sortingDirection = get(block, "sortingDirection", "asc");

  if (sortingColumn) {
    const spreadsheet = savedSpreadsheets.find(
      (s) => s.id === block.spreadsheet
    );

    const fieldData = get(
      spreadsheet,
      ["field_data", "config", sortingColumn],
      {}
    );

    let columnType = "text";

    if (get(fieldData, "componentId") === "DateTimePicker") {
      columnType = "date";
    }

    // Sort rows
    timelineEvents = sortRecords(
      items,
      sortingColumn,
      sortingDirection,
      columnType,
      get(fieldData, "dateFormat", {}),
      appDateFormat
    );
  }

  return (
    <MuiTimeline>
      {timelineEvents.length === 0 && <div>No results</div>}
      {timelineEvents?.map((event, index) => {
        return (
          <TimelineItem key={index}>
            {block.leftContent && (
              <TimelineOppositeContent>
                {get(event, block.leftContent)}
              </TimelineOppositeContent>
            )}
            <TimelineSeparator>
              <TimelineDot
                style={event.icon ? { height: "30px", width: "30px" } : {}}
              ></TimelineDot>
              {index !== timelineEvents.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography>{get(event, block.rightContent)}</Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </MuiTimeline>
  );
};
// sx={{ py: "12px", px: 2 }}
export default Timeline;
