import { get, startCase } from "lodash";
import { rApp, rSavedSpreadsheets } from "app/utils/recoil";

import { AdminForm } from "app/adminApp/components";
import { safeArray } from "app/utils/utils";
import { useRecoilValue } from "recoil";

const UserActionEditor = ({ data }) => {
  const activeApp = useRecoilValue(rApp);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const value = get(data, "value", {});

  const schema = safeArray(activeApp, "user_schema").map((f) => f.name);

  const usersSheetId = get(activeApp, ["users_sheet_config", "spreadsheet_id"]);
  const usersSheetHeaders = get(
    savedSpreadsheets.find((s) => s.id === usersSheetId),
    "headers",
    []
  );

  let fields = ["first_name", "last_name", ...schema];

  fields = [
    ...fields,
    ...usersSheetHeaders.filter(
      (h) => !fields.includes(h) && h !== "frontly_id" && h !== "email"
    ),
  ];

  // Map to objects
  fields = fields.map((f) => ({
    key: f,
    id: f,
    label: startCase(f),
    componentId: "DynamicString",
    value: get(value, f, ""),
  }));

  return (
    <AdminForm
      sectionPadding="0px"
      labelWidth="200px"
      onChange={(k, v) => data.onChange({ ...value, [k]: v })}
      fields={fields}
    />
  );
};

export default UserActionEditor;
