import { rAiBlockData, rWebsocketRequests } from "app/utils/recoil";

import { Button } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useState } from "react";

const AIBlock = ({ block, page }) => {
  const [prompt, setPrompt] = useState("");

  const [websocketRequests, setWebsocketRequests] =
    useRecoilState(rWebsocketRequests);

  const [aiBlockData, setAiBlockData] = useRecoilState(rAiBlockData);

  const blockData = get(aiBlockData, block.id, {});

  const data = get(blockData, "data");

  const isFetching = get(blockData, "isFetching", false);

  const hiddenFilters = get(block, "hiddenFilters", []);

  const excludedColumns = get(block, "excludedColumns", "")
    .split(",")
    .map((x) => x.trim());

  const submit = () => {
    setAiBlockData((prevState) => ({
      ...prevState,
      [block.id]: { isFetching: true, data: null },
    }));

    const instanceId = (
      Math.random().toString(36) + Math.random().toString(36)
    ).substr(2, 12);

    setWebsocketRequests([
      ...websocketRequests,
      {
        instanceId,
        blockId: block.id,
      },
    ]);

    apiRequest.post("/ai_block_action/", {
      prompt,
      data_source_id: block.spreadsheet,
      max_characters: block.maxCharacters,
      data_source_context_filters: hiddenFilters,
      instance_id: instanceId,
      excluded_columns: excludedColumns,
    });
  };

  const reset = () => {
    setAiBlockData((prevState) => ({ ...prevState, [block.id]: null }));
  };

  return (
    <div>
      {data && (
        <>
          <Title>{prompt}</Title>
          <Response>{data}</Response>
          <Button
            data={{
              onClick: reset,
              text: "Ask Another Question",
              type: "basic",
              icon: "FiRefreshCcw",
              size: "large",
            }}
          />
        </>
      )}

      {!data && (
        <>
          <Title>{get(block, "label", "Ask anything about your data")}</Title>
          <StyledTextArea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <Button
            data={{
              onClick: submit,
              isFetching,
              text: get(block, "submitButtonText", "Submit"),
              size: "large",
            }}
          />
        </>
      )}
    </div>
  );
};

export default AIBlock;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: ${colors.default};
`;

const Response = styled.div`
  font-size: 40px;
  font-weight: 300;
  color: ${colors.default};
  margin: 20px 0 20px 0;
`;

const StyledTextArea = styled.textarea`
  margin: 20px 0 15px 0;
  resize: none;
  min-height: 120px;
  height: fit-content;
  background: white;
  border: 0px;
  width: 100%;
  padding: 20px;
  font-size: 18px;
  outline: none;
  font-weight: 400;
  border-radius: 15px;
  color: ${colors.default};
  min-width: 400px;
  border: 1px solid ${colors.divider};
  width: 100%;
  cursor: pointer;
  &::placeholder {
    color: ${colors.grey3};
  }
  @media screen and (max-width: 800px) {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
  }
`;
