import { arrayMove, isBlockChildOf } from "app/utils/utils";
import { get, isEmpty, isNil } from "lodash";
import { useDrag, useDrop } from "react-dnd";
import { useEffect, useRef } from "react";

import useHoverPosition from "./useHoverPosition";

// I don't really know what changed, but for some reason, some layoutParents are null and some are undefined, which is causing issues when comparing them.
const matchingLayoutParents = (a, b) => {
  if (isNil(a) && isNil(b)) {
    return true;
  } else if (a === b) {
    return true;
  }
  return false;
};

const getBlocksMatchingLayoutParent = (blocks, layoutParent) => {
  let childBlocks = [];
  let otherBlocks = [];

  blocks.forEach((b) => {
    if (matchingLayoutParents(b.layoutParent, layoutParent)) {
      childBlocks.push(b);
    } else {
      otherBlocks.push(b);
    }
  });

  return { childBlocks, otherBlocks };
};

export const useDragAndDropHandlers = ({
  page,
  block,
  index,
  parentType,
  containerRef,
  setPage,
  blocks,
  addBlock,
}) => {
  const componentId = get(block, "componentId");

  const blockId = get(block, "id");

  const canDrop = true;

  const isRowOrColumn = ["Row", "Column"].includes(componentId);

  let type = "element";
  if (isRowOrColumn) {
    type = componentId.toLowerCase();
  }

  const id = blockId;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type,
      item: { id, type },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, type]
  );

  const pageRef = useRef(page);
  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const blocksRef = useRef(blocks);
  useEffect(() => {
    blocksRef.current = blocks;
  }, [blocks]);

  const hoverLocation = useHoverPosition(containerRef);

  const hoverLocationRef = useRef(hoverLocation);
  useEffect(() => {
    hoverLocationRef.current = hoverLocation;
  }, [hoverLocation]);

  const indexRef = useRef(index);
  useEffect(() => {
    indexRef.current = index;
  }, [index]);

  const layoutParentRef = useRef(block.layoutParent);
  useEffect(() => {
    layoutParentRef.current = block.layoutParent;
  }, [block.layoutParent]);

  const handleDifferentContainer = (item, newBlock = null) => {
    const currentBlocks = blocksRef.current;

    const { childBlocks, otherBlocks } = getBlocksMatchingLayoutParent(
      currentBlocks,
      layoutParentRef.current
    );

    const originalIndex = indexRef.current;

    const dragItem = currentBlocks.find((b) => b.id === item.id);

    let selectedIndex = 0;

    const isMiddle =
      hoverLocationRef.current.isInMiddle50PercentHorizontally &&
      hoverLocationRef.current.isInMiddle50PercentVertically;

    if (
      (["Column", null].includes(parentType) &&
        hoverLocationRef.current.isInUpperHalf) ||
      (parentType === "Row" && hoverLocationRef.current.isInLeftHalf)
    ) {
      selectedIndex = originalIndex;
    } else if (
      (["Column", null].includes(parentType) &&
        hoverLocationRef.current.isInLowerHalf) ||
      (parentType === "Row" && hoverLocationRef.current.isInRightHalf)
    ) {
      selectedIndex = originalIndex + 1;
    }

    let newBlockObj = {
      ...newBlock,
      layoutParent: layoutParentRef.current,
    };

    let newChildBlocks = [...childBlocks];

    if (newBlock) {
      if (isRowOrColumn && isMiddle) {
        newBlockObj["layoutParent"] = block.id;
      }

      // console.log("newChildBlocks", [...childBlocks]);
      // console.log("newBlockObj", newBlockObj);
      // console.log("selectedIndex", selectedIndex);

      newChildBlocks.splice(selectedIndex, 0, newBlockObj);

      // console.log("newChildBlocks 2", newChildBlocks);

      const finalBlocks = [...otherBlocks, ...newChildBlocks];

      // console.log("finalBlocks", finalBlocks);

      // Insert the new blocks array back into the page
      setPage({
        ...pageRef.current,
        blocks: finalBlocks,
      });

      return;
    }

    // insert the block into the new child blocks array at selectedIndex
    newChildBlocks.splice(selectedIndex, 0, {
      ...dragItem,
      layoutParent: layoutParentRef.current,
    });

    const newOtherBlocks = otherBlocks.filter((b) => b.id !== item.id);

    const finalBlocks = [...newOtherBlocks, ...newChildBlocks];

    // Insert the new blocks array back into the page
    setPage({
      ...pageRef.current,
      blocks: finalBlocks,
    });
  };

  const handleNonRowColumnDrop = (item) => {
    const originalIndex = indexRef.current;

    let newBlock = null;

    if (item.blockType) {
      newBlock = addBlock({
        type: item.blockType,
        returnInsteadOfAdd: true,
        latestBlocks: blocksRef.current,
        layoutParent: layoutParentRef.current,
      });
    }

    const currentBlocks = blocksRef.current;

    const { childBlocks, otherBlocks } = getBlocksMatchingLayoutParent(
      currentBlocks,
      layoutParentRef.current
    );

    const dragItemIndex = childBlocks.findIndex((b) => b.id === item.id);
    const dragItem = currentBlocks.find((b) => b.id === item.id);

    const isDifferentContainer = !matchingLayoutParents(
      get(dragItem, "layoutParent"),
      layoutParentRef.current
    );

    if (isDifferentContainer || newBlock) {
      handleDifferentContainer(item, newBlock);
      return;
    }

    let addedIndex = 0;

    const dragDirection = originalIndex > dragItemIndex ? "up" : "down";

    if (
      (["Column", null].includes(parentType) &&
        hoverLocationRef.current.isInUpperHalf) ||
      (parentType === "Row" && hoverLocationRef.current.isInLeftHalf)
    ) {
      addedIndex = originalIndex;

      // If dragging up, subtract 1 from the added index, but skip if it's dragged from the block header
      if (dragDirection === "up") {
        addedIndex -= 1;
      }
    } else if (
      (["Column", null].includes(parentType) &&
        hoverLocationRef.current.isInLowerHalf) ||
      (parentType === "Row" && hoverLocationRef.current.isInRightHalf)
    ) {
      addedIndex = originalIndex;

      // If dragging down, add 1 to the added index, but skip if it's dragged from the block header
      if (dragDirection === "down") {
        addedIndex += 1;
      }
    }

    // Re-order the child blocks
    const reorderedChildBlocks = arrayMove(
      childBlocks,
      dragItemIndex,
      addedIndex
    );

    // Insert the newly ordered child blocks back into the blocks array after the parent block index
    const newBlocks = [...otherBlocks, ...reorderedChildBlocks];

    setPage({
      ...pageRef.current,
      blocks: newBlocks,
    });
  };

  // Handle dropping
  const handleRowColumnDrop = (item) => {
    const currentBlocks = blocksRef.current;

    const newBlocks = currentBlocks.map((b) => {
      if (b.id === item.id) {
        return {
          ...b,
          layoutParent: id,
        };
      }

      return b;
    });

    setPage({
      ...pageRef.current,
      blocks: newBlocks,
    });
  };

  // Handle drop routing
  const onDrop = (item) => {
    const currentBlocks = blocksRef.current;
    const matchingDragItem = currentBlocks.find((b) => b.id === item.id);

    if (item.blockType) {
      handleNonRowColumnDrop(item);
      return;
    }

    const isChildOf = isBlockChildOf(
      block,
      matchingDragItem,
      blocksRef.current
    );

    if (
      isChildOf ||
      blockId === item.id ||
      blockId === matchingDragItem.layoutParent
    ) {
      return;
    }

    // Figure out if I should use this Or just the single check below
    const isMiddle =
      hoverLocationRef.current.isInMiddle50PercentHorizontally &&
      hoverLocationRef.current.isInMiddle50PercentVertically;

    const treatAsNonRowOrColumn =
      (["Column", null].includes(parentType) && !isMiddle) ||
      (parentType === "Row" && !isMiddle);

    if (!isRowOrColumn || treatAsNonRowOrColumn) {
      handleNonRowColumnDrop(item);
    } else {
      handleRowColumnDrop(item);
    }
  };

  const [{ isOver, canDropHere }, drop] = useDrop(
    () => ({
      accept: canDrop ? ["row", "column", "element"] : [],
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        onDrop(item);
        return { targetId: id };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        canDropHere: monitor.canDrop(),
      }),
    }),
    [id, canDrop]
  );

  let position = {};

  if (isOver) {
    if (isRowOrColumn) {
      const hasChildren = blocks.find((b) => b.layoutParent === blockId);

      // HAS NO CHILDREN
      if (!hasChildren) {
        // DROPPING INTO A ROW OR COLUMN
        if (
          componentId === "Row" &&
          get(hoverLocation, "isInMiddle50PercentVertically") &&
          get(hoverLocation, "isInMiddle50PercentHorizontally")
        ) {
          position = {
            top: "50%",
            right: "15%",
            height: "5px",
            left: "15%",
          };
        } else if (
          componentId === "Column" &&
          get(hoverLocation, "isInMiddle50PercentVertically") &&
          get(hoverLocation, "isInMiddle50PercentHorizontally")
        ) {
          position = {
            top: "50%",
            right: "15%",
            height: "5px",
            left: "15%",
          };
        }
      }

      // HAS CHILDREN OR HAS NO POSITION SET YET
      if (hasChildren || isEmpty(position)) {
        // console.log("HAS CHILDREN OR HAS NO POSITION SET YET");
        if (["Column", null].includes(parentType)) {
          // IN COLUMN OR AT ROOT, WHICH IS TECHNICALLY A COLUMN
          if (get(hoverLocation, "isInUpperHalf")) {
            position = {
              height: "5px",
              left: 0,
              right: 0,
              top: 0,
            };
          } else if (get(hoverLocation, "isInLowerHalf")) {
            position = {
              height: "5px",
              left: 0,
              right: 0,
              bottom: 0,
            };
          }
        } else {
          // IN ROW
          if (get(hoverLocation, "isInLeftHalf")) {
            position = {
              width: "5px",
              top: 0,
              bottom: 0,
              left: 0,
            };
          } else if (get(hoverLocation, "isInRightHalf")) {
            position = {
              width: "5px",
              top: 0,
              bottom: 0,
              right: 0,
            };
          }
        }
      }
    }

    // DROPPING INTO A NON ROW OR COLUMN
    else if (["Column", null].includes(parentType)) {
      // IN COLUMN OR AT ROOT, WHICH IS TECHNICALLY A COLUMN
      if (get(hoverLocation, "isInUpperHalf")) {
        position = {
          height: "5px",
          left: 0,
          right: 0,
          top: 0,
        };
      } else if (get(hoverLocation, "isInLowerHalf")) {
        position = {
          height: "5px",
          left: 0,
          right: 0,
          bottom: 0,
        };
      }
    } else {
      // IN ROW
      if (get(hoverLocation, "isInLeftHalf")) {
        position = {
          width: "5px",
          top: 0,
          bottom: 0,
          left: 0,
        };
      } else if (get(hoverLocation, "isInRightHalf")) {
        position = {
          width: "5px",
          top: 0,
          bottom: 0,
          right: 0,
        };
      }
    }
  }

  const isDropTarget =
    isOver && canDropHere && ["Row", "Column"].includes(componentId);

  return {
    drag,
    drop,
    isDragging,
    isDropTarget,
    position,
    dragPreviewFunction: preview,
  };
};
