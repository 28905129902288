import { Button, Row, Text } from "app/components";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import { rApp } from "app/utils/recoil";
import styled from "styled-components";
import useActionResolver from "../useActionResolver";
import { useRecoilValue } from "recoil";
import useUtils from "app/renderingApp/useUtils";

const TextButtonRow = ({ page, block }) => {
  const { processDynamicText } = useUtils();

  const activeApp = useRecoilValue(rApp);

  const { actionResolver } = useActionResolver(page);
  const text = processDynamicText({
    text: get(block, "text", "Text"),
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord: get(block, "repeatingRecord") },
  });
  const color = get(activeApp, "primary_color", colors.primary);
  const actions = get(block, "actions", []);
  const pageActions = get(page, "actions", []);
  const styling = get(activeApp, "styling", {});
  const buttonBorderRadius = get(styling, "buttonBorderRadius");

  return (
    <Container>
      <Text data={{ text, fontStyle: "headingLg" }} />
      <Row gap="15px" style={{ flexWrap: "wrap" }}>
        {actions.map((a) => {
          const matchingAction = pageActions.find((pa) => pa.id === a.value);
          return (
            <Button
              data={{
                borderRadius: buttonBorderRadius
                  ? getPixels(buttonBorderRadius)
                  : null,
                backgroundColor: color,
                text: processDynamicText({
                  text: get(matchingAction, "label"),
                  reusableBlockId: block.reusableBlockId,
                  context: { repeatingRecord: get(block, "repeatingRecord") },
                }),
                onClick: () => actionResolver({ actionId: a.value }),
              }}
            />
          );
        })}
      </Row>
    </Container>
  );
};

export default TextButtonRow;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;
