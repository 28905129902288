import { AIBlock } from "./AIBlock";
import { Button } from "./Button";
import { Calendar } from "./Calendar";
import { Chart } from "./Chart";
import { Chart2 } from "./Chart2";
import { Chatbot } from "./Chatbot";
import { Column } from "./Column";
import { Custom } from "./Custom";
import { Form } from "./Form";
import { Grid } from "./Grid";
import { Icon } from "./Icon";
import { Iframe } from "./Iframe";
import { Image } from "./Image";
import { InfoList } from "./InfoList";
import { Input } from "./Input";
import { Kanban } from "./Kanban";
import { Layout } from "./Layout";
import { Map } from "./Map";
import { Menu } from "./Menu";
import { Placeholder } from "./Placeholder";
import { Progress } from "./Progress";
import { QuoteCalculator } from "./QuoteCalculator";
import { RichText } from "./RichText";
import { Row } from "./Row";
import { Select } from "./Select";
import { SmartBlock } from "./SmartBlock";
import { Stat } from "./Stat";
import { Switch } from "./Switch";
import { Table } from "./Table";
import { Text } from "./Text";
import { TextArea } from "./TextArea";
import { TextButtonRow } from "./TextButtonRow";
import { Timeline } from "./Timeline";
import { Video } from "./Video";

export const blockConfigs = {
  Switch,
  TextArea,
  Input,
  Select,
  AIBlock,
  SmartBlock,
  Button,
  Calendar,
  Chatbot,
  Form,
  Grid,
  Kanban,
  Table,
  Text,
  Chart,
  Chart2,
  Stat,
  Iframe,
  Map,
  Timeline,
  InfoList,
  Layout,
  Row,
  Column,
  Image,
  Video,
  TextButtonRow,
  RichText,
  QuoteCalculator,
  Icon,
  Progress,
  Custom,
  Placeholder,
  Menu,
};
