import { Icon, Text } from "app/components";
import { getPixels, isFrontlyAdmin } from "app/utils/utils";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import useUtils from "../useUtils";

const IFrame = ({ block }) => {
  const { processDynamicText } = useUtils();
  const iframeUrl = processDynamicText({
    text: get(block, "url", ""),
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord: get(block, "repeatingRecord") },
  });

  return (
    <div
      style={{
        height: getPixels(block.height) || "400px",
        width: "100%",
      }}
    >
      {isFrontlyAdmin && (
        <Container>
          <Icon data={{ icon: "FiGlobe", color: colors.grey3 }} />
          <Text
            data={{
              text: "Iframe content will render in live mode",
              fontStyle: "bodyXl",
            }}
          />
        </Container>
      )}
      {!isFrontlyAdmin && iframeUrl && (
        <iframe
          allow="microphone; fullscreen"
          src={iframeUrl}
          style={{ border: "0px", borderRadius: "8px" }}
          width="100%"
          height={"100%"}
          title="iframe description"
          allowFullScreen
        />
      )}
    </div>
  );
};

export default IFrame;

const Container = styled.div`
  background: white;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;
