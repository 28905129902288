import Checkbox from "./Checkbox";
import { get } from "lodash";

// interface IOption {
//   label: string;
//   value: string;
// }

// interface Data {
//   options: IOption[];
//   onChange: (values: any) => void;
// }

const CheckboxesGroup = ({ data }) => {
  const options = get(data, "options", []);

  return (
    <>
      {!options || (options.length === 0 && <p>No options provided</p>)}
      {options.map((o, index) => (
        <Checkbox
          key={index}
          data={{
            width: "auto",
            label: o.label,
            value: o.value,
            onChange: () => data.onChange(index),
          }}
        />
      ))}
    </>
  );
};

export default CheckboxesGroup;
