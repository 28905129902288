import { cloneDeep, get } from "lodash";

import { blockConfigs } from "./blockConfigs";

const useAdminAppUtils = () => {
  // Recursive function to find all child (and nested child) block IDs
  const findAllChildBlockIds = (blockId, blocks) => {
    let childBlockIds = [];

    blocks.forEach((block) => {
      if (block.parent === blockId || block.layoutParent === blockId) {
        childBlockIds.push(block.id);
        // Recursively find nested children
        childBlockIds = childBlockIds.concat(
          findAllChildBlockIds(block.id, blocks)
        );
      }
    });

    return childBlockIds;
  };

  const getCleanedBlocksAndActions = (
    blockId,
    currentBlocks,
    currentActions
  ) => {
    let blocksCopy = cloneDeep(currentBlocks);
    let actionsCopy = cloneDeep(currentActions);

    // Find all child block IDs for the target block, including nested children
    const allChildBlockIds = findAllChildBlockIds(blockId, blocksCopy);
    // Include the target block ID itself for deletion
    allChildBlockIds.push(blockId);

    // Collect all action IDs associated with the blocks being deleted
    let actionIdsToDelete = [];

    allChildBlockIds.forEach((id) => {
      const block = blocksCopy.find((b) => b.id === id);
      if (block) {
        const activeBlockConfig = get(blockConfigs, get(block, "componentId"));

        const resources = get(activeBlockConfig, "resources", []);

        const customActionsResources = resources.filter((r) => r.isAction);

        customActionsResources.forEach((r) => {
          if (r.isMultiAction) {
            // MULTI ACTIONS
            const matchingMultiActionsArray = get(block, r.id, []);
            const matchingMultiActionIds = matchingMultiActionsArray.map(
              (obj) => obj.value
            );
            matchingMultiActionIds.forEach((actionId) => {
              actionIdsToDelete.push(actionId);
            });

            // Also handle each version of the block and their actions
            get(block, "versions", []).forEach((v) => {
              const versionData = get(v, "data", {});
              const matchingMultiActionsArray = get(versionData, r.id, []);
              const matchingMultiActionIds = matchingMultiActionsArray.map(
                (obj) => obj.value
              );
              matchingMultiActionIds.forEach((actionId) => {
                actionIdsToDelete.push(actionId);
              });
            });
          } else {
            // Also handle each version of the block and their actions
            get(block, "versions", []).forEach((v) => {
              const versionData = get(v, "data", {});
              const targetActionId = get(versionData, r.id);
              if (targetActionId) {
                actionIdsToDelete.push(targetActionId);
              }
            });

            // SINGLE ACTION
            const targetCustomActionId = get(block, r.id);
            if (targetCustomActionId) {
              actionIdsToDelete.push(targetCustomActionId);
            }
          }
        });
      }
    });

    // Filter blocks to remove the target block and all its children
    blocksCopy = blocksCopy.filter(
      (block) => !allChildBlockIds.includes(block.id)
    );

    // Filter actions to remove actions associated with deleted blocks
    actionsCopy = actionsCopy.filter(
      (action) => !actionIdsToDelete.includes(get(action, "id"))
    );

    return {
      finalBlocks: blocksCopy,
      finalActions: actionsCopy,
    };
  };

  return {
    findAllChildBlockIds,
    getCleanedBlocksAndActions,
  };
};

export default useAdminAppUtils;
