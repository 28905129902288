import { Button, Spinner } from "app/components";
import {
  CenterContainer,
  HeaderDescription,
  HeaderText,
  Main,
} from "./components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, startCase } from "lodash";
import {
  rApp,
  rOrganization,
  rPreviewApp,
  rSetConfig,
  rSetOrganization,
  rSubscription,
} from "app/utils/recoil";
import {
  rCurrentContext,
  rCurrentStep,
  rDescription,
  rError,
  rFetching,
  rFirstPage,
  rLocalData,
  rOptionTab,
  rResetCounter,
} from "./state";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AIPreview from "app/adminApp/pageBuilder/AIPreview";
import Complete from "./screens/Complete";
import Error from "./screens/Error";
import Footer from "./screens/Footer";
import GoogleIntegration from "app/adminApp/spreadsheets/GoogleIntegration";
import LoadingAnimation from "../LoadingAnimation";
import PageBlocks from "./screens/PageBlocks";
import Pages from "./screens/Pages";
import Prompt from "./screens/Prompt";
import SampleRecords from "./screens/SampleRecords";
import SheetColumns from "./screens/SheetColumns";
import Sidebar from "./screens/Sidebar";
import Spreadsheets from "./screens/Spreadsheets";
import { apiRequest } from "app/utils/apiRequests";
import { decryptConfig } from "app/utils/encryptionUtils";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";

const AiGeneration = () => {
  const navigate = useNavigate();

  const activeApp = useRecoilValue(rApp);

  const subscription = useRecoilValue(rSubscription);

  let aiLimit = get(subscription, "ai_requests_limit", 5);

  const [generateIn, setGenerateIn] = useState("NEW");

  const setResetCounter = useSetRecoilState(rResetCounter);
  const setLocalData = useSetRecoilState(rLocalData);
  const setFirstPage = useSetRecoilState(rFirstPage);
  const [description, setDescription] = useRecoilState(rDescription);
  const setError = useSetRecoilState(rError);
  const [fetching, setFetching] = useRecoilState(rFetching);
  const fetchingRef = useRef(fetching); // Initialize the ref with the current state

  const [currentStep, setCurrentStep] = useRecoilState(rCurrentStep);
  const [currentContext, setCurrentContext] = useRecoilState(rCurrentContext);
  const setPreviewApp = useSetRecoilState(rPreviewApp);
  const [showSheetsConnect, setShowSheetsConnect] = useState(false);
  const googleConnected = get(activeApp, "google_sheets_connected");
  const showGoogleConnect = !googleConnected && showSheetsConnect;

  const [timeoutId, setTimeoutId] = useState(null);
  const timeoutIdRef = useRef(timeoutId);

  const nextStep = get(nextStepMap, currentStep, "spreadsheets");

  const params = useParams();
  const sessionId = get(params, "id");

  // Update the ref whenever the state changes
  useEffect(() => {
    fetchingRef.current = fetching;
  }, [fetching]);

  useEffect(() => {
    timeoutIdRef.current = timeoutId;
  }, [timeoutId]);

  const timeoutFunction = () => {
    if (timeoutIdRef.current && fetchingRef.current) {
      setError("This request took a bit too long. Please try again.");
      setFetching(null);
    }
  };

  const handleSubmit = () => {
    setFetching("generating");
    setError(false);

    const timeoutId = setTimeout(timeoutFunction, 30000);
    setTimeoutId(timeoutId);

    if (window.ws && window.ws.readyState === WebSocket.OPEN) {
      // Set 30 second timer to prevent infinite loading

      window.ws.send(
        JSON.stringify({
          action: "ai_2024",
          description,
          context: currentContext,
          step: nextStep,
          session_id: sessionId,
        })
      );
    } else {
      console.error("WebSocket is not open.");
    }

    mixpanel.track(`AI - ${startCase(currentStep) || "Prompt"}`);
  };

  const resetApp = (triggerCounter) => {
    if (triggerCounter) {
      setResetCounter((prev) => prev + 1);
    } else {
      setResetCounter(0);
    }
    setPreviewApp(null);
    setFirstPage(null);
    setError(false);
    setLocalData(null);
    setCurrentContext(null);
    setCurrentStep(null);
    setFetching(null);
    navigate("/chat/new");
    setDescription("");
  };

  if (aiLimit === 0) {
    aiLimit = 5;
  }

  const showFooter =
    !showGoogleConnect && currentStep && currentStep !== "preview";

  const removeTimeout = () => {
    clearTimeout(timeoutIdRef.current);
    setTimeoutId(null);
  };

  return (
    <Background>
      <Sidebar inAiFlow showUsage={googleConnected} />
      <Body isPrompt={!currentStep}>
        <Content
          generateIn={generateIn}
          handleSubmit={handleSubmit}
          resetApp={resetApp}
          showSheetsConnect={showSheetsConnect}
          setShowSheetsConnect={setShowSheetsConnect}
          removeTimeout={removeTimeout}
        />
      </Body>
      {showFooter && <Footer handleSubmit={handleSubmit} />}
    </Background>
  );
};

export const nextStepMap = {
  spreadsheets: "sheet_columns",
  sheet_columns: "sample_records",
  sample_records: "pages",
  pages: "page_blocks",
  page_blocks: "block_details",
  block_details: "complete",
};

const statusMap = {
  spreadsheets: "Generating spreadsheets",
  sheet_columns: "Generating sheet columns",
  sample_records: "Generating sample data",
  pages: "Generating pages",
  page_blocks: "Generating blocks",
  block_details: "Finalizing app",
};

const Content = ({
  generateIn,
  handleSubmit,
  resetApp,
  showSheetsConnect,
  setShowSheetsConnect,
  removeTimeout,
}) => {
  const navigate = useNavigate();

  const organization = useRecoilValue(rOrganization);

  const setOrganization = useSetRecoilState(rSetOrganization);

  const activeApp = useRecoilValue(rApp);

  const subscription = useRecoilValue(rSubscription);

  let aiLimit = get(subscription, "ai_requests_limit", 5);

  const aiUsage = get(organization, "ai_usage", 0);

  // This seems to hold the modified data after the API processes it
  const [localData, setLocalData] = useRecoilState(rLocalData);

  const [error, setError] = useRecoilState(rError);
  const [fetching, setFetching] = useRecoilState(rFetching);
  const fetchingRef = useRef(fetching); // Initialize the ref with the current state
  const [firstPage, setFirstPage] = useRecoilState(rFirstPage);
  const setOptionTab = useSetRecoilState(rOptionTab);
  const [currentStep, setCurrentStep] = useRecoilState(rCurrentStep);
  const setCurrentContext = useSetRecoilState(rCurrentContext);
  const setDescription = useSetRecoilState(rDescription);

  const setConfig = useSetRecoilState(rSetConfig);

  useEffect(() => {
    fetchingRef.current = fetching;
  }, [fetching]);

  const [previewApp, setPreviewApp] = useRecoilState(rPreviewApp);
  const resetCounter = useRecoilValue(rResetCounter);

  const params = useParams();
  const sessionId = get(params, "id");

  const location = useLocation();
  const isInternalAdmin = get(location, "pathname").includes("internal_admin");

  const nextStep = get(nextStepMap, currentStep, "spreadsheets");

  useEffect(() => {
    if (sessionId === "new") {
      resetApp();

      // START NEW SESSION
      setFetching("thread");
      apiRequest.post("ai/session/").then((r) => {
        const sessionId = get(r, ["data", "session_id"]);
        navigate(`/chat/${sessionId}`);
        setFetching(null);
        setCurrentContext(null);
      });
    } else {
      // LOAD EXISTING SESSION
      setCurrentContext(null);
      setCurrentStep(null);
      setFetching("thread");
      apiRequest
        .get(
          `${
            isInternalAdmin ? "/internal_admin" : ""
          }/ai/session/?id=${sessionId}`,
          {
            timeout: 60000,
          }
        )
        .then((r) => {
          const data = get(r, "data", {});
          const step = get(data, "step", {});
          const prompt = get(data, "prompt", {});
          setDescription(prompt);

          const dataData = get(data, "data", {});

          if (step === "preview") {
            processPreview(dataData);
            setLocalData(dataData);
          }

          setFetching(null);
          setCurrentStep(step);
          setCurrentContext(get(data, "context", {}));
        });
    }
  }, [resetCounter]);

  const [shouldReconnect, setShouldReconnect] = useState(true);
  const reconnectAttempts = useRef(0);

  const connectWebSocket = () => {
    const isOpen = window.ws && window.ws.readyState === WebSocket.OPEN;

    if (sessionId !== "new" && shouldReconnect && !isOpen) {
      window.ws = new WebSocket(
        "wss://o0v9pmvz0g.execute-api.ca-central-1.amazonaws.com/production"
      );

      window.ws.onopen = () => {
        console.log("WebSocket Connected");
        reconnectAttempts.current = 0; // Reset reconnect attempts on successful connection
      };

      window.ws.onmessage = (event) => {
        const messageData = JSON.parse(event.data);

        const context = get(messageData, "context", {});
        const error = get(messageData, "error");
        const step = get(messageData, "step");
        const msgData = get(messageData, "data");

        if (!step || !fetchingRef.current) {
          // random timeout error - ignore
          return;
        }

        removeTimeout();

        // SAVE PROGRESS
        apiRequest.post("/ai/save_progress/", {
          step,
          error,
          data: msgData,
          prompt: get(messageData, "prompt"),
          session_id: sessionId,
        });

        if (error || step === "complete") {
          // Save response, no matter what happened
          apiRequest
            .post(
              "/ai/preview/",
              { ...messageData, context, session_id: sessionId },
              { timeout: 60000 }
            )
            .then((r) => {
              const modifiedData = get(r, ["data", "modified_data"]);

              if (error) {
                errorNotification(
                  error || "An unexpected error occurred. Please try again."
                );
                setError(error);
                setFetching(null);
              } else if (step === "complete") {
                setFetching(null);
                successNotification("App Generation Complete");
                processPreview(modifiedData);
              }
            });
        } else {
          setFetching(null);
          setCurrentStep(step);
          setCurrentContext((prev) => ({ ...prev, [step]: msgData }));
          setOptionTab(null);
        }
      };

      window.ws.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      window.ws.onclose = () => {
        console.log("WebSocket Disconnected");
        if (shouldReconnect) {
          // Implement retry logic (could be exponential backoff)
          setTimeout(
            connectWebSocket,
            Math.min(10000, 500 * 2 ** reconnectAttempts.current)
          ); // Example: retry with an increasing delay
          reconnectAttempts.current += 1;
        }
      };
    }
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      window.ws?.close();
      setShouldReconnect(false); // Prevent reconnection when the component is unmounting
    };
  }, [sessionId]); // Re-run effect if sessionId changes

  const processPreview = (c) => {
    if (!c) {
      setError(true);
      return;
    }

    // At some point, reconcile this with the 'currentContext' state so there's only 1
    setLocalData(c);

    const sampleData = get(c, "sample_records", {});

    const pages = get(c, "pages", []).filter(
      (p) => get(p, "blocks", []).length > 0
    );

    setCurrentStep("preview");

    setPreviewApp({
      ...c,
      pages,
      sampleData,
      activePageRoute: get(pages, [0, "route"]),
      custom_app_object: {
        navigation: {
          type: "header",
          textColor: "light",
          backgroundColor: "#1d3557",
        },
        primary_color: "#457b9d",
      },
    });
  };

  // STEP 3 - Finalize App
  const finalizeApp = () => {
    mixpanel.track(`AI - Finalize`);
    setFetching("finalizing");
    setCurrentStep(null);

    let spreadsheets = {};
    Object.keys(get(localData, "data", {})).forEach((sheetKey) => {
      spreadsheets[sheetKey] = {
        schema: get(localData, ["data", sheetKey]),
        data: get(localData, ["sample_records", sheetKey]),
      };
    });

    apiRequest
      .post(
        "/ai/finalize2/",
        {
          app_structure: {
            name: get(localData, "name"),
            pages: get(localData, "pages"),
            spreadsheets,
          },
          session_id: sessionId,
          existing_app_id: generateIn === "NEW" ? null : generateIn,
        },
        { timeout: 60000 }
      )
      .then((finalResponse) => {
        const data = decryptConfig(get(finalResponse, "data"));

        console.log("DATA", data);

        const error = get(data, "error");

        if (error) {
          errorNotification(error);
        } else {
          const pages = get(data, "pages", []);
          const firstPage = get(pages, 0);
          successNotification("App Created");

          // Set everything
          setConfig(data);

          // setOrganization({
          //   ai_usage: get(organization, "ai_usage") + 1,
          // });

          setFirstPage(firstPage);

          localStorage.setItem("appId", get(data, ["app", "id"]));
        }
        setFetching(null);
      });
  };

  const googleConnected = get(activeApp, "google_sheets_connected");
  const showGoogleConnect = !googleConnected && showSheetsConnect;

  // Generated App Preview Before Finalization
  if (previewApp) {
    return (
      <AIPreview
        reset={resetApp}
        finalize={() => {
          setPreviewApp(null);

          if (googleConnected) {
            finalizeApp();
          } else {
            setShowSheetsConnect(true);
          }
        }}
      />
    );
  }

  // Error Screen
  if (error) {
    return (
      <Error resetApp={resetApp} handleSubmit={handleSubmit} error={error} />
    );
  }

  // Fetching Existing Chat From API
  if (fetching === "thread") {
    return (
      <Main>
        <CenterContainer style={{ bottom: "100px" }}>
          <HeaderText>Loading AI Session...</HeaderText>
          <Spinner color="white" size={60} padding="30px 0 0 0" />
        </CenterContainer>
      </Main>
    );
  }

  if (aiUsage >= aiLimit) {
    return (
      <Main>
        <CenterContainer style={{ bottom: "100px" }}>
          <HeaderText>You have reached your AI token limit.</HeaderText>
          <HeaderDescription>
            Upgrade your plan to generate more apps.
          </HeaderDescription>
          <Button
            data={{
              text: "View Pricing Plans",
              onClick: () => navigate("/billing"),
              size: "extraLarge",
              type: "basic",
            }}
          />
        </CenterContainer>
      </Main>
    );
  }

  // Generating App
  if (fetching === "generating") {
    return (
      <Main>
        <CenterContainer style={{ bottom: "100px" }}>
          <HeaderText style={{ marginBottom: "0px" }}>{`${get(
            statusMap,
            nextStep,
            "Generating your app"
          )}...`}</HeaderText>
          <LoadingAnimation spinner dots />
        </CenterContainer>
      </Main>
    );
  }

  // Google Connection Requirement
  if (showGoogleConnect) {
    return (
      <GoogleIntegration
        inChat
        label="Connect Google Sheets"
        description="Frontly uses your spreadsheets as a no-code database. To finalize your app, connect your Google Sheets account."
      />
    );
  }

  // 1. SPREADSHEETS
  if (currentStep === "spreadsheets") {
    return <Spreadsheets />;
  }

  // 2. SHEET COLUMNS
  if (currentStep === "sheet_columns") {
    return <SheetColumns />;
  }

  // 3. SAMPLE RECORDS
  if (currentStep === "sample_records") {
    return <SampleRecords />;
  }

  // 4. PAGES
  if (currentStep === "pages") {
    return <Pages />;
  }

  // 5. PAGE BLOCKS
  if (currentStep === "page_blocks") {
    return <PageBlocks />;
  }

  // App Is Complete, Show Buttons For View & Edit
  if (firstPage) {
    return <Complete />;
  }

  // Finalizing App Post-Generation
  if (fetching === "finalizing") {
    return (
      <Main label="Finalizing your app...">
        <LoadingAnimation dots spinner />
      </Main>
    );
  }

  // Finalizing App Post-Generation
  if (!currentStep) {
    return <Prompt handleSubmit={handleSubmit} />;
  }
};

export default AiGeneration;

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: linear-gradient(
    236deg,
    rgb(170, 0, 255) 0%,
    rgb(0, 85, 255) 100%
  );
`;

export const Body = styled.div`
  height: calc(100vh - ${(p) => (p.isPrompt ? "160px" : "100px")});
  overflow-y: auto;
  flex: 1;
`;
