import React from "react";
import { SimpleDropdown } from "app/components";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import useActionResolver from "../useActionResolver";
import useDynamicText from "../useDynamicText";
import useUtils from "../useUtils";

const Menu = ({ page, block }) => {
  const { actionResolver } = useActionResolver(page);

  const { processDynamicText } = useDynamicText();

  const { passesDisplayConditions } = useUtils();

  const menuItems = get(block, "items", []).filter((f) =>
    passesDisplayConditions({
      conditions: get(f, "conditions", []),
      reusableBlockId: block.reusableBlockId,
      context: {
        repeatingRecord: get(block, "repeatingRecord"),
      },
    })
  );

  return (
    <div style={{ width: "max-content" }}>
      <SimpleDropdown
        data={{
          isBlock: true,
          options: menuItems.map((b) => ({
            label: processDynamicText({
              text: get(b, "label"),
              reusableBlockId: block.reusableBlockId,
              context: { repeatingRecord: get(block, "repeatingRecord") },
            }),
            onClick: () => {
              actionResolver({
                actionId: b.clickAction,
              });
            },
            icon: b.icon,
          })),
          icon: {
            icon: get(block, "icon", "FiMoreHorizontal"),
            size: get(block, "height", 25),
            hover: true,
            color: get(block, "color", colors.grey4),
          },
        }}
      />
    </div>
  );
};

export default Menu;
