import { getCurrentDomain, isFrontlyAdmin } from "app/utils/utils";
import { rApp, rBranding } from "./utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { get } from "lodash";

const fetchData = async (setData, setBrandingStatus, domain) => {
  try {
    const response = await fetch(
      `https://res.cloudinary.com/frontly/raw/upload/v1717391911/client_branding/${domain}.json`
    );
    if (!response.ok) {
      // console.log("No branding found 1");
      setBrandingStatus("invalid");
      return "invalid";
    }
    const jsonData = await response.json();

    setData(jsonData);
    setBrandingStatus("valid");
  } catch (error) {
    // console.log("No branding found 2");
    setBrandingStatus("invalid");
    // console.error("Error fetching the JSON file:", error);
  }
};

export const useBranding = () => {
  const [brandingObj, setBrandingObj] = useRecoilState(rBranding);
  const [brandingStatus, setBrandingStatus] = useState(null);

  const app = useRecoilValue(rApp);

  useEffect(() => {
    if (!isFrontlyAdmin) {
      let domain = getCurrentDomain();
      if (domain.includes(".frontly.ai")) {
        domain = domain.split(".frontly.ai")[0];
      }

      fetchData(setBrandingObj, setBrandingStatus, domain);
    }
  }, []);

  const activeAppName = get(app, "name");

  // Set branding and SEO
  useEffect(() => {
    if (!isFrontlyAdmin) {
      // Set branding from remote JSON
      if (brandingStatus === "valid" && brandingObj) {
        const name = get(brandingObj, "name");
        const description = get(brandingObj, "name");
        const favicon = get(brandingObj, "favicon");
        const logo = get(brandingObj, "logo");
        setMetaData(name, description, favicon, logo);
      } else if (brandingStatus === "invalid" && activeAppName) {
        setMetaData(activeAppName, activeAppName, "", "");
      }
    } else {
      document.title = "Frontly";
      document
        .querySelector('link[rel="icon"]')
        .setAttribute(
          "href",
          "https://res.cloudinary.com/frontly/image/upload/h_100/v1693778907/favicon_vqx84w.png"
        );
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", "Build apps with no code");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandingStatus, brandingObj, activeAppName]);

  const setMetaData = (name, description, favicon, logo) => {
    if (name) {
      document.title = name;
    }

    if (description) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", description);
    }

    if (favicon) {
      const fav = document.querySelector('link[rel="icon"]');
      fav.setAttribute("href", favicon);
    }

    if (logo) {
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", logo);
    }

    // MANIFEST STUFF
    const manifestData = {
      short_name: name,
      name,
      icons: [
        {
          src: favicon,
          sizes: "64x64 32x32 24x24 16x16",
          type: "image/x-icon",
        },
      ],
      // start_url: ".",
      display: "standalone",
      theme_color: "#000000",
      background_color: "#ffffff",
    };

    // Create a blob from the JSON data
    const manifestBlob = new Blob([JSON.stringify(manifestData)], {
      type: "application/json",
    });

    // Generate a URL for the blob
    const manifestURL = URL.createObjectURL(manifestBlob);

    // Replace the existing manifest link element
    let link = document.querySelector('link[rel="manifest"]');
    if (link) {
      link.setAttribute("href", manifestURL);
    } else {
      // If no manifest link element exists, create one
      link = document.createElement("link");
      link.setAttribute("rel", "manifest");
      link.setAttribute("href", manifestURL);
      document.head.appendChild(link);
    }
  };
};
