import { addSlash, findDefaultPage, isFrontlyAdmin, safeArray } from "./utils";
import { rApp, rForms, rPages, rPreviewApp, rUser } from "./recoil";
import { useLocation, useParams } from "react-router-dom";

import { get } from "lodash";
import useActiveCustomBlock from "./useActiveCustomBlock";
import { useRecoilValue } from "recoil";

const usePage = () => {
  const location = useLocation();
  const path = get(location, "pathname");

  const params = useParams();
  const pageId = parseInt(get(params, "id"));

  const app = useRecoilValue(rApp);
  const user = useRecoilValue(rUser);
  const pages = useRecoilValue(rPages);
  const previewApp = useRecoilValue(rPreviewApp);
  const previewRoute = get(previewApp, "activePageRoute");
  const forms = useRecoilValue(rForms);

  const appDefaultPage = get(app, "default_page");
  const userGroups = safeArray(app, "user_groups");

  const { activeCustomBlock } = useActiveCustomBlock();

  // const memoizedPage = useMemo(() => {
  //   console.log("PAGE CHANGE", activeCustomBlock);

  if (activeCustomBlock) {
    return activeCustomBlock;
  }

  let page = pages.find((p) => p.id === pageId);

  const defaultPage =
    pages.find((p) => p.id === appDefaultPage) || get(pages, 0, {});

  const defPage = findDefaultPage({
    user,
    userGroups,
    pages,
    defaultPage,
    app,
    passesDisplayConditions: null, // TODO = Ideally this would be passed in
  });

  if (!isFrontlyAdmin) {
    const currentRoute = addSlash(path);

    page = pages.find((p) => {
      const pRoute = addSlash(p.route);
      const matchesRoute = pRoute === currentRoute;
      if (matchesRoute) {
        return true;
      }
    });

    if (!page) {
      let matchingForm = forms.find((p) => {
        const pRoute = addSlash(p.route);
        const matchesRoute = pRoute === currentRoute;
        if (matchesRoute) {
          return true;
        }
      });

      if (matchingForm) {
        page = { ...matchingForm, isForm: true };
      }
    }

    if (!page) {
      // Find 'default' page
      page = pages.find((p) => {
        const pRoute = addSlash(p.route);
        const matchesRoute = pRoute === defPage;
        if (matchesRoute) {
          return true;
        }
      });
    }
  }

  // Handle Preview app
  if (previewApp) {
    if (previewRoute) {
      page = pages.find((p) => p.route === previewRoute);
    } else {
      page = get(pages, 0);
    }
  }

  //   return page;
  // }, [
  //   path,
  //   pageId,
  //   appDefaultPage,
  //   userGroups,
  //   appId,
  //   user,
  //   pages,
  //   previewRoute,
  //   activeCustomBlock,
  // ]);

  // return memoizedPage;

  return page;
};

export default usePage;
