import "chartjs-plugin-datalabels";

import * as charts from "react-chartjs-2";

import { Chart as ChartJS, registerables } from "chart.js";
import {
  colorSchemes,
  defaultScheme,
  groupBy,
} from "app/components/charts/utils";

import ApexChart from "app/components/charts/ApexChart";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { get } from "lodash";
import { parseNumber } from "app/utils/utils";
import { sortRecords } from "./Table";
import styled from "styled-components";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import useUtils from "../useUtils";

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

const ChartSubContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 250px;
`;

const ChartOld = ({ block, page }) => {
  const { processObjects } = useProcessObjects();

  const { getListData } = useListData();

  const data = getListData(block);

  const { processDynamicText } = useUtils();

  const groupByColumn = get(block, "groupByColumn");
  const groupByMetric = get(block, "groupByMetric");

  const finalFields = [
    {
      mapKey: "valueKey", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "valueKey"),
    },
    {
      mapKey: "labelKey", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "labelKey"),
    },
  ];

  let items = processObjects(
    page,
    block,
    finalFields,
    data,
    processDynamicText
  );

  const chartType = get(block, "chartType", "Bar");
  const sorting = get(block, "sorting");
  const colorScheme = get(block, "colorScheme", 5);

  const title = get(block, "chartLabel");

  const activeColorScheme = get(colorSchemes, colorScheme, defaultScheme).map(
    (s) => ({
      background: s,
      border: s,
    })
  );

  const showDataLabels = get(block, "showDataLabels", true);
  const showValues = get(block, "showValues", false);

  if (groupByColumn) {
    const convertedValues = groupBy(
      items,
      groupByColumn,
      get(block, "valueKey")
    );

    items = convertedValues
      .filter((v) => v.key)
      .map((v) => ({
        labelKey: v.key,
        valueKey: parseNumber(get(v, groupByMetric)),
      }));
  }

  if (sorting) {
    // Sort rows
    items = sortRecords(items, "valueKey", sorting);
  }

  const ChartComponent = get(charts, chartType || "Bar");

  const labels = items.map((i) => get(i, "labelKey", "").toString());
  const values = items.map((i) => get(i, "valueKey"));

  if (chartType.includes("Apex")) {
    return (
      <ApexChart
        data={{
          ...block,
          labels,
          series: [
            {
              name: "Series 1",
              data: values,
            },
          ],
          type: chartType,
        }}
      />
    );
  }

  const getDefaultColor = (index, type) => {
    const colorCount = activeColorScheme.length;
    const multiple = Math.floor(index / colorCount);
    const correctedIndex =
      index >= colorCount ? index - multiple * colorCount : index;
    const defaultColor = get(activeColorScheme, [correctedIndex, type]);
    return defaultColor;
  };

  const borderColors = items.map(
    (s, index) => s.borderColor || getDefaultColor(index, "border")
  );

  const backgroundColors = items.map(
    (s, index) => s.backgroundColor || getDefaultColor(index, "background")
  );

  const isLegendVisible = () => {
    if (["Bar", "Line", "Radar"].includes(chartType)) {
      return false;
    }
    return true;
  };

  const displayLabels = (charts) => {
    if (charts.includes(chartType)) {
      if (showDataLabels) {
        return labels;
      }
      return [];
    }
    return labels;
  };

  const roundedShapedCharts = ["Doughnut", "Pie", "PolarArea", "Radar"];

  const displayYCoordinates = !roundedShapedCharts.includes(chartType);

  const displayLines = false;

  const props = {
    options: {
      // TODO - Figure out how to handle this. We need the clicked item index
      // onClick: (e) => console.log(e),
      scales: {
        x: {
          grid: {
            display: displayLines,
          },
          display: !roundedShapedCharts.includes(chartType) && showDataLabels,
        },
        y: {
          display: displayYCoordinates,
          grid: {
            display: displayLines,
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: showValues,
          color: "#ffffff",
        },
        legend: {
          display: isLegendVisible(),
          position: "right",
        },
        title: {
          display: title && true,
          text: title,
        },
      },
    },
    data: {
      labels: displayLabels(roundedShapedCharts),
      datasets: [
        {
          data: values,
          fill: true,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
          pointBackgroundColor: "#ffffff",
          tension: 0.4,
        },
      ],
    },
  };

  return (
    <ChartContainer>
      <ChartSubContainer>
        <ChartComponent {...props} />
      </ChartSubContainer>
    </ChartContainer>
  );
};

export default ChartOld;

ChartJS.register(ChartDataLabels);

ChartJS.register(...registerables);
