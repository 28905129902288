import {
  rApp,
  rAppDateFormat,
  rDarkMode,
  rLiveSpreadsheets,
  rSavedSpreadsheets,
} from "app/utils/recoil";

import { InfoBox } from "app/adminApp/settings/InfoBox";
import { ViewOnlyList } from "app/components";
import { get } from "lodash";
import { getFormFields } from "./Form/utils";
import { getRelationHeaders } from "app/adminApp/components/StaticFields";
import { isFrontlyAdmin } from "app/utils/utils";
import useDynamicText from "../useDynamicText";
import { useRecoilValue } from "recoil";
import useUtils from "app/renderingApp/useUtils";

const InfoList = ({ block }) => {
  const appDateFormat = useRecoilValue(rAppDateFormat);
  const liveSpreadsheets = useRecoilValue(rLiveSpreadsheets);

  const darkMode = useRecoilValue(rDarkMode);

  const app = useRecoilValue(rApp);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const gridLayout = get(block, "gridLayout");

  const { processDynamicText } = useDynamicText();

  const { passesDisplayConditions } = useUtils();

  const sheet = savedSpreadsheets.find((s) => s.id === block.spreadsheet);

  const fieldData = get(block, "fieldData", {});
  // Block-level field overrides
  const config = get(fieldData, "config", {});
  const localOrder = get(fieldData, "order", []);

  // Spreadsheet-level field overrides
  const sheetFieldData = get(sheet, "field_data", {});
  const sheetConfig = get(sheetFieldData, "config", {});
  const sheetOrder = get(sheetFieldData, "order", []);

  const sheetHeaders = get(sheet, "headers", []);

  const firstRecord = get(sheet, ["data", 0], []);
  const formState = isFrontlyAdmin
    ? firstRecord
    : get(liveSpreadsheets, block.id);

  const relatedHeaders = getRelationHeaders(sheet, app, savedSpreadsheets);

  const { displayFields } = getFormFields({
    processDynamicText,
    appDateFormat,
    relatedHeaders,
    sheetOrder,
    localOrder,
    sheetHeaders,
    sheetFieldData: sheetConfig,
    localFieldData: config,
    valuesObject: formState,
    block,
    conditionsObject: {
      passesDisplayConditions,
      conditionKey: "conditions",
    },
  });

  const rowId = get(block, "rowId");
  const incomplete = !rowId;

  if (incomplete) {
    return (
      <InfoBox
        darkMode={darkMode}
        warning
        margin="15px 5px 5px 5px"
        helpLink="https://help.frontly.ai/en/articles/8589612-infolist-block"
      >
        To complete InfoList setup, please add a 'Row ID' value.
      </InfoBox>
    );
  }

  const isFetching = get(block, "isFetching");

  return (
    <ViewOnlyList
      isFetching={isFetching}
      darkMode={darkMode}
      gridLayout={gridLayout}
      displayFields={displayFields}
      formState={formState}
      processDynamicText={processDynamicText}
    />
  );
};

export default InfoList;
