import { Button, Modal, Text } from ".";
import { get, startCase } from "lodash";

import { AdminForm } from "app/adminApp/components";
import useActiveBlock from "app/utils/useActiveBlock";
import { useState } from "react";

const CustomCSS = ({ data }) => {
  const [showModal, setShowModal] = useState(false);

  const activeBlock = useActiveBlock();

  const cssObject = get(data, "value", {});

  const fields = getFields(activeBlock.componentId).map((f) => ({
    ...f,
    value: get(cssObject, f.id, ""),
    label: f.label || startCase(f.id),
  }));

  return (
    <>
      <Button
        data={{
          ...data,
          text: "Edit CSS",
          icon: data.value,
          width: "80px",
          size: "small",
          type: "basic",
          onClick: () => setShowModal(true),
        }}
      />
      {showModal && (
        <Modal
          header={{ title: "Edit CSS Styling" }}
          hide={() => setShowModal(false)}
        >
          {fields.length === 0 ? (
            <Text
              data={{
                text: "No custom CSS available for this component",
                size: "large",
              }}
            />
          ) : (
            <AdminForm
              sectionPadding="0px"
              fields={fields}
              onChange={(k, v) => {
                console.log("OBJ", { ...cssObject, [k]: v });
                data.onChange({ ...cssObject, [k]: v });
              }}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default CustomCSS;

const getFields = (componentId) => {
  let fields = [];

  if (["Row", "Column"].includes(componentId)) {
    fields = [
      ...fields,
      {
        id: "container",
        key: "container",
        componentId: "TextArea",
        monospace: true,
        height: "150px",
      },
    ];
  }

  if (componentId === "Table") {
    fields = [
      ...fields,
      {
        id: "tableHeaderText",
        key: "tableHeaderText",
        componentId: "TextArea",
        monospace: true,
        height: "150px",
      },
      // {
      //   id: "tableRow",
      //   key: "tableRow",
      //   componentId: "TextArea",
      //   monospace: true,
      //   height: "150px",
      // },
    ];
  }

  if (
    [
      "Table",
      "Grid",
      "Kanban",
      "Calendar",
      "InfoList",
      "Form",
      "Stat",
      "Chart",
      "QuoteCalculator",
      "Timeline",
      "Row",
      "Column",
    ].includes(componentId)
  ) {
    fields = [
      ...fields,
      {
        id: "blockContainer",
        key: "blockContainer",
        componentId: "TextArea",
        monospace: true,
        height: "150px",
      },
    ];
  }

  if (
    [
      "Table",
      "Grid",
      "Kanban",
      "Calendar",
      "InfoList",
      "Form",
      "Stat",
      "Chart",
      "QuoteCalculator",
      "Timeline",
    ].includes(componentId)
  ) {
    fields = [
      ...fields,
      {
        id: "blockLabel",
        key: "blockLabel",
        componentId: "TextArea",
        monospace: true,
        height: "150px",
      },
    ];
  }

  if (["Button"].includes(componentId)) {
    fields = [
      ...fields,
      {
        id: "buttonContainer",
        key: "buttonContainer",
        componentId: "TextArea",
        monospace: true,
        height: "150px",
      },
    ];
  }

  if (["Text"].includes(componentId)) {
    fields = [
      ...fields,
      {
        id: "text",
        key: "text",
        componentId: "TextArea",
        monospace: true,
        height: "150px",
      },
    ];
  }

  return fields;
};
