import { colors, fontObject } from "app/utils/theme";
import { getPixels, truncateText } from "app/utils/utils";

import MDEditor from "@uiw/react-md-editor";
import React from "react";
import { get } from "lodash";
import styled from "styled-components";
import useUtils from "app/renderingApp/useUtils";

export const StyledText = styled.div`
  font-size: ${(p) => getPixels(p.fontSize)};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color || p.fontColor || colors.default};
  padding: ${(p) => getPixels(p.padding || 0)};
  margin: ${(p) => getPixels(p.margin || 0)};
  text-align: ${(p) => p.textAlign || "initial"};
  cursor: ${(p) => (p.onClick ? "pointer" : p.cursor || "auto")};
  text-decoration: none !important;
  line-height: ${(p) => p.lineHeight || "auto"};
  white-space: ${(p) => p.whiteSpace || "normal"};
  overflow-wrap: ${(p) => p.overflowWrap || ""};
  width: ${(p) => p.width || "auto"};
  ${(p) =>
    !p.allowSelect &&
    `-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; -o-user-select: none; user-select: none; display: flex;
  `}
  ${(p) => p.fontFamily && `font-family: ${p.fontFamily};`};
  ${(p) => p.customCss}
`;

const Text = ({ data }) => {
  let { text } = data;

  const { processDynamicText } = useUtils();

  text = text && processDynamicText({ text });

  if (data.truncate) {
    text = truncateText(text, data.truncate);
  }

  const fontStyle = get(data, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);

  if (data.isMarkdown) {
    const fontColor = get(data, "fontColor");
    return (
      <div data-color-mode="light" onClick={data?.onClick}>
        <MDEditor.Markdown
          source={text}
          style={{ background: "transparent", color: fontColor }}
        />
      </div>
    );
  }

  return (
    <StyledText {...styleObject} {...data} fontFamily={data.fontFamily}>
      {text}
    </StyledText>
  );
};

export default Text;
