import { Menu, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";
import { rApp, rPages } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import UserGroupPermissions from "app/adminApp/components/UserGroupPermissions";
import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";

const PageUserGroups = ({ data }) => {
  const { onChange } = data;

  const app = useRecoilValue(rApp);

  const userGroups = get(app, "user_groups", []) || [];
  const [pages, setPages] = useRecoilState(rPages);

  const userGroupsMap = userGroups.reduce((acc, group) => {
    acc[parseInt(group.id)] = group.name;
    return acc;
  }, {});

  const pageOptions = pages.map((page) => {
    const pUserGroups = get(page, "userGroups", []).map((g) =>
      get(userGroupsMap, parseInt(g))
    );

    const userGroupsString = pUserGroups.join(", ");

    return { ...page, userGroupsString };
  });

  const [activePageId, setActivePageId] = useState(null);
  const [anchor, setAnchor] = useState(null);

  const activeItem = pages.find((i) => i.id === activePageId);
  const activePageUserGroups = get(activeItem, "userGroups", []);

  return (
    <div style={{ width: "200px" }}>
      {activePageId && (
        <Menu anchorElement={anchor} hide={() => setActivePageId(null)}>
          <UserGroupPermissions
            anchorElement={anchor}
            noGroupsText="To determine who can view this page, define User Groups in User Settings"
            onClose={() => {
              setAnchor(null);
              setActivePageId(null);
            }}
            values={activePageUserGroups}
            onChange={(v) => {
              onChange({ pageId: activePageId, newUserGroups: v });

              const newPages = pages.map((page) => {
                if (page.id === activePageId) {
                  return { ...page, userGroups: v };
                }
                return page;
              });

              setPages(newPages);
            }}
          />
        </Menu>
      )}
      {pageOptions.length > 0 && (
        <Container>
          {pageOptions.map((p) => (
            <FieldContainer
              className="drag-item"
              onClick={(e) => {
                setActivePageId(p.id);
                setAnchor(e.currentTarget);
              }}
            >
              <Text
                data={{
                  text: p.name,
                  fontStyle: "headingMd",
                  cursor: "pointer",
                }}
              />
              <Text
                data={{
                  text: p.userGroupsString || "All Users",
                  cursor: "pointer",
                  margin: "0 3px 0 3px",
                }}
              />
            </FieldContainer>
          ))}
        </Container>
      )}
    </div>
  );
};

export default PageUserGroups;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
`;

const FieldContainer = styled.div`
  padding: ${spacing.s2} 0px ${spacing.s2} 0px;
  border-bottom: 1px solid ${colors.grey2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 200ms all;
  &:hover {
    background: ${colors.grey1};
  }
  :not(:last-child) {
    border-bottom: 1px solid ${colors.grey2};
  }
  :first-child {
    border-top: 1px solid ${colors.grey2};
  }
`;
