import styled, { css } from "styled-components";

import { colors } from "app/utils/theme";

const getColorPickerBackground = (color, newGrey1) => {
  const length = color && color.length;

  const halfGrid =
    "https://res.cloudinary.com/frontly/image/upload/v1648145984/transparency-half_lxynff.png";
  const grid =
    "https://res.cloudinary.com/frontly/image/upload/v1648145182/transparency_nvxhnu.png";

  let result = "";

  if (
    color === newGrey1 ||
    // color === "#ffffff" ||
    (length === 9 && color[7] === "f" && color[8] === "f")
  ) {
    result = css`
      background-color: ${color};
    `;
  } else if (
    length === 9 &&
    ((color[7] !== "f" && color[8] === "f") ||
      (color[7] === "f" && color[8] !== "f") ||
      (color[7] !== "f" && color[8] !== "f"))
  ) {
    result = css`
      background-color: ${color};
      background-image: url(${halfGrid});
    `;
  } else if (color === "" || length < 9) {
    result = css`
      background-image: url(${grid});
    `;
  }

  return result;
};

export const ColorDisplay = styled.div`
  ${(props) => getColorPickerBackground(props.color, colors.grey1)}
  background-size: cover;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  border: 1px solid grey;
  cursor: pointer;
  user-select: none;
`;
