import { cloneDeep, get } from "lodash";
import { getHighest, safeArray } from "app/utils/utils";
import { rActiveBlockId, rPage } from "app/utils/recoil";
import { useEffect, useRef, useState } from "react";

import { BackButton } from "..";
import { Button } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";

const multiLinePlaceholder = `An ecommerce product card with an image at the top, a middle section with a title, price, and description, and a full-with button at the bottom...

A blog post with a title, author, date, and content...

An analytics dashboard divided into 2 sections horizontally with multiple charts and graphs on the left and a single large chart on the right...`;

const AiBlockGenerator = ({ hide, hideAll }) => {
  // const page = useRecoilValue(rPage);
  const page = usePage();

  const { setPage } = useSetPage();

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const [responses, setResponses] = useState([]);

  const activeBlockIdRef = useRef(activeBlockId);
  useEffect(() => {
    activeBlockIdRef.current = activeBlockId;
  }, [activeBlockId]);

  const pageRef = useRef(page);
  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const pageBlocks = get(page, "blocks", []);

  const blocksRef = useRef(pageBlocks);
  useEffect(() => {
    blocksRef.current = pageBlocks;
  }, [pageBlocks]);

  const [aiGenerationPrompt, setAiGenerationPrompt] = useState("");

  const promptRef = useRef(aiGenerationPrompt);
  useEffect(() => {
    promptRef.current = aiGenerationPrompt;
  }, [aiGenerationPrompt]);

  const [shouldReconnect, setShouldReconnect] = useState(true);
  const reconnectAttempts = useRef(0);

  const [timeoutId, setTimeoutId] = useState(null);
  const timeoutIdRef = useRef(timeoutId);

  const [fetching, setFetching] = useState(false);
  const fetchingRef = useRef(fetching);

  const [error, setError] = useState("");

  const processResponse = (res) => {
    const layout = safeArray(res, "layout");

    if (layout.length === 0) {
      // console.log("TIMEOUT ERROR, IGNORE");
      return;
    }

    const updatedLayout = updateLayoutIds(
      layout,
      blocksRef.current,
      activeBlockIdRef.current
    );

    const newBlocks = [...blocksRef.current, ...updatedLayout];

    setPage({
      ...pageRef.current,
      blocks: newBlocks,
    });

    // Save request
    apiRequest.post("/save_block_generation/", {
      prompt: promptRef.current,
      elements: updatedLayout,
    });

    setShouldReconnect(false); // Prevent reconnection when the component is unmounting
    window.ws?.close(); // close websocket connection

    setFetching(false);
    hideAll();
  };

  useEffect(() => {
    const unprocessedResponse = responses.find((r) => !r.processed);
    if (unprocessedResponse) {
      processResponse(get(unprocessedResponse, "data", []));
      setResponses((prevResponses) =>
        prevResponses.map((r) =>
          r.id === unprocessedResponse.id ? { ...r, processed: true } : r
        )
      );
    }
  }, [responses]);

  const removeTimeout = () => {
    clearTimeout(timeoutIdRef.current);
    setTimeoutId(null);
  };

  const connectWebSocket = () => {
    const isOpen = window.ws && window.ws.readyState === WebSocket.OPEN;

    if (shouldReconnect && !isOpen) {
      window.ws = new WebSocket(
        "wss://o0v9pmvz0g.execute-api.ca-central-1.amazonaws.com/production"
      );

      window.ws.onopen = () => {
        console.log("WebSocket Connected");
        reconnectAttempts.current = 0; // Reset reconnect attempts on successful connection
      };

      window.ws.onmessage = (event) => {
        const messageData = JSON.parse(event.data);
        // console.log("MESSAGE DATA", messageData);

        removeTimeout();

        setResponses((prevResponses) => [
          ...prevResponses,
          {
            id: Math.floor(Math.random() * 1000000),
            processed: false,
            data: messageData,
          },
        ]);
      };

      window.ws.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      window.ws.onclose = () => {
        console.log("WebSocket Disconnected");

        // Disabling the auto-reconnection for now because it's causing issues
        // if (shouldReconnect && responses.length === 0) {
        //   // Implement retry logic (could be exponential backoff)
        //   setTimeout(
        //     connectWebSocket,
        //     Math.min(10000, 500 * 2 ** reconnectAttempts.current)
        //   ); // Example: retry with an increasing delay
        //   reconnectAttempts.current += 1;
        // }
      };
    }
  };

  useEffect(() => {
    connectWebSocket();
    return () => {
      window.ws?.close();
      setShouldReconnect(false); // Prevent reconnection when the component is unmounting
    };
  }, []);

  useEffect(() => {
    timeoutIdRef.current = timeoutId;
  }, [timeoutId]);

  const timeoutFunction = () => {
    if (timeoutIdRef.current && fetchingRef.current) {
      setError("This request took a bit too long. Please try again.");
      setFetching(false);
    }
  };

  const handleSubmit = () => {
    setFetching(true);
    setError(false);
    const timeoutId = setTimeout(timeoutFunction, 60000);
    setTimeoutId(timeoutId);

    if (window.ws && window.ws.readyState === WebSocket.OPEN) {
      // Set 60 second timer to prevent infinite loading
      window.ws.send(
        JSON.stringify({
          action: "generate_layout",
          prompt: aiGenerationPrompt,
        })
      );
    } else {
      console.error("WebSocket is not open.");
    }
    mixpanel.track(`AI - Generate Block`);
  };

  return (
    <div>
      <BackButton
        width="auto"
        margin="0px 0 15px 0"
        text="All Blocks"
        onClick={() => {
          hide() && setAiGenerationPrompt("");
          setShouldReconnect(false); // Prevent reconnection when the component is unmounting
          window.ws?.close(); // close websocket connection
        }}
      />
      <AIGenerationInput
        placeholder={multiLinePlaceholder}
        value={aiGenerationPrompt}
        onChange={(e) => setAiGenerationPrompt(e.target.value)}
      />
      <Button
        data={{
          text: "Generate",
          icon: "HiSparkles",
          margin: "10px 0 0 0",
          size: "large",
          disabled: aiGenerationPrompt.length < 10 || fetching,
          onClick: handleSubmit,
          isFetching: fetching,
        }}
      />
    </div>
  );
};

export default AiBlockGenerator;

const AIGenerationInput = styled.textarea`
  padding: 15px;
  width: 100%;
  border: 1px solid ${colors.divider};
  outline: none;
  font-size: 20px;
  min-height: 100px;
  height: 200px;
  border-radius: 20px;
  &::placeholder {
    color: ${colors.grey3};
  }
`;

const updateLayoutIds = (layout, pageBlocks, activeBlockId) => {
  // Deep clone the layout to avoid mutating the original
  const newLayout = cloneDeep(layout);

  const currentHighestId =
    pageBlocks.length === 0 ? 0 : getHighest(pageBlocks, "id");

  const newRootId = currentHighestId + 1;

  // Create a mapping from old IDs to new IDs
  const idMapping = {};

  // Update the root ID and create the initial mapping
  newLayout[0].id = newRootId;

  idMapping[layout[0].id] = newRootId;

  // Update the rest of the IDs and their parent references
  let currentId = newRootId + 1;
  for (let i = 1; i < newLayout.length; i++) {
    const oldId = newLayout[i].id;
    newLayout[i].id = currentId;
    idMapping[oldId] = currentId;
    currentId++;
  }

  // Update layoutParent references
  for (let i = 0; i < newLayout.length; i++) {
    if (newLayout[i].layoutParent !== null) {
      newLayout[i].layoutParent = idMapping[newLayout[i].layoutParent];
    }
  }

  newLayout[0].layoutParent = activeBlockId;

  return newLayout;
};
