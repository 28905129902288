import { AdminForm, Tile } from ".";
import { Modal, Text } from "app/components";
import {
  dateFormatOptions,
  defaultDateFormat,
  getDateFormatString,
} from "app/utils/utils";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rAppDateFormat } from "app/utils/recoil";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const DateFormatConfig = ({ data }) => {
  const [edit, setEdit] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const value = get(data, "value");

  const inputDate = get(value, "inputDate");
  const outputDate = get(value, "outputDate");

  const inputTime = get(value, "inputTime");
  const outputTime = get(value, "outputTime");

  const showAmPm = get(value, "showAmPm");

  const enabledFields = get(data, "enabledFields", []);

  const showAdv = enabledFields.length < 5 || showAdvanced;

  const customFormat = get(value, "customFormat", "");
  const formatType = get(value, "formatType", "default");

  const isDefaultFormat = formatType !== "custom";

  // TODO - Spend more time thinking about how this works - time and date formats etc
  const formatStr = getDateFormatString({
    ...appDateFormat,
    ...value,
    showAmPm: false,
  });

  const fields = [
    {
      id: "inputDate",
      label: "Date Format",
      componentId: "Select",
      required: true,
      hideEmptyItem: true,
      defaultValue: get(appDateFormat, "inputDate", defaultDateFormat),
      options: dateFormatOptions,
      value: inputDate,
      displayCondition: () => isDefaultFormat,
    },
    {
      id: "outputDate",
      label: "Date Output Format",
      componentId: "Select",
      required: true,
      hideEmptyItem: true,
      options: [...dateFormatOptions, { label: "Relative", value: "relative" }],
      value: outputDate,
      displayCondition: () => isDefaultFormat && showAdv,
    },
    {
      id: "inputTime",
      label: "Time Format",
      componentId: "Select",
      required: true,
      // hideEmptyItem: true,
      value: inputTime,
      options: [
        {
          label: "h:mm",
          value: "h:mm",
        },
        {
          label: "h:mm:ss",
          value: "h:mm:ss",
        },
      ],
      displayCondition: () => isDefaultFormat,
    },
    {
      id: "outputTime",
      label: "Time Output Format",
      componentId: "Select",
      hideEmptyItem: true,
      required: true,
      value: outputTime,
      options: [
        {
          label: "None",
          value: "none",
        },
        {
          label: "h:mm",
          value: "h:mm",
        },
        {
          label: "h:mm:ss",
          value: "h:mm:ss",
        },
      ],
      displayCondition: () =>
        outputDate !== "relative" && isDefaultFormat && showAdv,
    },
    {
      id: "showAmPm",
      label: "Show AM/PM",
      componentId: "Switch",
      defaultValue: false,
      value: showAmPm,
      displayCondition: () =>
        outputDate !== "relative" &&
        isDefaultFormat &&
        showAdv &&
        outputTime !== "none",
    },
  ].filter((f) => enabledFields.length === 0 || enabledFields.includes(f.id));

  const finalFields = [
    {
      id: "formatType",
      label: "Format Type",
      componentId: "Select",
      value: formatType,
      hideEmptyItem: true,
      options: [
        {
          label: "Default",
          value: "default",
        },
        {
          label: "Custom",
          value: "custom",
        },
      ],
      hint: "Select a format from the pre-defined options or define your own",
      displayCondition: () => showAdv,
    },
    {
      displayCondition: () => !isDefaultFormat && showAdv,
      id: "customFormat",
      label: "Custom Format",
      componentId: "Input",
      value: customFormat,
      hint: "Enter a custom date format. See the docs for more info.",
    },
    ...fields,
  ].filter(
    (f) => !f.displayCondition || (f.displayCondition && f.displayCondition(f))
  );

  return (
    <>
      {edit && (
        <Modal
          header={{ title: "Edit Date Format" }}
          hide={() => setEdit(false)}
          minWidth="400px"
        >
          <AdminForm
            sectionPadding="0px"
            onChange={(k, v) => data.onChange({ ...value, [k]: v })}
            orientation={"horizontal"}
            width="200px"
            fields={finalFields}
          />
          {!showAdv && (
            <Text
              data={{
                text: "+ Show Advanced Settings",
                color: colors.primary,
                margin: "15px 0 0 0",
                fontStyle: "headingSm",
                onClick: () => setShowAdvanced(true),
              }}
            />
          )}
          {enabledFields.length > 3 && showAdv && formatType !== "custom" && (
            <Text
              data={{
                text: "- Hide Advanced Settings",
                color: colors.primary,
                margin: "15px 0 0 0",
                fontStyle: "headingSm",
                onClick: () => setShowAdvanced(false),
              }}
            />
          )}
          {formatType === "custom" && (
            <Text
              data={{
                text: "Learn about Custom Date Formatting",
                color: colors.primary,
                margin: "10px 0 0 0",
                fontStyle: "headingSm",
                onClick: () =>
                  window.open(
                    "https://help.frontly.ai/en/articles/8437663-using-time-variables#h_43a6ba6e6d"
                  ),
              }}
            />
          )}
        </Modal>
      )}
      <Tile icon="FiEdit" label={formatStr} onClick={() => setEdit(true)} />
    </>
  );
};

export default DateFormatConfig;
