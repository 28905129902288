import { rDarkMode, rFormState, rFormValidation } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { Switch as SwitchComponent } from "app/components";
import { get } from "lodash";
import { getInputStyles } from "app/utils/inputUtils";
import { isFrontlyAdmin } from "app/utils/utils";

const Switch = ({ page, block }) => {
  const darkMode = useRecoilValue(rDarkMode);
  const [formState, setFormState] = useRecoilState(rFormState);

  const fieldId = get(block, "key");
  const concatId = `${page.id}-${fieldId}`;

  const formValidation = useRecoilValue(rFormValidation);
  const validationError = get(formValidation, block.key, null);

  const onChange = (v) => {
    setFormState((prev) => ({
      ...prev,
      [concatId]: v,
    }));
  };

  const value = get(formState, concatId, "");

  return (
    <SwitchComponent
      data={{
        id: `formInput__${block.id}`,
        onChange,
        value,
        ballHeight: block.ballHeight,
        backgroundWidth: block.backgroundWidth,
        disabled: isFrontlyAdmin,
        isDarkMode: darkMode,
        ...getInputStyles(block, validationError),
      }}
    />
  );
};

export default Switch;
