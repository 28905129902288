import { fixBlocks, getHighest } from "app/utils/utils";

import { Button } from "app/components";
import { blockConfigs } from "../blockConfigs";
import { get } from "lodash";
import { rActiveBlockId } from "app/utils/recoil";
import useActiveBlock from "app/utils/useActiveBlock";
import usePage from "app/utils/usePage";
import useSetPageNew from "app/utils/useSetPageNew";
import { useSetRecoilState } from "recoil";

export const getBlockBundle = (data) => {
  const { block, actions, blocks, blocksToDuplicate } = data;

  let newActions = [];
  let newBlocks = [];

  // Recursive function to duplicate a block and its children
  const duplicateBlockAndChildren = (block, newBlocks, newActions) => {
    const allBlocks = [...blocks, ...newBlocks];
    const allActions = [...actions, ...newActions];

    let highestActionId = getHighest(allActions, "id");

    let uniqueId = getHighest(allBlocks, "id") + 1;

    // Duplicate the block
    let newBlock = {
      ...block,
      id: uniqueId, // Assign a new ID
    };

    const blockConfig = get(blockConfigs, get(block, "componentId"));

    const duplicateActions = (resource, b, versionId = null) => {
      if (resource.isMultiAction || resource.isAction) {
        let newData = { ...b };
        // HANDLE MULTI ACTIONS
        if (resource.isMultiAction) {
          let newMultiActions = [];

          const multiActionsArray = get(b, resource.id, []);

          multiActionsArray.forEach((obj) => {
            let newMultiAction = { ...obj };

            const matchingAction = actions.find((a) => a.id === obj.value);

            highestActionId += 1;

            newMultiAction.value = highestActionId;

            newMultiActions.push(newMultiAction);

            newActions.push({ ...matchingAction, id: highestActionId });
          });

          newData[resource.id] = newMultiActions;
        }
        // HANDLE SINGLE ACTION
        else if (resource.isAction) {
          const matchingAction = actions.find(
            (a) => a.id === get(b, resource.id)
          );

          if (matchingAction) {
            highestActionId += 1;
            newData[resource.id] = highestActionId;
            newActions.push({ ...matchingAction, id: highestActionId });
          }
        }

        if (versionId) {
          // Update this version of this block with the new data

          newBlock = {
            ...newBlock,
            versions: get(newBlock, "versions", []).map((ver) => {
              if (get(ver, "id") === versionId) {
                return { ...ver, data: { ...ver.data, ...newData } };
              }
              return ver;
            }),
          };
        } else {
          // Update the main block data
          newBlock = { ...newBlock, ...newData };
        }
      }
    };

    // Find and duplicate actions associated with the block
    get(blockConfig, "resources", []).forEach((resource) => {
      // Duplicate the default version actions
      duplicateActions(resource, newBlock, null);

      // Duplicate any actions for each version of the block
      get(newBlock, "versions", []).forEach((v) => {
        duplicateActions(resource, get(v, "data", {}), v.id);
      });
    });

    newBlocks.push(newBlock);

    // Now, find and duplicate all child blocks
    blocksToDuplicate
      .filter((b) => b.layoutParent === block.id || b.parent === block.id)
      .forEach((childBlock) => {
        // Set the new parent ID for the child block
        let updatedChildBlock = { ...childBlock };

        // Handle updating the layoutParent or parent
        if (childBlock.layoutParent === block.id) {
          updatedChildBlock["layoutParent"] = newBlock.id;
        } else if (childBlock.parent === block.id) {
          updatedChildBlock["parent"] = newBlock.id;
        }

        // Recursively duplicate this child blockw
        duplicateBlockAndChildren(updatedChildBlock, newBlocks, newActions);
      });

    return {
      blockId: newBlock.id,
      copiedActions: newActions,
      copiedBlocks: newBlocks,
      // copiedBlocks: fixCustomBlocks(newBlocks),
    }; // Return the new block, primarily for updating the active block ID later
  };

  // Start the duplication process with the active block
  const { blockId, copiedActions, copiedBlocks } = duplicateBlockAndChildren(
    block,
    newBlocks,
    newActions
  );

  return {
    blockId,
    actions: copiedActions,
    blocks: copiedBlocks,
  };
};

const DuplicateBlock = () => {
  const { setPage } = useSetPageNew();

  const activeBlock = useActiveBlock();

  const page = usePage();

  const setActiveBlockId = useSetRecoilState(rActiveBlockId);

  const duplicate = () => {
    const actions = [...get(page, "actions", [])];
    const blocks = fixBlocks(get(page, "blocks", []));
    const bundle = getBlockBundle({
      block: activeBlock,
      actions,
      blocks,
      blocksToDuplicate: blocks,
    });

    const finalActions = [...actions, ...get(bundle, "actions", [])];
    const finalBlocks = [...blocks, ...get(bundle, "blocks", [])];

    setPage({
      ...page,
      actions: finalActions,
      blocks: finalBlocks,
    });

    setActiveBlockId(get(bundle, "blockId")); // Set the newly duplicated block as active
  };

  return (
    <Button
      data={{
        text: "Duplicate Block",
        onClick: duplicate,
        icon: "FiCopy",
        type: "basic",
        size: "small",
      }}
    />
  );
};

export default DuplicateBlock;
