import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import { rSavedSpreadsheets } from "app/utils/recoil";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const DataSourcesCard = () => {
  const navigate = useNavigate();
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const dataSourceCount = savedSpreadsheets.filter(
    (ds) => ds.service !== "mock"
  ).length;

  // If there are sources, don't render
  if (dataSourceCount > 0) {
    return <></>;
  }

  // If there are no sources, render the card
  return (
    <EmptyStateDashboard
      title="You don't have any data sources"
      subtitle="To build a live app, you will need to add your own data. Get started by clicking the button below."
      graphic={
        "https://res.cloudinary.com/frontly/image/upload/v1716245131/Graphs_y2esoe.svg"
      }
      buttonText="Add Data Source"
      onClick={() => navigate("/spreadsheets")}
    />
  );
};

export default DataSourcesCard;
