import { get, startCase } from "lodash";
import { operatorLabelMap, operators } from "app/utils/utils";

import { MultiForm } from "app/adminApp/components";
import mixpanel from "mixpanel-browser";
import useActiveBlockSheet from "app/utils/useActiveBlockSheet";
import { useState } from "react";

const getLabel = (filter) => {
  if (filter.key) {
    const key = startCase(filter.key);
    const operator = get(filter, "operator", "equals");

    if (operator.includes("date")) {
      return startCase(`${key} ${startCase(operator.replace("date_", ""))}`);
    }

    return `${key} ${startCase(operator)}`;
  }

  return "New Visible Filter";
};

export const getOperators = (componentId, isDataBase = false) => {
  // Initialize operators
  let finalOperators = [...operators];

  if (isDataBase) {
    finalOperators = [
      "equals",
      "does_not_equal",
      "greater_than",
      "less_than",
      "contains",
    ];
  }

  // Remove date operators if not DateTimePicker or DateRangePicker
  if (!["DateTimePicker", "DateRangePicker"].includes(componentId)) {
    finalOperators = finalOperators.filter(
      (o) =>
        !["date_equals", "date_after", "date_before", "date_in_range"].includes(
          o
        )
    );
  }

  // DateTimePicker
  else if (componentId === "DateTimePicker") {
    finalOperators = ["date_equals", "date_after", "date_before"];
  }

  // DateRangePicker
  else if (componentId === "DateRangePicker") {
    finalOperators = [];
  }

  return finalOperators;
};

const getVisibleFilterFields = (activeFilter, isDataBase = false) => {
  return [
    {
      key: "label",
      label: "Label",
      hint: "A custom visual label",
      componentId: "Input",
    },
    {
      key: "key",
      label: "Column",
      hint: "Which column to use for matching the row",
      componentId: "SpreadsheetColumnSelect",
    },
    {
      key: "componentId",
      label: "Component",
      hint: "The type of input field for users to filter their data with",
      componentId: "Select",
      defaultValue: "Input",
      hideEmptyItem: true,
      options: [
        { label: "Input", value: "Input" },
        { label: "Numeric Range", value: "NumericRange" },
        { label: "True/False", value: "Boolean" },
        { label: "Select Dropdown", value: "Select" },
        { label: "Multi Select", value: "MultiSelect" },
        { label: "Date", value: "DateTimePicker" },
        { label: "Date Range Picker", value: "DateRangePicker" },
      ],
      displayCondition: (f) => get(f, "key"),
    },
    {
      key: "operator",
      label: "Operator",
      componentId: "Select",
      hideEmptyItem: true,
      defaultValue: "equals",
      options: getOperators(
        get(activeFilter, "componentId", "Input"),
        isDataBase
      ).map((o) => ({
        label: get(operatorLabelMap, o),
        value: o,
      })),
      displayCondition: (f) =>
        get(f, "key") &&
        get(f, "componentId") &&
        !["DateRangePicker", "Boolean", "NumericRange"].includes(
          get(f, "componentId")
        ),
    },
    {
      key: "defaultValue",
      label: "Default Value",
      componentId: "DynamicString",
      displayCondition: (f) =>
        !["DateRangePicker", "NumericRange"].includes(get(f, "componentId")),
    },
    {
      key: "selectSorting",
      label: "Sort Options",
      componentId: "Select",
      requiresSheet: true,
      hideEmptyItem: true,
      options: [
        { label: "None", value: null },
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
      advanced: true,
      displayCondition: (f) =>
        ["Select", "MultiSelect"].includes(get(f, "componentId")),
    },
    {
      key: "preventAutoGeneratedOptions",
      label: "Prevent Auto Generated Options",
      hint: "By default, Frontly will show all valid options from the live data as select options. Check this box to prevent that.",
      componentId: "Switch",
      requiresSheet: true,
      advanced: true,
      displayCondition: (f) =>
        ["Select", "MultiSelect"].includes(get(f, "componentId")),
    },
    {
      key: "useDataSourceOptions",
      label: "Use Data Source Options",
      hint: "Enable this setting to use the options from the data source instead of defining the options here.",
      componentId: "Switch",
      requiresSheet: true,
      advanced: true,
      displayCondition: (f) =>
        ["Select", "MultiSelect"].includes(get(f, "componentId")),
    },
    {
      key: "options",
      label: "Options",
      componentId: "DataGrid",
      orientation: "vertical",
      hint: "Define the options to appear in your select dropdown.",
      requiresSheet: true,
      newObject: { label: "Option 1", value: "option_1" },
      orientation: "vertical",
      columns: [
        {
          key: "label",
          componentId: "Input",
        },
        {
          key: "value",
          componentId: "Input",
        },
      ],
      displayCondition: (f) =>
        !get(f, "useDataSourceOptions") &&
        ["Select", "MultiSelect"].includes(get(f, "componentId")),
    },
    {
      key: "icon",
      label: "Icon",
      componentId: "IconBrowser",
      displayCondition: (f) => get(f, "key") && get(f, "componentId"),
    },
  ].filter(
    (f) =>
      !f.displayCondition ||
      (f.displayCondition && f.displayCondition(activeFilter))
  );
};

const VisibleFiltersConfig = ({ data }) => {
  const activeBlockSheet = useActiveBlockSheet();

  const isDataBase = get(activeBlockSheet, "service") === "supabase";

  const filters = get(data, "value", []);

  const [activeFilterIndex, setActiveFilterIndex] = useState(null);

  const activeFilter = filters.find((f, i) => i === activeFilterIndex);

  const fields = getVisibleFilterFields(activeFilter, isDataBase);

  return (
    <MultiForm
      data={{
        label: "Filters",
        width: "330px",
        orientation: "vertical",
        labelSingular: "Visible Filter",
        componentId: "MultiForm",
        value: filters,
        fields,
        newObject: { componentId: "Input" },
        getItemLabel: (f) => getLabel(f),
        itemClick: (i) => setActiveFilterIndex(i),
        onChange: (newFilters) => {
          data.onChange(newFilters);
          mixpanel.track("Add Visible Filter");
        },
      }}
    />
  );
};

export default VisibleFiltersConfig;
