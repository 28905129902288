import * as React from "react";

import DateTimePicker from "./DateTimePicker";

const DateRangePicker = ({ data }) => {
  return (
    <DateTimePicker
      data={{
        ...data,
        disableTime: true,
        isRange: true,
      }}
    />
  );
};

export default DateRangePicker;
