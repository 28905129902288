import { colors, spacing } from "app/utils/theme";

import { Button } from "app/components";
import { frontlyLogoDark } from "app/utils/utils";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Logo = styled.img`
  height: 40px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px 24px 32px;
`;
const HeaderLink = styled.div`
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: ${colors.grey4};

  ${(p) =>
    p.hideMobile &&
    `
    @media screen and (max-width: 800px) {
      display: none;
    }
  `}
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.s8};
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.s8};
`;

export const Header = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderLeft>
        <Logo
          alt="Frontly Logo"
          src={frontlyLogoDark}
          onClick={() => navigate("/")}
        />
      </HeaderLeft>
      <HeaderRight>
        <HeaderLink hideMobile onClick={() => navigate("/app_templates")}>
          Templates
        </HeaderLink>
        <HeaderLink
          hideMobile
          onClick={() => window.open("https://www.frontly.ai/pricing")}
        >
          Pricing
        </HeaderLink>
        <HeaderLink
          hideMobile
          onClick={() => window.open("https://help.frontly.ai")}
        >
          Help Center
        </HeaderLink>
        <HeaderLink hideMobile onClick={() => navigate("/login")}>
          Login
        </HeaderLink>
        <Button
          data={{
            text: "Start for free",
            onClick: () => navigate("/signup"),
          }}
        />
      </HeaderRight>
    </HeaderContainer>
  );
};
