import { useEffect, useState } from "react";
import { isFrontlyAdmin } from "app/utils/utils";

const Marker = ({ map, position, onClick }) => {
  const [marker, markerSet] = useState(null);

  useEffect(() => {
    // add marker
    if (!marker) {
      const m = new window.google.maps.Marker();

      // Add custom action
        if (!isFrontlyAdmin) {
          m.addListener("click", () => {
            onClick()
          });
        }

      markerSet(m);
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
        markerSet(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker && map) {
      marker.setMap(map);
      marker.setPosition(position);
    }
  }, [marker, map, position]);

  return null;
};

export default Marker;
