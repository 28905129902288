import { Button, Text } from "app/components";
import { get, isEmpty } from "lodash";
import { rApp, rPages } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { AdminForm } from "../components";
import SettingsSection from "../settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const UserGroups = () => {
  const [app, setApp] = useRecoilState(rApp);

  const [userGroupChanges, setUserGroupChanges] = useState(false);
  const [pagePermissionChanges, setPagePermissionChanges] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const userGroups = get(app, "user_groups", []) || [];
  const pages = useRecoilValue(rPages);

  const savePagePermissionChanges = () => {
    setPagePermissionChanges({});
    successNotification("Page Permissions Saved");
    apiRequest.post("/page_permission_changes/", pagePermissionChanges);
  };

  const save = async () => {
    setIsSaving(true);
    setUserGroupChanges(false);
    successNotification("User Groups Saved");
    await apiRequest.post("/app_settings/", {
      user_groups: userGroups,
    });
    setIsSaving(false);
  };

  const fieldKeys = [
    {
      key: "name",
      label: "Group Name",
      placeholder: "Group Name",
      componentId: "Input",
      width: "100%",
    },
    {
      key: "defaultPage",
      label: "Default Page",
      placeholder: "Default Page",
      componentId: "Select",
      options: pages.map((p) => ({ label: p.name, value: p.id })),
      width: "100%",
    },
    {
      id: "conditions",
      label: "Conditions",
      componentId: "DisplayConditions",
      orientation: "vertical",
    },
  ];

  const fields = [
    {
      id: "user_groups",
      label: "User Groups",
      componentId: "MultiForm",
      labelSingular: "User Group",
      width: "300px",
      getItemLabel: (item) => get(item, "name", "Group Name"),
      value: userGroups,
      fields: fieldKeys,
    },
  ];

  return (
    <SettingsSection
      title="User Groups"
      description="Create user groups for custom page permissions"
    >
      <Text
        data={{
          text: "User Groups",
          fontStyle: "headingMd",
        }}
      />
      <Text
        data={{
          text: "Create groups to manage user permissions.",
          fontStyle: "bodyMd",
          margin: "5px 0 20px 0",
        }}
      />
      <Text
        data={{
          text: "Click to learn how to use Users & Permissions",
          onClick: () =>
            window.open(
              "https://help.frontly.ai/en/articles/7982162-users-permissions"
            ),
          fontStyle: "headingSm",
          color: colors.primary,
          margin: "0 0 15px 0",
        }}
      />
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="5px"
        fields={fields}
        submitText={"Save Changes"}
        submit={!isEmpty(userGroupChanges) && save}
        width="220px"
        isFetching={isSaving}
        onChange={(k, v) => {
          setUserGroupChanges(true);
          setApp({
            user_groups: v,
          });
        }}
      />
      {userGroupChanges && (
        <Button
          data={{
            margin: "20px 0 0 0",
            text: "Save",
            onClick: save,
          }}
        />
      )}

      <Text
        data={{
          text: "Page Permissions",
          fontStyle: "headingMd",
          margin: "30px 0 0 0",
        }}
      />
      <Text
        data={{
          text: "Manage which pages are visible to each user group.",
          fontStyle: "bodyMd",
          margin: "5px 0 20px 0",
        }}
      />
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="5px"
        fields={[
          {
            id: "pageUserGroups",
            componentId: "PageUserGroups",
            orientation: "vertical",
          },
        ]}
        submitText={"Save Changes"}
        submit={!isEmpty(pagePermissionChanges) && savePagePermissionChanges}
        orientation={"horizontal"}
        width="180px"
        onChange={(k, v) => {
          const { pageId, newUserGroups } = v;
          setPagePermissionChanges({
            ...pagePermissionChanges,
            [pageId]: newUserGroups,
          });
        }}
      />
    </SettingsSection>
  );
};

export default UserGroups;
