import { get } from "lodash";
import { parseNumber } from "app/utils/utils";

const mixpanelColors = [
  "hsla(252, 100%, 67%, 1)",
  "hsla(11, 100%, 67%, 1)",
  "hsla(175, 62%, 69%, 1)",
  "hsla(41, 93%, 60%, 1)",
  "hsla(354, 37%, 52%, 1)",
  "hsla(205, 86%, 70%, 1)",
  "hsla(194, 85%, 34%, 1)",
  "hsla(151, 48%, 45%, 1)",
  "hsla(7, 97%, 85%, 1)",
];

export const defaultScheme = [
  "#003f5c",
  // "#374c80",
  "#7a5195",
  // "#bc5090",
  "#ef5675",
  // "#ff764a",
  "#ffa600",
];

const blues = [
  "#003a5c",
  // "#004d70",
  "#006183",
  // "#007594",
  "#008aa4",
  // "#009fb2",
  "#00b5be",
];

const flower = [
  "#ffe926",
  "#ffbe29",
  "#ff9442",
  "#ff6d5c",
  "#f34e73",
  "#cb3d85",
  "#993a8e",
];

const greens = [
  "#216d54",
  "#33886b",
  "#45a482",
  "#58c09b",
  "#6bdeb4",
  "#7efcce",
];

export const colorSchemes = {
  1: defaultScheme,
  2: blues,
  3: greens,
  4: flower,
  5: mixpanelColors,
};

export const getColor = (index, colorScheme = 5, customColorScheme = null) => {
  if (customColorScheme && customColorScheme.length > 0) {
    return get(customColorScheme, index % customColorScheme.length);
  }

  const matchingColors = get(colorSchemes, colorScheme);
  return get(matchingColors, index % matchingColors.length);
};

export const groupBy = (arr, groupByKey, valueKey) => {
  // First, group by 'groupByKey' and compute sum and count for each group
  const grouped = arr.reduce((acc, curr) => {
    if (!acc[curr[groupByKey]]) {
      acc[curr[groupByKey]] = {
        key: curr[groupByKey],
        sum: 0,
        count: 0,
      };
    }
    acc[curr[groupByKey]].sum += parseNumber(get(curr, valueKey));
    acc[curr[groupByKey]].count += 1;
    return acc;
  }, {});

  // Then, calculate total sum for percent calculation
  const totalSum = Object.values(grouped).reduce(
    (acc, curr) => acc + curr.sum,
    0
  );

  // Transform the grouped object into an array and compute average and percent
  const result = Object.values(grouped).map((group) => {
    group.average = group.sum / group.count;
    group.percent = (group.sum / totalSum) * 100;
    return group;
  });

  return result;
};
