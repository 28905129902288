import { colors, hslColor } from "app/utils/theme";
import { get, isArray } from "lodash";

import styled from "styled-components";

export const getColorBadgeRowValues = (columns, rows) => {
  const colorBadgeColumns = columns
    .filter((c) => c.columnType === "badge")
    .map((c) => c.key);

  // GET UNIQUE COLOR BADGE COLORS BASED ON VALUES
  let uniqueValuesObject = {};
  // TODO - I think we might need to actually use the valuepath here instead of just key
  rows &&
    isArray(rows) &&
    rows.forEach((r) => {
      colorBadgeColumns.forEach((c) => {
        let arr = get(uniqueValuesObject, c, []);
        const val = get(r, c);
        if (!arr.includes(val)) {
          arr.push(val);
        }
        uniqueValuesObject[c] = arr;
      });
    });

  return uniqueValuesObject;
};

export const getUniqueBadgeColors = (values) => {
  const len = values.length;
  let colorSize = parseInt(360 / len);

  if (colorSize > 80) {
    colorSize = 60;
  }

  let colors = {};
  values &&
    values.forEach((v, i) => {
      const hue = colorSize * (i + 1);
      colors[v] = hslColor(hue, 90, 85);
    });
  return colors;
};

const BadgeContainer = styled.div`
  color: black;
  background: ${(p) => p.background || colors.grey2};
  border-radius: 12px;
  font-weight: 400;
  padding: 4px 8px 4px 8px;
  font-size: 12px;
  width: fit-content;
  block-size: fit-content;
`;

const Badge = ({ color, value }) => {
  return <BadgeContainer background={color}>{value}</BadgeContainer>;
};

export default Badge;
