import { AdminForm, Tile } from "../../components";
import { Button, Icon, Modal, Row, Text } from "app/components";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rApps } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const DemoAppsEditor = ({ data }) => {
  const [edit, setEdit] = useState(false);

  const { setProfile, profile } = data;

  const apps = useRecoilValue(rApps);

  const demoApps = get(profile, "demo_apps", []);

  const deleteDemoApp = (index) => {
    setProfile({
      ...profile,
      demo_apps: demoApps.filter((d, i) => i !== index),
    });
  };

  const addDemoApp = () => {
    setProfile({
      ...profile,
      demo_apps: [...demoApps, {}],
    });
  };

  const updateDemoApp = (index, k, v) => {
    setProfile({
      ...profile,
      demo_apps: demoApps.map((d, i) => {
        if (index === i) {
          return { ...d, [k]: v };
        }
        return d;
      }),
    });
  };

  const appOptions = apps.map((app) => ({ value: app.id, label: app.name }));

  return (
    <>
      {edit && (
        <Modal
          header={{ title: "Add Previous Work To Your Profile" }}
          hide={() => setEdit(false)}
        >
          <DemoApps>
            {demoApps.map((demoApp, i) => {
              const projectType = get(demoApp, "type", "frontlyApp");

              return (
                <DemoAppWrapper>
                  <Row
                    alignItems="center"
                    justifyContent="space-between"
                    margin="0 0 15px 0"
                  >
                    <Text
                      data={{
                        text: `Project ${i + 1}`,
                        fontStyle: "headingLg",
                      }}
                    />
                    <Icon
                      data={{
                        icon: "FiTrash",
                        color: colors.grey3,
                        size: 20,
                        hover: true,
                        onClick: () => deleteDemoApp(i),
                      }}
                    />
                  </Row>
                  <AdminForm
                    sectionPadding={"0px"}
                    labelStyle="headingSm"
                    fields={[
                      {
                        id: "type",
                        label: "Type",
                        componentId: "Select",
                        hideEmptyItem: true,
                        options: [
                          {
                            label: "Frontly Application",
                            value: "frontlyApp",
                          },
                          {
                            label: "Other Project",
                            value: "other",
                          },
                        ],
                        value: get(demoApp, "type"),
                        defaultValue: "frontlyApp",
                      },
                      {
                        id: "id",
                        label: "App",
                        componentId: "Select",
                        hint: "Select a live Frontly app to showcase on your profile. Make sure it's public so users can view it.",
                        options: appOptions,
                        value: get(demoApp, "id"),
                        displayCondition: () => projectType === "frontlyApp",
                      },
                      {
                        id: "name",
                        label: "Name",
                        componentId: "Input",
                        hint: "A visual label for the project.",
                        value: get(demoApp, "name"),
                        displayCondition: () => projectType === "other",
                      },
                      {
                        id: "link",
                        label: "Link (optional)",
                        componentId: "Input",
                        hint: "A link to an external project.",
                        value: get(demoApp, "link"),
                        displayCondition: () => projectType === "other",
                      },
                      {
                        id: "image",
                        label: "Image",
                        componentId: "ImageUpload",
                        value: get(demoApp, "image"),
                      },
                      {
                        id: "description",
                        label: "Short Description",
                        componentId: "Input",
                        value: get(demoApp, "description"),
                      },
                      {
                        id: "fullDescription",
                        label: "Full Description",
                        componentId: "TextArea",
                        value: get(demoApp, "fullDescription"),
                        minHeight: "150px",
                      },
                    ].filter(
                      (f) =>
                        !f.displayCondition ||
                        (f.displayCondition && f.displayCondition())
                    )}
                    onChange={(k, v) => updateDemoApp(i, k, v)}
                  />
                </DemoAppWrapper>
              );
            })}
          </DemoApps>
          <Row alignItems="center" gap="15px" margin="20px 0 0 0">
            <Button
              data={{
                text: "Save Changes",
                onClick: () => setEdit(false),
              }}
            />
            <Button
              data={{
                text: "Add Content",
                icon: "FiPlus",
                onClick: addDemoApp,
                type: "basic",
              }}
            />
          </Row>
        </Modal>
      )}
      <Tile
        icon="FiEdit"
        label={"Edit Previous Work"}
        onClick={() => setEdit(true)}
      />
    </>
  );
};

export default DemoAppsEditor;

const DemoApps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
`;

const DemoAppWrapper = styled.div`
  border: 1px solid ${colors.grey2};
  background: ${colors.grey1};
  border-radius: 10px;
  padding: 20px;
`;
