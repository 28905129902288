import { rApp, rPage } from "app/utils/recoil";

import AdminForm from "./AdminForm";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useSetPage from "app/utils/useSetPage";
import { useState } from "react";
import useUtils from "app/renderingApp/useUtils";

const ZapConfig = ({ data }) => {
  const [changes, setChanges] = useState(false);
  const { processDynamicText } = useUtils();

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const { setPage } = useSetPage();

  const activeApp = useRecoilValue(rApp);

  const step = get(data, "step", {});
  const actionId = get(data, "actionId");
  const actions = get(page, "actions", []);
  const action = actions.find((a) => a.id === actionId);
  const zaps = get(activeApp, "zaps", []);

  const save = () => {
    setChanges(false);

    let customData = {};
    get(step, "customData", []).forEach((d) => {
      customData[d.key] = processDynamicText({ text: d.value });
    });

    apiRequest.post("/zapier/edit/", {
      id: step.zap,
      custom_data: customData,
    });
  };

  return (
    <AdminForm
      sectionPadding="0px"
      onChange={(k, v) => {
        setChanges(true);
        setPage({
          ...page,
          actions: actions.map((a) => {
            if (a.id === actionId) {
              return {
                ...a,
                steps: get(action, "steps", []).map((s) => {
                  if (s.id === step.id) {
                    return { ...s, [k]: v };
                  }
                  return s;
                }),
              };
            }
            return a;
          }),
        });
      }}
      fields={[
        {
          id: "zap",
          label: "Zap",
          componentId: "Select",
          value: get(step, "zap"),
          options: zaps.map((z) => ({ label: z.id, value: z.id })),
        },
        {
          id: "customData",
          label: "Custom Data",
          componentId: "DataGrid",
          orientation: "vertical",
          hint: "Each field will be passed to Zapier when this action is triggered",
          value: get(step, "customData"),
          columns: [
            {
              key: "key",
              componentId: "Input",
            },
            {
              key: "value",
              componentId: "DynamicString",
            },
          ],
        },
      ]}
      disableSubmit={!changes}
      submit={save}
      submitText={"Save Zap Data"}
    />
  );
};

export default ZapConfig;
