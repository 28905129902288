import "react-quill/dist/quill.snow.css";

import { useMemo, useState } from "react";

import { Modal } from "app/components";
import ReactQuill from "react-quill";
import { Tile } from "..";
import { colors } from "app/utils/theme";

const RichTextEditor = ({ data }) => {
  const modules = useMemo(
    () => ({
      clipboard: {
        matchVisual: false,
      },
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "strike",
          "underline",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          {
            color: [
              "#000000",
              "#FFFFFF",
              "#808080",
              "#FF0000",
              "#0000FF",
              "#008000",
              "#FFFF00",
              "#800080",
              "#00FFFF",
              "#FFA500",
            ],
          },
        ],
      ],
    }),
    []
  );

  const formats = useMemo(
    () => [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "strike",
      "underline",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      // "image",
      "color",
    ],
    []
  );

  const handleChange = (text) => {
    data?.onChange(text);
  };

  const [edit, setEdit] = useState(false);

  return (
    <>
      {edit && (
        <Modal header={{ title: "Edit Rich Text" }} hide={() => setEdit(false)}>
          <style>
            {/* Overriding React Quill CSS */}
            {`
              .ql-toolbar.ql-snow {
                font-family: "Roboto", "Helvetica", "Helvetica Neue", sans-serif;
                border-radius: 4px 4px 0 0;
                border: 1px solid ${colors.grey2};
              }
              .ql-container.ql-snow {
                border: 1px solid ${colors.grey2};
                border-radius: 0 0 4px 4px;
              }
              .ql-toolbar.ql-snow .ql-picker-label {
                border: 1px solid ${colors.grey2};
                border-radius: 4px;
              }
              .ql-snow .ql-picker {
                color: ${colors.default};
              }
              .ql-container, .ql-container > .ql-editor, .ql-snow, .ql-container.ql-snow {
                line-height: initial;
                font-size: initial;
              }
              .ql-formats { margin: 0px !important; }
            `}
          </style>
          <ReactQuill
            id="editor"
            modules={modules}
            formats={formats}
            value={data.value}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={handleChange}
            placeholder={data.placeholder || "Enter your text..."}
          />
        </Modal>
      )}
      <Tile
        icon="FiEdit"
        label={`Edit ${data.label || "Rich Text"}`}
        onClick={() => setEdit(true)}
      />
    </>
  );
};

export default RichTextEditor;
