import { rDarkMode, rFormState, rFormValidation } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { Select as SelectComponent } from "app/components";
import { get } from "lodash";
import { getInputStyles } from "app/utils/inputUtils";
import { isFrontlyAdmin } from "app/utils/utils";
import useUtils from "../useUtils";

const Select = ({ page, block }) => {
  const darkMode = useRecoilValue(rDarkMode);
  const [formState, setFormState] = useRecoilState(rFormState);
  const { processDynamicText } = useUtils();

  const formValidation = useRecoilValue(rFormValidation);
  const validationError = get(formValidation, block.key, null);

  const fieldId = get(block, "id");

  const concatId = `${page.id}-${fieldId}`;

  const allowSearch = get(block, "allowSearch", true);

  const onChange = (v) => {
    setFormState((prev) => ({
      ...prev,
      [concatId]: v,
    }));
  };

  const value = get(
    formState,
    concatId,
    processDynamicText({ text: get(block, "defaultValue", "") })
  );
  const options = get(block, "options", []);

  return (
    <SelectComponent
      data={{
        onChange,
        options,
        value,
        selectText: processDynamicText({ text: get(block, "selectText") }),
        ...getInputStyles(block, validationError, darkMode),
        disabled: isFrontlyAdmin,
        allowSearch,
      }}
    />
  );
};

export default Select;
