import { Button, Icon, Modal, Row, Spinner, Text } from "app/components";
import { boxShadow, colors, spacing } from "app/utils/theme";
import { rSetConfig, rUser } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Template } from "../appManagement/Template";
import TemplateModal from "./TemplateModal";
import { copyApp } from "../home/duplicateUtils";
import { demoApps } from "../appManagement/Templates";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateModal = ({ hide, showNewApp, defaultType = null }) => {
  const [type, setType] = useState(defaultType);
  const navigate = useNavigate();

  const user = useRecoilValue(rUser);

  const setConfig = useSetRecoilState(rSetConfig);

  const [copyingStatus, setCopyingStatus] = useState(false);
  const [copyAppSubdomain, setCopyAppSubdomain] = useState(null);
  const [activeTemplateId, setActiveTemplateId] = useState(null);

  const createDemoApp = async (subdomain, type) => {
    setCopyAppSubdomain(null);
    try {
      const newAppData = await copyApp(subdomain, setCopyingStatus, type);
      // TODO - HANDLE SET FULL APP CONFIG
      setConfig(newAppData);

      successNotification("App Created");
      navigate("/pages");
      localStorage.setItem("appId", get(newAppData, ["app", "id"]));
      mixpanel.track("Demo App Created", { template: subdomain });
    } catch (error) {
      console.error("Error creating demo app:", error);
      // Handle error accordingly. Maybe set an error state or show a notification to the user.
    }
  };

  if (copyAppSubdomain) {
    return (
      <Modal
        hide={() => setCopyAppSubdomain(null)}
        minWidth="600px"
        header={{
          title: "Install Template",
          description:
            "Do you want a brand-new app, or do you want the spreadsheets and pages to copy into your current app?",
        }}
      >
        <Row gap={"15px"} margin="10px 0 0 0">
          <Button
            data={{
              text: "Create New App",
              onClick: () => createDemoApp(copyAppSubdomain, "new"),
            }}
          />
          <Button
            data={{
              text: "Copy Content Into Current App",
              onClick: () => createDemoApp(copyAppSubdomain, "current"),
            }}
          />
        </Row>
      </Modal>
    );
  }

  if (activeTemplateId) {
    return (
      <TemplateModal
        activeTemplateId={activeTemplateId}
        setActiveTemplateId={setActiveTemplateId}
      />
    );
  }

  return (
    <Modal
      minWidth="900px"
      header={{
        title: copyingStatus
          ? `${copyingStatus}...`
          : get(
              {
                app: "How do you want to create your app?",
                page: "How do you want to create your page?",
              },
              type,
              "What do you want to create?"
            ),
      }}
      hide={hide}
    >
      {copyingStatus && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <Spinner color={colors.primary} />
        </div>
      )}

      {!copyingStatus && (
        <>
          {!type && (
            <Row gap="15px">
              <CreateOption
                title="New App"
                icon="FiGrid"
                description={
                  user.can_create_apps
                    ? "Create a separate app with its own website domain, pages and sheets"
                    : "This setting is disabled by the account owner"
                }
                onClick={() => setType("app")}
                disabled={!user.can_create_apps}
              />
              <CreateOption
                title="New Page"
                description="Create a new page in the current app"
                icon="FiFile"
                onClick={() => setType("page")}
              />
            </Row>
          )}
          {type === "app" && (
            <div>
              {!activeTemplateId && (
                <Row gap="15px">
                  <CreateOption
                    title="Create With AI"
                    icon="HiSparkles"
                    description="Use our AI-powered chat flow to generate a new app with text prompts"
                    onClick={() => navigate("/chat/new")}
                  />
                  <CreateOption
                    title="Create From Scratch"
                    description="Manually add pages and sheets to create a new app from scratch"
                    icon="FiFile"
                    onClick={showNewApp}
                  />
                </Row>
              )}

              <Text
                data={{
                  text: "Or Select A Template:",
                  fontStyle: "headingMd",
                  margin: "5px 0 15px 0",
                }}
              />
              <Templates>
                {demoApps.map((app) => (
                  <Template
                    small
                    data={app}
                    onClick={() => setActiveTemplateId(app.key)}
                  />
                ))}
              </Templates>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default CreateModal;

export const CreateOption = ({
  title,
  description,
  icon,
  onClick,
  disabled = false,
}) => {
  return (
    <CreateOptionWrapper
      disabled={disabled}
      onClick={disabled ? null : onClick}
      style={{ margin: "0 0 15px 0", opacity: disabled ? "0.6" : 1.0 }}
    >
      <Icon data={{ icon, color: colors.primary, disabled }} />
      <Text
        data={{
          text: title,
          disabled,
          fontStyle: "headingLg",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      />
      <Text
        data={{
          text: description,
          fontStyle: "bodyMd",
          cursor: disabled ? "not-allowed" : "pointer",
          color: colors.grey3,
          disabled,
          textAlign: "center",
        }}
      />
    </CreateOptionWrapper>
  );
};

const CreateOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  align-self: stretch;
  border: 1px solid ${colors.pitchBorder};
  background: white;
  padding: 20px;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}
  &:hover {
    box-shadow: ${boxShadow.card};
  }
`;

const Templates = styled.div`
  display: grid;
  grid-gap: ${spacing.s8};
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(261px, 1fr));
  }
`;
