import { Modal, Spinner } from "app/components";

import { AdminForm } from ".";
import { get } from "lodash";
import { rRefreshSheetData } from "app/utils/recoil";
import { useRecoilState } from "recoil";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import { useState } from "react";

const RefreshSheetModal = () => {
  const [refreshSheetData, setRefreshSheetData] =
    useRecoilState(rRefreshSheetData);

  const { refreshSheet } = useSpreadsheetRequests();

  const [isFetching, setIsFetching] = useState(false);

  const [data, setData] = useState({
    auto_detect_types: true,
    disable_new_fields: false,
  });

  if (!refreshSheetData) return null;

  const onChange = (k, v) => {
    setData({ ...data, [k]: v });
  };

  const refresh = async () => {
    setIsFetching(true);
    await refreshSheet({ ...refreshSheetData, refreshObject: data });
    setIsFetching(false);
    setRefreshSheetData(null);
  };

  return (
    <Modal
      header={{ title: "Refresh Fields" }}
      hide={() => setRefreshSheetData(null)}
      buttons={[
        {
          text: "Cancel",
          type: "basic",
          onClick: () => setRefreshSheetData(null),
        },
        {
          text: "Refresh",
          onClick: refresh,
        },
      ]}
    >
      {isFetching && <Spinner color={"black"} size={30} />}
      {!isFetching && (
        <AdminForm
          sectionPadding="0px"
          onChange={onChange}
          labelStyle={"headingSm"}
          fields={[
            {
              id: "auto_detect_types",
              label: "Auto Detect Types (Overwrite Current Settings)",
              componentId: "Switch",
              value: get(data, "auto_detect_types", true),
              description:
                "We'll scan your data and headers to auto-detect the most likely types and components to use and overwrite your current settings.",
            },
            {
              id: "disable_new_fields",
              label: "Disable New Fields",
              componentId: "Switch",
              value: get(data, "disable_new_fields", true),
              description:
                "If any new fields are detected, they will be disabled by default so that they don't appear throughout your app unintentionally.",
            },
          ]}
        />
      )}
    </Modal>
  );
};

export default RefreshSheetModal;
