import { CheckboxGroup, Input, Modal, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";
import {
  rDateFieldsModalData,
  rNewDataSourceModalData,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import { apiRequest } from "app/utils/apiRequests";
import mixpanel from "mixpanel-browser";
import { useState } from "react";

const parseGoogleSheetId = (string) => {
  let idStartIndex = string.indexOf("/d/") + 3;
  let idEndIndex = string.indexOf("/edit");
  return string.slice(idStartIndex, idEndIndex);
};

const Google = () => {
  const setDateFields = useSetRecoilState(rDateFieldsModalData);

  const [data, setData] = useRecoilState(rNewDataSourceModalData);

  const [errors, setErrors] = useState(null);
  const [isCreatingSheet, setIsCreatingSheet] = useState(null);
  const [isImporting, setIsImporting] = useState(false);

  // Local Data
  const [sheetId, setSheetId] = useState(null);
  const [tabs, setTabs] = useState([]);

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const isValid = () => {
    const err = {};
    if (!sheetId || !sheetId.length || !sheetId.includes("docs.google")) {
      err.spreadsheet_id = "Please enter valid link URL.";
    }
    setErrors(err);

    return isEmpty(err);
  };

  const createSheet = () => {
    if (!isValid()) return;

    setIsCreatingSheet(true);

    const validatedData = {
      title: get(data, "title"),
      spreadsheet_id: parseGoogleSheetId(sheetId),
    };

    apiRequest.post("/sheet_management/", validatedData).then((response) => {
      const newSheet = get(response, "data");
      const tabs = get(newSheet, "tabs");
      const error = get(newSheet, "error");

      if (error) {
        errorNotification(error);
        setIsCreatingSheet(false);
        return;
      }

      if (tabs && tabs.length > 0) {
        const newTabs = tabs.filter((t) => {
          const match = savedSpreadsheets.find(
            (s) => s.tab_id == t.tab_id && s.spreadsheet_id == t.spreadsheet_id
          );
          return !match;
        });

        setTabs(
          newTabs.map((tab) => ({
            ...tab,
            label: tab.tab_name,
            value: true,
          }))
        );
        setIsCreatingSheet(false);
      } else {
        setSavedSpreadsheets([...savedSpreadsheets, newSheet]);
        successNotification("Google Sheet imported");
        mixpanel.track("Import Google Sheet");
        setIsCreatingSheet(false);
        setData(null);
      }
    });
  };

  const importTabs = () => {
    setIsImporting(true);
    const selected = tabs.filter((t) => t.value);
    apiRequest.post("/import_tabs/", { tabs: selected }).then((response) => {
      const newSheet = get(response, "data");
      const tabs = get(newSheet, "spreadsheets", []);

      setSavedSpreadsheets([...savedSpreadsheets, ...tabs]);

      let dateFields = [];
      tabs.forEach((t) => {
        const config = get(t, ["field_data", "config"], {});
        Object.keys(config).forEach((k) => {
          const match = get(config, k);
          if (match.componentId === "DateTimePicker") {
            dateFields.push({
              id: t.id,
              field: k,
            });
          }
        });
      });

      // TODO - Make this data-source agnostic and build into the other field selection process
      // Instead of this being during the setup process, it should just be a new interface that
      // can be accessed any time
      // Handle forcing users to set date formats
      if (dateFields.length > 0) {
        setDateFields(dateFields);
      }

      successNotification("Import successful");
      setIsImporting(false);
      mixpanel.track("Import Google Sheet");
      setData(null);
      setTabs([]);
    });
  };

  const showTabs = tabs && tabs.length > 0;

  return (
    <Modal
      minWidth="400px"
      header={{
        title: showTabs ? "Select Sheets For Import" : "Add Google Sheet",
      }}
      buttons={
        showTabs
          ? [
              {
                text: "Import Tabs",
                onClick: importTabs,
                isFetching: isImporting,
              },
            ]
          : [
              {
                text: "Import Sheet",
                onClick: createSheet,
                isFetching: isCreatingSheet,
              },
            ]
      }
      hide={() => setData(null)}
    >
      {showTabs && (
        <>
          <Text
            data={{
              margin: "0 0 20px 0",
              text: "Each Sheet / Tab in your spreadsheet document can be imported as its own data source",
              fontStyle: "bodySm",
            }}
          />

          <CheckboxGroup
            data={{
              options: tabs,
              onChange: (index) => {
                setTabs(
                  tabs.map((t, i) => {
                    if (i === index) {
                      return { ...t, value: !t.value };
                    }
                    return t;
                  })
                );
              },
            }}
          />
        </>
      )}
      {!showTabs && (
        <>
          <Text
            data={{
              text: "Copy the full link from the address bar of your Google Spreadsheet",
              margin: "0 0 20px 0",
            }}
          />
          <Input
            data={{
              label: "Google Sheet Link",
              placeholder:
                "https://docs.google.com/spreadsheets/d/90132849012384kkjdslflkjad/edit#gid=0",
              required: true,
              width: "100%",
              value: get(data, "spreadsheet_id"),
              error: get(errors, "spreadsheet_id"),
              onChange: (value) => setSheetId(value),
            }}
          />
        </>
      )}
    </Modal>
  );
};

export default Google;
