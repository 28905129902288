import { Button, Menu } from "app/components";
import { get, startCase } from "lodash";

import AdminForm from "./AdminForm";
import { useState } from "react";

const DataSourceValidation = ({ data }) => {
  const validation = get(data, "value");
  const dataType = get(data, "dataType");

  const updateField = (key, value) => {
    data.onChange({
      ...validation,
      [key]: value,
    });
  };

  const [anchorElement, setAnchorElement] = useState(null);

  const validationsMap = {
    date: ["required"],
    integer: ["required", "minOffset", "maxOffset"],
    float: ["required", "minOffset", "maxOffset"],
    boolean: ["required", "isTrue"],
  };

  const relevantValidations = get(validationsMap, dataType, null);

  const validationFields = [
    {
      id: "required",
      label: "Required",
      componentId: "Switch",
      defaultValue: true,
      orientation: "horizontal",
      hint: "Is this field required to submit form?",
      value: get(validation, "required") === false ? false : true,
    },
    {
      id: "isEmail",
      label: "Is Email",
      componentId: "Switch",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains a valid email address",
      value: get(validation, "isEmail"),
    },
    {
      id: "isPrice",
      label: "Is Price",
      componentId: "Switch",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains a valid price. Allows $ but will be removed on save.",
      value: get(validation, "isPrice"),
    },
    {
      id: "isNumber",
      label: "Is Number",
      componentId: "Switch",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains only valid numbers",
      value: get(validation, "isNumber"),
    },
    {
      id: "isTrue",
      label: "Is True",
      componentId: "Switch",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value is true, generally used to confirm a checkbox or switch are turned 'on'.",
      value: get(validation, "isTrue"),
    },
    {
      id: "isAlphabetical",
      label: "Is Alphabetical",
      componentId: "Switch",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains only letters",
      value: get(validation, "isAlphabetical"),
    },
    {
      id: "minOffset",
      label: "Min Chars/Value",
      hint: "Enter minimum characters required or minimum number for the input to be",
      componentId: "Input",
      required: true,
      type: "number",
      defaultValue: "",
      value: get(validation, "minOffset"),
    },
    {
      id: "maxOffset",
      label: "Max Chars/Value",
      hint: "Enter maximum characters required or maximum number for the input to be",
      componentId: "Input",
      type: "number",
      required: true,
      defaultValue: "",
      value: get(validation, "maxOffset"),
    },
    {
      id: "customRegex",
      label: "Custom Regex",
      hint: "A custom regex to validate the input value. This is an advanced feature for highly technical users.",
      componentId: "Input",
      required: false,
      defaultValue: "",
      value: get(validation, "customRegex"),
    },
    {
      id: "customRegexError",
      label: "Custom Regex Error",
      hint: "An error to be displayed when the user's input doesn't meet the the custom regex requirements",
      componentId: "Input",
      required: false,
      defaultValue: "",
      value: get(validation, "customRegexError"),
    },
  ].filter((f) => {
    if (relevantValidations && !relevantValidations.includes(f.id)) {
      return false;
    }

    return true;
  });

  const validations = validationFields
    .map((field) => {
      if (field.value) {
        return field.id;
      }
    })
    .filter((field) => field !== undefined)
    .map((field) => startCase(field));

  let validationString = validations.join(", ");

  let validationText = "No Validation Rules";
  if (validations.length > 0) {
    if (validations.length > 3) {
      validationString =
        validations.slice(0, 3).join(", ") +
        ` and ${validations.length - 3} more`;
    }

    validationText = `Rules: ${validationString}`;
  }

  return (
    <div>
      <Menu
        anchorElement={anchorElement}
        hide={() => {
          setAnchorElement(null);
        }}
        label="Field Validation"
      >
        <AdminForm
          sectionPadding="0px"
          fields={validationFields}
          onChange={(k, v) => updateField(k, v)}
        />
      </Menu>
      <Button
        data={{
          text: validationText,
          type: "basic",
          onClick: (e) => setAnchorElement(e.currentTarget),
        }}
      />
    </div>
  );
};

export default DataSourceValidation;
